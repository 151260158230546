/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from "react";
import { BiSearch } from "react-icons/bi";
import "../../assets/css/generals.css";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { LANG } from "../../pages/commun/lang";
import AuthContext from "../../context/authContext";

function BackButton(props) {
	const navigate = useNavigate();
	const {lang} = useContext(AuthContext)


	useEffect(() => {
		return () => {};
	}, []);

	return (
		
			<Button
				className="btn_back transition"
				icon={<div className="icon"><HiOutlineChevronLeft size={16} /></div>}
				onClick={() => navigate(-1)}
				size="large"
			>
				{LANG.REGISTER.BACK[lang]}
			</Button>
	
	);
}

export default BackButton;
