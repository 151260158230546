import React, { useEffect, useState } from 'react'
import { BsCircle, BsRecordCircleFill } from 'react-icons/bs'
import { colors } from '../../utilities/colors'
import Renderif from '../Renderif'

export default function CheckBox({onChange, text, active, value, children}) {

    const [state, setState] = useState({
        active
    }) 

    const _onChange = () => {
        state.active = !state.active
        setState({...state})
        onChange({value:value, active:state.active})
  
    }
    return (
    <div className='d-table cursor-pointer' onClick={()=>{
        _onChange()
    }}>
        <div className='d-flex justify-content-center align-items-center p-1'>
            <Renderif isTrue={state.active}>
            <BsRecordCircleFill color={colors.mint}></BsRecordCircleFill>
            </Renderif>
            <Renderif isTrue={!state.active}>
                <BsCircle  color={colors.mint}></BsCircle>
            </Renderif>
            <div className='text-white px-1 f-Poppins-Medium non-select'>{text}</div>
            {children}
        </div>
    </div>
    )
}
CheckBox.defaultProps = {
    active:false
}
