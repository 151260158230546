/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import "../../assets/css/dashboard.css";
import "../../assets/css/antdCustom.css";
import "../../assets/css/generals.css";
import "../../assets/css/weeklyPlanScreen.css";

import HeaderDashboard from "../../components/HeaderDashboard";
import WeeklyColapse from "./WeeklyColapse";

import WeeklySegment from "./WeeklySegment";

import "antd/dist/antd.css";

import { Service } from "../../services/api";
import PlanContext from "../../context/planContext";
import GeneralButton from "../../components/buttons/GeneralButton";
import { IoMdCalendar, IoMdShuffle } from "react-icons/io";
import {  BiCommentDetail, BiSend } from "react-icons/bi";
import BackButton from "../../components/buttons/BackButton";
import { useNavigate, useParams } from "react-router-dom";
import { colors } from "../../utilities/colors";
import Renderif from "../../components/Renderif";
import { MdClose } from "react-icons/md";
import moment from "moment";
import AuthContext from "../../context/authContext";
import { BsCalendar2 } from "react-icons/bs";
import { TfiReload } from "react-icons/tfi";
import { LANG } from "../commun/lang";

function WeeklyDetail() {

	let navigate = useNavigate()

	const {id_plan} = useParams('id_plan')


	
	const {currentWeekPlan, setCurrentWeekPlanRecipes, randomizeWeekPlan, weekRecipes,setCurrentWeekPlanStatus} = useContext(PlanContext)
	const {user, lang} = useContext(AuthContext)
	const [state, setState] = useState({
		showComments:false,
		comment:"",
		comments:[]
	})

	useEffect(()=>{
		
		const data = async () => {
		
			getComments()
			if(currentWeekPlan?.status === 0){
				randomizeWeekPlan();
				return
			}
			let _week_recipes = await Service('GET', `recipes/weeklyRecipes/${currentWeekPlan ? currentWeekPlan.id : id_plan}`)
			console.log(_week_recipes);
			
			setCurrentWeekPlanRecipes(_week_recipes.data.response.week_recipes)
		}
		data()
		return () => {};	
	},[])

	const getComments = async() => {
		let _commnets = await Service('GET',`comment/${currentWeekPlan ? currentWeekPlan.id : id_plan}`);
		setState({...state, comments:_commnets.data.response});
		
	}

	const addComment = async () => {

		state.comments.push({
			...user,
			comment:state.comment,
			comment_created_at:Date.now()
		})
		
		let body = {
			comment:state.comment,
			id_plan:currentWeekPlan ? currentWeekPlan.id : id_plan,
			id_user:user.id_user
		}
		
		state.comment = ''
		setState({...state})
		let _addComment = await Service('POST', 'comment', body);
		

	}

	return (
		<>
			<HeaderDashboard />
			
			<div className="mb__center_content">

			<div className="title_section d-flex flex-wrap align-content-center mb__center_content">

				<div className="col-12 col-lg">
					<BackButton route="/plan-semanal" />

					<div className="text-white mb__section_title text-uppercase pt-2">
						{LANG.WEEKPLAN.WEEKPLAN[lang]} - {currentWeekPlan?.kitchen}
					</div>
					<div className="d-flex align-items-center">
						<BsCalendar2 color={colors.yellow}/>
						<div className="f-14 text-yellow f-Poppins-Bold px-2">{`${moment(currentWeekPlan?.start_date).format('D MMM')} - ${moment(currentWeekPlan?.end_date).format('D MMM')}`}</div>
					</div>

					<div className="mb__text_normal text-mint-shade mt-3">

						{LANG.WEEKPLAN.SELECTRECIPES[lang]}
					
						{/* Selecciona recetas manualmente o utiliza el botón{" "}
						<span className="text-yellow mb__text_normal_bold">
							Shuffle
						</span>{" "}
						para generar un plan aleatorio */}
					</div>

				</div>

				<div className="">
					<GeneralButton
						className="btn__blue btn__general mr-10"
						title="Shuffle"
						icon={<TfiReload size={18} />}
						onClick={() => randomizeWeekPlan()}
					/>
					<GeneralButton
						className="btn__blue btn__general  mr-10"
						title={LANG.WEEKPLAN.COMMENT[lang]}
						onClick= {()=>{
							setState({...state, showComments:true})
						}}
						icon={<BiCommentDetail />}
					/>
					<GeneralButton
						className="btn__blue btn__general"
						title={LANG.WEEKPLAN.VIEWFULL[lang]}
						icon={<IoMdCalendar />}
						onClick={() => navigate(`/plan-full/${id_plan}`)}
					/>
				</div>

			
			</div>
			</div>

			<div className="mb__center_content">
				<div className="d-flex flex-wrap mb__center_content">
					<div className="col-12 col-lg-9">
						<WeeklySegment />
					</div>
					<div className="col-12 col-lg-3 p-0 ps-lg-2">
						<WeeklyColapse />
					</div>
				
				
				
				</div>
			</div>
			<Renderif isTrue={state.showComments}>
				<div className="d-flex position-fixed vw-100 vh-100" style={{
					top: 0,
					right: 0,
					zIndex: 1,
					}}>

					<div className='col-6 col-lg-9 bg-overlay' onClick={()=>{setState({...state, showComments:false})}}></div> 
					<div className='col-6 col-lg-3'>

						<div className=' bg-dark-blue border-l-mint h-100 overflow-auto hide-scroll'>

						<div className='d-flex'>
							<div className='col'></div>
							<div className='cursor-pointer p-2' onClick={()=>{setState({...state, showComments:false})}}>
							<MdClose size={32} color={colors.white}></MdClose>
							</div>
						</div>

						<div className='f-30 f-anton text-white px-3 border-b-blue-shade'>{LANG.WEEKPLAN.COMMENTS[lang]}</div>

						{state.comments.map((comment,i)=>{
							
						
							return (
								<div key={i} className=" border-b-blue-shade px-3 py-3">
									<div className="d-flex ">
										<div className="f-14 f-Poppins-Bold text-yellow">{comment?.firstname}</div>
									
										<div className="col"></div>
										{/* <div className="text-white">{new Date(comment?.comment_created_at).getDate()}</div> */}
										{/* <div className="text-white">{new Date(comment.comment_created_at)}</div> */}
										<div className="f-12 f-Poppins-Medium text-blue-shade">{moment(new Date(parseInt(comment?.comment_created_at))).format("D MMM")}</div>
									</div>
									<div className="f-12 f-Poppins-Medium text-blue-shade">{LANG.PROFILE[comment.rol][lang]}</div>
									<div className="f-14 f-Poppins-Medium text-light-gray">{comment.comment} </div>
								</div>
							) 
						})}


					

					

						
						<div className="px-3 py-3">
							<textarea value={state.comment} onChange={(e)=>{setState({...state, comment:e.currentTarget.value})}} rows={5} cols={5} className="textarea"></textarea>

							<div className="d-flex justify-content-end">
							<GeneralButton
								className="btn__blue btn__general"
								title={LANG.WEEKPLAN.SEND[lang]}
								
								icon={<BiSend />}
								onClick={() => {

									addComment();
									
								}}
							/>
							</div>
						
						</div>

						
						</div>
					
					</div>

					



				
				</div>
			</Renderif>
		</>
	);
}

export default WeeklyDetail;
