/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import "../assets/css/dashboard.css";
import "../assets/css/mainMenu.css";
import logo from "../assets/images/logos/logo-miobio.png";
import { Link, Redirect, Navigate, useNavigate, useRoutes } from "react-router-dom";
import { FiHome, FiLogOut } from "react-icons/fi";
import { AiOutlineUser, AiOutlineUsergroupAdd } from "react-icons/ai";
import { FaRegAddressCard, FaShippingFast } from "react-icons/fa";
import { MdOutlineCollectionsBookmark, MdTimer } from "react-icons/md";
import { BsCalendarMinus } from "react-icons/bs";
import { TbQrcode } from "react-icons/tb";
import { CgAddR } from "react-icons/cg";
import { mainMenu } from "./menuItems";
import { Layout } from "antd";
import AuthContext from "../context/authContext";
import { HiDocumentText } from "react-icons/hi";
import { Service } from "../services/api";



const { Sider, Content } = Layout;

function MainMenu() {
	const navigate = useNavigate();
	
	const {user, setUser, lang} = useContext(AuthContext)

	const [state, setState] = useState({
		page_active:'dashboard',
		nutricionist:null,
		menu:null

	})

	const getLang = () => {
		console.log(localStorage.getItem('lang'))
	}

	

	useEffect(() => {
	
		const data = async () => {
			
			let _nutricionist
			if(user?.id_rol === 2){
				_nutricionist = await Service('GET', `nutricionist/${user.id_user}`);
				console.log(_nutricionist)
			}
			setState({
				...state,
				nutricionist:_nutricionist?.data?.response[0],
				menu:mainMenu().filter((item) => item.rol === user?.id_rol)[0]?.menu
				
			});
		};
		data();
		return () => {};
	}, [user]);

	useEffect(() => {
		
	
		const update = async () => {
			
			setState({
				...state,
				menu:mainMenu().filter((item) => item.rol === user?.id_rol)[0]?.menu
				
			});
		};
		update();
		return () => {};
	}, [lang]);




	const selectMenu = (page) => {
		setState({...state, page_active:page === "logout" ? 'dashboard' : page});


		if (page === "logout") {
			localStorage.removeItem('user')
			setUser(null);
			navigate(`/`);
		} else {
			navigate(`/${page}`);
		}
	};

	const getIcon = (icon) => {
		switch (icon) {
			case "FiHome":
				return <FiHome className="M-icon" />;
			case "AiOutlineUser":
				return <AiOutlineUser className="M-icon" />;
			case "FaRegAddressCard":
				return <FaRegAddressCard className="M-icon" />;

			case "MdOutlineCollectionsBookmark":
				return <MdOutlineCollectionsBookmark className="M-icon" />;

			case "AiOutlineUsergroupAdd":
				return <AiOutlineUsergroupAdd className="M-icon" />;

			case "TbQrcode":
				return <TbQrcode className="M-icon" />;
			case "MdTimer":
				return <MdTimer className="M-icon" />;
			case "CgAddR":
				return <CgAddR className="M-icon" />;

			case "FiLogOut":
				return <FiLogOut className="M-icon" />;
			case "BsCalendarMinus":
				return <BsCalendarMinus className="M-icon" />;
			case 'HiDocumentText':
				return <HiDocumentText className="M-icon" />;

			case 'FaShippingFast' :
				return <FaShippingFast className="M-icon"></FaShippingFast>

			default:
				return false;
		}
	};


	return (
		<>
			{user ? (
				<>
					<Sider>
						<section className="mb__MainMenu bg-dark-blue">
							
							<div className="logo">
								<img className="d-none d-lg-block w-100" src={logo} alt="logo"></img>{" "}
								<img className="d-block d-lg-none w-100" src='../assets/logos/icon-miobio.png' alt="logo"></img>
							</div>
							
							<div className="menuItemsBlock">
								{state.menu?.map((menu_item,i) => {
									console.log(state.nutricionist)
									if(menu_item.item === 'Deportistas' && state.nutricionist?.id_suscription_nutricionist == 1){
										return
									}else{

										return(
											<div key={i}>
												<div className={`items transition ${state.page_active === menu_item.page ? 'active':''}`}>
													<div className="mb__menu_item mb__menu_normal" onClick={() => selectMenu(menu_item.page)}>
														<div className="mb__menu_item_icon">{getIcon(menu_item.icon)}</div>
														<div className="mb__menu_item_text">{menu_item.item}</div>
													</div>
												</div>
											</div>
										)
									}
								}
								)}
							</div>
						</section>
					</Sider>
				</>
			) : (<></>)}
			
		</>
	);
}

export default MainMenu;
