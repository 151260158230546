/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import HeaderDashboard from "../../components/HeaderDashboard";
import BackButton from "../../components/buttons/BackButton";
import { BiSave, BiSend } from "react-icons/bi";
import GeneralButton from "../../components/buttons/GeneralButton";
import { Service } from "../../services/api";
import AuthContext from "../../context/authContext";
import Renderif from "../../components/Renderif";
import { category_archive, category_hidrato, category_otros, category_proteina } from "../../utilities/recipes/categories";
import AlertContext from "../../context/alertContext";
import { MdClose } from "react-icons/md";
import { colors } from "../../utilities/colors";
import moment from "moment";
import { useParams } from "react-router-dom";
import { LANG } from "../commun/lang";


function AthleteNutricionalTableCocina() {

	const { showAlert } = useContext(AlertContext);
	const { user, lang } = useContext(AuthContext);

	const {id_athlete} = useParams('id_athlete')
	const {id_plan} = useParams('id_plan')

	const [state, setState] = useState({
		athlete: [],
		QuantityTabs: [],
		nutricionTab: [],
		quantiy: 0,
		index_1: 0,
		index_2: 0,
		modalVisible: false,
		slot_active: '',
		nutritional_data: null,
		comments:[],
		showComments:false,
		comment:'',
		nutricional:null
	});

	let quantity = 0;

	const setQuantity = ({x,y, value}) => {

		
		
		
		
		// 
		// 
		
		state.nutritional_data.week[y].qty[x].qty = quantity
		state.slot_active = ''
		setState({...state})
		
		
		// 
		// 	"NuticionalTabs",
		// 	state.QuantityTabs.week[state.index_1].day,
		// 	state.QuantityTabs.week[state.index_1].ingredients[state.index_2]
		// );

		// state.QuantityTabs.week[state.index_1].ingredients[
		// 	state.index_2
		// ].quantity = state.quantiy;

		// state.QuantityTabs.week[state.index_1].ingredients[
		// 	state.index_2
		// ].active = true;

		// setState({
		// 	...state,
		// 	modalVisible: false,
		// });
	};
	const onSaveNutritionalData = async () => {
	
		let body = {
			id_plan: !id_plan ? state.nutritional_data.id_plan: parseInt(id_plan),
			id_user: parseInt(id_athlete),
			id_nutricional:state.nutricional.id_nutricional,
			nutrition_data: JSON.stringify(state.nutritional_data),
		};
	

	
		
		let _saveNutritionalData = await Service(
			"PUT",
			"nutricionalTabs/update",
			body
		);
		
		showAlert({type:'success', message:LANG.NOT.guardar_tabla_nutricional[lang], description:LANG.NOT.listo[lang]})

		// setState({
		// 	...state,
		// 	QuantityTabs: NutricionalTabs,
		// });
	};

	useEffect(() => {
		// 
		// setNutricionalTabs(NutricionalTabs);

		const data = async () => {
			let _athlete = await Service("GET", `athlete/${id_athlete}`);
			let _nutritional_data = await Service("GET", `nutricionalTabs`);
			
			let _nutritional_table = _nutritional_data.data.response?.filter((table)=>table.id_user == id_athlete && table.id_plan == id_plan);

		
			console.log(_nutritional_table);
			


			if(_nutritional_table?.length === 0 || !_nutritional_table){

				let nutritional_data = {};
				nutritional_data.week = []

				for (const hidrato in category_hidrato) {

					nutritional_data.week.push({
						category:hidrato.charAt(0).toUpperCase() + hidrato.slice(1),
						category_id:category_hidrato[hidrato],
						qty:[
							{day:'Lunes', qty:0},
							{day:'Martes', qty:0},
							{day:'Miercoles', qty:0},
							{day:'Jueves', qty:0},
							{day:'Viernes', qty:0},
							{day:'Sabado', qty:0},
						]
					})

				
				}
				for (const proteina in category_proteina) {
					nutritional_data.week.push({
						category:(proteina.charAt(0).toUpperCase() + proteina.slice(1)).replace('_', ' '),
						category_id:category_proteina[proteina],
						qty:[
							{day:'Lunes', qty:0},
							{day:'Martes', qty:0},
							{day:'Miercoles', qty:0},
							{day:'Jueves', qty:0},
							{day:'Viernes', qty:0},
							{day:'Sabado', qty:0},
						]
					})
				}
				for (const otro in category_otros) {
					nutritional_data.week.push({
						category:otro.charAt(0).toUpperCase() + otro.slice(1),
						category_id:category_otros[otro],
						qty:[
							{day:'Lunes', qty:0},
							{day:'Martes', qty:0},
							{day:'Miercoles', qty:0},
							{day:'Jueves', qty:0},
							{day:'Viernes', qty:0},
							{day:'Sabado', qty:0},
						]
					})
				}
				let body = {
					id_user: parseInt(id_athlete),
					id_plan: parseInt(id_plan),
					nutrition_data: JSON.stringify(nutritional_data),
				};
				let _createNutritionalData = await Service(
					"POST",
					"nutricionalTabs/create",
					body
				);
				setState({...state, athlete: _athlete.data.response,  nutritional_data, nutricional:_createNutritionalData.data.response})
				console.log("NUTRICIONAL TABLE CREATED");
				
				return;
			}

			let nutritional_data;
			nutritional_data = add_category_patch({category:'Ternera', category_id:category_proteina.ternera, data:_nutritional_table[0].nutrition_data});
			nutritional_data = add_category_patch({category:'Proteína Vegetal', category_id:category_proteina.vegetal, data:_nutritional_table[0].nutrition_data});



			nutritional_data = remove_category_patch({category:'Carne roja', category_id:category_archive.carne_roja, data:_nutritional_table[0].nutrition_data});
			nutritional_data = remove_category_patch({category:'Bulgur', category_id:category_archive.bulgur, data:_nutritional_table[0].nutrition_data});
			nutritional_data = remove_category_patch({category:'Legumbre', category_id:category_archive.legumbre, data:_nutritional_table[0].nutrition_data});
			nutritional_data = remove_category_patch({category:'Maiz', category_id:category_archive.maiz, data:_nutritional_table[0].nutrition_data});
			nutritional_data = remove_category_patch({category:'Polenta', category_id:category_archive.polenta, data:_nutritional_table[0].nutrition_data});
			nutritional_data = remove_category_patch({category:'Tofu', category_id:category_archive.tofu, data:_nutritional_table[0].nutrition_data});

			setState({...state, athlete: _athlete.data.response, nutritional_data, nutricional:_nutritional_table[0]})
		

		};

	

		data();
		return () => {};
	}, []);

	const RenderNutritionalSlot = ({data, slot}) => {

		let slots = slot.split(',')
		console.log(slot)
		
		
		return  (
			<td className="p-0 m-0 col">

				<div className={`nutritional-input events-none ${state.slot_active === slot ? 'd-block' : 'd-none'}`}>

					<input autoFocus type="number" defaultValue={data === 0 ? '' : data} onChange={(evt)=>{
						
						quantity = parseInt(evt.target.value);

					}} onKeyUpCapture={(e)=>{
						if(e.key === 'Enter'){
							if(state.slot_active !== ''){
								setQuantity({x:parseInt(slots[0]),y:parseInt(slots[1])});
							}
						}
					}}></input>
				</div>

				<div className={`nutritional-slot ${state.slot_active === slot ? 'nutritional-slot-active' : ''} ${data !== 0 ? 'text-mint' : ''} ${user.id_rol===3 ? 'events-none':''}`} onMouseLeave={()=>{
					if(state.slot_active !== ''){
						setState({...state, slot_active:''})
					}
				}} onClick={()=>{
		
					setState({...state, slot_active:slot})
				}}>
					<div className={`f-14 f-Poppins-Bold text-center`}>{data} {slots[1] === '11' ? 'pza.' : 'gr.'}</div>
				</div>
			</td>
				
			)
	}

	const addComment = async () => {

		state.comments.push({
			...user,
			comment:state.comment,
			comment_created_at:Date.now()
		})
		
		let body = {
			comment:state.comment,
			id_plan:id_plan,
			id_user:user.id_user
		}
		
		state.comment = ''
		setState({...state})
		let _addComment = await Service('POST', 'comment', body);
		

	}

	const add_category_patch = ({category, category_id, data}) => {

	
		if(data.week.some((item) => item.category_id === category_id)){
			return data;
		}
		let _category = {
			category,
			category_id,
			qty:[
				{day:'Lunes', qty:0},
				{day:'Martes', qty:0},
				{day:'Miercoles', qty:0},
				{day:'Jueves', qty:0},
				{day:'Viernes', qty:0},
				{day:'Sabado', qty:0},
			]
		}
		data.week.splice(11, 0, _category);
		return data;
	}
	const remove_category_patch = ({category_id, data}) => {
	
		data.week = data.week.filter((item)=> item.category_id !== category_id);

		return data
	}

	return (
		<>
			<HeaderDashboard />

			{/* <NutricionistComments /> */}
			<div className="mb__center_content">
				<div className="section_title text-white mb__section_title text-uppercase">
					<BackButton route="/users" />

					<div className="text-white mb__section_title text-uppercase">
						{LANG.PLANDETAIL.tabla_nutricional[lang]}
					</div>

					{/* <div className="d-flex align-items-center py-2">
						<BsCalendar2 color={colors.yellow}/>
						<div className="f-14 text-yellow f-Poppins-Bold px-2">{`${moment(state.nutricional?.start_date).format('D MMM')} - ${moment(state.nutricional?.end_date).format('D MMM')}`}</div>
					</div> */}
				</div>

				<div className='mb__center_content'>
					<div className='text-white bg-dark-blue rounded'>

						<div className='d-flex p-3'>
							<div className='col'></div>
						
							{/* <GeneralButton
								className="btn__blue btn__general"
								title="Comentarios"
								icon={<BiComment />}
								onClick={() => {
									
									setState({...state, showComments:true})
								}}
							/> */}
							<Renderif isTrue={user.id_rol !== 3}>
								<GeneralButton
									className="btn__blue btn__general ms-2"
									title={LANG.WEEKPLAN.SAVE[lang]}
									icon={<BiSave />}
									onClick={() => {onSaveNutritionalData()}}
								/>
							</Renderif>

							
							
						</div>

						<div className=' bg-mint-light'>
							<div className='border-b-mint d-flex p-3'>
								<div style={{marginTop:-35}} className='bg-yellow p-1 rounded-3'>
									<img style={{width:100}} className='rounded-3' src='../../assets/images/profile.png'></img>
								</div>
								<div className='col ps-2 d-flex flex-column justify-content-end'>
									<div className='f-20 f-Poppins-Bold text-mint-shade line-1'>{state.athlete?.firstname}</div>
									<div className='f-14 f-Poppins-Regular text-mint-shade'>{state?.athlete?.rol}</div>
								</div>
							</div>

							<div className='d-flex border-b-mint py-2 px-3'>
								<div className=' col f-Poppins-Medium text-mint-shade'>{LANG.PROFILE.FULLNAME[lang]}:</div>
								<div className=' col f-Poppins-Medium text-mint-shade'>{state?.athlete.firstname} {state?.athlete.lastname}</div>
							</div>
							<div className='d-flex border-b-mint py-2 px-3'>
								<div className=' col f-Poppins-Medium text-mint-shade'>Email:</div>
								<div className=' col f-Poppins-Medium text-mint-shade'>{state?.athlete?.email}</div>
							</div>
							<div className='d-flex border-b-mint py-2 px-3'>
								<div className=' col f-Poppins-Medium text-mint-shade'>{LANG.REGISTER.Telefono[lang]}:</div>
								<div className=' col f-Poppins-Medium text-mint-shade'>{state?.athlete.phone}</div>
							</div>
							<div className='d-flex border-b-mint py-2 px-3'>
								<div className=' col f-Poppins-Medium text-mint-shade'>{LANG.NTABLE.locacion[lang]}:</div>
								<div className=' col f-Poppins-Medium text-mint-shade'>{state?.athlete?.city} {state?.athlete.state} {state?.athlete.country}</div>
							</div>
							<div className='d-flex border-b-mint py-2 px-3'>
								<div className=' col f-Poppins-Medium text-mint-shade'>{LANG.NTABLE.filosofia_alimentaria[lang]}:</div>
								<div className=' col f-Poppins-Medium text-mint-shade'>{LANG.NOT[state?.athlete?.fedding][lang]}</div>
							</div>
							<div className='d-flex border-b-mint py-2 px-3'>
								<div className=' col f-Poppins-Medium text-mint-shade'>{LANG.NTABLE.alergias_intolerancias[lang]}:</div>
								<div className=' col f-Poppins-Medium text-mint-shade'>{state?.athlete?.allergy}</div>
							</div>

						</div>
						<div className="py-4"></div>

		
					</div>
				</div>

				<div className="mb__center_content overflow-auto  ">
					<table className="ant-table w-100 bg-dark-blue rounded-3 overflow-hidden">
					<thead className="ant-table-thead rounded-5">
						<tr className="p-0 m-0 d-flex w-100">
							<th className="col"><div className="text-left f-anton f-14 text-uppercase">{LANG.NOT.ingredientes[lang]}</div></th>
							<th className="col"><div className="text-center f-anton f-14">{LANG.WEEKPLAN.MONDAY[lang]}</div></th>
							<th className="col"><div className="text-center f-anton f-14">{LANG.WEEKPLAN.TUESDAY[lang]}</div></th>
							<th className="col"><div className="text-center f-anton f-14">{LANG.WEEKPLAN.WEDNESDAY[lang]}</div></th>
							<th className="col"><div className="text-center f-anton f-14">{LANG.WEEKPLAN.THURSDAY[lang]}</div></th>
							<th className="col"><div className="text-center f-anton f-14">{LANG.WEEKPLAN.FRIDAY[lang]}</div></th>
							<th className="col"><div className="text-center f-anton f-14">{LANG.WEEKPLAN.SATURDAY[lang]}</div></th>
						</tr>
					</thead>
					<tbody className="ant-table-tbody">
						
						
								{state.nutritional_data?.week.map((item,i)=>{

									return (
										<tr className="p-0 m-0 d-flex w-100" key={i}>

											<td className="col d-flex align-items-center p-0">
												
													<div className="text-white f-14 f-Poppins-Bold ps-3">{item.category}</div>
												
											</td>
											<RenderNutritionalSlot slot={`0,${i}`} data={item.qty[0].qty}/>
											<RenderNutritionalSlot slot={`1,${i}`} data={item.qty[1].qty}/>
											<RenderNutritionalSlot slot={`2,${i}`} data={item.qty[2].qty}/>
											<RenderNutritionalSlot slot={`3,${i}`} data={item.qty[3].qty}/>
											<RenderNutritionalSlot slot={`4,${i}`} data={item.qty[4].qty}/>
											<RenderNutritionalSlot slot={`5,${i}`} data={item.qty[5].qty}/>
											

										</tr>
									)

								})}
								
								
						
						
					</tbody>
					</table>
				</div>
			</div>
			<Renderif isTrue={state.showComments}>
				<div className="d-flex position-fixed vw-100 vh-100" style={{
					top: 0,
					right: 0,
					zIndex: 1,
					}}>

					<div className='col-6 col-lg-9 bg-overlay' onClick={()=>{setState({...state, showComments:false})}}></div> 
					<div className='col-6 col-lg-3'>

						<div className=' bg-dark-blue border-l-mint h-100 overflow-auto hide-scroll'>

						<div className='d-flex'>
							<div className='col'></div>
							<div className='cursor-pointer p-2' onClick={()=>{setState({...state, showComments:false})}}>
							<MdClose size={32} color={colors.white}></MdClose>
							</div>
						</div>

						<div className='f-30 f-anton text-white px-3 border-b-blue-shade'>COMENTARIOS</div>

						{state.comments.map((comment,i)=>{
							
						
							return (
								<div key={i} className=" border-b-blue-shade px-3 py-3">
									<div className="d-flex ">
										<div className="f-14 f-Poppins-Bold text-yellow">{comment?.firstname}</div>
									
										<div className="col"></div>
										{/* <div className="text-white">{new Date(comment?.comment_created_at).getDate()}</div> */}
										{/* <div className="text-white">{new Date(comment.comment_created_at)}</div> */}
										<div className="f-12 f-Poppins-Medium text-blue-shade">{moment(new Date(parseInt(comment?.comment_created_at))).format("D MMM")}</div>
									</div>
									<div className="f-12 f-Poppins-Medium text-blue-shade">{LANG.PROFILE[comment.rol][lang]}</div>
									<div className="f-14 f-Poppins-Medium text-light-gray">{comment.comment} </div>
								</div>
							) 
						})}


					

					

						
						<div className="px-3 py-3">
							<textarea value={state.comment} onChange={(e)=>{setState({...state, comment:e.currentTarget.value})}} rows={5} cols={5} className="textarea"></textarea>

							<div className="d-flex justify-content-end">
							<GeneralButton
								className="btn__blue btn__general"
								title="Enviar"
								
								icon={<BiSend />}
								onClick={() => {

									addComment();
									
								}}
							/>
							</div>
						
						</div>

						
						</div>
					
					</div>

					



				
				</div>
			</Renderif>
		


		
			
		</>
	);
}



export default AthleteNutricionalTableCocina;
