import React, { useContext } from 'react'
import { MdPayments } from "react-icons/md";
import Renderif from '../../components/Renderif';
import AuthContext from '../../context/authContext';
import { colors } from '../../utilities/colors';


export default function Payment() {

    const {user,getUser } = useContext(AuthContext)

  
    return (
    <Renderif isTrue={user?.active === 1}>
        <div className="payment-status">

            <div className="payment-status-card d-flex flex-column">
                <div className="d-flex">
                    <div className=""><MdPayments size={48} color={colors.mint}></MdPayments></div>
                    <div className="pt-1 ps-2 f-Poppins-Medium text-black-50">No hemos podido procesar tu pago, haz clic <span onClick={()=>{
                        console.log("GO PAGO")
                    }} className="f-Poppins-Bold text-mint-shade text-decoration-underline cursor-pointer">aquí</span> para intentarlo de nuevo.</div>
                </div>
                <div className="col"></div>
                <div className="d-flex">
                    <div className="col"></div>
                    <div onClick={()=>{
                        console.log("VERIFIACAR")
                        getUser()
                    }}  className="f-Poppins-Bold text-mint-shade text-decoration-underline cursor-pointer pe-2">Verificar</div>
                </div>
            </div>
        </div>
    </Renderif>
  )
}
