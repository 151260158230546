
import { Switch } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import AlertContext from "../context/alertContext";
import AuthContext from "../context/authContext";
import PlanContext from "../context/planContext";
import { LANG } from "../pages/commun/lang";

import { Service } from "../services/api";
import {
	category_hidrato,
	category_otros,
	category_proteina,
	cena_primero_category,
	cena_segundo_category,
	comida_primero_category,
	comida_primero_category_conditional,
	comida_segundo_category,
	otros_category,
	ternera_category,
} from "../utilities/recipes/categories";

const PlanState = ({ children }) => {
	const {showAlert} = useContext(AlertContext)
	const {user, lang} = useContext(AuthContext);
	
	const [state, setState] = useState({
		recipes: [],
		extraRecipes: {
			hidratos: [],
			proteinas: [],
			otros: [],
		},
		currentWeekPlan: null,
		weekRecipes: [],
		kitchen: "0",
		kitchens: [],
		athletes:[],
		weekplans: [],
		weekPlanRecipeSlot: null,
		NutricionalData: [],
	});

	// const week_meals = {
	// 	course: [
	// 		"Comida Primero",
	// 		"Comida Segundo",
	// 		"Cena Primero",
	// 		"Cena Segundo",
	// 	],
	// 	week: [
	// 		{
	// 			day: "Lunes",
	// 			meals: [
	// 				{ course: "Comida Primero", recipe: 14 },
	// 				{ course: "Comida Segundo", recipe: 14 },
	// 				{ course: "Cena Primero", recipe: 14 },
	// 				{ course: "Cena Segundo", recipe: 14 },
	// 			],
	// 		},
	// 		{
	// 			day: "Martes",
	// 			meals: [
	// 				{ course: "Comida Primero", recipe: 14 },
	// 				{ course: "Comida Segundo", recipe: 14 },
	// 				{ course: "Cena Primero", recipe: 14 },
	// 				{ course: "Cena Segundo", recipe: 14 },
	// 			],
	// 		},
	// 		{
	// 			day: "Miercoles",
	// 			meals: [
	// 				{ course: "Comida Primero", recipe: 14 },
	// 				{ course: "Comida Segundo", recipe: 14 },
	// 				{ course: "Cena Primero", recipe: 14 },
	// 				{ course: "Cena Segundo", recipe: 14 },
	// 			],
	// 		},
	// 		{
	// 			day: "Jueves",
	// 			meals: [
	// 				{ course: "Comida Primero", recipe: 14 },
	// 				{ course: "Comida Segundo", recipe: 14 },
	// 				{ course: "Cena Primero", recipe: 14 },
	// 				{ course: "Cena Segundo", recipe: 14 },
	// 			],
	// 		},
	// 		{
	// 			day: "Viernes",
	// 			meals: [
	// 				{ course: "Comida Primero", recipe: 14 },
	// 				{ course: "Comida Segundo", recipe: 14 },
	// 				{ course: "Cena Primero", recipe: 14 },
	// 				{ course: "Cena Segundo", recipe: 14 },
	// 			],
	// 		},
	// 		{
	// 			day: "Sabado",
	// 			meals: [
	// 				{ course: "Comida Primero", recipe: 14 },
	// 				{ course: "Comida Segundo", recipe: 14 },
	// 				{ course: "Cena Primero", recipe: 14 },
	// 				{ course: "Cena Segundo", recipe: 14 },
	// 			],
	// 		},
	// 	],
	// };
	

	useEffect(() => {
		
		const data = async () => {
			//tspoonlab
			let _recipes = await Service(
				"TSPOONLAB",
				"listRecipesPaged?start=0&rows=1000&withTypes=true&withImages=true"
			);
			
			
			let _weekplans = await Service("GET", "weeklyPlans/full");
			
			let _kitchens = await Service("GET", "kitchens");
			
			let _athletes = await Service("GET",'athletes');
			
			

			setState((state) => ({
				...state,
				weekplans: _weekplans.data.response,
				kitchens: _kitchens.data.response,
				recipes: _recipes.data.filter((item) => item.listComponentTypes !== null),
				athletes: _athletes.data.response,
			}));
		};
		data();
		return () => {};
	}, []);

	//METHODS
	const setCurrentWeekPlan = (weekPlan) => {
		state.currentWeekPlan = weekPlan;
	};
	const setCurrentWeekPlanRecipes = (_weekRecipes) => {
		state.weekRecipes = JSON.parse(_weekRecipes);
		randomizeMoreRecipes();
		// setState({ ...state });
	};
	const setNutricionalTabs = (NutricionalTabs) => {
		state.NutricionalData = NutricionalTabs;
		
	};
	const saveCurrentWeekPlanRecipes = async () => {
		
		let body = {
			id_week_plan: state.currentWeekPlan.id,
			week_recipes: JSON.stringify(state.weekRecipes),
		};
		let _saveCurrentWeekPlanRecipes;
		if (state.currentWeekPlan.status === 0) {
			_saveCurrentWeekPlanRecipes = await Service(
				"POST",
				"recipes/weeklyRecipes",
				body
			);
			
			showAlert({type:'success', message:LANG.NOT.guardar_plan_semanal[lang], description:LANG.NOT.plan_semanal_guardado[lang]})
			setCurrentWeekPlanStatus(3);
			return;
		}
		_saveCurrentWeekPlanRecipes = await Service(
			"PUT",
			"recipes/weeklyRecipes",
			body
		);
		showAlert({type:'success', message:LANG.NOT.guardar_plan_semanal[lang], description:LANG.NOT.plan_semanal_guardado[lang]})

		
		
		
		
	};
	const setCurrentWeekPlanStatus = () => {
		state.currentWeekPlan.status = 3;
	};
	const randomizeMoreRecipes = async () => {
		let extra_hidratos = [];
		let extra_proteinas = [];
		let extra_otros = [];

		let _polenta = getRecipesByCategory(comida_primero_category_conditional[3], []);
		extra_hidratos.push(_polenta[0].id);

		let _couscous = getRecipesByCategory(comida_primero_category[2], []);
		extra_hidratos.push(_couscous[0].id);

		let _hiratos_arroz = state.recipes.filter((recipe) => recipe.listComponentTypes.filter((type) => type.id === category_hidrato.arroz).length > 0);
		shuffleArray(_hiratos_arroz);
		extra_hidratos.push(_hiratos_arroz[2].id);
		extra_hidratos.push(_hiratos_arroz[3].id);
		extra_hidratos.push(_hiratos_arroz[4].id);

		let _hiratos_pasta = state.recipes.filter((recipe) => recipe.listComponentTypes.filter((type) => type.id === category_hidrato.pasta).length > 0);
		shuffleArray(_hiratos_pasta);
		extra_hidratos.push(_hiratos_pasta[2].id);
		extra_hidratos.push(_hiratos_pasta[3].id);
		extra_hidratos.push(_hiratos_pasta[4].id);

		let _hiratos_legumbre = state.recipes.filter((recipe) => recipe.listComponentTypes.filter((type) => type.id === category_hidrato.legumbre).length > 0);
		shuffleArray(_hiratos_legumbre);
		extra_hidratos.push(_hiratos_legumbre[2].id);
		extra_hidratos.push(_hiratos_legumbre[3].id);
		
		

		// let _hidratos_1s_conditional = state.recipes.filter((recipe) => recipe.listComponentTypes.filter((type) =>
		// 			type.id === category_hidrato.polenta ||
		// 			type.id === category_hidrato.gnocchi ||
		// 			type.id === category_hidrato.quinoa ||
		// 			type.id === category_hidrato.couscous
		// 	).length > 0
		// );
		// shuffleArray(_hidratos_1s_conditional);
		// extra_hidratos.push(_hidratos_1s_conditional[2].id);
		// extra_hidratos.push(_hidratos_1s_conditional[3].id);
	

		let proteinas_pescado_blanco = getRecipesByCategory(category_proteina.pescado_blanco)
		shuffleArray(proteinas_pescado_blanco);
		extra_proteinas.push(proteinas_pescado_blanco[2].id);
		extra_proteinas.push(proteinas_pescado_blanco[3].id);


		let proteinas_pescado_azul = getRecipesByCategory(category_proteina.pescado_azul)
		shuffleArray(proteinas_pescado_azul);
		extra_proteinas.push(proteinas_pescado_azul[2].id);
		extra_proteinas.push(proteinas_pescado_azul[3].id);
	


		let _proteinas_carne_blanca = getRecipesByCategory(category_proteina.carne_blanca)
		shuffleArray(_proteinas_carne_blanca);
		extra_proteinas.push(_proteinas_carne_blanca[4].id);
		extra_proteinas.push(_proteinas_carne_blanca[5].id);
		extra_proteinas.push(_proteinas_carne_blanca[6].id);
		extra_proteinas.push(_proteinas_carne_blanca[7].id);
		extra_proteinas.push(_proteinas_carne_blanca[8].id);
		
		

		let _proteinas_carne_roja = [
			...getRecipesByCategory(category_proteina.cerdo),
			...getRecipesByCategory(category_proteina.ternera)
		]

		shuffleArray(_proteinas_carne_roja);
		extra_proteinas.push(_proteinas_carne_roja[1]?.id);

	

	

		state.extraRecipes.hidratos = extra_hidratos;
		state.extraRecipes.proteinas = extra_proteinas;

		let _extra_otros = getRecipesByCategory(category_otros.verduras)
		shuffleArray(_extra_otros);
		
		extra_otros.push(_extra_otros[0].id);
		extra_otros.push(_extra_otros[1].id);
		extra_otros.push(_extra_otros[2].id);
		extra_otros.push(_extra_otros[3].id);
		extra_otros.push(_extra_otros[4].id);
		extra_otros.push(_extra_otros[5].id);
		extra_otros.push(_extra_otros[6].id);
		extra_otros.push(_extra_otros[7].id);
		extra_otros.push(_extra_otros[8].id);
		extra_otros.push(_extra_otros[9].id);
		

		state.extraRecipes.otros = extra_otros;

		console.log(state.extraRecipes)

		setState({...state})

	};
	const randomizeWeekPlan = async () => {

		
		if(state.currentWeekPlan.status !== 0){
			randomizeMoreRecipes();
			return;
		}

		let week_days = [
			"Lunes",
			"Martes",
			"Miercoles",
			"Jueves",
			"Viernes",
			"Sabado",
		];



		let _recipes_locked = [];
	

		let _comida_primero = [null,null,null,null,null,null]
		let _comida_segundo = [null,null,null,null,null,null]
		let _cena_primero = [null,null,null,null,null,null]
		let _cena_segundo = [null,null,null,null,null,null]
		


	

		let _placements_cords_1 = [
			[0,2,1],
			[3,5,4],
			[1,4,null],
		];
		//shuffleArray(_placements_cords_1)
		
		let _placements_cords_2 = [
			[1,3,2],
			[2,4,3],
		];
		//shuffleArray(_placements_cords_2)

		let _placements = [_placements_cords_1, _placements_cords_2];
	
	

		//shuffleArray(_placements);
		


		let _arroz_all = getRecipesByCategory(comida_primero_category[1], _recipes_locked);
		let _pasta_all = getRecipesByCategory(comida_primero_category[0], _recipes_locked);
		let _maiz = getRecipesByCategory(comida_primero_category[3], _recipes_locked);
		let _legumbre = getRecipesByCategory(comida_primero_category_conditional[0], _recipes_locked);
		let _polenta = getRecipesByCategory(comida_primero_category_conditional[3], _recipes_locked);

		let _gnochi = getRecipesByCategory(comida_primero_category_conditional[2], _recipes_locked);
		
		let _quinoa = filterBy({filter:'ONLY', recipes:[
			...getRecipesByCategory(cena_primero_category[0], _recipes_locked)
		]})

		
		
		let _couscous = getRecipesByCategory(comida_primero_category[2], _recipes_locked);
		
		let _tuberculo_all = getRecipesByCategory(cena_primero_category[3], _recipes_locked);

		let _verduras = filterBy({filter:'ONLY', recipes:[
			...getRecipesByCategory(otros_category.verduras, _recipes_locked)
		]})
		
		let _tuberculo = filterBy({filter:'HIDR', recipes:[
			...getRecipesByCategory(cena_primero_category[3], _recipes_locked)
		]})

		let _arroz = filterBy({filter:'HIDR', recipes:[
			...getRecipesByCategory(comida_primero_category[1], _recipes_locked)

		]})
		let _proteina_arroz = filterBy({filter:'COMB', recipes:[
			...getRecipesByCategory(comida_primero_category[1], _recipes_locked)
		]})

		let _pasta = filterBy({filter:'ONLY', recipes:[
			...getRecipesByCategory(comida_primero_category[0], _recipes_locked)

		]})
		let _proteina_pasta = filterBy({filter:'COMB', recipes:[
			...getRecipesByCategory(comida_primero_category[0], _recipes_locked)
		]})


		let proteinas_huevo = filterBy({filter:'ONLY', recipes:[
			...getRecipesByCategory(cena_segundo_category[1], _recipes_locked)
		]})

		let proteinas_pescado_azul = filterBy({filter:'ONLY', recipes:[
			...getRecipesByCategory(category_proteina.pescado_azul, _recipes_locked)
		]})
	
		let proteinas_pescado_blanco = filterBy({filter:'ONLY', recipes:[
			...getRecipesByCategory(category_proteina.pescado_blanco, _recipes_locked)
		]})
	
		
		
		let proteinas_ternera = filterBy({filter:'ONLY', recipes:[
			...getRecipesByCategory(category_proteina.ternera, _recipes_locked),
		]})
		console.log(proteinas_ternera)
		
		

		let proteinas_carne_blanca = filterBy({filter:'PROT', recipes:[
			...getRecipesByCategory(comida_segundo_category[0], _recipes_locked),
		]})
	
		let proteinas_vegetal =  filterBy({filter:'ONLY', recipes:[
			...getRecipesByCategory("29327070840126955734775725599303421944", _recipes_locked)
		]})
		console.log(proteinas_vegetal)
		
		

		// let nochi = filterBy({filter:'NOCHI', recipes:[
		// 	...state.recipes,
		// 	// ...getRecipesByCategory(comida_segundo_category[2], _recipes_locked),
		// 	// ...getRecipesByCategory(comida_segundo_category[3], _recipes_locked),
		// ]})

	
		shuffleArray(_verduras)
		shuffleArray(proteinas_vegetal)
		shuffleArray(proteinas_huevo)
		shuffleArray(proteinas_pescado_azul)
		shuffleArray(proteinas_pescado_blanco)
		shuffleArray(proteinas_ternera)
		shuffleArray(proteinas_carne_blanca)


		shuffleArray(_arroz);
		shuffleArray(_pasta);
		shuffleArray(_maiz);
		shuffleArray(_tuberculo);
		shuffleArray(_proteina_pasta);

		
	

		let _comun_hidratos = [
			_quinoa[0],
			_gnochi[0],
			// _couscous[0]
		]

		shuffleArray(_comun_hidratos)
		

		
	
		let _order = [0,1,2,3,4,5]
		shuffleArray(_order)
		// console.log(_order)



		//COMIDA PRIMERO


		_comida_primero[_order[0]] =  _arroz[0] //arroz
		_comida_primero[_order[1]] =  _pasta[0] //pasta
		_comida_primero[_order[2]] = _comun_hidratos[0]	//cous, quinoa
		_comida_primero[_order[3]] = _proteina_pasta[0]	//proteina_pasta
		_comida_primero[_order[4]] = _arroz[1]	//arroz
		_comida_primero[_order[5]] = _pasta[1]	//pasta
		console.log(_comida_primero)


		_comida_segundo[_order[0]] = proteinas_carne_blanca[0] //carne blanca
		_comida_segundo[_order[1]] = proteinas_pescado_azul[0] //pescado azul
		_comida_segundo[_order[2]] = proteinas_carne_blanca[1] //carne blanca
		_comida_segundo[_order[3]] = _verduras[0] // verduras
		_comida_segundo[_order[4]] = proteinas_ternera[0] //ternera
		_comida_segundo[_order[5]] = proteinas_carne_blanca[2] //carne blanca

		console.log(_comida_segundo)

		_cena_primero[_order[0]] = _pasta[2] //pasta
		_cena_primero[_order[1]] = _arroz[2] //arroz
		_cena_primero[_order[2]] = _tuberculo[0] //tuberculo
		_cena_primero[_order[4]] = _proteina_arroz[1] // proteina arroz
		_cena_primero[_order[3]] = _tuberculo[1] //tuberculo
		_cena_primero[_order[5]] = _tuberculo[2] //tuberculo

		console.log(_cena_primero)

		_cena_segundo[_order[0]] = proteinas_huevo[0] //huevo
		_cena_segundo[_order[1]] = proteinas_carne_blanca[3] //carne blanca
		_cena_segundo[_order[4]] = _verduras[1] //tuberculo
		_cena_segundo[_order[3]] = proteinas_pescado_blanco[0] // pescadoblanco
		_cena_segundo[_order[2]] = proteinas_vegetal[1] //proteina vegetal
		_cena_segundo[_order[5]] = proteinas_pescado_azul[2] //pescado azul

		console.log(_cena_segundo)
		console.log("=================")

				//FILL WEEK
				let week = [];

				for (let i = 0; i < week_days.length; i++) {
					week[i] = {
						day: week_days[i],
						comments:'',
						comments_athlete:[],
						meals: [
							{
								course: "Comida Primero",
								recipe: _comida_primero[i].id,
							},
							{
								course: "Comida Segundo",
								recipe: _comida_segundo[i].id,
							},
							{
								course: "Cena Primero",
								recipe: _cena_primero[i].id,
							},
							{
								course: "Cena Segundo",
								recipe: _cena_segundo[i].id,
							},
							{ course: "Extra Comida", recipe: null },
							{ course: "Extra Cena", recipe: null },
						],
					};
				}
		
				let week_meals = {
					course: [
						"Comida Primero",
						"Comida Segundo",
						"Cena Primero",
						"Cena Segundo",
						"Extra Comida",
						"Extra Cena",
					],
				};
				week_meals.week = week;
				setCurrentWeekPlanRecipes(JSON.stringify(week_meals));
		return;





	
		_comida_primero[_placements[0][0][1]] =  _tuberculo[1]

		_comida_primero[_placements[0][1][0]] =  _pasta[0]
		_comida_primero[_placements[0][1][1]] =  _arroz[1]

		_comida_primero[_placements[0][2][0]] =  _comun_hidratos[0]
		_comida_primero[_placements[0][2][1]] =  _comun_hidratos[1]

		_comun_hidratos = []


	
	
	


		//CENA PRIMERO

		_comun_hidratos = [
		
			_gnochi[0],
			_maiz[0],
			_quinoa[0],
		
		]

		shuffleArray(_comun_hidratos);
		let _rand = rand(0,2);
		console.log(_rand)
		if(_rand === 0){
			_cena_primero[5] =  _tuberculo[3]
		}else{
			_cena_primero[0] =  _tuberculo[3]
		}

		_cena_primero[_placements[0][0][2]] =  _pasta[1]
		_cena_primero[_placements[0][1][2]] =  _tuberculo[2]

		// _comun_hidratos.push(_quinoa[0])

		for (let i = 0; i < _cena_primero.length; i++) {
			if(_cena_primero[i] === null){
				_cena_primero[i] = _comun_hidratos[0];
				_comun_hidratos.shift()
			
			}
		}
		
		console.log(_comida_primero)
		console.log(_cena_primero)



		
		//COMIDA SEGUNDO
		for (let i = 0; i < _comida_primero.length; i++) {
		
			// let combinados = filterBy({filter:'HIDR,PROT', recipes:getRecipesByCategory(category_proteina.carne_blanca, _recipes_locked)})
			// let hidratos = filterBy({filter:'HIDR', recipes:getRecipesByCategory(category_proteina.carne_blanca, _recipes_locked)})
			//shuffleArray(comida_segundo_category)
			let proteinas = filterBy({filter:'PROT', recipes:[
				...getRecipesByCategory(comida_segundo_category[0], _recipes_locked),
				...getRecipesByCategory(comida_segundo_category[1], _recipes_locked),
			]})
			shuffleArray(proteinas)

			let comida_primero_type = 	getTypeRecipe({recipe:_comida_primero[i]})
			switch (comida_primero_type) {
				case 'COMBINADO':
					//VERDURA
					_comida_segundo[i] = _verduras[0]
					_recipes_locked.push(_verduras[0].id)
					_verduras.shift()
					break;
				case 'HIDRATO':
					//PROTEINA GRAL.
					_comida_segundo[i] = proteinas[0]
					_recipes_locked.push(proteinas[0].id)

					break;
			
				default:
					break;
			}
			
		
		}
		
		//CENA SEGUNDO

		let _proteinas_huevo = getRecipesByCategory(cena_segundo_category[1], _recipes_locked)

		let eval_pescado_azul_cena = _cena_primero.some((recipe) => recipe.listComponentTypes.some((category)=>category.id == category_proteina.pescado_azul ));
		let eval_pescado_blanco_cena = _cena_primero.some((recipe) => recipe.listComponentTypes.some((category)=>category.id == category_proteina.pescado_blanco ));

		let eval_pescado_azul_comida = _comida_primero.some((recipe) => recipe.listComponentTypes.some((category)=>category.id == category_proteina.pescado_azul ));
		let eval_pescado_blanco_comida = _comida_primero.some((recipe) => recipe.listComponentTypes.some((category)=>category.id == category_proteina.pescado_blanco ));

		// if(eval_pescado_azul_cena || eval_pescado_azul_comida){
		// 	console.log("YA HAY PESCADO AZUL EN EL PLAN")
		// }
		
		// if(eval_pescado_blanco_cena || eval_pescado_blanco_comida){
		// 	console.log("YA HAY PESCADO BLANCO EN EL PLAN")
		// }
		


		let _proteinas_pescado_azul = getRecipesByCategory(category_proteina.pescado_azul, _recipes_locked)
		let _proteinas_pescado_blanco = getRecipesByCategory(category_proteina.pescado_blanco, _recipes_locked)

		let _proteinas_ternera = getRecipesByCategory(category_proteina.ternera, _recipes_locked)
		let _proteinas = filterBy({filter:'PROT', recipes:[
			...getRecipesByCategory(comida_segundo_category[0], _recipes_locked),
			...getRecipesByCategory(comida_segundo_category[2], _recipes_locked),
			...getRecipesByCategory(comida_segundo_category[3], _recipes_locked),
		]})
		shuffleArray(_proteinas_huevo)
		shuffleArray(_proteinas_pescado_azul)
		shuffleArray(_proteinas_pescado_blanco)
		shuffleArray(_proteinas_ternera)
		shuffleArray(_proteinas)

		let _cena_picked = [
			_proteinas_huevo[0],
			!eval_pescado_azul_comida && !eval_pescado_azul_cena ? _proteinas_pescado_azul[0] : _proteinas[2] ,
			!eval_pescado_blanco_comida && !eval_pescado_blanco_cena ? _proteinas_pescado_blanco[0] : _proteinas[3] ,
			_proteinas_ternera[0],
			_proteinas[0],
			_proteinas[1]

		]
	
		shuffleArray(_cena_picked);
	
		
		
		for (let i = 0; i < _cena_primero.length; i++) {
		

			// let proteinas_huevo = getRecipesByCategory(comida_segundo_category[1], _recipes_locked)
			// let _proteinas_pescado_azul = getRecipesByCategory(category_proteina[4], _recipes_locked)
			// let _proteinas_pescado_blanco = getRecipesByCategory(comida_segundo_category[5], _recipes_locked)
			// let _proteinas_ternera = getRecipesByCategory(comida_segundo_category[7], _recipes_locked)
			
			

			// let proteinas = filterBy({filter:'PROT', recipes:[
			// 	...getRecipesByCategory(comida_segundo_category[0], _recipes_locked),
			// 	...getRecipesByCategory(comida_segundo_category[2], _recipes_locked),
			// 	...getRecipesByCategory(comida_segundo_category[3], _recipes_locked),
			// ]})
			// shuffleArray(proteinas)
			// shuffleArray(proteinas_huevo)
			

			
			let comida_primero_type = 	getTypeRecipe({recipe:_cena_primero[i]})
			switch (comida_primero_type) {
				case 'COMBINADO':
					//VERDURA
					

					if(_cena_picked[i].listComponentTypes.some((type)=>type.id === category_proteina.carne_blanca)){
					
						_cena_segundo[i] = _verduras[0]
						_recipes_locked.push(_verduras[0].id)
						_verduras.shift()
					}else{
						_cena_segundo[i] = _cena_picked[i]
						_recipes_locked.push(_cena_picked[i].id)
					}

					

					break;
				case 'HIDRATO':

					//PROTEINA GRAL.
					_cena_segundo[i] = _cena_picked[i]
					_recipes_locked.push(_cena_picked[i].id)
					break;
			
				default:
					break;
			}
		}


		

		//_comida_segundo = addCarneRoja({course:_comida_segundo, recipe:_carne_ternera[0]})
		//_cena_segundo = addCarnePescado({course:_cena_segundo, recipe:_pescados[0]})


		
		//FILL WEEK
		let _week = [];

		for (let i = 0; i < week_days.length; i++) {
			_week[i] = {
				day: week_days[i],
				comments:'',
				comments_athlete:[],
				meals: [
					{
						course: "Comida Primero",
						recipe: _comida_primero[i].id,
					},
					{
						course: "Comida Segundo",
						recipe: _comida_segundo[i].id,
					},
					{
						course: "Cena Primero",
						recipe: _cena_primero[i].id,
					},
					{
						course: "Cena Segundo",
						recipe: _cena_segundo[i].id,
					},
					{ course: "Extra Comida", recipe: null },
					{ course: "Extra Cena", recipe: null },
				],
			};
		}

		let _week_meals = {
			course: [
				"Comida Primero",
				"Comida Segundo",
				"Cena Primero",
				"Cena Segundo",
				"Extra Comida",
				"Extra Cena",
			],
		};
		_week_meals.week = _week;
		setCurrentWeekPlanRecipes(JSON.stringify(_week_meals));
		
	};
	const addCarnePescado = ({course, recipe}) => {
		let count = 0
		while (true) {
			
			if(count ==6){
				break;
			}

			let _rand = rand(0,6);
			if(!course[_rand].listComponentTypes.some((category)=>category.descr.toUpperCase().includes('VERD'))){
				course[_rand] = recipe
				break;
			}
			count++;
		}
		return course;
	};
	const addCarneRoja = ({course, recipe}) => {
		let count = 0
		while (true) {
			
			if(count ==6){
				break;
			}

			let _rand = rand(0,6);
			if(!course[_rand].listComponentTypes.some((category)=>category.descr.toUpperCase().includes('VERD'))){
				console.log(recipe);
				course[_rand] = recipe
				break;
			}

			count++;
		}
		console.log(course);
		return course
	};

	//EVENTS
	const onSelectKitchen = (kitchen) => {
		setState({ ...state, kitchen });
	};
	const onCreateWeeklyPlan = async ({start_date, end_date}) => {
		
		let aux_kitchen = user.id_rol == 2 ? 4 : state.kitchen

		if(start_date === '' || end_date === ''){

			showAlert({type:'error', message:LANG.NOT.crear_plan_semana[lang], description:LANG.NOT.indicar_fecha_inicio_fin[lang]})
		
			
			return;
		}

		let _nutritional_data = await Service("GET", `nutricionalTabs`);
	

		let body = {
			id_kitchen: parseInt(aux_kitchen),
			start_date,
			end_date,
			status: 0,
			total_athletes: 0,
			id_nutricionist: user.id_rol === 2 ? user.id_user : null
		};

		
		let _create_weekplan = await Service(
			"POST",
			"weeklyPlans/create",
			body
		);
		
		
			console.log(state.kitchens)
		let _kitchen = state.kitchens.filter((kitchen) => kitchen.id_kitchen === aux_kitchen)[0];
	
		let _weekplan = {
			..._create_weekplan.data.response,

			kitchen: _kitchen.kitchen,
			final_date:
				moment(start_date).format("D") +
				"-" +
				moment(end_date).format("D MMM"),
			location: "HOLA",
		};
		state.weekplans.unshift(_weekplan);
		setState({ ...state });

		let athletes = state.athletes.filter((athlete) => athlete.id_kitchen == state.kitchen);

		for (let i = 0; i < athletes.length; i++) {
			
			let nutritional_data = _nutritional_data.data.response.filter((item) => item.id_athlete === athletes[i].id_athlete);
			nutritional_data.reverse();

			let body = {
				id_user: parseInt(athletes[i].id_user),
				id_plan: parseInt(_create_weekplan.data.response.id),
				nutrition_data: JSON.stringify(nutritional_data[0].nutrition_data),
				
			};
			let _createNutritionalData = await Service(
				"POST",
				"nutricionalTabs/create",
				body
			);
			

		}

		showAlert({type:'success', message:LANG.NOT.crear_plan_semana[lang], description:LANG.NOT.nuevo_plan_semanal_creado[lang]})

		
	};
	const onRemoveWeekPlanRecipe = ({ day, course }) => {
		state.weekRecipes.week[day].meals[course].recipe = null;
		state.weekPlanRecipeSlot = { day, course };
		setState({ ...state });
	};
	const onSelectWeekPlanSlot = ({ day, course }) => {
		// state.weekRecipes.week[day].meals[course] = null;
		
		state.weekPlanRecipeSlot = { day, course };
		setState({ ...state });
	};
	const onAssignWeelPlanRecipe = ({ recipe }) => {
		if (!state.weekPlanRecipeSlot) {
			return;
		}
		state.weekRecipes.week[state.weekPlanRecipeSlot.day].meals[
			state.weekPlanRecipeSlot.course
		].recipe = recipe;
		state.weekPlanRecipeSlot = null;
		setState({ ...state });
	};
	const onRemovePlan = async ({plan}) => {
		
		let _onRemovePlan = await Service('PUT', `weeklyPlans/${plan.id}`,{status:-1})
		console.log(_onRemovePlan);
		state.weekplans = state.weekplans.filter((weekplan)=> weekplan.id !== plan.id);
		setState({...state});
	}

	//UTILS
	const rand = (min, max) => {
		return Math.floor(Math.random() * (max - min)) + min;
	};
	const getRecipesByCategory = (category, locked = []) => {

		
		return state.recipes.filter((recipe) =>
			recipe?.listComponentTypes.filter((type) => type.id === category).length > 0 && !locked.includes(recipe.id)
		);
	}
	const filterBy = ({filter = '', recipes}) =>{


		switch (filter) {
			case 'ONLY':
				
				return recipes.filter((recipe) => {
					if(recipe.listComponentTypes.length <= 2){
						return true;
					}
					return false;
			});
			case 'PROT':
				
				return recipes.filter((recipe) => {
					if(recipe.listComponentTypes.some((category)=>category.descr.toUpperCase().includes('HIDR'))){
						return false;
					}
					return true;
			});

			case 'PROT':
				
				return recipes.filter((recipe) => {
					if(recipe.listComponentTypes.some((category)=>category.descr.toUpperCase().includes('HIDR'))){
						return false;
					}
					return true;
				});
				
			case 'HIDR':
				return recipes.filter((recipe) => {
					if(recipe.listComponentTypes.some((category)=>category.descr.toUpperCase().includes('PROT'))){
						return false;
					}
					return true;
				});
			
			case 'COMB':
				return recipes.filter((recipe) => 
				recipe.listComponentTypes.some((category)=>category.descr.toUpperCase().includes('PROT')) &&
				recipe.listComponentTypes.some((category)=>category.descr.toUpperCase().includes('HIDR')));
			default:
				return false
		}



	}
	const getTypeRecipe = ({recipe}) => {
		let proteina;
		let hidrato;
		if(recipe.listComponentTypes.some((type)=> type.descr.toUpperCase().includes('PROT'))){
			proteina = true;
		}
		if(recipe.listComponentTypes.some((type)=> type.descr.toUpperCase().includes('HIDR'))){
			hidrato = true;
		}
		if(proteina && hidrato){
			return 'COMBINADO';
			
		}
		if(proteina && !hidrato){
			return 'PROTEINA';
		}
		if(!proteina && hidrato){
			return 'HIDRATO';
		}		
	}
	const getRecipeByID = (id) => {
		return state.recipes.filter((recipe) => recipe.id === id)[0];
	};
	function shuffleArray(array) {
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[array[i], array[j]] = [array[j], array[i]];
		}
		return array;
	}

	return (
		<PlanContext.Provider
			value={{
				...state,
				setCurrentWeekPlan,
				setCurrentWeekPlanRecipes,
				setNutricionalTabs,
				getRecipeByID,
				randomizeWeekPlan,
				saveCurrentWeekPlanRecipes,
				setCurrentWeekPlanStatus,
				onSelectKitchen,
				onCreateWeeklyPlan,
				onRemoveWeekPlanRecipe,
				onSelectWeekPlanSlot,
				onAssignWeelPlanRecipe,
				randomizeMoreRecipes,
				onRemovePlan
			
			}}
		>
			{children}
		</PlanContext.Provider>
	);
};
export default PlanState;
