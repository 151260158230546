/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState, useMemo } from "react";
import "../../assets/css/dashboard.css";
import {useNavigate, useParams } from "react-router-dom";

import HeaderDashboard from "../../components/HeaderDashboard";
import { Segmented } from "antd";


import AuthContext from "../../context/authContext";
import BackButton from "../../components/buttons/BackButton";

import { BiDish, BiSave, BiSend, BiUser } from "react-icons/bi";

import { colors } from "../../utilities/colors";
import PlanContext from "../../context/planContext";

import { Service } from "../../services/api";
import { getCategoryByID } from "../../utilities/recipes/categories";
import Renderif from "../../components/Renderif";
import RecipeThumbnail from "../../components/RecipeThumbnail";
import { MdClose, MdInfo } from "react-icons/md";
import GeneralButton from "../../components/buttons/GeneralButton";
import AlertContext from "../../context/alertContext";
import { LANG } from "../commun/lang";


function CantidadesAdmin() {
	let navigate = useNavigate();
	const { user, lang } = useContext(AuthContext);
	const { getRecipeByID, currentWeekPlan } = useContext(PlanContext);
    const { showAlert } = useContext(AlertContext);

	const [state, setState] = useState({
		nutritional_data:[],
		weekPlanRecipes:[],
		tab_active:null,
		showRecipeDetail:false, 
		loadingRecipe:false,
		recipeDetail:null,
        loading:false,
		categories_totals: []
	})

	const {id_plan} = useParams('id_plan')
	const {id_kitchen} = useParams('id_kitchen')
	// const {course} = useParams('course')
	const {day} = useParams('day')



	const course = 0;

	// const courses = [
	// 	'Comida Primero',
	// 	'Comida Segundo',
	// 	'Cena Primero',
	// 	'Cena Segundo',
	// 	'Extra Comida',
	// 	'Extra Cena',
	// ]

	// const geTotals = useMemo(()=> {
	// 	console.log(state.nutritional_data)
	// 	if(state.nutritional_data.length !== 0 && state.tab_active != null){

	// 	}
	// },
	// [state.tab_active]
	// );


	useEffect(() => {
		const data = async () => {
			let _nutritional_data = await Service('GET','nutricionalTabs');
			//console.log(_nutritional_data.data.response.filter((item)=>item.id_kitchen === parseInt(id_kitchen) && item.id_plan===parseInt(id_plan)));
			// console.log(_nutritional_data.data.response.filter((item)=>item.id_kitchen === parseInt(id_kitchen) && item.id_plan===parseInt(id_plan)))


			
			let _weeklyPlan = await Service('GET',`recipes/weeklyRecipes/${id_plan}`);
		

			let week_day_meals = JSON.parse(_weeklyPlan.data.response.week_recipes).week[day].meals;

			let aux = _nutritional_data.data.response.filter((item)=>item.id_kitchen === parseInt(id_kitchen) && item.id_plan===parseInt(id_plan))
			

			let categories_totals = [];
			let _categories = [];
			for (let i = 0; i < week_day_meals.length; i++) {
				let categories = getRecipeByID(week_day_meals[i]?.recipe)?.listComponentTypes;
				let category;
				if(categories){
					
					categories = categories.filter((item) => item.id !== "20826336231849157380772641799556162416" && item.id !== "176197763669011160392532919951293537373")
					console.log(categories)

					try {
					category = getCategoryByID(categories[0]?.id)
					_categories.push({
						category:category?.name,
						course:week_day_meals[i].course
					})
					} catch (error) {
							console.log("S")
					}
					
				}
				
			}
		
			for (let i = 0; i < _categories.length; i++) {
				let total_category = 0;
				let amount = 0;
				for (let j = 0; j < aux.length; j++) {

					let category_week = aux[j].nutrition_data.week.filter((cat)=>cat.category === _categories[i].category)[0]
					// console.log(category_week)
					let category_day_qty = category_week.qty[day].qty
					if(category_day_qty > 0){
						console.log("asdasdsa")
						amount++
					}
					// console.log(category_day_qty)
					total_category = total_category + category_day_qty

				}
				categories_totals.push({
					course:_categories[i].course,
					category:_categories[i].category,
					total:total_category,
					amount
				})
				

			}
					
			
			setState({...state, 
				nutritional_data:_nutritional_data.data.response.filter((item)=>item.id_kitchen === parseInt(id_kitchen) && item.id_plan===parseInt(id_plan)),
				weekPlanRecipes:JSON.parse(_weeklyPlan.data.response.week_recipes).week,
				tab_active:parseInt(day),
				categories_totals

			})

		}

		data()
		return () => {}

		
	}, []);

	const getTotals = () => {

	}

	const getQtyByCategoryId = ({category_id,id_user, name}) => {
		
	
		if(!category_id){
			return 0
		}
		let _athlete = state.nutritional_data.find((item)=>item.id_user === id_user)
		let _qty = _athlete.nutrition_data.week.find((item) => item.category_id === category_id)?.qty;
	
		if(!_qty){
			return 0;
		}
		//let qty = 0;
		// _qty.map((item)=>{
		// 	qty = qty + item.qty
		// })

		return _qty[state.tab_active].qty;
	}
	const getTotalByCategoryId = ({category_id}) => {
		let total = 0
		state.nutritional_data.map((user) => {
		
			let _qty = getQtyByCategoryId({category_id,id_user:user.id_user})

			total = total + (_qty + (_qty * user.invitados))
		})
		return total;
	}
	const showRecipeDetail = async (recipe) => {
		setState({...state, showRecipeDetail:true, loadingRecipe:true})
		let _recipeDetail = await Service('TSPOONLAB',`recipe/${recipe.id}`)
		setState({...state, recipeDetail:_recipeDetail.data, loadingRecipe:false, showRecipeDetail:true})
	}

    const saveCurrentWeekPlanRecipes = async () => {

        setState({...state, loading:true})
     
        let week_recipes = {
            course:[
				"Comida Primero",
				"Comida Segundo",
				"Cena Primero",
				"Cena Segundo",
				"Extra Comida",
				"Extra Cena",
			]
        };
        week_recipes.week = state.weekPlanRecipes;
		
		let body = {
			id_week_plan: parseInt(id_plan),
			week_recipes: JSON.stringify(week_recipes),
		};
		let _saveCurrentWeekPlanRecipes;

		_saveCurrentWeekPlanRecipes = await Service(
			"PUT",
			"recipes/weeklyRecipes",
			body
		);



        for (let i = 0; i < state.nutritional_data.length; i++) {
           
            let body = {
                id_user: state.nutritional_data[i].id_user,
                id_nutricional:state.nutritional_data[i].id_nutricional,
                comments:state.nutritional_data[i].comments
            };
            let _saveCurrentNutritionalComments = await Service(
                "PUT",
                "nutricionalTabs/update",
                body
            );

        }

		showAlert({type:'success', message:LANG.NOT.guardar_plan_semanal[lang], description:LANG.NOT.plan_semanal_guardado[lang]})
        setState({...state, loading:false})
		
	};
    const onSendToKitchen = async () => {

		let _onSend = await Service('PUT', `weeklyPlans/${id_plan}`,{status:1})
	
		let _onNotificate = await Service('POST', `notification`,{
			notification:`${user.firstname} ${LANG.NOT.plan_semanal_enviado[lang]}`,
			id_sender:user.id_user,
			id_user:currentWeekPlan.id_user
		})
		showAlert({type:'success', message:LANG.NOT.enviar_a_cocina, description:LANG.NOT.enviado_a_cocina})
	}
	const onAddAthleteComment = async ({comment, id_athlete}) => {

		if(!state.weekPlanRecipes[state.tab_active].comments_athlete){
			state.weekPlanRecipes[state.tab_active].comments_athlete = [];
		}

		let _comments_athlete = state.weekPlanRecipes[state.tab_active]?.comments_athlete.filter((comment)=>comment.id_athlete !== id_athlete);
		_comments_athlete.push({id_athlete, comment})
		state.weekPlanRecipes[state.tab_active].comments_athlete = _comments_athlete;
		setState({...state});
	}

	return (
		<>
			<HeaderDashboard />
				<div className="container-fluid p-3">
					<div className="d-flex flex-wrap align-content-center">
						<div className="col-12 col-lg">
							<BackButton route="/plan-detalle" />
						</div>
                        <Renderif isTrue={state.loading}>
                            <div className="spinner-border text-light" role="status"></div>
                        </Renderif>

                        <Renderif isTrue={!state.loading && state.nutritional_data.length > 0}>
                            <GeneralButton
                                className="btn__blue btn__general"
                                title={LANG.WEEKPLAN.SAVE[lang]}
                                icon={<BiSave />}
                                onClick={() => {
									//console.log(state.nutritional_data)
									saveCurrentWeekPlanRecipes()
								}}
                            />
							{user.id_rol !== 2?
                            <GeneralButton
                                className="btn__blue btn__general ms-2"
                                title={LANG.PLANDETAIL.enviar_a_cocina[lang]}
                                icon={<BiDish />}
                                onClick={() => {onSendToKitchen()}}
                            />
							:<></>}	
                        </Renderif>
                     

					</div>
				</div>

	

			<div className="contianer-fluid px-3 pb-5">

				<Segmented
					value={state.tab_active}
					options={[
				

						{label:LANG.WEEKPLAN.MONDAY[lang], value:0},
						{label:LANG.WEEKPLAN.TUESDAY[lang], value:1},
						{label:LANG.WEEKPLAN.WEDNESDAY[lang], value:2},
						{label:LANG.WEEKPLAN.THURSDAY[lang], value:3},
						{label:LANG.WEEKPLAN.FRIDAY[lang], value:4},
						{label:LANG.WEEKPLAN.SATURDAY[lang], value:5},


					]}
					onChange={(tab,i)=>{
						console.log(tab);
					
						let week_day_meals = state.weekPlanRecipes[tab].meals
					
					
						let categories_totals = [];
						let _categories = [];
						for (let i = 0; i < week_day_meals.length; i++) {
							let categories = getRecipeByID(week_day_meals[i]?.recipe)?.listComponentTypes;
					
							let category;
							if(categories){
								
								categories = categories.filter((item) => item.id !== "20826336231849157380772641799556162416" && item.id !== "176197763669011160392532919951293537373")
								console.log(categories)

								try {
								category = getCategoryByID(categories[0]?.id)
								_categories.push({
									category:category?.name,
									course:week_day_meals[i].course
								})
								} catch (error) {
										console.log("S")
								}
								
							}
							
						}
						
						for (let i = 0; i < _categories.length; i++) {
							let total_category = 0;
							let amount = 0;
							for (let j = 0; j < state.nutritional_data.length; j++) {

								let category_week = state.nutritional_data[j].nutrition_data.week.filter((cat)=>cat.category === _categories[i].category)[0]
							
								let category_day_qty = category_week?.qty[tab]?.qty
								

								if(category_day_qty > 0){
									amount++
								}
								total_category = total_category + category_day_qty

							}
							categories_totals.push({
								course:_categories[i].course,
								category:_categories[i].category,
								total:total_category,
								amount
							})
							

						}
					
					
						setState({...state, tab_active:tab, categories_totals})
					}}
					className="py-3"
				/>
				<Renderif isTrue={state.nutritional_data.length === 0 && state.loading === false}>
					<div className="container-fluid">
						<div className="d-flex align-items-center">
							<div className="icon">
								<MdInfo color={colors.mint} size={18}></MdInfo>
							</div>
							<div className="text-white f-Poppins-Medium">{LANG.NOT.para_ver[lang]}</div>
						</div>
						
					</div>
				</Renderif>
				<Renderif isTrue={state.nutritional_data.length > 0}>
					{user.id_rol !== 2 ? 
					<div className="bg-dark-blue rounded-2 p-2">
						<div className="text-white f-16 f-anton">{LANG.PLANDETAIL.comentarios_general[lang]}:</div>
						{/* <div className="text-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry</div> */}
						<div className="py-1">
							<textarea  value={state.weekPlanRecipes[state.tab_active]?.comments ? state.weekPlanRecipes[state.tab_active].comments : ''} onChange={(e)=>{

								if(!state.weekPlanRecipes[state.tab_active].comments){
									state.weekPlanRecipes[state.tab_active].comments = ''
								}

								state.weekPlanRecipes[state.tab_active].comments = e.target.value
								setState({...state})


							}} rows={3} className="textarea text-yellow fw-semibold"></textarea>
							</div>
					</div>
					:<></>}
					<div className="pt-3 w-100 overflow-auto no-wrap">

						{state.categories_totals.map((category, i)=>{

							return (
								<div key={i} className="bg-dark-blue rounded-2 widget">
									<div className=" bg-mint rounded-top py-1">
										<div className="text-center f-14 f-anton text-black">{category?.category}</div>
									</div>
									<div className="text-white text-center pt-3">{LANG.WEEKPLAN[category?.course?.replace(" ","_")][lang]}</div>
									<div className="text-white text-center f-anton f-24 pb-2">{category?.total} gr</div>
									<div className="border-b-blue-shade"></div>
									<div className="px-3 py-2">
										<div className="text-mint-shade f-12 f-Poppins-Medium text-center">{category?.amount} {LANG.NOT.porciones[lang]}</div>
									</div>
								</div>
							)
						})}

					

					</div>


					<div className="">
						{state.nutritional_data?.map((data, i)=>{

							
						let comment = state.weekPlanRecipes[state.tab_active]?.comments_athlete?.find((comment)=>comment.id_athlete === data.id_athlete)
						comment = comment ? comment.comment : '';
					
						
						return(
							<table key={i} className="ant-table w-100 bg-dark-blue rounded-2 overflow-hidden mt-4">
								<thead className="ant-table-thead p-0">
									<tr>
										<td colSpan={5}>
											<div className="p-2">
												<div className="d-flex align-items-center">
													<div className="icon p-0"><BiUser className="" color={colors.mint} size={16}></BiUser></div>

													<div className="ps-1 d-flex align-items-center">

														<div className="text-left text-white f-14 f-anton">{`${data?.firstname} ${data?.lastname}`}</div>

														

														<div className="text-left f-14 f-Poppins-Medium text-mint-shade ps-2">{LANG.NOT[data?.fedding][lang]}</div>
													</div>
												
												</div>
												<div className="text-left text-white f-12 f-anton d-flex mt-2">{LANG.PLANDETAIL.alergias_intolerancias[lang]}: <div className=" f-Poppins-Bold ms-2 text-mint"> {`${data?.allergy}`}</div></div>
											</div>
										</td>
									</tr>
									<tr className="p-0">
										<th className="col p-2"><div className="text-left f-anton f-14">{LANG.PLANDETAIL.servicio[lang]}</div></th>
										<th className="col p-2"><div className="text-left f-anton f-14">{LANG.PLANDETAIL.receta[lang]}</div></th>
										<th className="col p-2"><div className="text-center f-anton f-14">{LANG.PLANDETAIL.gramos[lang]}</div></th>
										{user.id_rol !== 2 ? 
										<th className="col p-2">
											<div className="d-flex justify-content-center align-items-center">

												<div className="text-center f-anton f-14">{LANG.PLANDETAIL.invitados[lang]}</div>

												<div className="bg-mint rounded-2 mx-1">
													<div className="f-12 text-dark-blue f-anton px-2">
														{data?.invitados}
													</div>
												</div>

												

											</div>
											
										</th>
										:<></>}
										<th className="col p-2"><div className="text-center f-anton f-14">{LANG.PLANDETAIL.total[lang]}</div></th>
									</tr>
								</thead>
								<tbody className="ant-table-tbody">
								
										{state.weekPlanRecipes[state.tab_active]?.meals.map((meal,i)=>{

									


										

											let categories = getRecipeByID(meal?.recipe)?.listComponentTypes;
											let category;
											for(const i in categories){
												if(getCategoryByID(categories[i].id)){
													category = getCategoryByID(categories[i].id)
													
													break;
												}
												
											}
											let qty_gramos = getQtyByCategoryId({category_id:category?.id, id_user:data.id_user});
											let qty_invitados = qty_gramos * data?.invitados;
											let qty_total = qty_gramos + qty_invitados;
											let show = true;

												switch (meal.course) {
													case 'Comida Primero':
														data.service === 'Comida' || data.service === 'Comida y Cena' ? show = true : show = false;
														break;
													case 'Comida Segundo':
														data.service === 'Comida' || data.service === 'Comida y Cena' ? show = true : show = false;
														break;
													case 'Cena Primero':
														data.service === 'Cena' || data.service === 'Comida y Cena' ? show = true : show = false;
														break;
													case 'Cena Segundo':
														data.service === 'Cena' || data.service === 'Comida y Cena' ? show = true : show = false;
														break;
													case 'Extra Comida':
														data.service === 'Comida' || data.service === 'Comida y Cena' ? show = true : show = false;
														break;
													case 'Extra Cena':
														data.service === 'Cena' || data.service === 'Comida y Cena' ? show = true : show = false;
														break;
												
													default:
														break;
											}
										return (
											
											<Renderif key={i} isTrue={show}>
													<tr>
														<td className="p-2">
															
															<div className="f-12 text-mint f-anton text-mint ">{category?.name}</div>
															<div className="f-14 text-white texrt-center f-Poppins-Regular" key={i}>{LANG.WEEKPLAN[category?.course?.replace(" ","_")][lang]}</div>
														</td>
														<td className="px-2 py-2">
															<div className="recipe_item">
																<Renderif isTrue={meal.recipe}>
																	<div className="foodContainer">
																		<div><RecipeThumbnail recipe={meal?.recipe}></RecipeThumbnail></div>
																		<div className="name d-flex align-items-center transition f-12 pe-3 unassigned cursor-pointer" onClick={()=>{showRecipeDetail(getRecipeByID(meal?.recipe))}}>
																			
																			<div className="col text-left f-Poppins-Medium ps-2">{getRecipeByID(meal?.recipe)?.descr}</div>
																		</div>
																	</div>
																</Renderif>
																<Renderif isTrue={!meal.recipe}>
																	<div className="foodContainer">
																		<div><RecipeThumbnail recipe={null}></RecipeThumbnail></div>
																		<div className={`name events-none`}>
																			<div className="f-12 f-Poppins-Regular text-mint-shade text-center ">
																				{LANG.PLANDETAIL.sin_asignar[lang]}
																			</div>
																		</div>
																	</div>
																</Renderif>
																
															</div>
														</td>
														<td className="px-2 py-2">
															<div className="text-white text-center f-Poppins-Regular" key={i}>{qty_gramos} gr.</div>
														</td>
														{user.id_rol !== 2 ? 
														<td className="px-2 py-2">
															<div className="text-white text-center f-Poppins-Regular" key={i}>{qty_invitados}gr.</div>
														</td>
														:<></>}
														<td className="px-2 py-2">
															<div className="text-white text-center f-Poppins-Regular" key={i}>{qty_total} gr.</div>
														</td>
													</tr>
											</Renderif>
										)
										})}
										<tr className="p-0">
											<td className="p-0" colSpan={5}>
											<div className="bg-dark-blue rounded-2 p-2">
												<div className="d-flex justify-content-end align-items-end">
													<div className="text-white f-16 f-anton">{LANG.WEEKPLAN.COMMENTS[lang]}:</div>
													<div className="col"></div>
												</div>
											
												
												<div className="py-1">
													<textarea value={comment}  onChange={(e)=>{
														
														onAddAthleteComment({comment:e.target.value, id_athlete:data.id_athlete})
														// state.nutritional_data[i].comments = e.target.value
														// setState({...state})
													}} rows={3} className="textarea text-yellow fw-semibold"></textarea>
												</div>
											</div>
											</td>
										</tr>
								</tbody>
							</table>
						)
							})}
					</div>
				</Renderif>

			</div>
			<Renderif isTrue={state.showRecipeDetail}>
				<div className="d-flex position-fixed vw-100 vh-100" style={{top: 0,right: 0,zIndex: 1,}}>

				

					<div className='col-3 col-lg-6 col-xl-7 col-xxl-8 bg-overlay' onClick={()=>{setState({...state, showRecipeDetail:false})}}></div>  
					<div className='col-9 col-lg-6 col-xl-5 col-xxl-4'>


			

						<div className=' bg-dark-blue border-l-mint h-100 overflow-auto hide-scroll'>

							<div className='d-flex'>
								<div className='col'></div>
								<div className='cursor-pointer p-2' onClick={()=>{setState({...state, showRecipeDetail:false})}}>
								<MdClose size={32} color={colors.white}></MdClose>
								</div>
							</div>


							<Renderif isTrue={state.loadingRecipe}>
								<div className="d-flex justify-content-center align-items-center h-75">
									<div className="spinner-border text-light" role="status">
										<span className="sr-only"></span>
									</div>
								</div>
							</Renderif>
							<Renderif isTrue={!state.loadingRecipe}>
							
								<div className='f-26 text-center f-anton text-white px-4'>{state?.recipeDetail?.descr}</div>
								

								<div className='d-flex justify-content-center align-items-center py-3'>
									<img className='w-50 ' src={`https://app.tspoonlab.com/recipes/api${state?.recipeDetail?.imageNormal}?rememberme=dGVjQGdvb3NlYW5kaG9wcGVyLmNvbToxNzI5MDIxNjEwNDEzOjo6MzRiOTEyMWI0ZmZlOGVlMTExYzI3MzAzODEwNGZhYjU`}></img>
								</div>

								<div className='px-4'>

									<div className='f-16 f-Poppins-Bold  text-yellow py-2'>{LANG.NOT.ingredientes[lang]}</div>
									<div className='py-2'>

									{state?.recipeDetail?.listComponentDetail.map((component,i)=>{
										return (
											<div key={i} className="d-flex  pb-2 pt-1 border-b-blue-shade">

												<div className="col-4">
													<div className="text-mint text-left f-12 f-Poppins-Medium">{component.quantity} {component.unit}</div>
												</div>

												<div className="col-10">
													<div>
														<div className="text-white f-12 f-Poppins-Medium px-2">
															{component.descr}
															{component.use !== null ? 
															<span className="px-1">({component.use})</span>
															:null}
														</div>

														{component.comment ? 
														<div className="text-mint-shade f-12 f-Poppins-Medium px-2">{component.comment}</div>
														:null}
														
														{component.quantityGross ? 
														<div className=" text-mint-shade f-12 f-Poppins-Medium px-2">Bruto: {component.quantityGross} {component.unit}</div>
														:null}
														
													
													</div>
												</div>
												
												
											

												
											</div>
										)
									})}
									</div>

								</div>
								<div className='px-4'>
									

									<div className='f-16 f-Poppins-Bold  text-yellow'>{LANG.NOT.preparacion[lang]}</div>
									<div className='f-14 f-Poppins-Regular text-justify text-white py-2'>{state?.recipeDetail?.process}</div>
							

								</div>
								<div className="py-4 my-4"></div>
							</Renderif>
							
							

						
						</div>
					
					</div>

					



				
				</div>
			</Renderif>


			
		</>
	);
}

export default CantidadesAdmin;
