/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import "../../assets/css/dashboard.css";
import { Link, Redirect, Navigate, useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HeaderDashboard from "../../components/HeaderDashboard";
import GeneralButton from "../../components/buttons/GeneralButton";
import { BiEdit } from "react-icons/bi";
import AuthContext from "../../context/authContext";
import BackButton from "../../components/buttons/BackButton";
import { useFilePicker } from "use-file-picker";
import { Service } from "../../services/api";
import AlertContext from "../../context/alertContext";
import { LANG } from "../commun/lang";

function EditProfile() {
	const navigate = useNavigate();
    const {auth,updateUser, lang} = useContext(AuthContext);
    const {showAlert} = useContext(AlertContext)

 
    const [openFileSelector, { filesContent, loading, errors, plainFiles, clear }] = useFilePicker({
        multiple:false,
        accept: 'image/*',
        readAs: "DataURL"
    });

    const [user, setUser] = useState({
        firstname : auth.firstname,
        lastname : auth.lastname,
        gender : auth.gender,
        country : auth.country,
        state : auth.state,
        city : auth.city,
        cp : auth.cp,
        email : auth.email,
        phone : auth.phone,
        picture : auth.picture
        
        // password : '',
        // picture : '',
    }) 
    const onSave = async() => {

 
    
        let _user = await Service('PUT', 'user', {...user, id_user:auth.id_user});
        console.log(_user);
        // let _athlete = await Service('PUT', 'athlete/update', {...deportista, id_athlete:athlete.id_athlete});
        // console.log(_athlete)

        if(filesContent.length > 0){
            var body = new FormData()
            body.set('image',plainFiles[0])
            let _image = await Service('POST', 'user/uploadimage/'+auth.id_user, body); 
            console.log(_image);
            auth.picture = _image.data.response.picture;
            
        }
        
        // athlete.firstname = user.firstname
        // athlete.lastname = user.lastname
        // athlete.city = user.city;
        // athlete.state = user.state;
        // athlete.email = user.email;
        // athlete.club = deportista.club;
        // athlete.delevery_address = deportista.delevery_address;
        // athlete.allergy = deportista.allergy;
        // athlete.delevery_occur = deportista.delevery_occur;
        // athlete.fedding = deportista.fedding;

        auth.firstname = user.firstname
        auth.lastname = user.lastname
        auth.gender = user.gender
        auth.country = user.country
        auth.state = user.state
        auth.city = user.city
        auth.cp = user.cp
        auth.email = user.email
        auth.phone = user.phone
    
        updateUser(auth);
        // updateAthlete(athlete);
        navigate(-1)
        showAlert({type:'success', message:LANG.NOT.guardar[lang], description:LANG.NOT.cambios_guardados[lang]})
      
    
      
      }
    

	return (
		<>
        <HeaderDashboard />
          <div className="container-fluid p-3">

            <div className="d-flex flex-wrap align-content-center">
                <div className="col-12 col-lg">
                    <BackButton route="/plan-detalle" />
                    <div className="text-white f-30 f-anton text-uppercase ps-1">
                        EDITAR PERFIL
                    </div>
                
                </div>
            </div>
        </div>
      
        <div className="container-fluid px-3">
            <div className='text-white bg-dark-blue rounded'>

                <div className='d-flex p-3'>
                    <div className='col'></div>
                    
                    <GeneralButton
                        className="btn__blue btn__general"
                        title="GUARDAR"
                        icon={<BiEdit />}
                        onClick={() => {
                            onSave()
                        }}
                    />
                    
                </div>

                <div className=' bg-mint-light'>
                    <div className='border-b-mint d-flex p-3'>
                        <div style={{marginTop:-35}} className='bg-yellow p-1 rounded-3 position-relative'>
                            <div className='image-placeholder' onClick={()=>{
                                    openFileSelector()
                                }}>
                                    <BiEdit size={36}></BiEdit>
                                </div>
                                <img style={{width:100}} className='rounded-3 h-100' src={
                                    filesContent.length > 0 ? filesContent[0].content :
                                    user?.picture ? process.env.REACT_APP_API_URL+'/images/'+user?.picture : process.env.REACT_APP_API_URL + '/images/profile.png' 
                                    
                                    }></img>
                        </div>
                        <div className='col ps-2 d-flex flex-column justify-content-end'>
                            <div className='f-20 f-Poppins-Bold text-mint-shade line-1'>{auth?.firstname}</div>
                            <div className='f-14 f-Poppins-Regular text-mint-shade'>{auth?.rol}</div>
                        </div>
                    </div>

                    <div className='d-flex border-b-mint p-3'>
                        <div className=' col f-Poppins-Medium text-mint-shade'>{LANG.PROFILE.FULLNAME[lang]}:</div>
                        {/* <div className=' col f-Poppins-Medium text-mint-shade'>{user?.firstname} {user?.lastname}</div> */}
                        <div className='col'>
                            <input required type='text' className='input text-mint-shade fw-bold' value={user?.firstname} onChange={(value)=>{setUser({...user, firstname:value.currentTarget.value})}}></input>
                        </div>
                    </div>
                    <div className='d-flex border-b-mint p-3'>
                        <div className=' col f-Poppins-Medium text-mint-shade'>{LANG.REGISTER.Apellido[lang]}:</div>
                        {/* <div className=' col f-Poppins-Medium text-mint-shade'>{user?.firstname} {user?.lastname}</div> */}
                        <div className='col mt-1'>
                            <input required type='text' className='input text-mint-shade fw-bold' value={user?.lastname} onChange={(value)=>{setUser({...user, lastname:value.currentTarget.value})}}></input>
                        </div>
                    </div>
                    <div className='d-flex border-b-mint p-3'>
                        <div className=' col f-Poppins-Medium text-mint-shade'>E-mail:</div>
                        {/* <div className=' col f-Poppins-Medium text-mint-shade'>{user?.email}</div> */}
                        <div className='col'>
                            <input required type='text' className='input text-mint-shade' value={user?.email} onChange={(value)=>{setUser({...user, email:value.currentTarget.value})}}></input>
                        </div>
                    </div>
                    <div className='d-flex border-b-mint p-3'>
                        <div className=' col f-Poppins-Medium text-mint-shade'>{LANG.REGISTER.Telefono[lang]}:</div>
                        {/* <div className=' col f-Poppins-Medium text-mint-shade'>{user?.phone}</div> */}
                        <div className='col'>
                            <input type='number' className='input p-2 text-mint-shade' value={user.phone} onChange={(value)=>{setUser({...user, phone:value.currentTarget.value})}}></input>
                        </div>
                    </div>

                    <div className='d-flex border-b-mint p-3'>
                        <div className=' col f-Poppins-Medium text-mint-shade'>{LANG.REGISTER.Ciudad[lang]}:</div>
                        {/* <div className=' col f-Poppins-Medium text-mint-shade'>{user?.city} {user?.state} {user?.country}</div> */}
                        <div className='col'>
                            <input required type='text' className='input text-mint-shade' value={user?.city} onChange={(value)=>{setUser({...user, city:value.currentTarget.value})}}></input>
                        </div>
                    </div>
                    <div className='d-flex border-b-mint p-3'>
                        <div className=' col f-Poppins-Medium text-mint-shade'>{LANG.REGISTER.Estado[lang]}:</div>
                        {/* <div className=' col f-Poppins-Medium text-mint-shade'>{user?.city} {user?.state} {user?.country}</div> */}
                        <div className='col'>
                            <input required type='text' className='input text-mint-shade' value={user?.state} onChange={(value)=>{setUser({...user, state:value.currentTarget.value})}}></input>
                        </div>
                    </div>
                   

                </div>
                <div className="py-4"></div>

            </div>
        </div>
    </>
	);
}

export default EditProfile;
