
import { useContext, useEffect, useState } from "react";
import AuthContext from "../context/authContext";
import NutricionistaContext from "../context/nutricionistaContext";


import { Service } from "../services/api";


export const NutricionistaState = ({children}) => {


    const [state, setState] = useState({
        athletes:[],
        nutricionist:null,
        weekPlans:[]
        
    })

    const {user} = useContext(AuthContext);

    useEffect(()=>{
        const data = async () => {
            let _nutricionist = await Service("GET",`nutricionist/${user?.id_user}`);
           

            let _athletes = await Service("GET",`athletes`);
            console.log(user.id_user);
            console.log(_athletes)
            let athletes = _athletes.data.response.filter((athlete)=> athlete.id_nutricionist === _nutricionist.data.response[0].id_nutricionist || athlete.id_nutricionist_owner === user.id_user);
    
            let _weekplans = await Service("GET",`weeklyPlans/full`);
            let weekPlans = _weekplans.data.response.filter((weekPlan)=> athletes.some((athlete) => athlete.id_kitchen === weekPlan.id_kitchen));
            console.log(_weekplans);
           
           
            setState((state) =>({
              ...state,
              athletes,
              nutricionist:_nutricionist.data.response[0],
              weekPlans,
            }));
        }
        data()

        return () => {}
    },[])



    

  
    return (
      <NutricionistaContext.Provider value={{
        ...state
      }}>
        
        {children}
      </NutricionistaContext.Provider>
    );
  };