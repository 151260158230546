/* eslint-disable no-unused-vars */

import { Segmented } from "antd";
import { useContext } from "react";
import SearchForm from "../../components/SearchForm";
import AuthContext from "../../context/authContext";
import { LANG } from "../commun/lang";

function UsersSegment({tab, onChangeTab, onSearch}) {

	const {user, lang} = useContext(AuthContext);
	
	return (
		<div className="container-fluid px-3">
			<div className="d-flex rounded-2 bg-dark-blue p-2">


					{/* <Spin spinning={false}> */}
						<Segmented
							defaultValue={tab?.charAt(0)?.toUpperCase() + tab?.slice(1)}
							options={
							user.id_rol === 2 ? [
								{value:"Deportistas", label:LANG.DASHBOARD.ATHLETES[lang]}
							]:
							[
								{value:"Deportistas", label:LANG.DASHBOARD.ATHLETES[lang]},
								{value:"Nutricionistas", label:LANG.DASHBOARD.NUTRICIONISTS[lang]},
								{value:"Cocinas", label:LANG.DASHBOARD.KITCHENS[lang]}
								
								// "Chefs",
								
							]}
							onChange={(tab)=>{onChangeTab(tab)}}
							className=""
						/>
					{/* </Spin> */}
	
					<div className="col"></div>
		
		
					<SearchForm
						width="300px"
						height="40px"
						placeholder={LANG.USERS.SEARCH[lang]}
						color="#15252d"
						background="#c9fdfa"
						borderColor="#c9fdfa"
						onChange={(text)=>{
							onSearch(text)
						}}
					/>
		

				<div className="clear"></div>
			</div>
		</div>
	);
}
export default UsersSegment;
