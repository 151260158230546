
import { useContext, useEffect, useState } from "react";
import AuthContext from "../context/authContext";

import CocinaContext from "../context/cocinaContext";
import { Service } from "../services/api";


export const CocinaState = ({children}) => {


    const [state, setState] = useState({
        athletes:[],
        weekPlans:[],
        kitchen:[]
    })

    const {user} = useContext(AuthContext);

    useEffect(()=>{
        const data = async () => {
            let _kitchen = await Service("GET",`chef/${user?.id_user}`);
            let _athletes = await Service("GET",`athletes/kitchen/${_kitchen.data.response.id_kitchen}`);
            let _weekplans = await Service(
              "GET",
              `weeklyPlans/full/athlete/cocina/${user?.id_user}`
            );
            console.log(_weekplans);
            setState({
              ...state,
              athletes:_athletes.data.response,
              kitchen:_kitchen.data.response,
              weekPlans:_weekplans.data.response.filter((plan)=>plan.status === 1)
            });
        }
        data()

        return () => {}
    },[])

    return (
      <CocinaContext.Provider value={{
        ...state
      }}>
        
        {children}
      </CocinaContext.Provider>
    );
  };