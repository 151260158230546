import React, { useContext, useEffect, useState } from 'react'
import "../../assets/css/registro.css";
import { BsCircle, BsRecordCircleFill } from 'react-icons/bs';
import {IoIosArrowBack,IoIosArrowDown} from 'react-icons/io'
import {FaStripe,FaCcPaypal} from 'react-icons/fa'
import Renderif from '../../components/Renderif';
import { colors } from '../../utilities/colors';

import CheckBox from '../../components/buttons/checkbox';
import { Service } from '../../services/api';
import { useNavigate } from 'react-router-dom';

import AuthContext from '../../context/authContext';
import { ELITE_LINKS, ELITE_LINKS_STAGE, NUTRICIONISTA_PRO, NUTRICIONISTA_PRO_STAGE, PRO_LINKS, PRO_LINKS_STAGE } from '../../payment/stripe_links';
import { LANG } from '../commun/lang';






export default function Registro() {

  const navigate = useNavigate();

  let cocina_steps = [
    'Tipo de registro',
    'Datos',
    'Terminos y condiciones',
  ]
  let nutricionista_steps = [
    'Tipo de registro',
    'Datos',
    'Terminos y condiciones',
    'Pago',
  ]
  let deportista_steps = [
    'Tipo de registro',
    'Datos generales',
    'Terminos y condiciones',
    'Datos del deportista',
    'Servicio',
    'Pago',
  ]

  const {user, setUser, lang} = useContext(AuthContext)
  const [formValidation, setFormValidation] = useState({
    validated:false,
  })
  const [state, setState] = useState({
    step:0,
    steps: ['Tipo de registro'],
    rol_active:'Deportista',
    tyco_active:true,
    newsletter_active:false,
    plan_active:'PRO',
    payment:'Stripe',
    password_confirmed:'',

  })

  //DATOS DE REGISTRO EN COMUN
  const [registro, setRegistro] = useState({
    firstname : '',
    lastname : '',
    gender : '',
    country : 'Spain',
    state : '',
    city : '',
    cp : '',
    email : '',
    phone : '',
    password : '',
    picture : '',
  }) 

  //DATOS DE REGISTRO COMO DEPORTISTA
  const [deportista, setDeportista] = useState({
    fedding :'',
    id_suscription :1,
    birthday :'',
    club :'',
    allergy :'',
    service :'Comida',
    service_days :[],
    delevery_address :'',
    delevery_occur :'',
    delevery_time :'',
    invitados :'0',
    objetivo:'',
    peso:'',
    estatura:'',
    imc:'',
    payment_method :'',
  }) 

  const onRegister = async() => {
    if(typeof(deportista.service_days) === 'object'){
      deportista.service_days = JSON.stringify(deportista.service_days)
  
    }

    let active;
    switch (state.rol_active) {
      case 'Cocina':
        active = 2
        break;
      case 'Nutricionista':
        if(deportista.id_suscription == 2){
          active = 1
        }else{
          active = 2
        }
        break;
      case 'Deportista':
        active = 1
        break;

      default:
        active = 2
        break;
    }
    
    let _registro = await Service('POST', 'user/register', {active:active, ...registro});
    

    switch (_registro.data.response.id_rol) {
      case 2:
        let _nutricionist = await Service('POST', 'nutricionist', {id_user:_registro.data.response.id_user, id_suscription_nutricionist:deportista.id_suscription});
        let payment_url_nutricionist;
        if(deportista.id_suscription === 2){
          payment_url_nutricionist = NUTRICIONISTA_PRO[0]
          window.open(payment_url_nutricionist+registro.email, '_blank');
        }
       
        
        break;
      case 3:
        let _chef = await Service('POST', 'chef', {id_user:_registro.data.response.id_user, kitchen:registro.firstname});
        
        break;
      case 4:
        
        let _athlete = await Service('POST', 'athlete', {...deportista, id_user:_registro.data.response.id_user});
        let payment_url;
        if(deportista.id_suscription === 1){

          //PRO
          switch (deportista.invitados) {
            case '0':
              payment_url = PRO_LINKS[0]
              break;
            case '1':
              payment_url = PRO_LINKS[1]
              break;
            case '2':
              payment_url = PRO_LINKS[2]
              break;
            case '3':
      
            default:
              payment_url = PRO_LINKS[0]
              break;
          }
        }else{
    
          //ELITE
          switch (deportista.invitados) {
            case '0':
              payment_url = ELITE_LINKS[0]
              break;
            case '1':
              payment_url = ELITE_LINKS[1]
              break;
            case '2':
              payment_url = ELITE_LINKS[2]
              break;
            case '3':
              payment_url = ELITE_LINKS[3]
              break;
            default:
              payment_url = ELITE_LINKS[0]
              break;
          }
    
        }
        window.open(payment_url+registro.email, '_blank');
        break;
      default:
        break;
    }

    localStorage.setItem("user", JSON.stringify(_registro.data.response));
    setUser(_registro.data.response)


   

   

  


 
    navigate("/dashboard");
  }
  const onNextStep = () => {
    

    setFormValidation({...formValidation, validated:true})


    if(state.step !== 0){
      if(!formValidation.avaibale){

        return;
      }
    }

    if(state.step === 2){
      if(registro.password !== state.password_confirmed){
        return;
      }
    }

    // if(state.step === 4){
    //   if(deportista.service_days.length === 0){
    //     return;
    //   }
    // }
  

  

  
    switch (state.rol_active) {
      case 'Cocina':
        if(state.step === 2){
          registro.id_rol = 3;
          onRegister();
          return;
        }
        state.steps.push(cocina_steps[state.step + 1]);

        break;
      case 'Nutricionista':
        if(state.step === 3){
          registro.id_rol = 2;
          onRegister();
          return;
        }
          
        state.steps.push(nutricionista_steps[state.step + 1]);
        break;
      case 'Deportista':
        if(state.step === 5){
          registro.id_rol = 4;
          onRegister();
          return;
        }
        state.steps.push(deportista_steps[state.step + 1]);
        break;
      default:
        break;
    }
    state.step = state.step + 1;
    setState({...state})
    setFormValidation({validated:false, avaibale:false})

  }
  const onPrevStep = () => {
  
    if(state.step === 0 ){
      navigate('/')
      return;
    }
  
    if(state.step !== 0){
        state.steps.pop()
        state.step = state.step - 1;
        setState({...state})
    }
  }
  const RenderNextStep = () => {
    return (
      <div className='d-flex justify-content-end py-3 px-3 '>
        <div><input type="submit" className='input px-4' value={LANG.REGISTER.siguiente[lang]} onClick={()=>{
          onNextStep()
        }} ></input></div>
      </div>
    )
  }
  const onChangeDay = (day) => {


    if(typeof(deportista.service_days) === 'string'){
      deportista.service_days = JSON.parse(deportista.service_days);
      setDeportista({...deportista});
    }
   

    if(day.active){
      deportista.service_days.push(day.value)
      return;
    }
    deportista.service_days = deportista.service_days.filter((item) => item !== day.value)

  }
  const fieldValidation = (field) => {
    
    if(!formValidation.validated){
      return 'd-none';
    }
    if(field === 'service_days'){
      if(deportista.service_days.length === 0){
        return 'd-block'
      }
    }
    if(field === 'password_confirmed'){
      if(state.password_confirmed !== '' && registro.password !== state.password_confirmed){
        return 'd-block'
      }else{
        return 'd-none'
      }
    }

    if(registro[field] === '' || deportista[field] === ''){
      return 'd-block'
    }

    return'd-none'
  }

  return (
    <section className="mb__registro bg-secundary-dark-blue">
      <div className="containe-fluid d-flex flex-column h-100 ">


        <div className='container d-flex py-4'>
          <Renderif isTrue={state.rol_active === 'Cocina'}>
            {cocina_steps.map((item, i)=> {
              return (
                <div key={i}  className={`col mx-2 pt-3 ${state.step >= i ? 'registro_step--active' : 'registro_step'}`}>  
                    <div className='f-Poppins-Bold text-mint-shade f-12 line-1'>{LANG.REGISTER.STEP[lang]} {i + 1}</div>
                    <div className='f-Poppins-Medium text-white f-14'>{LANG.NOT[item][lang]}</div>
                </div>
              )
            })}
          </Renderif>

          <Renderif isTrue={state.rol_active === 'Nutricionista'}>
            {nutricionista_steps.map((item, i)=> {
              return (
                <div key={i} className={`col mx-2 pt-3 ${state.step >= i ? 'registro_step--active' : 'registro_step'}`}>
                    <div className='f-Poppins-Bold text-mint-shade f-12 line-1'>{LANG.REGISTER.STEP[lang]} {i + 1}</div>
                    <div className='f-Poppins-Medium text-white f-14'>{LANG.NOT[item][lang]}</div>
                </div>
              )
            })}
          </Renderif>

          <Renderif isTrue={state.rol_active === 'Deportista'}>
            {deportista_steps.map((item, i)=> {

              if(i !== 2 && i !== 4){
                
                return (
                  // <div>{i === 3 ? 3 : i === 5 ? 4 : i + 1}</div>
                  <div key={i}  className={`col mx-2 pt-3 ${state.step >= i ? 'registro_step--active' : 'registro_step'}`}>
                      <div className='f-Poppins-Bold text-mint-shade f-12 line-1'>{LANG.REGISTER.STEP[lang]} {i === 3 ? 3 : i === 5 ? 4 : i + 1}</div>
                      <div className='f-Poppins-Medium text-white f-14'>{LANG.NOT[item][lang]}</div>
                  </div>
                )
        
              }
              return null
              
            })}
          </Renderif>
        </div>


        <div className='col bg-dark-blue py-3 overflow-auto'>

          <div className='container d-flex align-items-center p-0 px-3 px-md-0 my-3'>
            <IoIosArrowBack color={colors.mint_shade} size={16}></IoIosArrowBack>
            <div className=' text-mint-shade f-Poppins-Medium f-14 cursor-pointer mx-2 non-select' onClick={()=>{onPrevStep()}}>{LANG.REGISTER.BACK[lang]}</div>
          </div>

          <Renderif isTrue={state.steps.at(-1) === 'Tipo de registro'}>
            <div className='container p-0'>
              <div className='text-white display-6 f-anton cursor-pointer text-uppercase'>{LANG.REGISTER.ROL[lang]}</div>
            </div>

            <div className='container bg-secundary-dark-blue rounded border-blue-shade p-0 mt-4'>

                <div className={`registro_rol ${state.rol_active==='Deportista' ? 'bg-blue-shade-overlay' :''} border-b-blue-shade d-flex felx- col py-3 cursor-pointer `} onClick={()=>{setState({...state, rol_active:'Deportista'})}}>
                  <div className='px-3'>
                    <Renderif isTrue={state.rol_active === 'Deportista'}>
                      <BsRecordCircleFill color={colors.mint}></BsRecordCircleFill>
                    </Renderif>
                    <Renderif isTrue={state.rol_active !== 'Deportista'}>
                        <BsCircle  color={colors.mint}></BsCircle>
                    </Renderif>
                  </div>  

                  <div className='col'>
                    <div className='f-Poppins-Medium text-mint-shade f-14'>{LANG.DASHBOARD.ATHLETE[lang]}</div>
                    <div className='f-Poppins-Medium text-white f-14'>{LANG.REGISTER.AWELCOME[lang]}</div>
                  </div>
                </div>

                <div className={`registro_rol ${state.rol_active==='Nutricionista' ? 'bg-blue-shade-overlay' :''} border-b-blue-shade d-flex felx- col py-3 cursor-pointer `} onClick={()=>{setState({...state, rol_active:'Nutricionista'})}}>
                  <div className='px-3'>
                    <Renderif isTrue={state.rol_active === 'Nutricionista'}>
                      <BsRecordCircleFill color={colors.mint}></BsRecordCircleFill>
                    </Renderif>
                    <Renderif isTrue={state.rol_active !== 'Nutricionista'}>
                        <BsCircle  color={colors.mint}></BsCircle>
                    </Renderif>
                  </div>  

                  <div className='col'>
                    <div className='f-Poppins-Medium text-mint-shade f-14'>{LANG.DASHBOARD.NUTRICIONIST[lang]}</div>
                    <div className='f-Poppins-Medium text-white f-14'>{LANG.REGISTER.NWELCOME[lang]}</div>
                  </div>
                </div>

                <div className={`registro_rol ${state.rol_active==='Cocina' ? 'bg-blue-shade-overlay' :''} border-b-blue-shade d-flex felx- col py-3 cursor-pointer `} onClick={()=>{setState({...state, rol_active:'Cocina'})}}>
                  <div className='px-3'>
                    <Renderif isTrue={state.rol_active === 'Cocina'}>
                      <BsRecordCircleFill color={colors.mint}></BsRecordCircleFill>
                    </Renderif>
                    <Renderif isTrue={state.rol_active !== 'Cocina'}>
                        <BsCircle  color={colors.mint}></BsCircle>
                    </Renderif>
                  </div>  

                  <div className='col'>
                    <div className='f-Poppins-Medium text-mint-shade f-14'>{LANG.DASHBOARD.KITCHEN[lang]}</div>
                    <div className='f-Poppins-Medium text-white f-14'>{LANG.REGISTER.KWELCOME[lang]}</div>
                  </div>
                </div>

                <RenderNextStep></RenderNextStep>
            </div>
          </Renderif>

          <Renderif isTrue={state.steps.at(-1) === 'Datos'}>
          <FormValidator onValidate={(validated)=>{
              formValidation.avaibale = validated
            }}>
            <div className='container p-0 px-3 px-md-0'>
              <div className='text-white display-6 f-anton cursor-pointer text-uppercase'>

                

                {state.rol_active.toUpperCase() === "DEPORTISTA" ? LANG.REGISTER.Registro_deportista[lang] : ''}
                {state.rol_active.toUpperCase() === "NUTRICIONISTA" ? LANG.REGISTER.Registro_nutricionista[lang] : ''}
                {state.rol_active.toUpperCase() === "COCINA" ? LANG.REGISTER.Registro_cocina[lang] : ''}

               
              
              </div>
            </div>
            <div className='container bg-secundary-dark-blue rounded p-3 mt-4'>

              <div className='d-flex flex-column'>

                <div className='d-flex flex-column flex-md-row p-1 mt-3'>
                  <div className='col p-2'>
                    <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.Nombre[lang]} (s)</div>
                    <input required type='text' className='input' value={registro.firstname} onChange={(value)=>{setRegistro({...registro, firstname:value.currentTarget.value})}}></input>
                    <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('firstname')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                  </div>

                

                  <div className='col p-2'>
                    <div className="text-white f-Poppins-Medium f-12 my-1">Correo electrónico</div>
                    <input required type='email' className='input'value={registro.email} onChange={(value)=>{setRegistro({...registro, email:value.currentTarget.value})}}></input>
                    <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('email')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                  </div>
                  <div className='col p-2'>
                    <div className="text-white f-Poppins-Medium f-12 my-1">Teléfono</div>
                    <input type='number' className='input p-2' value={registro.phone} onChange={(value)=>{setRegistro({...registro, phone:value.currentTarget.value})}}></input>
                  </div>
                </div>
                <div className='d-flex flex-column flex-md-row p-1 mt-3'> 
                  <div className='col p-2'>
                      <div className="text-white f-Poppins-Medium f-12 my-1">Pais</div>
                      <div className='input d-flex'>
                        <select required className='w-100' value={registro.country} onChange={(value)=>{setRegistro({...registro, country:value.currentTarget.value})}}>
                          <option value=''>Selecionar</option>
                          <option value='Spain'>España</option>
                
                        </select>
                        <div className='px-2 border-l-mint d-flex align-items-center'><IoIosArrowDown color={colors.mint}></IoIosArrowDown></div>
                      </div>
                      <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('country')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                      
                    </div>
                    <div className='col p-2'>
                      <div className="text-white f-Poppins-Medium f-12 my-1">Ciudad</div>
                      <input required type='text' className='input' value={registro.city} onChange={(value)=>{setRegistro({...registro, city:value.currentTarget.value})}}></input>
                      <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('city')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                    </div>
                    <div className='col p-2'>
                      <div className="text-white f-Poppins-Medium f-12 my-1">Estado / Provincia</div>
                      <input type='text' className='input' value={registro.state} onChange={(value)=>{setRegistro({...registro, state:value.currentTarget.value})}}></input>
                    </div>
                    <div className='col p-2'>
                      <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.codigo_postal[lang]}</div>
                      <input type='text' className='input' value={registro.cp} onChange={(value)=>{setRegistro({...registro, cp:value.currentTarget.value})}}></input>
                    </div>
                
                </div>

                
                <RenderNextStep></RenderNextStep>
              </div>
            </div>
          </FormValidator>
          </Renderif>

          <Renderif isTrue={state.steps.at(-1) === 'Datos generales'}>
            
            <FormValidator onValidate={(validated)=>{
              formValidation.avaibale = validated
            }}>
  

            <div className='container p-0 px-3 px-md-0'>
              <div className='text-white display-6 f-anton cursor-pointer text-uppercase'>{LANG.REGISTER.Registro_deportista[lang]}</div>
            </div>

            <div className='container bg-secundary-dark-blue rounded p-3 mt-4'>

              <div className='d-flex flex-column'>

                <div className='d-flex flex-column flex-md-row p-1 mt-3'>
                  <div className='col p-2'>
                    <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.Nombre[lang]} (s)</div>
                   
                      <input required type='text' className='input'value={registro.firstname} onChange={(value)=>{setRegistro({...registro, firstname:value.currentTarget.value})}}></input>
                      <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('firstname')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
              
                  </div>

                  <div className='col p-2'>
                    <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.Apellido[lang]} (s)</div>
                    <input required type='text' className='input' value={registro.lastname} onChange={(value)=>{setRegistro({...registro, lastname:value.currentTarget.value})}}></input>
                    <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('lastname')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                  </div>

                  <div className='col p-2'>
                    <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.Club_al_que_pertenece[lang]} </div>
                    <input required type='text' className='input' value={deportista.club} onChange={(value)=>{setDeportista({...deportista, club:value.currentTarget.value})}}></input>
                    <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('club')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                  </div>
                </div>

                <div className='d-flex flex-column flex-md-row p-1 mt-3'> 
                  <div className='col p-2'>
                    <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.Fecha_de_nacimiento[lang]} </div>
                    <input required type='date' className='input p-2' value={deportista.birthday} onChange={(value)=>{setDeportista({...deportista, birthday:value.currentTarget.value})}}></input>
                    <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('birthday')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                    
                  </div>
                  <div className='col p-2'>
                    <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.Gender[lang]} </div>

                    <div className='input d-flex'>
                      <select required className='w-100' value={registro.gender} onChange={(value)=>{setRegistro({...registro, gender:value.currentTarget.value})}}>
                        <option value=''>{LANG.REGISTER.Seleccionar[lang]} </option>
                        <option value='Femenino'>{LANG.REGISTER.Femenino[lang]} </option>
                        <option value='Masculino'>{LANG.REGISTER.Masculino[lang]} </option>
                        <option value='Otro'>{LANG.REGISTER.Otro[lang]} </option>
                      </select>
                      <div className='px-2 border-l-mint d-flex align-items-center'><IoIosArrowDown color={colors.mint}></IoIosArrowDown></div>
                    </div>
                    <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('gender')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>

                  </div>
                  <div className='col p-2'>
                    <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.Correo_electronico[lang]} </div>
                    <input required type='email' className='input' value={registro.email} onChange={(value)=>{setRegistro({...registro, email:value.currentTarget.value})}}></input>
                    <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('email')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                  </div>
                  <div className='col p-2'>
                    <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.Telefono[lang]} </div>
                    <input type='number' className='input p-2' value={registro.phone} onChange={(value)=>{setRegistro({...registro, phone:value.currentTarget.value})}}></input>
                  </div>
                </div>

                <div className='d-flex flex-column flex-md-row p-1 mt-3'> 
                  <div className='col p-2'>
                    <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.País[lang]} </div>
                    <div className='input d-flex'>
                      <select required className='w-100' value={registro.country} onChange={(value)=>{setRegistro({...registro, country:value.currentTarget.value})}}>
                        <option value=''>{LANG.REGISTER.seleccionar[lang]} </option>
                        <option value='Spain'>España</option>
                   
                      </select>
                      <div className='px-2 border-l-mint d-flex align-items-center'><IoIosArrowDown color={colors.mint}></IoIosArrowDown></div>
                    </div>
                    <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('country')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                  </div>
                  <div className='col p-2'>
                    <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.Ciudad[lang]} </div>
                    <input required type='text' className='input' value={registro.city} onChange={(value)=>{setRegistro({...registro, city:value.currentTarget.value})}}></input>
                    <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('city')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                  </div>
                  <div className='col p-2'>
                    <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.Estado[lang]}  / {LANG.REGISTER.Provincia[lang]} </div>
                    <input type='text' className='input' value={registro.state} onChange={(value)=>{setRegistro({...registro, state:value.currentTarget.value})}}></input>
                  </div>
                  <div className='col p-2'>
                    <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.codigo_postal[lang]}</div>
                    <input type='text' className='input' value={registro.cp} onChange={(value)=>{setRegistro({...registro, cp:value.currentTarget.value})}}></input>
                  </div>
                </div>

                <RenderNextStep></RenderNextStep>
              </div>
            </div>

            </FormValidator>
          </Renderif>

          <Renderif isTrue={state.steps.at(-1) === 'Datos del deportista'}>
            <FormValidator onValidate={(validated)=>{
              formValidation.avaibale = validated
            }}>
              <div className='container p-0 px-3 px-md-0'>
                <div className='text-white display-6 f-anton cursor-pointer text-uppercase'>{LANG.REGISTER.Registro_deportista[lang]}</div>
              </div>
              <div className='container bg-secundary-dark-blue rounded p-3 mt-4'>

                <div className='d-flex flex-column'>

                  <div className='d-flex'>
                    <div className='col-12 col-md-6 d-flex flex-column p-1 mt-3'> 

                      <div className='col p-2'>
                        <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.filosofia_alimentaria[lang]}</div>
                        <div className='input d-flex'>
                          <select required className='w-100' value={deportista.fedding} onChange={(value)=>{setDeportista({...deportista, fedding:value.currentTarget.value})}}>
                            <option value=''>{LANG.REGISTER.seleccionar[lang]}</option>
                            <option value='Omnívoro'>{LANG.REGISTER.omnivoro[lang]}</option>
                            <option value='Vegetariano'>{LANG.REGISTER.vegetariano[lang]}</option>
                            <option value='Vegano'>{LANG.REGISTER.vegano[lang]}</option>
                            
                          </select>
                          <div className='px-2 border-l-mint d-flex align-items-center'><IoIosArrowDown color={colors.mint}></IoIosArrowDown></div>
                        </div>
                        <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('fedding')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                      </div>
                    

                      <div className='col p-2'>
                        {/* <div className="text-white f-Poppins-Medium f-12 my-1">¿Algún tipo de intolerancia o alergia?</div> */}
                        <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.intolerancia_alergia[lang]}</div>
                        <input type="text" className='input' value={deportista.allergy} onChange={(value)=>{setDeportista({...deportista, allergy:value.currentTarget.value})}}></input>
                      </div>

                      <div className='col p-2'>
                        <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.preferencias_alimentos[lang]}</div>
                        <input type="text" className='input' value={deportista.delevery_occur} onChange={(value)=>{setDeportista({...deportista, delevery_occur:value.currentTarget.value})}}></input>
                      </div>
                      <div className='col p-2'>
                        <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.objetivo[lang]}</div>
                        <div className='input d-flex'>
                          <select className='w-100' value={deportista.objetivo} onChange={(value)=>{setDeportista({...deportista, objetivo:value.currentTarget.value})}}>
                            <option value=''>{LANG.REGISTER.seleccionar[lang]}</option>
                            <option value='Aumento muscular'>{LANG.REGISTER.masa_muscular[lang]}</option>
                            <option value='Menos peso'>{LANG.REGISTER.menos_peso[lang]}</option>
                            <option value='Definicion'>{LANG.REGISTER.definicion[lang]}</option>
                            
                          </select>
                          <div className='px-2 border-l-mint d-flex align-items-center'><IoIosArrowDown color={colors.mint}></IoIosArrowDown></div>
                        </div>
                        {/* <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('fedding')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div> */}
                      </div>
                    </div>

                    <div className='col-12 col-md-6 d-flex flex-column p-1 mt-3'> 


                      <div className='p-2'>
                        <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.peso[lang]}</div>
                        <input placeholder='kg.' type="number" className='input' value={deportista.peso} onChange={(value)=>{setDeportista({...deportista, peso:value.currentTarget.value})}}></input>
                      </div>

                      <div className='p-2'>
                        <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.estatura[lang]}</div>
                        <input placeholder='cm.' type="number" className='input' value={deportista.estatura} onChange={(value)=>{setDeportista({...deportista, estatura:value.currentTarget.value})}}></input>
                      </div>

                      <div className='col p-2'>
                        <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.imc[lang]}</div>
                        <input type="text" className='input' value={deportista.imc} onChange={(value)=>{setDeportista({...deportista, imc:value.currentTarget.value})}}></input>
                      </div>
                    </div>

                   

                  </div>

                  <RenderNextStep></RenderNextStep>


                </div>
              </div>
            </FormValidator>
           
          </Renderif>

          <Renderif isTrue={state.steps.at(-1) === 'Servicio'}>
          <FormValidator onValidate={(validated)=>{
              formValidation.avaibale = validated
            }}>

            <div className='container p-0 px-3 px-md-0'>
              <div className='text-white display-6 f-anton cursor-pointer text-uppercase'>{LANG.REGISTER.Registro_deportista[lang]}</div>
            </div>
            <div className='container bg-secundary-dark-blue rounded p-3 mt-4'>

              <div className='d-flex flex-column'>


                <div className='col-12 col-md-6 d-flex flex-column p-1 mt-3'> 

                  {/* <div className='col p-2'>
                    <div className="text-white f-Poppins-Medium f-12 my-1">¿Qué tipo de servicio necesita?</div>
                    <div className='input d-flex'>
                      <select required className='w-100' value={deportista.service} onChange={(value)=>{setDeportista({...deportista, service:value.currentTarget.value})}}>
                        <option value=''>Selecionar</option>
                        <option value='Comida'>Comida</option>
                        <option value='Cena'>Cena</option>
                        <option value='Comida y Cena'>Comida y Cena</option>
                      </select>
                      <div className='px-2 border-l-mint d-flex align-items-center'><IoIosArrowDown color={colors.mint}></IoIosArrowDown></div>
                    </div>
                    <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('service')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                  </div> */}

                  {/* <div className='col p-2'>
                    <div className="text-white f-Poppins-Medium f-12 my-1">¿Cuántos dias a la semana quiere el servicio?</div>
                    
                    
                    <div className='d-flex'>
                      <CheckBox text="Lunes" value="Lunes" active={deportista.service_days.includes('Lunes')} onChange={(value)=>{onChangeDay(value)}}></CheckBox>
                      <CheckBox text="Martes" value="Martes" active={deportista.service_days.includes('Martes')} onChange={(value)=>{onChangeDay(value)}}></CheckBox>
                      <CheckBox text="Miércoles" value="Miercoles" active={deportista.service_days.includes('Miercoles')} onChange={(value)=>{onChangeDay(value)}}></CheckBox>
                      <CheckBox text="Jueves" value="Jueves" active={deportista.service_days.includes('Jueves')} onChange={(value)=>{onChangeDay(value)}}></CheckBox>
                      <CheckBox text="Viernes" value="Viernes" active={deportista.service_days.includes('Viernes')} onChange={(value)=>{onChangeDay(value)}}></CheckBox>
                    </div>
                    <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('service_days')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                

                    <div className='input d-none'>
                      <select className='w-100'>
                        <option>Selecionar</option>
                      </select>
                      <div className='px-2 d-flex border-l-mint align-items-center'><IoIosArrowDown color={colors.mint}></IoIosArrowDown></div>
                    </div>
                  </div> */}

                  <div className='col p-2'>
                    <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.lugar_entrega_direccion[lang]}</div>
                 
                    <input required type="text" className='input' value={deportista.delevery_address} onChange={(value)=>{setDeportista({...deportista, delevery_address:value.currentTarget.value})}}></input>
                    <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('delevery_address')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                  </div>

                  {/* <div className='col p-2'>
                    <div className="text-white f-Poppins-Medium f-12 my-1">¿Cuántas veces a la semana queires que te enviemos?</div>
                    <div className='input d-flex'>
                      <select required className='w-100' value={deportista.delevery_occur} onChange={(value)=>{setDeportista({...deportista, delevery_occur:value.currentTarget.value})}}>
                        <option value=''>Selecionar</option>
                        <option value='1'>1</option>
                        <option value='2'>2</option>
                        <option value='3'>3</option>
                        <option value='4'>4</option>
                        <option value='5'>5</option>
                      </select>
                      <div className='px-2 border-l-mint d-flex align-items-center'><IoIosArrowDown color={colors.mint}></IoIosArrowDown></div>
                    </div>
                    <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('delevery_occur')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                  </div> */}

                  <div className='col p-2'>
                    <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.hora_entrega[lang]}</div>
                    <input required type="time" className='input' value={deportista.delevery_time} onChange={(value)=>{setDeportista({...deportista, delevery_time:value.currentTarget.value})}}></input>
                    <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('delevery_time')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                  </div>

                </div>

                <RenderNextStep></RenderNextStep>

              </div>
            </div>

          </FormValidator>
           
          </Renderif>
              
          <Renderif isTrue={state.steps.at(-1) === 'Terminos y condiciones'}>
            <FormValidator onValidate={(validated)=>{
              formValidation.avaibale = validated
            }}>
              <div className='container p-0 px-3 px-md-0'>
                <div className='text-white display-6 f-anton cursor-pointer text-uppercase'>
                  
                  {state.rol_active.toUpperCase() === "DEPORTISTA" ? LANG.REGISTER.Registro_deportista[lang] : ''}
                  {state.rol_active.toUpperCase() === "NUTRICIONISTA" ? LANG.REGISTER.Registro_nutricionista[lang] : ''}
                  {state.rol_active.toUpperCase() === "COCINA" ? LANG.REGISTER.Registro_cocina[lang] : ''}
                
                </div>
              </div>
              <div className='container bg-secundary-dark-blue rounded p-0 mt-4'>

                <div className='d-flex flex-column'>                  
                  <div className='d-flex col-12 col-md-6 p-1 mt-3 px-3'> 
                    <div className='col p-2'>
                      <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.ingresa_contrasena[lang]} </div>
                      <input required type="password" className='input' value={registro.password} onChange={(value)=>{setRegistro({...registro, password:value.currentTarget.value})}}></input>
                      <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('password')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                    </div>

                    <div className='col p-2'>
                      <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.confirma_contrasena[lang]} </div>
                      <input required type="password" className='input' value={state.password_confirmed} onChange={(value)=>{setState({...state, password_confirmed:value.currentTarget.value})}}></input>
                      <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('password_confirmed')}`}>Las constraseñas no coinciden.</div>
                    </div>
                  </div>

                  <div className='border-t-blue-shade border-b-blue-shade py-5 px-3 mt-4'>

                    <div className='d-flex cursor-pointer my-2' onClick={()=>{setState({...state, tyco_active:!state.tyco_active})}}>
                      <div className='px-3'>
                        <Renderif isTrue={state.tyco_active}>
                          <BsRecordCircleFill color={colors.mint}></BsRecordCircleFill>
                        </Renderif>
                        <Renderif isTrue={!state.tyco_active}>
                            <BsCircle  color={colors.mint}></BsCircle>
                        </Renderif>
                      </div>  
                      <div className='col'>
                        {/* <div className='f-Poppins-Medium text-white f-14'>Acepto los <span className='cursor-pointer text-mint text-decoration-underline'><a href='/tycos' className='text-mint' target="_blank">Términos y Condiciones</a></span></div> */}
                        <div className='f-Poppins-Medium text-white f-14'>{LANG.REGISTER.acepto_terminos_condiciones[lang]}</div>
                      </div>
                    </div>

                    <div className='d-flex cursor-pointer my-2' onClick={()=>{setState({...state, newsletter_active:!state.newsletter_active})}}>
                      <div className='px-3'>
                        <Renderif isTrue={state.newsletter_active}>
                          <BsRecordCircleFill color={colors.mint}></BsRecordCircleFill>
                        </Renderif>
                        <Renderif isTrue={!state.newsletter_active}>
                            <BsCircle  color={colors.mint}></BsCircle>
                        </Renderif>
                      </div>  
                      <div className='col'>
                        <div className='f-Poppins-Medium text-white f-14'>{LANG.REGISTER.deseo_recibir_promociones[lang]}</div>
                      </div>
                    </div>

                  </div>

                  <RenderNextStep></RenderNextStep>

                </div>
              </div>
            </FormValidator>
          </Renderif>

       
          
          <Renderif isTrue={state.steps.at(-1) === 'Pago'}>
            <FormValidator onValidate={(validated)=>{
                formValidation.avaibale = validated
              }}>
              <div className='container p-0 px-3 px-md-0'>
                <div className='text-white display-6 f-anton cursor-pointer text-uppercase'>

                  {state.rol_active.toUpperCase() === "DEPORTISTA" ? LANG.REGISTER.Registro_deportista[lang] : ''}
                  {state.rol_active.toUpperCase() === "NUTRICIONISTA" ? LANG.REGISTER.Registro_nutricionista[lang] : ''}
                  {state.rol_active.toUpperCase() === "COCINA" ? LANG.REGISTER.Registro_cocina[lang] : ''}
                  
         
                
                </div>
              </div>
              <div className='container bg-secundary-dark-blue rounded p-3 mt-4'>

                <div className='d-flex flex-column flex-md-row mt-3'>

              
                  <div className='col-12 col-md-6 p-3'>
                    <div className='f-Poppins-Medium text-white f-14'>{LANG.REGISTER.plan_necesitas[lang]}</div>
                    <div className='d-flex flex-column bg-dark-blue rounded-3 position-relative overflow-hidden mt-2'> 
                      <div className={`${state.plan_active==='PRO' ? 'bg-mint-shade' :''} d-flex felx- col py-3 cursor-pointer `} onClick={()=>{
                        deportista.id_suscription=1;
                        setState({...state, plan_active:'PRO'})
                        }}>
                        
                        <div className='px-3'>
                          <Renderif isTrue={state.plan_active === 'PRO'}>
                            <BsRecordCircleFill color={colors.mint}></BsRecordCircleFill>
                          </Renderif>
                          <Renderif isTrue={state.plan_active !== 'PRO'}>
                              <BsCircle  color={colors.mint}></BsCircle>
                          </Renderif>
                        </div>  

                        <div className='col'>
                          <div className='f-Poppins-Medium text-white f-14'>{state.rol_active !== "Nutricionista" ? 'PRO' : LANG.REGISTER.gratuito[lang]}</div>
                       
                          <div className='f-Poppins-Medium text-white f-14'>{state.rol_active !== "Nutricionista" ? LANG.REGISTER.comidas_cenas_440[lang] : LANG.REGISTER.soy_nutricionista[lang]}</div>
                        </div>
                      </div>

                      <div className={`${state.plan_active==='ELITE' ? 'bg-mint-shade' :''} d-flex felx- col py-3 cursor-pointer `} onClick={()=>{
                        deportista.id_suscription=2;
                        setState({...state, plan_active:'ELITE', service:'Comida y Cena'})}}>
                        <div className='px-3'>
                          <Renderif isTrue={state.plan_active === 'ELITE'}>
                            <BsRecordCircleFill color={colors.mint}></BsRecordCircleFill>
                          </Renderif>
                          <Renderif isTrue={state.plan_active !== 'ELITE'}>
                              <BsCircle  color={colors.mint}></BsCircle>
                          </Renderif>
                        </div>  

                        <div className='col'>
                       
                          <div className='f-Poppins-Medium text-white f-14'>{state.rol_active !== "Nutricionista" ? 'ÉLITE' : 'PRO'}</div>
                         
                          <div className='f-Poppins-Medium text-white f-14'>{state.rol_active !== "Nutricionista" ? LANG.REGISTER.comidas_cenas_880[lang] : 'Accede a todas las funcionalidades de MIA'}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-12 col-md-6 p-3'>
                    <Renderif isTrue={state.rol_active != 'Nutricionista'}>
                      <div className="text-white f-Poppins-Medium f-14 my-1">{LANG.REGISTER.personas_servicio[lang]}</div>

                      <div className='input d-flex'>
                        <select className='w-100' value={deportista.invitados} onChange={(value)=>{setDeportista({...deportista, invitados:value.currentTarget.value})}}>
                          <option value='0'>0</option>
                          {[1,2,3].map((value,i)=>(<option key={i} value={value}>{value}</option>))}
                        </select>
                        <div className='px-2 border-l-mint d-flex align-items-center'><IoIosArrowDown color={colors.mint}></IoIosArrowDown></div>
                      </div>
                   
                      <Renderif isTrue={deportista.id_suscription === 1}>

                        <div className='col py-2'>
                          <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.tipo_servicio[lang]}</div>
                          <div className='input d-flex'>
                            <select required className='w-100' value={deportista.service} onChange={(value)=>{setDeportista({...deportista, service:value.currentTarget.value})}}>
                              
                              <option value='Comida'>{LANG.REGISTER.comidas[lang]}</option>
                              <option value='Cena'>{LANG.REGISTER.cenas[lang]}</option>
                            
                            </select>
                            <div className='px-2 border-l-mint d-flex align-items-center'><IoIosArrowDown color={colors.mint}></IoIosArrowDown></div>
                          </div>
                          <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('service')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                        </div>

                      </Renderif>

                      <Renderif isTrue={deportista.id_suscription === 2}>
                        <div className='col py-2'>
                            <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.tipo_servicio[lang]}</div>
                            <input required type="text" value="Comida y Cena" className='input' disabled={true}></input>
                        </div>
                      </Renderif>
                    </Renderif>


                    <div className='border-b-blue-shade mt-5'>

                       <div className='f-Poppins-Medium text-white'>{LANG.REGISTER.plan[lang]} {state.rol_active !== 'Nutricionista' ? state.plan_active : state.plan_active == 'PRO' ?  LANG.REGISTER.gratuito[lang]: 'PRO'} : 
                          <Renderif isTrue={state.plan_active === 'ELITE'}>


                         
                              {
                                state.rol_active === 'Nutricionista' ? 
                                <span> € {"15.00"}</span>
                                :
                                <span> € {440 * (parseInt(deportista.invitados) + 1)}</span>
                              }

                          </Renderif>
                          <Renderif isTrue={state.plan_active === 'PRO'}>

                            {
                              state.rol_active === 'Nutricionista' ? 
                              <span> € {"0.00"}</span>
                              :
                              <span> € {440 * (parseInt(deportista.invitados) + 1)}</span>
                            }
                      

                          </Renderif>
                        </div>

                        <div className='f-Poppins-Medium text-white'>
                          
                        {LANG.REGISTER.iva[lang]}:
                          <Renderif isTrue={state.plan_active === 'ELITE'}>
                           
                            {
                              state.rol_active === 'Nutricionista' ? 
                              <span> € {"3.15"}</span>
                              :
                              <span> € {88 * (parseInt(deportista.invitados) + 1)}</span>
                            }
                          </Renderif>


                          <Renderif isTrue={state.plan_active === 'PRO'}>

                        
                            {
                              state.rol_active === 'Nutricionista' ? 
                              <span> € {"0.00"}</span>
                              :
                              <span> € {44 * (parseInt(deportista.invitados) + 1)}</span>
                            }
                      

                          </Renderif>
                        </div>

                        <Renderif isTrue={state.rol_active !== 'Nutricionista'}>
                          <div className='f-Poppins-Medium text-white'>{LANG.REGISTER.costo_envio[lang]}: € {200 * (parseInt(deportista.invitados) + 1)}</div>
                        </Renderif>

                        <div className='f-Poppins-Medium text-yellow mt-1 mb-3'>{LANG.REGISTER.costo_total[lang]}: 
                          <Renderif isTrue={state.plan_active === 'ELITE'}>
                           
                            {
                              state.rol_active === 'Nutricionista' ? 
                              <span> € {"18.15"}</span>
                              :
                              <span> € {1168 * (parseInt(deportista.invitados) + 1)}</span>
                            }
                          </Renderif>
                          <Renderif isTrue={state.plan_active === 'PRO'}>
                        
                            {
                              state.rol_active === 'Nutricionista' ? 
                              <span> € {"0.00"}</span>
                              :
                              <span> € {684 * (parseInt(deportista.invitados) + 1)}</span>
                            }
                          </Renderif>
                        </div>

                    </div>

                    <Renderif isTrue={state.plan_active === 'ELITE' || state.rol_active === 'Deportista'}>
                      <div className='d-flex align-items-center mt-4'>
                          <div className='f-Poppins-Medium text-white'>{LANG.REGISTER.metodo_pago[lang]}:</div>
                          <div className='col'></div>
                          {/* <div className={`cursor-pointer d-flex align-items-center`}  onClick={()=>{
                            deportista.payment_method = 'PayPal'
                            setState({...state, payment:'PayPal'})}}>
                            <div className='px-3'>
                              <Renderif isTrue={state.payment === 'PayPal'}>
                                <BsRecordCircleFill color={colors.mint}></BsRecordCircleFill>
                              </Renderif>
                              <Renderif isTrue={state.payment !== 'PayPal'}>
                                  <BsCircle  color={colors.mint}></BsCircle>
                              </Renderif>
                            </div>  

                            <div className='col'>
                            <FaCcPaypal size={40} color={colors.white}></FaCcPaypal>
                            </div>
                          </div> */}

                          <div className={`cursor-pointer d-flex align-items-center`} onClick={()=>{
                            deportista.payment_method = 'Stripe'
                            setState({...state, payment:'Stripe'})}}>
                            <div className='px-3'>
                              <Renderif isTrue={state.payment === 'Stripe'}>
                                <BsRecordCircleFill color={colors.mint}></BsRecordCircleFill>
                              </Renderif>
                              <Renderif isTrue={state.payment !== 'Stripe'}>
                                  <BsCircle  color={colors.mint}></BsCircle>
                              </Renderif>
                            </div>  

                            <div className='col'>
                            {/* <FaStripe size={50} color={colors.white}></FaStripe> */}
                            <img width={100} src='/assets/images/tarjetas.png'></img>
                            </div>
                          </div>
                      </div>
                    </Renderif>

                  </div>

                

                </div>
                <RenderNextStep></RenderNextStep>
              </div>
            </FormValidator>
          </Renderif>
        
        
        </div>

      </div>
        
    </section>
  )
}





const FormValidator =({children, onValidate}) => {

  const [validated, setValidated] = useState(false)
  let fields = []
  const getFileds = (el)=>{
    if(el.type === 'input' || el.type === 'select'){
      fields.push(el)
    }
    if(el.type === 'div'){
      if(typeof(el.props.children) === 'object' && el.props.children.length){
        el.props.children.map((el)=>{
          getFileds(el)
        })
      }
      return;
    }
    return;
  }

  useEffect(()=>{
    children.map((el)=>{
      getFileds(el.props.children)
    })
    let _fields = fields.filter((el) => el.props.required === true && el.props.value === '');

    if(_fields.length === 0){
      onValidate(true)
      return;
    }
    onValidate(false)
  })

  return(
    <>
      {children}
    </>
  )
}