/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from "react";
import "../../assets/css/login.css";
import LoginForm from "./LoginForm";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/authContext";
import { LANG } from "../commun/lang";
import { MdLanguage } from "react-icons/md";
import { Popover } from "antd";
import { colors } from "../../utilities/colors";
import { LangOptions } from "../../components/HeaderDashboard";

function Login() {
	const navigate = useNavigate();
	const {user, lang} = useContext(AuthContext);


	useEffect(()=>{
		if(user){
			navigate('/dashboard');
		}
	},[user])

	return (
		<>
			<section className="mb__login">

				
				

				<div className="d-flex flex-column flex-md-row h-100 overflow-auto">

					

					

					<div className="col col-12 col-md-7 d-none d-md-flex flex-column bg-white mb__login_bg">

						
						
						<div className="col d-flex align-items-center justify-content-end">
							<h1 className="display-2 text-white text-right f-anton me-5 text-uppercase">
								{LANG.LOGIN.SOLUTIONS_A[lang]} <br></br> {LANG.LOGIN.SOLUTIONS_B[lang]}
								{/* SOLUCIONES PARA <br></br> DEPORTISTAS */}
							
							</h1>
						</div>
						<div className="col"></div>
					</div>
				

					<div className="col-12 col-md-5 d-flex flex-column align-items-center  px-4 py-3 ">

					

						<div className="d-flex flex-row w-100">
							<div className="col"></div>
							<div className="ps-3  d-flex justify-content-center align-items-center">
								<MdLanguage color={colors.mint} size={18}></MdLanguage>
								<Popover
					
									zIndex={99}
									placement="bottomRight"
									content={<LangOptions></LangOptions>}
									trigger="click"
								>
									<div className="cursor-pointer text-white f-14 f-Poppins-Bold ps-1">{lang}</div>
								</Popover>
							</div>
						</div>

						
						<div className="col"></div>
						
						<LoginForm></LoginForm>
						<div className="col"></div>
					</div>

				</div>
			
				
			</section>
		</>
	);
}

export default Login;
