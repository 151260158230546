import { LANG } from "../pages/commun/lang";

export const mainMenu = () => {
	
return	[
	{
		rol: 1,
		menu: [
			{
				item: "Dashboard",
				icon: "FiHome",
				page: "dashboard",
			},
			{
				item: LANG.WEEKPLAN.WEEKPLAN[localStorage.getItem("lang")],
				icon: "BsCalendarMinus",
				page: "plan-semanal",
			},
			{
				item: LANG.USERS.USERS[localStorage.getItem("lang")],
				icon: "AiOutlineUsergroupAdd",
				page: "users",
			},

			{
				item: LANG.PROFILE.PROFILE[localStorage.getItem("lang")],
				icon: "AiOutlineUser",
				page: "profile",
			},

			{
				item:  LANG.WEEKPLAN.LOGOUT[localStorage.getItem("lang")],
				icon: "FiLogOut",
				page: "logout",
			},
		],
	},
	{
		rol: 2,
		menu: [
			{
				item: "Dashboard",
				icon: "FiHome",
				page: "dashboard",
			},
			{
				item:  LANG.WEEKPLAN.WEEKPLAN[localStorage.getItem("lang")],
				icon: "BsCalendarMinus",
				page: "plan-semanal",
			},
			{
				item:  LANG.DASHBOARD.ATHLETES[localStorage.getItem("lang")],
				icon: "AiOutlineUsergroupAdd",
				page: "deportistas",
			},

			{
				item:  LANG.PROFILE.PROFILE[localStorage.getItem("lang")],
				icon: "AiOutlineUser",
				page: "profile",
			},
			{
				item:  LANG.WEEKPLAN.LOGOUT[localStorage.getItem("lang")],
				icon: "FiLogOut",
				page: "logout",
			},
			
		],
	},
	{
		rol: 3,
		menu: [
			{
				item: "Dashboard",
				icon: "FiHome",
				page: "dashboard",
			},
			{
				item:  LANG.WEEKPLAN.WEEKPLAN[localStorage.getItem("lang")],
				icon: "BsCalendarMinus",
				page: "plan-semanal",
			},
			
			{
				item: LANG.NOT.envios[localStorage.getItem("lang")],
				icon: "FaShippingFast",
				page: "envios",
			},

			{
				item: LANG.PROFILE.PROFILE[localStorage.getItem("lang")],
				icon: "AiOutlineUser",
				page: "profile",
			},
			{
				item: LANG.WEEKPLAN.LOGOUT[localStorage.getItem("lang")],
				icon: "FiLogOut",
				page: "logout",
			},
		],
	},
	{
		rol: 4,
		menu: [
			{
				item: "Dashboard",
				icon: "FiHome",
				page: "dashboard",
			},
			{
				item: LANG.WEEKPLAN.WEEKPLAN[localStorage.getItem("lang")],
				icon: "BsCalendarMinus",
				page: "plan-semanal",
			},
			{
				item: LANG.NOT.mi_plan[localStorage.getItem("lang")],
				icon: "HiDocumentText",
				page: "plan",
			},
			{
				item: LANG.PROFILE.PROFILE[localStorage.getItem("lang")],
				icon: "AiOutlineUser",
				page: "profile",
			},
			{
				item: LANG.WEEKPLAN.LOGOUT[localStorage.getItem("lang")],
				icon: "FiLogOut",
				page: "logout",
			},
		],
	},
]};
