export const TYPES = {
	GET_DATA: "GET_DATA",
	POST_DATA: "POST_DATA",
	PUT_DATA: "PUT_DATA",
	GET_DATA_RECIPES: "GET_DATA_RECIPES",
	GET_DATA_CHEFS: "GET_DATA_CHEFS",
	GET_DATA_NUTRICIONIST: "GET_DATA_NUTRICIONIST",
	GET_DATA_KITCHENS: "GET_DATA_KITCHENS",
	GET_DATA_WEEKLY_RECIPES: "GET_DATA_WEEKLY_RECIPES",
	GET_REVISION_BY_SEAL_ID: "GET_REVISION_BY_SEAL_ID",
};
