import axios from "axios";

const api_base = process.env.REACT_APP_API_URL;
const api_tspoonlab = 'https://app.tspoonlab.com/recipes/api'

const config = () =>{
    return { headers: {
        "Content-Type": "application/json",
      }}
}
const tspoonlabConfing = () =>{
    return { headers: {
        "Content-Type": "application/json",
        "order":"71379317900419268251344356417414886871",
        "rememberme":"dGVjQGdvb3NlYW5kaG9wcGVyLmNvbToxNzI5MDIxNjEwNDEzOjo6MzRiOTEyMWI0ZmZlOGVlMTExYzI3MzAzODEwNGZhYjU"
      }}
}


export const Service = (method, service, body) => {
    var url  = `${api_base}/${service}`
    switch (method) {
        case "GET":
      
            return axios.get(url,config()).then((res)=>{
                //return response(res)
                return res
            }).catch((err)=>{
                return err
            });

        case "POST":

            return axios.post(url,body,config()).then((res)=>{
                //return response(res)
                return res
            }).catch((err)=>{
                return err
            });


           
        case "PUT":

            return axios.put(url,body,config()).then((res)=>{
                //return response(res)
                return res
            }).catch((err)=>{
                return err
            });
           
            
        case "DELETE":
            return axios.delete(url,body,config()).then((res)=>{
                //return response(res)
                return res
            }).catch((err)=>{
                return err
            });
            break;

        case "TSPOONLAB":
            url = `${api_tspoonlab}/${service}`;
            return axios.get(url,tspoonlabConfing()).then((res)=>{
                //return response(res)
                return res
            }).catch((err)=>{
                return err
            });
          

        default:
            break;
    }
}
