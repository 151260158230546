/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import { Modal, Button } from "antd";
import { BsFileEarmarkText } from "react-icons/bs";
import { Upload, message } from "antd";
import DataContext from "../../context/dataContext";
import { AiOutlineCheck } from "react-icons/ai";
import GeneralButton from "../../components/buttons/GeneralButton";

var sha1 = require("sha1");
function ModalDeleteAthlete(props) {
	const {
		modalDeleteUser,
		setModalDeleteUser,
		putData,
		getData,
		idDeleteUser,
	} = useContext(DataContext);

	const closeModal = () => {
		setModalDeleteUser(false);
	};

	const deleteAthlete = () => {
		
		const userData = {
			id: idDeleteUser,
		};
		putData("athletes/delete", userData).then((result) => {
			if (result.data.status === 200) {
				
				closeModal();
				getData("athletes");
			}
			
		});
	};
	useEffect(() => {
		return () => {};
	}, []);

	return (
		<>
			<Modal
				className="modalMessage"
				visible={modalDeleteUser}
				onCancel={closeModal}
				width={380}
				centered
			>
				<>
					<div className="mb-20 mb__text_normal_bold">
						¿Estás seguro que deseas <br />
						eliminar este usuario?
					</div>

					<div className="btn_1">
						<GeneralButton
							className="btn__blue btn__general mr-10"
							title="Cancelar"
							onClick={() => closeModal()}
						/>

						<GeneralButton
							className="btn__blue btn__general mr-10"
							title="Eliminar"
							onClick={() => deleteAthlete()}
						/>
					</div>
				</>
			</Modal>
		</>
	);
}

export default ModalDeleteAthlete;
