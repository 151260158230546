import { FaFireAlt } from "react-icons/fa";
import {IoMdPeople} from "react-icons/io"
import { colors } from "../colors";
import { BiCalendar } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { render } from "@testing-library/react";
import { Popover } from "antd";
import { HiDotsVertical } from "react-icons/hi";
import { useContext } from "react";
import AlertContext from "../../context/alertContext";

import PlanContext from "../../context/planContext";
import { LANG } from "../../pages/commun/lang";
import AuthContext from "../../context/authContext";
import Renderif from "../../components/Renderif";




function PlanOptions({plan}) {


	const navigate = useNavigate();
	const {lang} = useContext(AuthContext)
	
	


	const {showAlert} = useContext(AlertContext)
	const {onRemovePlan} = useContext(PlanContext)

	const deletePlan = () => {
		console.log(plan)
		
		showAlert({type:'confirmation', message:LANG.NOT.eliminar_plan[lang], description:LANG.NOT.dar_de_baja_plan[lang], onConfirm:async ()=>{
	
		onRemovePlan({plan});
			

			
		}})
	
	};

	


	return (
		<>
			<div className="UserMenuToolTip">

				<div className="item" onClick={() => deletePlan()}>
				{LANG.DASHBOARD.REMOVEPLAN[lang]}
				
				</div>
			</div>
		</>
	);
}


export const WeeklyColumns = () => {return [
	{
		title: <div className="text-left f-anton">{LANG.DASHBOARD.KITCHEN[localStorage.getItem("lang")]}</div>,
		dataIndex: "kitchen",
		key: "kitchen",
		render: (text, item) => (
			<div className="d-flex align-items-center cursor-pointer">
				<div className="pe-3">
					<FaFireAlt color={colors.mint} size={22} />
				</div>
				<div>
					<div className="f-Poppins-Medium text-white">
						{item.kitchen}
					</div>
					<div className="f-Poppins-Regular text-mint-shade">
						{item.email}
					</div>
				</div>
			</div>
		),
	},
	{
		title: <div className="text-left f-anton">{LANG.DASHBOARD.WEEK[localStorage.getItem("lang")]}</div>,
		dataIndex: "final_date",
		key: "final_date",
		render: (text, item) => (
			<div className="d-flex align-items-end py-2">
				<div className="pe-2"><BiCalendar color={colors.mint}  size={20} /></div>
				<div className=" f-Poppins-Bold line-1 pb-1">{`${moment(item?.start_date).format('D MMM')} - ${moment(item?.end_date).format('D MMM')}`}</div>
			</div>
		),
	},

	{
		title: <div className="text-center f-anton">{LANG.DASHBOARD.ATHLETES[localStorage.getItem("lang")]}</div>,
		dataIndex: "total_athletes",
		key: "total_athletes",
		render: (text, item) => <CallDeportistas item={item}></CallDeportistas>,
	},

	{
		title: <div className="text-center f-anton">Status</div>,
		key: "status",
		dataIndex: "status",
		render: (status) => (
			<>
				{(() => {
					if (status === 0) {
						return (
							<div className="status_tab status_pendient">
								{/* Pendiente */}
								{LANG.DASHBOARD.PENDING[localStorage.getItem('lang')]}
							</div>
						);
					} else if (status === 1) {
						return (
							<div className="status_tab status_yellow">
								{/* Enviado a cocina */}
								{LANG.DASHBOARD.SENTKIT[localStorage.getItem('lang')]}
							</div>
						);
					} else if (status === 2) {
						return (
							<div className="status_tab status_purple">
								Revisar
							</div>
						);
					} else if (status === 3) {
						return (
							<div className="status_tab status_green">
								{/* Completo */}
								{LANG.DASHBOARD.COMPLETE[localStorage.getItem('lang')]}
							</div>
						);
					}
				})()}
			</>
		),
	},
	{
		title:<></>,
		render: (text, item) => (
			<div className="" onClick={(event)=>{
				event.stopPropagation()
			}}>
				<Popover
			
					zIndex={99}
					placement="bottomLeft"
					content={<PlanOptions plan={item}></PlanOptions>}
					trigger="click"
				>
					<div className="cursor-pointer"><HiDotsVertical color={colors.mint} size={25}/></div>
				</Popover>
			</div>
		)
	}
]};
export const WeeklyColumnsNutricionist = (id_suscription) => {return[
	{
		title: <div className="text-left f-anton">{LANG.DASHBOARD.KITCHENS[localStorage.getItem('lang')]}</div>,
		dataIndex: "kitchen",
		key: "kitchen",
		render: (text, item) => (
			<div className="d-flex align-items-center cursor-pointer">
				<div className="pe-3">
					<FaFireAlt color={colors.mint} size={22} />
				</div>
				<div>
					<div className="f-Poppins-Medium text-white">
						{item.kitchen}
					</div>
					<div className="f-Poppins-Regular text-mint-shade">
						{item.email}
					</div>
				</div>
			</div>
		),
	},
	{
		title: <div className="text-left f-anton">{LANG.DASHBOARD.WEEK[localStorage.getItem('lang')]}</div>,
		dataIndex: "final_date",
		key: "final_date",
		render: (text, item) => (
			<div className="d-flex align-items-end py-2">
				<div className="pe-2"><BiCalendar color={colors.mint}  size={20} /></div>
				<div className=" f-Poppins-Bold line-1 pb-1">{`${moment(item?.start_date).format('D MMM')} - ${moment(item?.end_date).format('D MMM')}`}</div>
			</div>
		),
	},
	{
		title: <div className="text-center f-anton">{LANG.DASHBOARD.ATHLETES[localStorage.getItem('lang')]}</div>,
		dataIndex: "total_athletes",
		key: "total_athletes",
		render: (text, item) => <CallDeportistas item={item}></CallDeportistas>,
	},

	// {
	// 	title: <div className="text-center f-anton">Status</div>,
	// 	key: "status",
	// 	dataIndex: "status",
	// 	render: (status) => (
	// 		<>
	// 			{(() => {
	// 				if (status === 0) {
	// 					return (
	// 						<div className="status_tab status_pendient">
	// 							Pendiente
	// 						</div>
	// 					);
	// 				} else if (status === 1) {
	// 					return (
	// 						<div className="status_tab status_yellow">
	// 							Enviado a cocina
	// 						</div>
	// 					);
	// 				} else if (status === 2) {
	// 					return (
	// 						<div className="status_tab status_purple">
	// 							Revisar
	// 						</div>
	// 					);
	// 				} else if (status === 3) {
	// 					return (
	// 						<div className="status_tab status_green">
	// 							Completo
	// 						</div>
	// 					);
	// 				}
	// 			})()}
	// 		</>
	// 	),
	// },
	{
		title:<></>,
		render: (text, item) => (
			<Renderif isTrue={id_suscription === 2}>
				<div className="" onClick={(event)=>{
					event.stopPropagation()
				}}>
					<Popover
				
						zIndex={99}
						placement="bottomLeft"
						content={<PlanOptions plan={item}></PlanOptions>}
						trigger="click"
					>
						<div className="cursor-pointer"><HiDotsVertical color={colors.mint} size={25}/></div>
					</Popover>
				</div>
			</Renderif>
			
		)
	}
]};



function CallDeportistas({item}) {
	const navigate = useNavigate();
	const {lang} = useContext(AuthContext)
  return (
	<div className="d-flex justify-content-center">
		<div className="cta cta-outline" onClick={(e)=>{
			e.stopPropagation();
			navigate(`/plan-deportistas/${item.id}/${item.id_kitchen}`)
			
		}}>
			<div className="icon">
				<IoMdPeople color={colors.white} size={16}></IoMdPeople>
			</div>
			<div className="text-white f-12 f-anton line-1">{LANG.DASHBOARD.VIEWATH[lang]}</div>
		</div>
	</div>
  )
}
