/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import "../../assets/css/dashboard.css";
import "../../assets/css/antdCustom.css";
import "../../assets/css/generals.css";
import "../../assets/css/PlanSemanal.css";
import "antd/dist/antd.css";
import {useNavigate, useParams } from "react-router-dom";


import { BiComment, BiDish, BiSend } from "react-icons/bi";
import HeaderDashboard from "../../components/HeaderDashboard";

import GeneralButton from "../../components/buttons/GeneralButton";



import {

	Collapse,

} from "antd";

import moment from "moment";

import BackButton from "../../components/buttons/BackButton";
import PlanContext from "../../context/planContext";
import { BsCalendar2 } from "react-icons/bs";
import { colors } from "../../utilities/colors";

import Renderif from "../../components/Renderif";
import { MdClose } from "react-icons/md";
import { Service } from "../../services/api";
import RecipeThumbnail from "../../components/RecipeThumbnail";
import AlertContext from "../../context/alertContext";
import AuthContext from "../../context/authContext";
import { IoMdCalendar } from "react-icons/io";
import { LANG } from "../commun/lang";



function PlanSemanalFullDeportista() {
	const navigate = useNavigate();
	const {id_plan} = useParams('id_plan')

	const {currentWeekPlan, weekRecipes,recipes, getRecipeByID} = useContext(PlanContext)
	const {showAlert} = useContext(AlertContext)
	const {user, lang} = useContext(AuthContext)

	const [state, setState] = useState({
		showComments:false,
		comments:[],
		comment:'',
		showRecipeDetail:false,
		loadingRecipe:true,
		recipeDetail:null,
        weeklyPlan:[],
        weeklyRicipes:[]
	})

    useEffect(()=>{
	
		const data = async () => {
		let _weeklyPlan = await Service('GET',`recipes/weeklyRecipes/${id_plan}`);
        // console.log(_weeklyPlan)
        console.log(JSON.parse(_weeklyPlan.data.response.week_recipes))
                
		
		let _commnets = await Service('GET',`comment/${id_plan}`);

		setState({...state, 
			weeklyPlan:_weeklyPlan.data.response, 
			weeklyRicipes:JSON.parse(_weeklyPlan.data.response.week_recipes),
			// comments:_commnets.data.response
		})


		};


		data();
		return () => {};
	},[])

	// useEffect(()=>{
	// 	//getComments();

	// 	return () => {}
	// },[])

	

	const showRecipeDetail = async (recipe) => {
		setState({...state, showRecipeDetail:true, loadingRecipe:true})
		let _recipeDetail = await Service('TSPOONLAB',`recipe/${recipe.id}`)
		setState({...state, recipeDetail:_recipeDetail.data, loadingRecipe:false, showRecipeDetail:true})
	}

	const onSend = async () => {
		let _onSend = await Service('PUT', `weeklyPlans/${id_plan}`,{status:1})
	
		let _onNotificate = await Service('POST', `notification`,{
			notification:`${user.firstname} ha enviado el plan semanal a`,
			id_sender:user.id_user,
			id_user:currentWeekPlan.id_user
		})
		showAlert({type:'success', message:LANG.NOT.enviar_a_cocina[lang], description:LANG.NOT.enviado_a_cocina[lang]})
	}
	const getComments = async() => {
		let _commnets = await Service('GET',`comment/${currentWeekPlan ? currentWeekPlan.id : id_plan}`);
		setState({...state, comments:_commnets.data.response});
		
	}
	const addComment = async () => {

		state.comments.push({
			...user,
			comment:state.comment,
			comment_created_at:Date.now()
		})
		
		let body = {
			comment:state.comment,
			id_plan:currentWeekPlan ? currentWeekPlan.id : id_plan,
			id_user:user.id_user
		}
		
		state.comment = ''
		setState({...state})
		let _addComment = await Service('POST', 'comment', body);
		

	}
	

	return (
		<>
		
	
			<HeaderDashboard />

            <div className="container-fluid p-3">

                <div className="d-flex flex-wrap align-content-center">
                <div className="col-12 col-lg">
                    <BackButton route="/plan-detalle" />
                    <div className="text-white f-30 f-anton text-uppercase ps-1">
                    PLAN SEMANAL - {(state.weeklyPlan?.kitchen)?.toUpperCase()}
                    </div>
                    <div className="d-flex align-items-center ps-1">
                    <BsCalendar2 color={colors.yellow}/>
                    <div className="f-14 text-yellow f-Poppins-Bold px-2">{`${moment(state.weeklyPlan?.start_date).format('D MMM')} - ${moment(state.weeklyPlan?.end_date).format('D MMM')}`}</div>
                    </div>
                </div>

                <div className="">
                {/* <GeneralButton
                    className="btn__blue btn__general"
                    title="Comentarios"
                    icon={<BiComment />}
                    onClick={() => {setState({...state, showComments:true})}}
                /> */}
                {/* <GeneralButton
                    className="btn__blue btn__general"
                    title="Ver Plan Completo"
                    icon={<IoMdCalendar />}
                    onClick={() => {
                    navigate('/plan-full/'+id_plan)
                    }}
                /> */}
                </div>
                </div>

            </div>
			
			<div className="mb__center_content">
				
			
				<div className='mb__center_content'>
                <div className="table_recipes bg-dark-blue">

                    <div className="blockA">
                        <div className="header_a bg-blue-shade text-white text-uppercase">
						{LANG.REGISTER.comidas[lang]}
                        </div>

                        {state.weeklyRicipes.course?.map((course,i)=>{
                            return ( 
                                <div key={i} className="day_block bg-secundary-dark-blue text-white" onClick={()=>{
									//navigate(`/cantidades/${61}/${i}`)
								}}>
                                    <div className="day_item f-14 f-Poppins-Medium ">{LANG.WEEKPLAN[course.replace(" ","_")][lang]}</div>
                                </div>
                            )
                        })}
                    </div>

                    <div className="table_container">
                        <div className="table_subcontainer">

							{[
								{label:LANG.WEEKPLAN.MONDAY[lang], value:'Lunes'},
								{label:LANG.WEEKPLAN.TUESDAY[lang], value:'Martes'},
								{label:LANG.WEEKPLAN.WEDNESDAY[lang], value:'Miercoles'},
								{label:LANG.WEEKPLAN.THURSDAY[lang], value:'Jueves'},
								{label:LANG.WEEKPLAN.FRIDAY[lang], value:'Viernes'},
								{label:LANG.WEEKPLAN.SATURDAY[lang], value:'Sabado'},

							].map((day,i)=>{
								return (
									<div key={i} className="blockBN">
										<div className="header_b bg-blue-shade text-white text-center cta-mint" onClick={()=>{
												navigate(`/cantidades/${id_plan}/${currentWeekPlan?.id_kitchen}/${i}`)
											}}>
											
												{day.label}
										</div>
										{state.weeklyRicipes?.week?.find((week)=> week.day === day.value).meals.map((meal, i)=>{
											return (
											
													<div key={i} className="day_block no-border bg-primary-dark-blue mb__text_normal text-white">
														<div className="recipe_item">
															<Renderif isTrue={meal.recipe}>
																<div className="foodContainer">
																	<div><RecipeThumbnail recipe={meal?.recipe}></RecipeThumbnail></div>
																	<div className="name d-flex align-items-center transition f-12 pe-3 unassigned cursor-pointer" onClick={()=>{showRecipeDetail(getRecipeByID(meal?.recipe))}}>
																		
																		<div className="col text-left f-Poppins-Medium ps-2">{getRecipeByID(meal?.recipe)?.descr}</div>
																	</div>
																</div>
															</Renderif>
															<Renderif isTrue={!meal.recipe}>
																<div className="foodContainer">
																	<div><RecipeThumbnail recipe={null}></RecipeThumbnail></div>
																	<div className={`name events-none`}>
																		<div className="f-12 f-Poppins-Regular text-mint-shade text-center ">
																			Sin asignar
																		</div>
																	</div>
																</div>
															</Renderif>
														</div>
													</div>
											
							
											)
										})}
									</div>
								)
							})}
                        </div>
                    </div>

                    <div className="clear"></div>
                </div>
            </div>
			</div>

			<Renderif isTrue={state.showRecipeDetail}>
				<div className="d-flex position-fixed vw-100 vh-100" style={{top: 0,right: 0,zIndex: 1,}}>

				

					<div className='col-3 col-lg-6 col-xl-7 col-xxl-8 bg-overlay' onClick={()=>{setState({...state, showRecipeDetail:false})}}></div>  
					<div className='col-9 col-lg-6 col-xl-5 col-xxl-4'>


			

						<div className=' bg-dark-blue border-l-mint h-100 overflow-auto hide-scroll'>

							<div className='d-flex'>
								<div className='col'></div>
								<div className='cursor-pointer p-2' onClick={()=>{setState({...state, showRecipeDetail:false})}}>
								<MdClose size={32} color={colors.white}></MdClose>
								</div>
							</div>


							<Renderif isTrue={state.loadingRecipe}>
								<div className="d-flex justify-content-center align-items-center h-75">
									<div className="spinner-border text-light" role="status">
										<span className="sr-only"></span>
									</div>
								</div>
							</Renderif>
							<Renderif isTrue={!state.loadingRecipe}>
							
								<div className='f-26 text-center f-anton text-white px-4'>{state?.recipeDetail?.descr}</div>
								

								<div className='d-flex justify-content-center align-items-center py-3'>
									<img className='w-50 ' src={`https://app.tspoonlab.com/recipes/api${state?.recipeDetail?.imageNormal}?rememberme=dGVjQGdvb3NlYW5kaG9wcGVyLmNvbToxNzI5MDIxNjEwNDEzOjo6MzRiOTEyMWI0ZmZlOGVlMTExYzI3MzAzODEwNGZhYjU`}></img>
								</div>

								<div className='px-4'>

									<div className='f-16 f-Poppins-Bold  text-yellow py-2'>{LANG.NOT.ingredientes[lang]}</div>
									<div className='py-2'>

									{state?.recipeDetail?.listComponentDetail.map((component)=>{
										return (
											<div className="d-flex  pb-2 pt-1 border-b-blue-shade">

												<div className="col-4">
													<div className="text-mint text-left f-12 f-Poppins-Medium">{component.quantity} {component.unit}</div>
												</div>

												<div className="col-10">
													<div>
														<div className="text-white f-12 f-Poppins-Medium px-2">
															{component.descr}
															{component.use !== null ? 
															<span className="px-1">({component.use})</span>
															:null}
														</div>

														{component.comment ? 
														<div className="text-mint-shade f-12 f-Poppins-Medium px-2">{component.comment}</div>
														:null}
														
														{component.quantityGross ? 
														<div className=" text-mint-shade f-12 f-Poppins-Medium px-2">Bruto: {component.quantityGross} {component.unit}</div>
														:null}
														
													
													</div>
												</div>
												
												
											

												
											</div>
										)
									})}
									</div>

								</div>
								<div className='px-4'>
									

									<div className='f-16 f-Poppins-Bold  text-yellow'>{LANG.NOT.preparacion[lang]}</div>
									<div className='f-14 f-Poppins-Regular text-justify text-white py-2'>{state?.recipeDetail?.process}</div>
							

								</div>
								<div className="py-4 my-4"></div>
							</Renderif>
							
							

						
						</div>
					
					</div>

					



				
				</div>
			</Renderif>

			<Renderif isTrue={state.showComments}>
				<div className="d-flex position-fixed vw-100 vh-100" style={{
					top: 0,
					right: 0,
					zIndex: 1,
					}}>

					<div className='col-6 col-lg-9 bg-overlay' onClick={()=>{setState({...state, showComments:false})}}></div> 
					<div className='col-6 col-lg-3'>

						<div className=' bg-dark-blue border-l-mint h-100 overflow-auto hide-scroll'>

						<div className='d-flex'>
							<div className='col'></div>
							<div className='cursor-pointer p-2' onClick={()=>{setState({...state, showComments:false})}}>
							<MdClose size={32} color={colors.white}></MdClose>
							</div>
						</div>

						<div className='f-30 f-anton text-white px-3 border-b-blue-shade'>COMENTARIOS</div>

						{state.comments.map((comment,i)=>{
							
						
							return (
								<div key={i} className=" border-b-blue-shade px-3 py-3">
									<div className="d-flex ">
										<div className="f-14 f-Poppins-Bold text-yellow">{comment?.firstname}</div>
									
										<div className="col"></div>
										{/* <div className="text-white">{new Date(comment?.comment_created_at).getDate()}</div> */}
										{/* <div className="text-white">{new Date(comment.comment_created_at)}</div> */}
										<div className="f-12 f-Poppins-Medium text-blue-shade">{moment(new Date(parseInt(comment?.comment_created_at))).format("D MMM")}</div>
									</div>
									<div className="f-12 f-Poppins-Medium text-blue-shade">{LANG.PROFILE[comment.rol][lang]}</div>
									<div className="f-14 f-Poppins-Medium text-light-gray">{comment.comment} </div>
								</div>
							) 
						})}


					

					

						
						<div className="px-3 py-3">
							<textarea value={state.comment} onChange={(e)=>{setState({...state, comment:e.currentTarget.value})}} rows={5} cols={5} className="textarea"></textarea>

							<div className="d-flex justify-content-end">
							<GeneralButton
								className="btn__blue btn__general"
								title="Enviar"
								
								icon={<BiSend />}
								onClick={() => {

									addComment();
									
								}}
							/>
							</div>
						
						</div>

						
						</div>
					
					</div>

					



				
				</div>
			</Renderif>

		</>
	);
}

export default PlanSemanalFullDeportista;


