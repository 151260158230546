import React, { useContext, useEffect, useState } from 'react'
import HeaderDashboard from '../../components/HeaderDashboard'
import {FaCcPaypal} from 'react-icons/fa'
import { colors } from '../../utilities/colors'
import GeneralButton from '../../components/buttons/GeneralButton'
import { IoIosArrowDown, IoMdAdd } from 'react-icons/io'
import { BiEdit, BiSave } from 'react-icons/bi'
import DeportistaContext from '../../context/deportistaContext'
import BackButton from '../../components/buttons/BackButton'
import { Service } from '../../services/api'
import AuthContext from '../../context/authContext'
import AlertContext from '../../context/alertContext'
import { useNavigate, useParams } from 'react-router-dom'
import { useFilePicker } from 'use-file-picker'
import { Button } from 'antd'
import { HiOutlineChevronLeft } from 'react-icons/hi'
import { LANG } from '../commun/lang'


export default function EditarPerfilDeportista() {
    const {athlete, updateAthlete} = useContext(DeportistaContext);
    const {updateUser, auth, lang} = useContext(AuthContext)
    const {showAlert} = useContext(AlertContext)
    const navigate = useNavigate();
    const [openFileSelector, { filesContent, loading, errors, plainFiles, clear }] = useFilePicker({
        multiple:false,
        accept: 'image/*',
        readAs: "DataURL"
    });
	const {id_athlete} = useParams('id_athlete');
    const {id_user} = useParams('id_user');

    const [user, setUser] = useState({
        firstname : athlete?.firstname,
        lastname : athlete?.lastname,
        gender : athlete?.gender,
        country : athlete?.country,
        state : athlete?.state,
        city : athlete?.city,
        cp : athlete?.cp,
        email : athlete?.email,
        phone : athlete?.phone,
        // password : '',
        // picture : '',
    }) 
   
    const [deportista, setDeportista] = useState({
        fedding :athlete?.fedding,
        id_suscription :athlete?.id_suscription,
        birthday :athlete?.birthday,
        club :athlete?.club,
        allergy :athlete?.allergy,
        service :athlete?.service,
        // service_days :athlete.service_days,
        delevery_address :athlete?.delevery_address,
        delevery_occur :athlete?.delevery_occur,
        delevery_time :athlete?.delevery_time,
        invitados :athlete?.invitados,
        objetivo: athlete?.objetivo,
        peso: athlete?.peso,
        estatura: athlete?.estatura,
        imc: athlete?.imc,
        payment_method :athlete?.payment_method,
    }) 

      useEffect(()=>{

        const data = async () => {

            if(id_athlete){
                let athlete = await Service("GET",`athlete/${id_user}`);
                console.log(athlete)
                let athlete_response = athlete.data.response;
                console.log(athlete_response)

                setDeportista({
                    ...deportista,
                    fedding :athlete_response.fedding,
                    id_suscription :athlete_response.id_suscription,
                    birthday :athlete_response.birthday,
                    club :athlete_response?.club,
                    allergy :athlete_response.allergy,
                    service :athlete_response.service,
                    // service_days :athlete.service_days,
                    delevery_address :athlete_response.delevery_address,
                    delevery_occur :athlete_response.delevery_occur,
                    delevery_time :athlete_response.delevery_time,
                    invitados :athlete_response.invitados,
                    objetivo: athlete_response.objetivo,
                    peso: athlete_response.peso,
                    estatura: athlete_response.estatura,
                    imc: athlete_response.imc,
                    payment_method :athlete_response.payment_method,
                })
                setUser({
                    ...user,
                    firstname : athlete_response.firstname,
                    lastname : athlete_response.lastname,
                    gender : athlete_response.gender,
                    country : athlete_response.country,
                    state : athlete_response.state,
                    city : athlete_response.city,
                    cp : athlete_response.cp,
                    email : athlete_response.email,
                    phone : athlete_response.phone,
                    // password : '',
                    // picture : '',
                }) 


            }

        }  
        data()

        return () => {}
      },[])

      const onSave = async() => {

        // if(typeof(deportista.service_days) === 'object'){
        //   deportista.service_days = JSON.stringify(deportista.service_days)
      
        // }
        let _id_athlete;
        let _id_user;
        if(id_athlete){
            _id_athlete = id_athlete;
            _id_user = id_user;
        }else{
            _id_athlete = athlete.id_athlete
            _id_user = athlete.id_user
        }
    
        let _user = await Service('PUT', 'user', {...user, id_user: _id_user});
        console.log(_user);
        let _athlete = await Service('PUT', 'athlete/update', {...deportista, id_athlete:_id_athlete});
        console.log(_athlete)

        if(filesContent.length > 0){
            var body = new FormData()
            body.set('image',plainFiles[0])
            let _image = await Service('POST', 'user/uploadimage/'+_id_user, body); 
            console.log(_image);
            athlete.picture = _image.data.response.picture;
            
        }
        if(!id_athlete){
            athlete.firstname = user.firstname
            athlete.lastname = user.lastname
            athlete.city = user.city;
            athlete.state = user.state;
            athlete.email = user.email;
            athlete.club = deportista.club;
            athlete.delevery_address = deportista.delevery_address;
            athlete.allergy = deportista.allergy;
            athlete.delevery_occur = deportista.delevery_occur;
            athlete.fedding = deportista.fedding;
            athlete.objetivo = deportista.objetivo;
            athlete.peso = deportista.peso;
            athlete.estatura =  deportista.estatura;
            athlete.imc = deportista.imc;

            auth.firstname = user.firstname
            auth.lastname = user.lastname
            auth.gender = user.gender
            auth.country = user.country
            auth.state = user.state
            auth.city = user.city
            auth.cp = user.cp
            auth.email = user.email
            auth.phone = user.phone
            updateUser(auth);
            updateAthlete(athlete);
            navigate(-1)
        }else{
            //navigate(-2)

           // navigate(`/user`, athlete)
            
            
            // athlete.firstname = user.firstname
            // athlete.lastname = user.lastname
            // athlete.city = user.city;
            // athlete.state = user.state;
            // athlete.email = user.email;
            // athlete.club = deportista.club;
            // athlete.delevery_address = deportista.delevery_address;
            // athlete.allergy = deportista.allergy;
            // athlete.delevery_occur = deportista.delevery_occur;
            // athlete.fedding = deportista.fedding;
            // athlete.objetivo = deportista.objetivo;
            // athlete.peso = deportista.peso;
            // athlete.estatura =  deportista.estatura;
            // athlete.imc = deportista.imc;

            // updateAthlete(athlete);

        }
    
    
        
        showAlert({type:'success', message:LANG.NOT.guardar[lang], description:LANG.NOT.cambios_guardados[lang]})
      
    
      
      }
  return (

    <>
      <HeaderDashboard />
         <div className="container-fluid p-3">

            <div className="d-flex flex-wrap align-content-center">
            <div className="col-12 col-lg">
                {!id_athlete ? 
                <BackButton route="/plan-detalle" />
                :
                <Button
                    className="btn_back transition"
                    icon={<div className="icon"><HiOutlineChevronLeft size={16} /></div>}
                    onClick={() => navigate(-2)}
                    size="large"
                >
                    Regresar
                </Button>
                }
               
                
               

                <div className="text-white f-30 f-anton text-uppercase ps-1">
                    {LANG.PROFILE.EDIT[lang]} {LANG.PROFILE.PROFILE[lang]}
                </div>
               
            </div>

            <div className="">
            {/* <GeneralButton
                className="btn__blue btn__general"
                title="Comentarios"
                icon={<BiComment />}
                onClick={() => {setState({...state, showComments:true})}}
            /> */}
            {/* <GeneralButton
                className="btn__blue btn__general"
                title="Ver Plan Completo"
                icon={<IoMdCalendar />}
                onClick={() => {
                navigate('/plan-full/'+id_plan)
                }}
            /> */}
            </div>
            </div>
        </div>
      
        <div className='container-fluid px-3 pb-5'>

            <div className='text-white bg-dark-blue rounded'>

                <div className='d-flex p-3'>
                    <div className='col'></div>
                    {/* <GeneralButton
                        className="btn__blue btn__general mr-10"
                        title="Tabla nutricional"
                        icon={<BiEdit />}
                        onClick={() => {}}
                    /> */}
                    <GeneralButton
                        className="btn__blue btn__general"
                        title={LANG.WEEKPLAN.SAVE[lang]}
                        icon={<BiSave />}
                        onClick={() => {
                            onSave()
                        }}
                    />
                    
                </div>

                <div className=' bg-mint-light'>
                    <div className='border-b-mint d-flex p-3'>
                        <div className='bg-yellow p-1 rounded-3 position-relative'>
                                <div className='image-placeholder' onClick={()=>{
                                    openFileSelector()
                                }}>
                                    <BiEdit size={36}></BiEdit>
                                </div>
                                 <img style={{width:100}} className='rounded-3 h-100' src={
                                    filesContent.length > 0 ? filesContent[0].content :
                                    athlete?.picture ? process.env.REACT_APP_API_URL+'/images/'+athlete?.picture : process.env.REACT_APP_API_URL + '/images/profile.png' 
                                    
                                    }></img>
                        </div>
                        <div className='col ps-2'>
                            <div className='col'>
                                <input required type='text' className='input text-mint-shade fw-bold' value={user?.firstname} onChange={(value)=>{setUser({...user, firstname:value.currentTarget.value})}}></input>
                            </div>
                            <div className='col mt-1'>
                                <input required type='text' className='input text-mint-shade fw-bold' value={user?.lastname} onChange={(value)=>{setUser({...user, lastname:value.currentTarget.value})}}></input>
                            </div>
                            {/* <div className='f-20 f-Poppins-Bold text-mint-shade '>{athlete?.firstname + " " +athlete?.lastname}</div> */}
                            {/* <div className='f-14 f-Poppins-Regular text-mint-shade'>{athlete?.id_suscription == 1 ? 'PLAN PRO' : 'PLAN ELITE'} - Mensual</div> */}
                        </div>
                    </div>

                    <div className='d-flex border-b-mint p-3'>
                        <div className=' col f-Poppins-Medium text-mint-shade'>Club</div>
                        {/* <div className=' col f-Poppins-Medium text-mint-shade'>{athlete?.club}</div> */}
                        <div className='col'>
                            <input required type='text' className='input text-mint-shade' value={deportista?.club} onChange={(value)=>{setDeportista({...deportista, club:value.currentTarget.value})}}></input>
                        </div>
                    </div>
                    <div className='d-flex border-b-mint p-3'>
                        <div className=' col f-Poppins-Medium text-mint-shade'>{LANG.REGISTER.Ciudad[lang]}</div>
                        {/* <div className=' col f-Poppins-Medium text-mint-shade'>{athlete?.city}</div> */}
                        <div className='col'>
                            <input required type='text' className='input text-mint-shade' value={user?.city} onChange={(value)=>{setUser({...user, city:value.currentTarget.value})}}></input>
                        </div>
                    </div>
                    <div className='d-flex border-b-mint p-3'>
                        <div className=' col f-Poppins-Medium text-mint-shade'>{LANG.REGISTER.Estado[lang]} / {LANG.REGISTER.Provincia[lang]}</div>
                   
                         <div className='col'>
                            <input required type='text' className='input text-mint-shade' value={user?.state} onChange={(value)=>{setUser({...user, state:value.currentTarget.value})}}></input>
                        </div>
                    </div>
                    <div className='d-flex border-b-mint p-3'>
                        <div className=' col f-Poppins-Medium text-mint-shade'>{LANG.REGISTER.País[lang]}</div>
                        <div className=' col f-Poppins-Medium text-mint-shade'>{athlete?.country}</div>
                    </div>
                    <div className='d-flex border-b-mint p-3'>
                        <div className=' col f-Poppins-Medium text-mint-shade'>Email</div>
                        {/* <div className=' col f-Poppins-Medium text-mint-shade'>{athlete?.email}.</div> */}
                        <div className='col'>
                            <input required type='text' className='input text-mint-shade' value={user?.email} onChange={(value)=>{setUser({...user, email:value.currentTarget.value})}}></input>
                        </div>
                    </div>

                </div>

            
                <div className=' bg-secundary-dark-blue py-2 px-3'>
                    <div className=' f-20 f-anton text-white text-uppercase'>{LANG.REGISTER.GDATA[lang]}</div>
                </div>
                <div className='border-b-blue-shade d-flex p-3'>
                    <div className=' col f-Poppins-Medium text-white'>{LANG.REGISTER.Gender[lang]}:</div>
                    {/* <div className=' col f-Poppins-Medium text-white'>{athlete?.gender}</div> */}
                    <div className='col'>
                        <div className='input d-flex'>
                        <select required className='w-100' value={user.gender} onChange={(value)=>{setUser({...user, gender:value.currentTarget.value})}}>
                            <option value=''>{LANG.REGISTER.seleccionar[lang]}</option>
                            <option value='Femenino'>{LANG.REGISTER.Femenino[lang]}</option>
                            <option value='Masculino'>{LANG.REGISTER.Masculino[lang]}</option>
                            <option value='Otro'>{LANG.REGISTER.Otro[lang]}</option>
                        </select>
                        <div className='px-2 border-l-mint d-flex align-items-center'><IoIosArrowDown color={colors.mint}></IoIosArrowDown></div>
                        </div>
                    </div>
                </div>
                <div className='border-b-blue-shade d-flex p-3'>
                    <div className=' col f-Poppins-Medium text-white'>{LANG.REGISTER.Telefono[lang]}:</div>
                    {/* <div className=' col f-Poppins-Medium text-white'>{athlete?.phone}</div> */}
                    <div className='col'>
                        <input type='number' className='input p-2' value={user.phone} onChange={(value)=>{setUser({...user, phone:value.currentTarget.value})}}></input>
                    </div>
                </div>
                <div className='border-b-blue-shade d-flex p-3'>
                    <div className=' col f-Poppins-Medium text-white'>{LANG.REGISTER.lugar_entrega_direccion[lang].split("/")[1]}:</div>
                    {/* <div className=' col f-Poppins-Medium text-white'>{athlete?.delevery_address}</div> */}
                    <div className='col'>
                        <input type='text' className='input p-2' value={deportista?.delevery_address} onChange={(value)=>{setDeportista({...deportista, delevery_address:value.currentTarget.value})}}></input>
                    </div>
                </div>
                <div className=' bg-secundary-dark-blue py-2 px-3'>
                    <div className=' f-20 f-anton text-white text-uppercase'>{LANG.REGISTER.preferencias[lang]}</div>
                </div>
                <div className='border-b-blue-shade d-flex p-3'>
                    <div className=' col f-Poppins-Medium text-white'>{LANG.PLANDETAIL.alergias_intolerancias[lang]}:</div>
                    {/* <div className=' col f-Poppins-Medium text-white'>{athlete?.allergy}</div> */}
                    <div className='col'>
                        <input type='text' className='input p-2' value={deportista?.allergy} onChange={(value)=>{setDeportista({...deportista, allergy:value.currentTarget.value})}}></input>
                    </div>
                </div>
                <div className='border-b-blue-shade d-flex p-3'>
                    <div className=' col f-Poppins-Medium text-white'>{LANG.REGISTER.preferencias_alimentos[lang]}:</div>
                    {/* <div className=' col f-Poppins-Medium text-white'>{athlete?.delevery_occur}</div> */}
                    <div className='col'>
                        <input type='text' className='input p-2' value={deportista?.delevery_occur} onChange={(value)=>{setDeportista({...deportista, delevery_occur:value.currentTarget.value})}}></input>
                    </div>
                </div>
                <div className='border-b-blue-shade d-flex p-3'>
                    <div className=' col f-Poppins-Medium text-white'>{LANG.REGISTER.filosofia_alimentaria[lang]}:</div>
                    {/* <div className=' col f-Poppins-Medium text-white'>{athlete?.fedding}</div> */}
                    <div className='col'>
                        <input type='text' className='input p-2' value={deportista?.fedding} onChange={(value)=>{setDeportista({...deportista, fedding:value.currentTarget.value})}}></input>
                    </div>
                </div>

                <div className='border-b-blue-shade d-flex p-3'>
                    <div className=' col f-Poppins-Medium text-white'>{LANG.REGISTER.objetivo[lang]}:</div>
                    {/* <div className=' col f-Poppins-Medium text-white'>{athlete?.fedding}</div> */}
                    <div className='col'>
                        <input type='text' className='input p-2' value={deportista?.objetivo} onChange={(value)=>{setDeportista({...deportista, objetivo:value.currentTarget.value})}}></input>
                    </div>
                </div>
                <div className='border-b-blue-shade d-flex p-3'>
                    <div className=' col f-Poppins-Medium text-white'>{LANG.REGISTER.peso[lang]}:</div>
                    {/* <div className=' col f-Poppins-Medium text-white'>{athlete?.fedding}</div> */}
                    <div className='col'>
                        <input type='text' className='input p-2' value={deportista?.peso} onChange={(value)=>{setDeportista({...deportista, peso:value.currentTarget.value})}}></input>
                    </div>
                </div>
                <div className='border-b-blue-shade d-flex p-3'>
                    <div className=' col f-Poppins-Medium text-white'>{LANG.REGISTER.estatura[lang]}:</div>
                    {/* <div className=' col f-Poppins-Medium text-white'>{athlete?.fedding}</div> */}
                    <div className='col'>
                        <input type='text' className='input p-2' value={deportista?.estatura} onChange={(value)=>{setDeportista({...deportista, estatura:value.currentTarget.value})}}></input>
                    </div>
                </div>
                <div className='border-b-blue-shade d-flex p-3'>
                    <div className=' col f-Poppins-Medium text-white'>{LANG.REGISTER.imc[lang]}:</div>
                    {/* <div className=' col f-Poppins-Medium text-white'>{athlete?.fedding}</div> */}
                    <div className='col'>
                        <input type='text' className='input p-2' value={deportista?.imc} onChange={(value)=>{setDeportista({...deportista, imc:value.currentTarget.value})}}></input>
                    </div>
                </div>
                

                <div className=' bg-secundary-dark-blue py-2 px-3'>
                    <div className=' f-20 f-anton text-white text-uppercase'>{LANG.PLANDETAIL.servicio[lang]}</div>
                </div>
                <div className='border-b-blue-shade d-flex p-3'>
                    <div className=' col f-Poppins-Medium text-white'>{LANG.REGISTER.plan[lang]}</div>
                    <div className=' col f-Poppins-Medium text-white'>{athlete?.id_suscription == 1 ? 'PLAN PRO' : 'PLAN ELITE'}</div>
                </div>
                <div className='border-b-blue-shade d-flex p-3'>
                    <div className=' col f-Poppins-Medium text-white'>{LANG.PLANDETAIL.invitados[lang]}</div>
                    <div className=' col f-Poppins-Medium text-white'>{athlete?.invitados}</div>
                </div>
                <div className='border-b-blue-shade d-flex p-3'>
                    <div className=' col f-Poppins-Medium text-white'>{LANG.PLANDETAIL.servicio[lang]}</div>
                    <div className=' col f-Poppins-Medium text-white'>{LANG.NOT[athlete?.service][lang]}</div>
                </div>
                <div className='border-b-blue-shade d-flex p-3'>
                    <div className=' col f-Poppins-Medium text-white'>{LANG.NOT.hora[lang]}</div>
                    <div className=' col f-Poppins-Medium text-white'>{athlete?.delevery_time}</div>
                </div>
                {/* <div className='border-b-blue-shade d-flex p-3'>
                    <div className=' col f-Poppins-Medium text-white'></div>
                    <div className=' col f-Poppins-Medium text-white'>
                          <GeneralButton
                                className="btn__blue btn__general"
                                title="Guardar"
                                icon={<Save />}
                                onClick={() => {}}
                            />
                    </div>
                </div> */}

        

            </div>

        </div>
          
            
        
     
    </>
  )
}
