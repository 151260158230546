/* eslint-disable no-unused-vars */
import React, {useContext, useEffect, useState } from "react";
import "antd/dist/antd.css";
import "../../assets/css/seals.css";


import HeaderDashboard from "../../components/HeaderDashboard";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import UsersSegment from "./UsersSegment";

import DrawerAddSpecialist from "./DrawerAddSpecialist";

import { ChefColumns, CocinaColums, DeportistaColumns, DeportistaColumnsNutricionista, NutricionistaColumns } from "../../utilities/tabs/users";
import UsersTable from "./UsersTabs";
import AdminContext from "../../context/adminContext";
import Renderif from "../../components/Renderif";
import DrawerAddGuest from "./DrawerAddGuest";
import AuthContext from "../../context/authContext";
import GeneralButton from "../../components/buttons/GeneralButton";
import { IoMdAdd } from "react-icons/io";
import { LANG } from "../commun/lang";

function Users() {

	let navigate = useNavigate();
	const location = useLocation();
	const{lang} = useContext(AuthContext);

	const {tab } = useParams();
	const {deportistas, nutricionistas, cocinas, chefs, getUsers} = useContext(AdminContext)
	console.log(nutricionistas)
	const {user} = useContext(AuthContext)

	const [state, setState] = useState({
		tab:tab ? tab : 'deportistas',
		users_data:[],
		search:'',
		selected_user:null,
	})
	
	useEffect(()=>{
		getUsers();
	},[])


	const filter = ({data, key}) => {
	
	
		if(user.id_rol == 2){
		
			
			
			return state.search === '' ? 
			data.filter((item)=>item?.id_nutricionist_owner === user.id_user || item?.id_nutricionist === user.id_user || item?.nutricionista?.id_user === user.id_user) 
			: data.filter((item)=>item[key]?.toUpperCase().includes(state.search.toUpperCase()) && item.id_nutricionist_owner === user.id_user)
		}else{
			
			if(state.tab === 'nutricionistas'){
				return state.search === '' ? data: data.filter((item)=>item[key]?.toUpperCase().includes(state.search.toUpperCase()) && item.id_nutricionist_owner === null)
			}
			if(state.tab === 'cocinas'){
				console.log(data);
				return state.search === '' ? data: data.filter((item)=>item[key]?.toUpperCase().includes(state.search.toUpperCase()) && item.id_nutricionist_owner === null)
			}
			
			return state.search === '' ? data.filter((item)=>item.id_nutricionist_owner === null) : data.filter((item)=>item[key]?.toUpperCase().includes(state.search.toUpperCase()) && item.id_nutricionist_owner === null)
		}
		
	}


	return (
		<>
			<DrawerAddSpecialist />
			<DrawerAddGuest />
			<HeaderDashboard />
			

			<div className="container-fluid p-3">
				<div className="d-flex">
					<div className="text-white f-30 f-anton text-uppercase ps-1">


						{user.id_rol === 2 ? LANG.DASHBOARD.ATHLETES[lang] : LANG.USERS.USERS[lang]}

					</div>
					<div className="col">asdf</div>
					<div className="mr-3 mt-2">
						{/* {user.id_rol === 2 ? */}
						<GeneralButton
							className="btn__blue btn__general"
							title={LANG.NOT.nuevo_deportista[lang]}
							icon={<IoMdAdd />}
							onClick={() => navigate(`/alta-deportista`)}
						/>
						{/* :<></>} */}
					</div>
					<div className="mx-1"></div>
					<div className="mr-3 mt-2">
						{/* {user.id_rol === 2 ? */}
						<GeneralButton
							className="btn__blue btn__general"
							title={LANG.NOT.nuevo_nutricionista[lang]}
							icon={<IoMdAdd />}
							onClick={() => navigate(`/alta-nutricionista`)}
						/>
						{/* :<></>} */}
					</div>
				</div>
				
			</div>

			<UsersSegment 
				tab={state.tab} 
				onChangeTab={(tab)=>{
					setState({...state, tab:tab.toLowerCase()})
				}} 
				onSearch={(text)=>{
					
					setState({...state, search:text})
				}}
			/>

			
			<Renderif isTrue={state.tab === 'deportistas'}>
				<UsersTable  data={filter({data:deportistas, key:'firstname'})} columns={user.id_rol === 2 ? DeportistaColumnsNutricionista()  : DeportistaColumns()} />
			</Renderif>


			<Renderif isTrue={state.tab === 'nutricionistas'}>
				<UsersTable data={filter({data:nutricionistas, key:'firstname'})} columns={NutricionistaColumns()} />
			</Renderif>

	
			<Renderif isTrue={state.tab === 'cocinas'}>
				<UsersTable data={filter({data:cocinas, key:'name'})} columns={CocinaColums()} />
			</Renderif>

			
			<Renderif isTrue={state.tab === 'chefs'}>
				<UsersTable data={filter({data:chefs, key:'firstname'})} columns={ChefColumns} />
			</Renderif>			
		</>
	);
}

export default Users;


