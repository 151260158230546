/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import "../../assets/css/dashboard.css";
import { useNavigate } from "react-router-dom";
import HeaderDashboard from "../../components/HeaderDashboard";
import AuthContext from "../../context/authContext";
import { Service } from "../../services/api";
import { colors } from "../../utilities/colors";
import { AiOutlineCalendar } from "react-icons/ai";
import { FaFireAlt, FaShippingFast } from "react-icons/fa";
import CocinaContext from "../../context/cocinaContext";
import moment from "moment";
import { LANG } from "../commun/lang";


function EnviosCocina() {

	const navigate = useNavigate();

	const { user, lang } = useContext(AuthContext);
	const {weekPlans} = useContext(CocinaContext)



	return (
		<>
			<HeaderDashboard />

			<div className="container-fluid p-3">
				<div className="text-white f-30 f-anton text-uppercase ps-1">
					{LANG.NOT.envios[lang]}
				</div>
			</div>

			<div className="container-fluid px-3">
					<table className="ant-table w-100 bg-dark-blue rounded-2  overflow-hidden">
						<thead className="ant-table-thead">
							<tr>
								<th className="col"><div className="text-left f-anton f-14">{LANG.DASHBOARD.KITCHEN[lang]}</div></th>
								<th className="col"><div className="text-left f-anton f-14">{LANG.DASHBOARD.WEEK[lang]}</div></th>
								<th className="col"><div className="text-center f-anton f-14">{LANG.DASHBOARD.ATHLETES[lang]}</div></th>
								{/* <th className="col"><div className="text-center f-anton f-14">Status</div></th> */}
								<th className="col"><div className="text-center f-anton f-14">{LANG.NOT.envios[lang]}</div></th>
								
							</tr>
						</thead>
						<tbody className="ant-table-tbody">
								{weekPlans.map((item,i)=>{
									return (
										<tr className="" key={i}>
											<td className="">
												<div className="d-flex align-items-center cursor-pointer">
													<div className="pe-3">
														<FaFireAlt color={colors.mint} size={22} />
													</div>
													<div>
														<div className="f-Poppins-Medium text-white">
															{item.kitchen}
														</div>
														<div className="f-Poppins-Regular text-mint-shade">
															{item.email}
														</div>
													</div>
												</div>
											</td>
											<td>
												<div className="d-flex align-items-center ">
													<div className="pe-3">
														<AiOutlineCalendar color={colors.mint} size={22} />
													</div>
													<div className="">
														<div className="f-Poppins-Meidum text-white">{`${moment(item?.start_date).format('D MMM')} - ${moment(item?.end_date).format('D MMM')}`}</div>
													</div>
												</div>
											</td>
											<td>
												
												<div className="text-white text-center f-16 f-Poppins-Bold px-4 py-2 line-1">{item?.total_athletes}</div>
											
											</td>
											{/* <td>
												<RenderStatus status={item.status}></RenderStatus>
											</td> */}
											<td>
												

												<div className="d-flex justify-content-center">
													<div className="cta cta-outline" onClick={(e)=>{
														e.stopPropagation();
														navigate(`/envios/${item.id}`)
													}}>
														<div className="icon">
															<FaShippingFast color={colors.white} size={16}></FaShippingFast>
														</div>
														<div className="text-white f-14 f-anton line-1">{LANG.NOT.ver_envios[lang]}</div>
													</div>

												</div>

											</td>
										</tr>
									)

								})}
						</tbody>
					</table>
				</div>
		</>
	);
}

export default EnviosCocina;
