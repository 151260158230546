/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import "antd/dist/antd.css";
import "../../assets/css/seals.css";
import DataContext from "../../context/dataContext";
import HeaderDashboard from "../../components/HeaderDashboard";
import { Link, Redirect, Navigate, useNavigate } from "react-router-dom";
import GeneralButton from "../../components/buttons/GeneralButton";
import { IoMdAdd } from "react-icons/io";
import AthletesTab from "./AthletesTabs";
import AthleteSearch from "./AthleteSearch";
import ModalDeleteAthlete from "./ModalDeleteAthlete";
import { Service } from "../../services/api";
import AuthContext from "../../context/authContext";
import { AthletesColumns } from "./UsersAthlethesColums";
import { Table } from "antd";

function Athletes() {
	const { data, getData } = useContext(DataContext);
	const [initData, setInitData] = useState(false);
	const navigate = useNavigate();

	const { user } = useContext(AuthContext);

	const [open, setOpen] = useState(false);

	const showDrawer = () => {
		setOpen(true);
	};

	const onClose = () => {
		setOpen(false);
	};

	const [state, setState] = useState({
		weekplan: [],
		athletes: [],
	});

	useEffect(() => {
		
		const data = async () => {
			let _athletes = await Service(
				"GET",
				`athletes/nutricionist/${user?.id_user}`
			);
			

			setState({
				...state,
				athletes: _athletes.data.response,
			});
		};
		data();
		return () => {};
	}, []);

	return (
		<>
			<HeaderDashboard />
			<ModalDeleteAthlete />

			<div className="mb__center_content">
				<div className="section_title">
					<div className="d-flex">
						<div className="text-white mb__section_title title_section_plan text-uppercase">
							DEPORTISTAS{" "}
						</div>
						<div className="col"></div>
						<div className="btn_1">
							<GeneralButton
								className="btn__blue btn__general"
								icon={<IoMdAdd />}
								title="Alta Deportista"
								onClick={() => {
									navigate("/alta-deportista");
								}}
							/>
						</div>
					</div>

					{/* <div className="clear"></div> */}
				</div>

				{/* <div className="clear"></div> */}
				<AthleteSearch />

				<div className="mb__center_content">
					<div className="section_seals_table">
						<div className="data_tables bg-dark-blue">
							<Table
								columns={AthletesColumns}
								dataSource={state.athletes}
							/>

							<div className="clear"></div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Athletes;
