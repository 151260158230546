import React, { useContext, useEffect, useState } from 'react'
import "../../assets/css/registro.css";
import { BsCircle, BsRecordCircleFill } from 'react-icons/bs';
import {IoIosArrowBack,IoIosArrowDown} from 'react-icons/io'
import {FaStripe,FaCcPaypal} from 'react-icons/fa'
import Renderif from '../../components/Renderif';
import { colors } from '../../utilities/colors';

import CheckBox from '../../components/buttons/checkbox';
import { Service } from '../../services/api';
import { useNavigate } from 'react-router-dom';

import AuthContext from '../../context/authContext';
import { ELITE_LINKS, ELITE_LINKS_STAGE, NUTRICIONISTA_PRO, NUTRICIONISTA_PRO_STAGE, PRO_LINKS, PRO_LINKS_STAGE } from '../../payment/stripe_links';
import { LANG } from '../commun/lang';
import AlertContext from '../../context/alertContext';
import HeaderDashboard from '../../components/HeaderDashboard';






export default function AltaNutricionista() {

  const navigate = useNavigate();
  const {showAlert} = useContext(AlertContext)

  let cocina_steps = [
    'Tipo de registro',
    'Datos',
    'Terminos y condiciones',
  ]
  let nutricionista_steps = [
    // 'Tipo de registro',
    'Datos',
    // 'Terminos y condiciones',
    // 'Pago',
  ]
  let deportista_steps = [
    'Tipo de registro',
    'Datos generales',
    'Terminos y condiciones',
    'Datos del deportista',
    'Servicio',
    'Pago',
  ]

  const {user, setUser, lang} = useContext(AuthContext)
  const [formValidation, setFormValidation] = useState({
    validated:false,
  })
  const [state, setState] = useState({
    step:1,
    steps: ['Datos'],
    rol_active:'Nutricionista',
    tyco_active:true,
    newsletter_active:false,
    plan_active:'PRO',
    payment:'Stripe',
    password_confirmed:'1temporal',

  })

  //DATOS DE REGISTRO EN COMUN
  const [registro, setRegistro] = useState({
    firstname : '',
    lastname : '',
    gender : '',
    country : 'Spain',
    state : '',
    city : '',
    cp : '',
    email : '',
    phone : '',
    password : '1temporal',
    picture : '',
  }) 

  //DATOS DE REGISTRO COMO DEPORTISTA
  const [deportista, setDeportista] = useState({
    fedding :'',
    id_suscription :1,
    birthday :'',
    club :'',
    allergy :'',
    service :'Comida',
    service_days :[],
    delevery_address :'',
    delevery_occur :'',
    delevery_time :'',
    invitados :'0',
    objetivo:'',
    peso:'',
    estatura:'',
    imc:'',
    payment_method :'',
  }) 

  const onRegister = async() => {
    if(typeof(deportista.service_days) === 'object'){
      deportista.service_days = JSON.stringify(deportista.service_days)
  
    }

    let active = 2;
    // switch (state.rol_active) {
    //   case 'Cocina':
    //     active = 2
    //     break;
    //   case 'Nutricionista':
    //     if(deportista.id_suscription == 2){
    //       active = 1
    //     }else{
    //       active = 2
    //     }
    //     break;
    //   case 'Deportista':
    //     active = 1
    //     break;

    //   default:
    //     active = 2
    //     break;
    // }
    
    let _registro = await Service('POST', 'user/register', {active:active, ...registro});
    let _nutricionist = await Service('POST', 'nutricionist', {id_user:_registro.data.response.id_user, id_suscription_nutricionist:deportista.id_suscription});
    console.log(_nutricionist)

    navigate(-1);
    showAlert({type:'success', message:LANG.NOT.nuevo_nutricionista_creado[lang], description:LANG.NOT.registro[lang]})



    // switch (_registro.data.response.id_rol) {
    //   case 2:
    //     let _nutricionist = await Service('POST', 'nutricionist', {id_user:_registro.data.response.id_user, id_suscription_nutricionist:deportista.id_suscription});
    //     let payment_url_nutricionist;
    //     if(deportista.id_suscription === 2){
    //       payment_url_nutricionist = NUTRICIONISTA_PRO[0]
    //       window.open(payment_url_nutricionist+registro.email, '_blank');
    //     }
       
        
    //     break;
    //   case 3:
    //     let _chef = await Service('POST', 'chef', {id_user:_registro.data.response.id_user, kitchen:registro.firstname});
        
    //     break;
    //   case 4:
        
    //     let _athlete = await Service('POST', 'athlete', {...deportista, id_user:_registro.data.response.id_user});
    //     let payment_url;
    //     if(deportista.id_suscription === 1){

    //       //PRO
    //       switch (deportista.invitados) {
    //         case '0':
    //           payment_url = PRO_LINKS[0]
    //           break;
    //         case '1':
    //           payment_url = PRO_LINKS[1]
    //           break;
    //         case '2':
    //           payment_url = PRO_LINKS[2]
    //           break;
    //         case '3':
      
    //         default:
    //           payment_url = PRO_LINKS[0]
    //           break;
    //       }
    //     }else{
    
    //       //ELITE
    //       switch (deportista.invitados) {
    //         case '0':
    //           payment_url = ELITE_LINKS[0]
    //           break;
    //         case '1':
    //           payment_url = ELITE_LINKS[1]
    //           break;
    //         case '2':
    //           payment_url = ELITE_LINKS[2]
    //           break;
    //         case '3':
    //           payment_url = ELITE_LINKS[3]
    //           break;
    //         default:
    //           payment_url = ELITE_LINKS[0]
    //           break;
    //       }
    
    //     }
    //     window.open(payment_url+registro.email, '_blank');
    //     break;
    //   default:
    //     break;
    // }

    // localStorage.setItem("user", JSON.stringify(_registro.data.response));
    // setUser(_registro.data.response)

    // navigate("/dashboard");
  }
  const onNextStep = () => {
    

    setFormValidation({...formValidation, validated:true})


    if(state.step !== 0){
      if(!formValidation.avaibale){

        return;
      }
    }

    if(state.step === 1){
      if(registro.password !== state.password_confirmed){
        return;
      }
    }
    registro.id_rol = 2;
    console.log("REGISTRAR")
    onRegister()

    // if(state.step === 4){
    //   if(deportista.service_days.length === 0){
    //     return;
    //   }
    // }
  

  

  
    // switch (state.rol_active) {
    //   case 'Cocina':
    //     if(state.step === 2){
    //       registro.id_rol = 3;
    //       onRegister();
    //       return;
    //     }
    //     state.steps.push(cocina_steps[state.step + 1]);

    //     break;
    //   case 'Nutricionista':
    //     if(state.step === 3){
    //       registro.id_rol = 2;
    //       onRegister();
    //       return;
    //     }
          
    //     state.steps.push(nutricionista_steps[state.step + 1]);
    //     break;
    //   case 'Deportista':
    //     if(state.step === 5){
    //       registro.id_rol = 4;
    //       onRegister();
    //       return;
    //     }
    //     state.steps.push(deportista_steps[state.step + 1]);
    //     break;
    //   default:
    //     break;
    // }
    // state.step = state.step + 1;
    // setState({...state})
    // setFormValidation({validated:false, avaibale:false})

  }
  const onPrevStep = () => {
  
 
        navigate(-1)
 
  }

  const RenderNextStep = () => {
    return (
      <div className='d-flex justify-content-end py-3 px-3 '>
        <div>
            <input type="submit" className='input px-4' value={LANG.REGISTER.siguiente[lang]} onClick={()=>{
          onNextStep()
        }} ></input></div>
      </div>
    )
  }
  const onChangeDay = (day) => {


    if(typeof(deportista.service_days) === 'string'){
      deportista.service_days = JSON.parse(deportista.service_days);
      setDeportista({...deportista});
    }
   

    if(day.active){
      deportista.service_days.push(day.value)
      return;
    }
    deportista.service_days = deportista.service_days.filter((item) => item !== day.value)

  }
  const fieldValidation = (field) => {
    
    if(!formValidation.validated){
      return 'd-none';
    }
    if(field === 'service_days'){
      if(deportista.service_days.length === 0){
        return 'd-block'
      }
    }
    if(field === 'password_confirmed'){
      if(state.password_confirmed !== '' && registro.password !== state.password_confirmed){
        return 'd-block'
      }else{
        return 'd-none'
      }
    }

    if(registro[field] === '' || deportista[field] === ''){
      return 'd-block'
    }

    return'd-none'
  }

  return (
    <>
    <HeaderDashboard></HeaderDashboard>

      <div className="mb__center_content d-flex flex-column h-100 ">


        <div className='container d-flex py-0 px-1'>
   

          <Renderif isTrue={state.rol_active === 'Nutricionista'}>
            {nutricionista_steps.map((item, i)=> {
              return (
                <div key={i} className={`col mx-2 pt-3 ${state.step >= i ? 'registro_step--active' : 'registro_step'}`}>
                    <div className='f-Poppins-Bold text-mint-shade f-12 line-1'>{LANG.REGISTER.STEP[lang]} {i + 1}</div>
                    <div className='f-Poppins-Medium text-white f-14'>{LANG.NOT[item][lang]}</div>
                </div>
              )
            })}
          </Renderif>

        </div>


        <div className='col mb__center_content py-3 overflow-auto'>

          <div className='container d-flex align-items-center p-0 px-3 px-md-0 my-3'>
            <IoIosArrowBack color={colors.mint_shade} size={16}></IoIosArrowBack>
            <div className=' text-mint-shade f-Poppins-Medium f-14 cursor-pointer mx-2 non-select' onClick={()=>{onPrevStep()}}>{LANG.REGISTER.BACK[lang]}</div>
          </div>


          <Renderif isTrue={state.steps.at(-1) === 'Datos'}>
          <FormValidator onValidate={(validated)=>{
              formValidation.avaibale = validated
            }}>
            <div className='container p-0 px-3 px-md-0'>
              <div className='text-white display-6 f-anton cursor-pointer text-uppercase'>

                

                {state.rol_active.toUpperCase() === "DEPORTISTA" ? LANG.REGISTER.Registro_deportista[lang] : ''}
                {state.rol_active.toUpperCase() === "NUTRICIONISTA" ? LANG.REGISTER.Registro_nutricionista[lang] : ''}
                {state.rol_active.toUpperCase() === "COCINA" ? LANG.REGISTER.Registro_cocina[lang] : ''}

               
              
              </div>
            </div>
            <div className='container bg-secundary-dark-blue rounded p-3 mt-4'>

              <div className='d-flex flex-column'>

                <div className='d-flex flex-column flex-md-row p-1 mt-3'>
                  <div className='col p-2'>
                    <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.Nombre[lang]} (s)</div>
                    <input required type='text' className='input' value={registro.firstname} onChange={(value)=>{setRegistro({...registro, firstname:value.currentTarget.value})}}></input>
                    <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('firstname')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                  </div>

                

                  <div className='col p-2'>
                    <div className="text-white f-Poppins-Medium f-12 my-1">Correo electrónico</div>
                    <input required type='email' className='input'value={registro.email} onChange={(value)=>{setRegistro({...registro, email:value.currentTarget.value})}}></input>
                    <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('email')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                  </div>
                  <div className='col p-2'>
                    <div className="text-white f-Poppins-Medium f-12 my-1">Teléfono</div>
                    <input type='number' className='input p-2' value={registro.phone} onChange={(value)=>{setRegistro({...registro, phone:value.currentTarget.value})}}></input>
                  </div>
                </div>
                <div className='d-flex flex-column flex-md-row p-1 mt-3'> 
                  <div className='col p-2'>
                      <div className="text-white f-Poppins-Medium f-12 my-1">Pais</div>
                      <div className='input d-flex'>
                        <select required className='w-100' value={registro.country} onChange={(value)=>{setRegistro({...registro, country:value.currentTarget.value})}}>
                          <option value=''>Selecionar</option>
                          <option value='Spain'>España</option>
                
                        </select>
                        <div className='px-2 border-l-mint d-flex align-items-center'><IoIosArrowDown color={colors.mint}></IoIosArrowDown></div>
                      </div>
                      <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('country')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                      
                    </div>
                    <div className='col p-2'>
                      <div className="text-white f-Poppins-Medium f-12 my-1">Ciudad</div>
                      <input required type='text' className='input' value={registro.city} onChange={(value)=>{setRegistro({...registro, city:value.currentTarget.value})}}></input>
                      <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('city')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                    </div>
                    <div className='col p-2'>
                      <div className="text-white f-Poppins-Medium f-12 my-1">Estado / Provincia</div>
                      <input type='text' className='input' value={registro.state} onChange={(value)=>{setRegistro({...registro, state:value.currentTarget.value})}}></input>
                    </div>
                    <div className='col p-2'>
                      <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.codigo_postal[lang]}</div>
                      <input type='text' className='input' value={registro.cp} onChange={(value)=>{setRegistro({...registro, cp:value.currentTarget.value})}}></input>
                    </div>
                
                </div>

                
                <RenderNextStep></RenderNextStep>
              </div>
            </div>
          </FormValidator>
          </Renderif>

       
          
         
        
        
        </div>

      </div>
        
  
    </>

  )
}





const FormValidator =({children, onValidate}) => {

  const [validated, setValidated] = useState(false)
  let fields = []
  const getFileds = (el)=>{
    if(el.type === 'input' || el.type === 'select'){
      fields.push(el)
    }
    if(el.type === 'div'){
      if(typeof(el.props.children) === 'object' && el.props.children.length){
        el.props.children.map((el)=>{
          getFileds(el)
        })
      }
      return;
    }
    return;
  }

  useEffect(()=>{
    children.map((el)=>{
      getFileds(el.props.children)
    })
    let _fields = fields.filter((el) => el.props.required === true && el.props.value === '');

    if(_fields.length === 0){
      onValidate(true)
      return;
    }
    onValidate(false)
  })

  return(
    <>
      {children}
    </>
  )
}