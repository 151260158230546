import React, { useContext } from 'react'
import { BiDish } from 'react-icons/bi'
import PlanContext from '../context/planContext'
import { colors } from '../utilities/colors'
import Renderif from './Renderif'

export default function RecipeThumbnail({recipe}) {
    const {getRecipeByID} = useContext(PlanContext)
  return (
    <>
        <Renderif isTrue={getRecipeByID(recipe)?.imageSmall === ''}>
            <div className="thumb-small d-flex justify-content-center mx-2">
                <BiDish size={28} color={colors.mint}></BiDish>
            </div>
        </Renderif>
        <Renderif isTrue={getRecipeByID(recipe)?.imageSmall !== ''}>
            <div className="thumb-small rounded-circle mx-2" style={{
                backgroundImage:`url("https://app.tspoonlab.com/recipes/api${getRecipeByID(recipe)?.imageNormal}?rememberme=dGVjQGdvb3NlYW5kaG9wcGVyLmNvbToxNzI5MDIxNjEwNDEzOjo6MzRiOTEyMWI0ZmZlOGVlMTExYzI3MzAzODEwNGZhYjU")`
            }}></div>
        </Renderif>
    </>

  )
}
