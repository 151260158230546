/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import "../../assets/css/dashboard.css";
import {useNavigate, useParams } from "react-router-dom";

import HeaderDashboard from "../../components/HeaderDashboard";


import AuthContext from "../../context/authContext";
import { Service } from "../../services/api";
import { colors } from "../../utilities/colors";

import { FaShippingFast } from "react-icons/fa";
import { BsCalendar2 } from "react-icons/bs";
import BackButton from "../../components/buttons/BackButton";
import moment from "moment";
import CocinaContext from "../../context/cocinaContext";
import { BiUser } from "react-icons/bi";
import { LANG } from "../commun/lang";



function EnviosDetallCocina() {

	const navigate = useNavigate();

	const { user, lang } = useContext(AuthContext);
    const { athletes, weekPlans } = useContext(CocinaContext);
    const {id_plan} = useParams('id_plan');

	const [state, setState] = useState({
		weekPlan: null
	})

	useEffect(() => {
		const data = async () => {
	
		
			setState({
				...state,
				weekPlan: weekPlans.filter((weekPlan)=>weekPlan.id === parseInt(id_plan))[0]
			});
		};
		data();
		return () => {};
	}, []);

    const RenderStatus = ({status}) => {
        
		switch (status) {
			case 0:
				return <div className="f-12 text-center rounded-2 px-2 py-1 bg-blue-shade-overlay f-Poppins-Medium text-white line-1">Por enviar</div>
			case 1:
				return <div className="f-12 text-center rounded-2 px-2 py-1 bg-mint f-Poppins-Medium text-dark line-1">Enviado</div>
		}

	}

	const parseDay = (day) => {
		if(day === 'Miercoles') {
			return 'Miércoles'
		}
		if(day === 'Sabado') {
			return 'Sábado'
		}
		return day
	}


	return (
		<>
			<HeaderDashboard />

			

			<div className="container-fluid p-3">

				<BackButton route="/plan-detalle" />

				<div className="text-white f-30 f-anton text-uppercase ps-1">
				{LANG.NOT.envios[lang]} - {(state?.weekPlan?.kitchen)?.toUpperCase()}
				</div>

				<div className="d-flex align-items-center ps-1">
					<BsCalendar2 color={colors.yellow}/>
					<div className="f-14 text-yellow f-Poppins-Bold px-2">{`${moment(state?.weekPlan?.start_date).format('D MMM')} - ${moment(state?.weekPlan?.end_date).format('D MMM')}`}</div>
					<span className="bg-green-shade text-mint-shade f-12 f-Poppins-Medium rounded-5 px-2 mx-1">
						{state?.weekPlan?.total_athletes} {LANG.DASHBOARD.ATHLETES[lang]}
					</span>
				</div>


			</div>

			<div className="container-fluid overflow-auto hidden-scroll mt-4">
					<table className="ant-table w-100 bg-dark-blue rounded-2 overflow-hidden">
						<thead className="ant-table-thead">
							<tr>
								<th className="col"><div className="text-left f-anton f-14">{LANG.DASHBOARD.ATHLETE[lang]}</div></th>
								<th className="col"><div className="text-center f-anton f-14">{LANG.PLANDETAIL.invitados[lang]}</div></th>
								<th className="col"><div className="text-left f-anton f-14">{LANG.PLANDETAIL.servicio[lang]}</div></th>
								{/* <th className="col"><div className="text-left f-anton f-14">DÍAS DE SERIVCIO</div></th> */}
								<th className="col"><div className="text-left f-anton f-14">DÍA DE SERVICIO</div></th>
                                <th className="col"><div className="text-left f-anton f-14">HORA</div></th>
                                <th className="col"><div className="text-left f-anton f-14">DIRECCIÓN</div></th>
                                {/* <th className="col"><div className="text-center f-anton f-14">STATUS</div></th>
                                <th className="col"><div className="text-center f-anton f-14">ENVIAR</div></th> */}
								
							</tr>
						</thead>
						<tbody className="ant-table-tbody">
                            {athletes.map((athlete,i)=>{

                                let _service = JSON.parse(athlete.service_days)
                                
                                return _service.map((day,i)=>{
                                    return (
                                        <tr key={i}>
                                            <td>
                                                <div className="d-flex align-items-start">
                                                    <BiUser className="pt-1" color={colors.mint} size={18}></BiUser>
                                                    <div className="ps-1">
                                                        <div className="text-left text-white f-14 f-Poppins-Medium">{`${athlete?.firstname}`}</div>
                                                        <div className="text-left f-12 f-Poppins-Bold text-mint-shade">{athlete?.fedding}</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td><div className="text-white text-center f-Poppins-Medium">{athlete.invitados}</div></td>
                                            <td><div className="text-white text-left f-Poppins-Medium">{LANG.NOT[athlete?.service][lang]}</div></td>
                                            {/* <td><div className="text-white text-left f-Poppins-Medium">{_service[0] + " a " + _service[_service.length-1]}</div></td> */}
                                            <td><div className="text-white text-left f-Poppins-Medium">{parseDay(_service[i])}</div></td>
                                            <td><div className="text-white text-left f-Poppins-Medium">{athlete.delevery_time}</div></td>
                                            <td><div className="text-white text-left f-Poppins-Medium">{athlete.delevery_address}</div></td>
                                            {/* <td>
                                                <RenderStatus status={0}></RenderStatus>
                                            </td> */}
                                            {/* <td>
                                                <div className="d-flex justify-content-center">
													<div className="cta cta-outline" onClick={(e)=>{
														e.stopPropagation();
														// navigate(`/envios/${item.id}`)
													}}>
														<div className="icon">
															<FaShippingFast color={colors.white} size={16}></FaShippingFast>
														</div>
														<div className="text-white f-12 f-anton line-1">Enviar</div>
													</div>

												</div>
                                            </td> */}
                                            
                                        </tr>
                                    )

                                })
                                
                            })}
						</tbody>
					</table>
			</div>
		</>
	);
}

export default EnviosDetallCocina;
