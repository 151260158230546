import React, { useContext } from 'react'
import AnimatedNumber from "animated-number-react";
import Renderif from './Renderif';
import { LANG } from '../pages/commun/lang';
import AuthContext from '../context/authContext';
export default function DashboardWidget({data}) {
    const {lang} = useContext(AuthContext);


const format = (value) => value.toFixed(0);


  return (

    <div className='bg-dark-blue rounded-2'>
        <div className='d-flex p-3'>
            <div className='bg-yellow rounded-2 d-flex justify-content-center align-items-center'>
                <img className='p-1' width={56} src={data.img} alt="img"/>
            </div>

            <div className='px-2'>
                <div className='text-white f-14 f-Poppins-Bold'>{data.title}</div>
                <Renderif isTrue={data.subtitle !== undefined}>
                    <div className='text-white f-14 f-Poppins-Regular'>{data.subtitle}</div>
                </Renderif>

        
                <div className='text-mint f-16 f-Poppins-Bold'>
                    <Renderif isTrue={data.value !== undefined}>
                        <AnimatedNumber
                            value={data.value}
                            formatValue={format}
                        />
                    </Renderif>
                   
                </div>
            </div>
        </div>

        <div className='border-b-blue-shade'></div>
        <div className="px-3 py-2">
            <div className={`text-mint-shade f-Poppins-Medium ${!data.action? 'opacity-0':'cta-text'}`} onClick={()=>{
                if(data.action){
                    data.action();
                }
            }}>
                {LANG.DASHBOARD.VIEWALL[lang]}
            </div>
        </div>
    </div>
  )
}


