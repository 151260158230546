import React, { Children, useContext, useEffect, useState } from 'react'
import "../../assets/css/registro.css";
import { BsCircle, BsRecordCircleFill } from 'react-icons/bs';
import {IoIosArrowBack,IoIosArrowDown} from 'react-icons/io'
import {FaStripe,FaCcPaypal} from 'react-icons/fa'
import Renderif from '../../components/Renderif';
import { colors } from '../../utilities/colors';
import { Select,Option } from 'antd';
import CheckBox from '../../components/buttons/checkbox';
import { Service } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { filter } from 'domutils';
import AuthContext from '../../context/authContext';
import HeaderDashboard from '../../components/HeaderDashboard';
import AlertContext from '../../context/alertContext';
import { LANG } from '../commun/lang';





export default function AltaDeportista() {

  const navigate = useNavigate();
  const {showAlert} = useContext(AlertContext)
  const {lang} = useContext(AuthContext)

//   let cocina_steps = [
//     'Tipo de registro',
//     'Datos',
//     'Terminos y condiciones',
//   ]
//   let nutricionista_steps = [
//     'Tipo de registro',
//     'Datos',
//     'Terminos y condiciones',
//   ]
  let deportista_steps = [
    // 'Tipo de registro',
    'Datos generales',
    //'Terminos y condiciones',
    'Datos del deportista',
    //'Servicio',
    //'Pago',
  ]

  const {user, setUser} = useContext(AuthContext)
  console.log(user)
  const [formValidation, setFormValidation] = useState({
    validated:false,
  })
  const [state, setState] = useState({
    step:0,
    steps: ['Datos generales'],
    rol_active:'Deportista',
    tyco_active:true,
    newsletter_active:false,
    plan_active:'PRO',
    payment:'PayPal',
    password_confirmed:'',

  })

  //DATOS DE REGISTRO EN COMUN
  const [registro, setRegistro] = useState({
    firstname : '',
    lastname : '',
    gender : '',
    country : 'Spain',
    state : '',
    city : '',
    cp : '',
    email : '',
    phone : '',
    password : '',
    picture : '',
  }) 

  //DATOS DE REGISTRO COMO DEPORTISTA
  const [deportista, setDeportista] = useState({
    fedding :'',
    id_suscription :1,
    birthday :'',
    club :'',
    allergy :'',
    service :'Comida y Cena',
    service_days :[],
    delevery_address :'',
    delevery_occur :'',
    delevery_time :'',
    invitados :'0',
    objetivo:'',
    peso:'',
    estatura:'',
    imc:'',
    payment_method :'',
    id_nutricionist_owner: user.id_rol === 1 ? null : user.id_user,
    id_kitchen:4
  }) 
  

  const onRegister = async() => {
    if(typeof(deportista.service_days) === 'object'){
      deportista.service_days = JSON.stringify(deportista.service_days)
    }

    let _registro = await Service('POST', 'user/register', registro);
    console.log(_registro)
    let _athlete = await Service('POST', 'athlete', {...deportista, id_user:_registro.data.response.id_user});
    console.log(_athlete)

    navigate(-1);
    showAlert({type:'success', message:LANG.NOT.nuevo_deportista_creado[lang], description:LANG.NOT.registro[lang]})


  }
  const onNextStep = () => {
    
    

    setFormValidation({...formValidation, validated:true})



      if(!formValidation.avaibale){

        return;
      }

    if(state.step === 1){
        console.log("REGISTRAR")
        registro.id_rol = 4;
        onRegister();
        return;
    }
  

    state.steps.push(deportista_steps[state.step + 1]);
    state.step = state.step + 1;
    setState({...state})
    setFormValidation({validated:false, avaibale:false})

    // registro.id_rol = 4;
    // onRegister();

    // if(state.step === 3){
    //   if(deportista.service_days.length === 0){
    //     return;
    //   }
    // }
    // if(state.step === 4){
    //     registro.id_rol = 4;
    //     onRegister();
    //     return;
    // }
    // state.steps.push(deportista_steps[state.step + 1]);
    // state.step = state.step + 1;
    // setState({...state})
    // setFormValidation({validated:false, avaibale:false})



  }
  const onPrevStep = () => {
  
    if(state.step === 0 ){
        navigate(-1)
      return;
    }
  
    if(state.step !== 0){
        state.steps.pop()
        state.step = state.step - 1;
        setState({...state})
    }
  }
  const RenderNextStep = () => {
    return (
      <div className='d-flex justify-content-end py-3 px-3 '>
        <div><input type="submit" className='input px-4' value={LANG.REGISTER.siguiente[lang]} onClick={()=>{
          onNextStep()
        }} ></input></div>
      </div>
    )
  }
  const onChangeDay = (day) => {


    if(typeof(deportista.service_days) === 'string'){
      deportista.service_days = JSON.parse(deportista.service_days);
      setDeportista({...deportista});
    }
   

    if(day.active){
      deportista.service_days.push(day.value)
      return;
    }
    deportista.service_days = deportista.service_days.filter((item) => item !== day.value)

  }
  const fieldValidation = (field) => {
    
    if(!formValidation.validated){
      return 'd-none';
    }
    if(field === 'service_days'){
      if(deportista.service_days.length === 0){
        return 'd-block'
      }
    }
    if(field === 'password_confirmed'){
      if(state.password_confirmed !== '' && registro.password !== state.password_confirmed){
        return 'd-block'
      }else{
        return 'd-none'
      }
    }

    if(registro[field] === '' || deportista[field] === ''){
      return 'd-block'
    }

    return'd-none'
  }

  return (
    <>
    <HeaderDashboard></HeaderDashboard>


    <div className="mb__center_content d-flex flex-column h-100 ">
        
        <div className='container d-flex py-0 px-1'>

            <Renderif isTrue={state.rol_active === 'Deportista'}>
            {deportista_steps.map((item, i)=> {

                if(i !== 2 && i !== 4){
                
                return (
                    // <div>{i === 3 ? 3 : i === 5 ? 4 : i + 1}</div>
                    <div key={i}  className={`col mx-2 pt-3 ${state.step >= i ? 'registro_step--active' : 'registro_step'}`}>
                        <div className='f-Poppins-Bold text-mint-shade f-12 line-1'>{LANG.REGISTER.STEP[lang]} {i === 3 ? 3 : i === 5 ? 4 : i + 1}</div>
                        <div className='f-Poppins-Medium text-white f-12'>{LANG.NOT[item][lang]}</div>
                    </div>
                )
        
                }
                return null
                
            })}
            </Renderif>
        </div>


        <div className='col mb__center_content py-3 overflow-auto'>

            <div className='container d-flex align-items-center p-0 px-3 px-md-0 py-2'>
                <IoIosArrowBack color={colors.mint_shade} size={16}></IoIosArrowBack>
                <div className=' text-mint-shade f-Poppins-Medium f-14 cursor-pointer mx-2 non-select' onClick={()=>{onPrevStep()}}>{LANG.REGISTER.BACK[lang]}</div>
            </div>
            <div className='container p-0 px-3 px-md-0'>
                <div className='text-white display-6 f-anton cursor-pointer text-uppercase'>{LANG.REGISTER.Registro_deportista[lang]}</div>
            </div>

            <Renderif isTrue={state.steps.at(-1) === 'Datos generales'}>
            
                <FormValidator onValidate={(validated)=>{
                    formValidation.avaibale = validated
                }}>
                
                <div className='container bg-secundary-dark-blue rounded p-3 mt-2'>

                    <div className='d-flex flex-column'>

                    <div className='d-flex flex-column flex-md-row p-1 mt-3'>
                        <div className='col p-2'>
                        <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.Nombre[lang]} (s)</div>
                        
                            <input required type='text' className='input'value={registro.firstname} onChange={(value)=>{setRegistro({...registro, firstname:value.currentTarget.value})}}></input>
                            <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('firstname')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                    
                        </div>

                        <div className='col p-2'>
                        <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.Apellido[lang]} (s)</div>
                        <input required type='text' className='input' value={registro.lastname} onChange={(value)=>{setRegistro({...registro, lastname:value.currentTarget.value})}}></input>
                        <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('lastname')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                        </div>

                        <div className='col p-2'>
                        <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.Club_al_que_pertenece[lang]}</div>
                        <input required type='text' className='input' value={deportista.club} onChange={(value)=>{setDeportista({...deportista, club:value.currentTarget.value})}}></input>
                        <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('club')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                        </div>
                    </div>

                    <div className='d-flex flex-column flex-md-row p-1 mt-3'> 
                        <div className='col p-2'>
                        <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.Fecha_de_nacimiento[lang]}</div>
                        <input required type='date' className='input p-2' value={deportista.birthday} onChange={(value)=>{setDeportista({...deportista, birthday:value.currentTarget.value})}}></input>
                        <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('birthday')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                        
                        </div>
                        <div className='col p-2'>
                        <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.Gender[lang]}</div>

                        <div className='input d-flex'>
                            <select required className='w-100' value={registro.gender} onChange={(value)=>{setRegistro({...registro, gender:value.currentTarget.value})}}>
                            <option value=''>{LANG.REGISTER.seleccionar[lang]}</option>
                            <option value='Femenino'>{LANG.REGISTER.Femenino[lang]}</option>
                            <option value='Masculino'>{LANG.REGISTER.Masculino[lang]}</option>
                            <option value='Otro'>{LANG.REGISTER.Otro[lang]}</option>
                            </select>
                            <div className='px-2 border-l-mint d-flex align-items-center'><IoIosArrowDown color={colors.mint}></IoIosArrowDown></div>
                        </div>
                        <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('gender')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>

                        </div>
                        <div className='col p-2'>
                        <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.Correo_electronico[lang]}</div>
                        <input required type='email' className='input' value={registro.email} onChange={(value)=>{setRegistro({...registro, email:value.currentTarget.value})}}></input>
                        <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('email')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                        </div>
                        <div className='col p-2'>
                        <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.Telefono[lang]}</div>
                        <input type='number' className='input p-2' value={registro.phone} onChange={(value)=>{setRegistro({...registro, phone:value.currentTarget.value})}}></input>
                        </div>
                    </div>

                    <div className='d-flex flex-column flex-md-row p-1 mt-3'> 
                        <div className='col p-2'>
                        <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.País[lang]}</div>
                        <div className='input d-flex'>
                            <select required className='w-100' value={registro.country} onChange={(value)=>{setRegistro({...registro, country:value.currentTarget.value})}}>
                            <option value=''>{LANG.REGISTER.seleccionar[lang]}</option>
                            <option value='Spain'>España</option>
                        
                            </select>
                            <div className='px-2 border-l-mint d-flex align-items-center'><IoIosArrowDown color={colors.mint}></IoIosArrowDown></div>
                        </div>
                        <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('country')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                        </div>
                        <div className='col p-2'>
                        <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.Ciudad[lang]}</div>
                        <input required type='text' className='input' value={registro.city} onChange={(value)=>{setRegistro({...registro, city:value.currentTarget.value})}}></input>
                        <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('city')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                        </div>
                        <div className='col p-2'>
                        <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.Estado[lang]} / {LANG.REGISTER.Provincia[lang]}</div>
                        <input type='text' className='input' value={registro.state} onChange={(value)=>{setRegistro({...registro, state:value.currentTarget.value})}}></input>
                        </div>
                        <div className='col p-2'>
                        <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.codigo_postal[lang]}</div>
                        <input type='text' className='input' value={registro.cp} onChange={(value)=>{setRegistro({...registro, cp:value.currentTarget.value})}}></input>
                        </div>
                    </div>

                    <RenderNextStep></RenderNextStep>
                    </div>
                </div>

                </FormValidator>
            </Renderif>

            <Renderif isTrue={state.steps.at(-1) === 'Datos del deportista'}>
                <FormValidator onValidate={(validated)=>{
                    formValidation.avaibale = validated
                }}>

            
                    
                    <div className='container bg-secundary-dark-blue rounded p-3 mt-2'>

                    <div className='d-flex flex-column'>

                        <div className='d-flex'>
                            <div className='col-12 col-md-6 d-flex flex-column p-1 mt-3'> 

                            <div className='col p-2'>
                                <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.filosofia_alimentaria[lang]}</div>
                                <div className='input d-flex'>
                                <select required className='w-100' value={deportista.fedding} onChange={(value)=>{setDeportista({...deportista, fedding:value.currentTarget.value})}}>
                                    <option value=''>{LANG.REGISTER.seleccionar[lang]}</option>
                                    <option value='Omnívoro'>Omnívoro</option>
                                    <option value='Vegetariano'>Vegetariano</option>
                                    <option value='Vegano'>Vegano</option>
                                    
                                </select>
                                <div className='px-2 border-l-mint d-flex align-items-center'><IoIosArrowDown color={colors.mint}></IoIosArrowDown></div>
                                </div>
                                <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('fedding')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                            </div>
                            

                            <div className='col p-2'>
                                {/* <div className="text-white f-Poppins-Medium f-12 my-1">¿Algún tipo de intolerancia o alergia?</div> */}
                                <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.intolerancia_alergia[lang]}</div>
                                <input type="text" className='input' value={deportista.allergy} onChange={(value)=>{setDeportista({...deportista, allergy:value.currentTarget.value})}}></input>
                            </div>

                            <div className='col p-2'>
                                <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.preferencias_alimentos[lang]}</div>
                                <input type="text" className='input' value={deportista.delevery_occur} onChange={(value)=>{setDeportista({...deportista, delevery_occur:value.currentTarget.value})}}></input>
                            </div>
                            <div className='col p-2'>
                                <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.objetivo[lang]}</div>
                                <div className='input d-flex'>
                                <select className='w-100' value={deportista.objetivo} onChange={(value)=>{setDeportista({...deportista, objetivo:value.currentTarget.value})}}>
                                    <option value=''>{LANG.REGISTER.seleccionar[lang]}</option>
                                    <option value='Aumento muscular'>{LANG.REGISTER.masa_muscular[lang]}</option>
                                    <option value='Menos peso'>{LANG.REGISTER.menos_peso[lang]}</option>
                                    <option value='Definicion'>{LANG.REGISTER.definicion[lang]}</option>
                                    
                                </select>
                                <div className='px-2 border-l-mint d-flex align-items-center'><IoIosArrowDown color={colors.mint}></IoIosArrowDown></div>
                                </div>
                                {/* <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('fedding')}`}>Este campo es obligatorio</div> */}
                            </div>
                            </div>

                            <div className='col-12 col-md-6 d-flex flex-column p-1 mt-3'> 
                            <div className='p-2'>
                                <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.peso[lang]}</div>
                                <input placeholder='kg.' type="number" className='input' value={deportista.peso} onChange={(value)=>{setDeportista({...deportista, peso:value.currentTarget.value})}}></input>
                            </div>

                            <div className='p-2'>
                                <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.estatura[lang]}</div>
                                <input placeholder='cm.' type="number" className='input' value={deportista.estatura} onChange={(value)=>{setDeportista({...deportista, estatura:value.currentTarget.value})}}></input>
                            </div>

                            <div className='col p-2'>
                                <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.imc[lang]}</div>
                                <input type="text" className='input' value={deportista.imc} onChange={(value)=>{setDeportista({...deportista, imc:value.currentTarget.value})}}></input>
                            </div>
                            </div>
                        </div>
                        

                        <RenderNextStep></RenderNextStep>

                    </div>
                    </div>
                </FormValidator>
            
            </Renderif>

            <Renderif isTrue={state.steps.at(-1) === 'Servicio'}>
                <FormValidator onValidate={(validated)=>{
                    formValidation.avaibale = validated
                }}>

            
                <div className='container bg-secundary-dark-blue rounded p-3 mt-2'>

                    <div className='d-flex flex-column'>


                    <div className='col-12 col-md-6 d-flex flex-column p-1 mt-3'> 

                        <div className='col p-2'>
                        <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.tipo_servicio[lang]}</div>
                        <div className='input d-flex'>
                            <select required className='w-100' value={deportista.service} onChange={(value)=>{setDeportista({...deportista, service:value.currentTarget.value})}}>
                            <option value=''>{LANG.REGISTER.seleccionar[lang]}</option>
                            <option value='Comida'>{LANG.REGISTER.comidas[lang]}</option>
                            <option value='Cena'>{LANG.REGISTER.cenas[lang]}</option>
                            <option value='Comida y Cena'>{LANG.REGISTER.comidas[lang]} y {LANG.REGISTER.cenas[lang]}</option>
                            </select>
                            <div className='px-2 border-l-mint d-flex align-items-center'><IoIosArrowDown color={colors.mint}></IoIosArrowDown></div>
                        </div>
                        <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('service')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                        </div>

                        <div className='col p-2'>
                        <div className="text-white f-Poppins-Medium f-12 my-1">¿Cuántos dias a la semana quiere el servicio?</div>
                        
                        
                        <div className='d-flex'>
                            <CheckBox text="Lunes" value="Lunes" active={deportista.service_days.includes('Lunes')} onChange={(value)=>{onChangeDay(value)}}></CheckBox>
                            <CheckBox text="Martes" value="Martes" active={deportista.service_days.includes('Martes')} onChange={(value)=>{onChangeDay(value)}}></CheckBox>
                            <CheckBox text="Miercoles" value="Miercoles" active={deportista.service_days.includes('Miercoles')} onChange={(value)=>{onChangeDay(value)}}></CheckBox>
                            <CheckBox text="Jueves" value="Jueves" active={deportista.service_days.includes('Jueves')} onChange={(value)=>{onChangeDay(value)}}></CheckBox>
                            <CheckBox text="Viernes" value="Viernes" active={deportista.service_days.includes('Viernes')} onChange={(value)=>{onChangeDay(value)}}></CheckBox>
                        </div>
                        <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('service_days')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                    

                        <div className='input d-none'>
                            <select className='w-100'>
                            <option>{LANG.REGISTER.seleccionar[lang]}</option>
                            </select>
                            <div className='px-2 d-flex border-l-mint align-items-center'><IoIosArrowDown color={colors.mint}></IoIosArrowDown></div>
                        </div>

                        </div>

                        <div className='col p-2'>
                        <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.lugar_entrega_direccion[lang]}</div>
                        {/* <div className="text-white f-Poppins-Medium f-12 my-1">Dirección</div> */}
                        <input required type="text" className='input' value={deportista.delevery_address} onChange={(value)=>{setDeportista({...deportista, delevery_address:value.currentTarget.value})}}></input>
                        <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('delevery_address')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                        </div>

                        <div className='col p-2'>
                        <div className="text-white f-Poppins-Medium f-12 my-1">¿Cuántas veces a la semana queires que te enviemos?</div>
                        <div className='input d-flex'>
                            <select required className='w-100' value={deportista.delevery_occur} onChange={(value)=>{setDeportista({...deportista, delevery_occur:value.currentTarget.value})}}>
                            <option value=''>{LANG.REGISTER.seleccionar[lang]}</option>
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                            <option value='4'>4</option>
                            <option value='5'>5</option>
                            </select>
                            <div className='px-2 border-l-mint d-flex align-items-center'><IoIosArrowDown color={colors.mint}></IoIosArrowDown></div>
                        </div>
                        <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('delevery_occur')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                        
                        </div>

                        <div className='col p-2'>
                        <div className="text-white f-Poppins-Medium f-12 my-1">{LANG.REGISTER.hora_entrega[lang]}</div>
                        <input required type="time" className='input' value={deportista.delevery_time} onChange={(value)=>{setDeportista({...deportista, delevery_time:value.currentTarget.value})}}></input>
                        <div className={`f-12 f-Poppins-Regular text-red ${fieldValidation('delevery_time')}`}>{LANG.REGISTER.campo_obligatorio[lang]}</div>
                        </div>

                    </div>

                    <RenderNextStep></RenderNextStep>

                    </div>
                </div>

                </FormValidator>
            
            </Renderif>
                      
        
        </div>

    </div>
    </>
 
  )
}


const FormValidator =({children, onValidate}) => {

  const [validated, setValidated] = useState(false)
  let fields = []
  const getFileds = (el)=>{
    if(el.type === 'input' || el.type === 'select'){
      fields.push(el)
    }
    if(el.type === 'div'){
      if(typeof(el.props.children) === 'object' && el.props.children.length){
        el.props.children.map((el)=>{
          getFileds(el)
        })
      }
      return;
    }
    return;
  }

  useEffect(()=>{
    // children.map((el)=>{
    //   getFileds(el.props.children)
    // })
    
    getFileds(children.props.children)
    let _fields = fields.filter((el) => el.props.required === true && el.props.value === '');

    if(_fields.length === 0){
      onValidate(true)
      return;
    }
    onValidate(false)
  })

  return(
    <>
      {children}
    </>
  )
}