export const comida_primero_category = [
    "36280200569388246557278968113952748058", // pasta
    "295263533200019378657296659582772362919", // arroz
    "35667829400232569713759122614087586096", // couscous
    "204420419942376643966036331735655458520", // maiz
    // "151572764758055490694497255799615273757", // quinoa
]
export const comida_primero_category_conditional= [
    "214695799393981194261867148690118201572", // Legunbre
    "214695799393981194261867148690118201572", // Legunbre
    "91080678129201745842489365402820171953", // gnocchi
    "233815774440526534069040229522868621151", // polenta
]
export const comida_segundo_category= [
    "57735645613441776822909814216729423590",  // Carne blanca
    // "113979779994435279324732251948823450598", // Huevo
    "263370225479645588975161988878081623386", // Jamon
    "127174368198577063503503621586516358504", // Tofu
]
export const cena_primero_category = [
    "151572764758055490694497255799615273757", //quinoa
    "36280200569388246557278968113952748058", //pasta
    "295263533200019378657296659582772362919", //arroz
    
    "8839884265154396521215607001892326206", //tuberculo
]
export const cena_segundo_category= [
    "57735645613441776822909814216729423590",  // Carne blanca
    "113979779994435279324732251948823450598", // Huevo
    "263370225479645588975161988878081623386", // Jamon
    "127174368198577063503503621586516358504", // Tofu
]


export const otros_category = {
    verduras:"93416189990149969709574115339452627690",
}


export const ternera_category = "198157739851462503546074835874678663515"

export const category_otros = {
    verduras:"93416189990149969709574115339452627690",
}

export const category_hidrato = {
    pasta:"36280200569388246557278968113952748058",
    arroz:"295263533200019378657296659582772362919",
    bulgur:"70414692519642883889184800062456659503",
    couscous:"35667829400232569713759122614087586096",
    gnocchi:"91080678129201745842489365402820171953",
    legumbre:"214695799393981194261867148690118201572",
    maiz:"204420419942376643966036331735655458520",
    polenta:"233815774440526534069040229522868621151",
    quinoa:"151572764758055490694497255799615273757",
    tuberculo:"8839884265154396521215607001892326206",
}
export const category_hidrato_pastas = [
    "120245420807111775682037155741216910377",
    "36280200569388246557278968113952748058",
    "160338645023299453905442756719121070567",
    "233452696126807320040923405229360726475"
]

export const category_proteina = {
    carne_blanca:"57735645613441776822909814216729423590",
    cerdo:"41618831823209964004894650420368465466",
    huevo:'113979779994435279324732251948823450598',
    jamon:"263370225479645588975161988878081623386",
    pescado_azul:"30710831359732514053737909670129643576",
    pescado_blanco:"191308411057966662533770781663305932997",
    tofu:'127174368198577063503503621586516358504',
    ternera: '198157739851462503546074835874678663515',
    vegetal:'29327070840126955734775725599303421944'
}

export const category_archive = {
    carne_roja:"33759572732523516398327312717485182444",
    bulgur:"70414692519642883889184800062456659503",
    legumbre:"214695799393981194261867148690118201572",
    polenta:"233815774440526534069040229522868621151",
    maiz:"204420419942376643966036331735655458520",
    tofu:'127174368198577063503503621586516358504',
}

 export const getCategoryByID = (id) => {
    
    let categories = {...category_hidrato,...category_proteina,...category_otros }
    let name = (Object.keys(categories).find(key => categories[key] === id))
    if(name === undefined){
        return false
    }

    name = (name?.charAt(0).toUpperCase() + name?.slice(1))?.replace('_',' ')
    return {name, id}
 }


