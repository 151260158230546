export const LANG = {
      "LOGIN": {
        "":{
          "ES":"",
          "EN":"",
          "IT":""
        },
        null:{
          "ES":"",
          "EN":"",
          "IT":""
        },
        undefined:{
          "ES":"",
          "EN":"",
          "IT":""
        },
        "credenciales_incorrectas":{
            "ES":"Credenciales incorrectas",
            "EN":"Wrong credentials",
            "IT":"Credenziali errate"
        },
        "verifica_credenciales":{
          "ES":"Revisa tu correo o contraseña",
          "EN":"Wrong Verify email and password",
          "IT":"Controllare l'e-mail o la password"
        },
        "SOLUTIONS_A":{
            "ES":"Soluciones para",
            "EN":"Solutions for",
            "IT":"La soluzione per"
        },
        "SOLUTIONS_B":{
          "ES":"deportistas",
          "EN":"athletes",
          "IT":"gli atleti"
      },
        "ACCOUNT":{
            "ES":"Ingresa a tu cuenta",
            "EN":"Login to your account",
            "IT":"Accedi al tuo account"
        },
        "EMAIL":{
            "ES":"Correo electrónico",
            "EN":"Email address",
            "IT":"E-mail"
        },
        "PASSWORD":{
            "ES":"Contraseña",
            "EN":"Password",
            "IT":"Password"
        },
        "FORGOT":{
            "ES":"¿Olvidaste tu contraseña?",
            "EN":"Forgot your password ?",
            "IT":"Hai dimenticato la password?"
        },
        "SIGNIN":{
            "ES":"Ingresa",
            "EN":"Sign in",
            "IT":"Entra"
        },
        "NOACCOUNT":{
            "ES":"No tienes una cuenta",
            "EN":"Don't have an account",
            "IT":"Non hai un account?"
        },
        "REGISTER":{
            "ES":"Registrate aquí",
            "EN":"Register here",
            "IT":"Registrati qui"
        },
        "TEXT":{
            "ES":"TEXT",
            "EN":"TEXT",
            "IT":"TEXT"
        },    
      },
      "DASHBOARD":{
        "":{
          "ES":"",
          "EN":"",
          "IT":""
        },
        null:{
          "ES":"",
          "EN":"",
          "IT":""
        },
        undefined:{
          "ES":"",
          "EN":"",
          "IT":""
        },
        "DASHBOARD":{
            "ES":"Dashboard",
            "EN":"Dashboard",
            "IT":"Dashboard"
        },
        "ENVIADOS":{
          "ES":"Enviados",
          "EN":"Sent",
          "IT":"Inviato"
        },
        "PREPARACION":{
          "ES":"En Preparación",
          "EN":"Cooking",
          "IT":"Cucinando"
        }, 
        "ATHLETES":{
            "ES":"Deportistas",
            "EN":"Athletes",
            "IT":"Atleti"
        },  
        "ATHLETE":{
            "ES":"Deportista",
            "EN":"Athlete",
            "IT":"Atleti"
        },  
        "KITCHENS":{
            "ES":"Cocinas",
            "EN":"Kitchens",
            "IT":"Cucine"
        },  
        "KITCHEN":{
            "ES":"Cocina",
            "EN":"Kitchen",
            "IT":"Cucine"
        },  
        "CHEFS":{
            "ES":"Chefs",
            "EN":"Chefs",
            "IT":"Chef"
        },  
        "NUTRICIONISTS":{
            "ES":"Nutricionistas",
            "EN":"Nutritionists",
            "IT":"Nutrizionisti"
        }, 
        "NUTRICIONIST":{
          "ES":"Nutricionista",
          "EN":"Nutritionist",
          "IT":"Nutrizionisti"
      },  
        "VIEWALL":{
            "ES":"Ver todos",
            "EN":"View All",
            "IT":"Vedi tutti"
        },
        "WEEK":{
            "ES":"Semana",
            "EN":"Week",
            "IT":"Settimana"
        },
        "STATUS":{
            "ES":"Status",
            "EN":"Status",
            "IT":"Status"
        },
        "VIEWATH":{
            "ES":"Ver deportistas",
            "EN":"View Athletes",
            "IT":"Vedi Atleti"
        },
        "REMOVEPLAN":{
            "ES":"Eliminar plan",
            "EN":"Remove plan",
            "IT":"Elimina Piano"
        },    
        "COMPLETE":{
            "ES":"Completo",
            "EN":"Complete",
            "IT":"Completo"
        },    
        "SENTKIT":{
            "ES":"Enviado a cocina",
            "EN":"Sent to kitchen",
            "IT":"Inviato in cucina"
        },    
        "PENDING":{
            "ES":"Pendiente",
            "EN":"Pending",
            "IT":"In attesa"
        },        
        "TEXT":{
            "ES":"TEXT",
            "EN":"TEXT",
            "IT":"TEXT"
        }, 
        "TEXT":{
            "ES":"TEXT",
            "EN":"TEXT",
            "IT":"TEXT"
        }, 
      },
      "WEEKPLAN":{
        "":{
          "ES":"",
          "EN":"",
          "IT":""
        },
        null:{
          "ES":"",
          "EN":"",
          "IT":""
        },
        undefined:{
          "ES":"",
          "EN":"",
          "IT":""
        },
        "WEEKPLAN":{
            "ES":"Plan semanal",
            "EN":"Weekly plan",
            "IT":"Piano Settimanale"
        },
        "ALLKIT":{
            "ES":"Todas las cocinas",
            "EN":"All Kitchen",
            "IT":"Tutte le cucine"
        },
        "SUFFLE":{
            "ES":"Shuffle",
            "EN":"Shuffle",
            "IT":"Shuffle"
        },
        "COMMENT":{
            "ES":"Comentarios",
            "EN":"Comment",
            "IT":"Commenta"
        },
        "COMMENTS":{
            "ES":"Comentar",
            "EN":"Comments",
            "IT":"Commenti"
        },
        "VIEWFULL":{
            "ES":"Ver plan completo",
            "EN":"View full plan",
            "IT":"Visualizza piano completo"
        },
        "CLICKSAVE":{
          "ES":"Una vez que todo esté correcto, haz clic en guardar",
          "EN":"Once everything is correct, click on save",
          "IT":"Quando é tutto pronto, fai clic su Salva"
      }, 
        "SELECTRECIPES":{
            "ES":"Selecciona recetas manualmente o utiliza el botón Shuffle para generar un plan aleatorio",
            "EN":"Select recipes manually or use the Shuffle button to generate a new random plan",
            "IT":"Seleziona le ricette manualmente o utilizza il pulsante di Shuffle per generare un piano casuale"
        }, 
        "MONDAY":{
            "ES":"Lunes",
            "EN":"Monday",
            "IT":"Lunedì"
        }, 
        "TUESDAY":{
            "ES":"Martes",
            "EN":"Tuesday",
            "IT":"Martedì"
        }, 
        "WEDNESDAY":{
            "ES":"Miércoles",
            "EN":"Wednesday",
            "IT":"Mercoledì"
        }, 
        "THURSDAY":{
            "ES":"Jueves",
            "EN":"Thursday",
            "IT":"Giovedì"
        }, 
        "FRIDAY":{
            "ES":"Viernes",
            "EN":"Friday",
            "IT":"Venerdì"
        }, 
        "SATURDAY":{
            "ES":"Sábado",
            "EN":"Saturday",
            "IT":"Sabato"
        }, 
         "MONDAY":{
            "ES":"Lunes",
            "EN":"Monday",
            "IT":"Lunedì"
        }, 
        "TUESDAY":{
            "ES":"Martes",
            "EN":"Tuesday",
            "IT":"Martedì"
        }, 
        "WEDNESDAY":{
            "ES":"Miércoles",
            "EN":"Wednesday",
            "IT":"Mercoledì"
        }, 
        "THURSDAY":{
            "ES":"Jueves",
            "EN":"Thursday",
            "IT":"Giovedì"
        }, 
        "FRIDAY":{
            "ES":"Viernes",
            "EN":"Friday",
            "IT":"Venerdì"
        }, 
        "SATURDAY":{
            "ES":"Sábado",
            "EN":"Saturday",
            "IT":"Sabato"
        }, 

        //
        "Lunes":{
            "ES":"Lunes",
            "EN":"Monday",
            "IT":"Lunedì"
        }, 
        "Martes":{
            "ES":"Martes",
            "EN":"Tuesday",
            "IT":"Martedì"
        }, 
        "Miercoles":{
            "ES":"Miércoles",
            "EN":"Wednesday",
            "IT":"Mercoledì"
        }, 
        "Jueves":{
            "ES":"Jueves",
            "EN":"Thursday",
            "IT":"Giovedì"
        }, 
        "Viernes":{
            "ES":"Viernes",
            "EN":"Friday",
            "IT":"Venerdì"
        }, 
        "Sabado":{
            "ES":"Sábado",
            "EN":"Saturday",
            "IT":"Sabato"
        }, 
        "Comida_Primero":{
            "ES":"Comida primero",
            "EN":"First meal",
            "IT":"Primo piatto"
        }, 
        "Comida_Segundo":{
            "ES":"Comida segundo",
            "EN":"Second meal",
            "IT":"Secondo piatto"
        }, 
        "Cena_Primero":{
            "ES":"Cena primero",
            "EN":"First dinner",
            "IT":"Prima cena"
        }, 
        "Cena_Segundo":{
            "ES":"Cena segundo",
            "EN":"Second dinner",
            "IT":"Seconda Cena"
        }, 
        "Extra_Comida":{
            "ES":"Extra comida",
            "EN":"Extra meal",
            "IT":"Pranzo extra"
        }, 
        "Extra_Cena":{
            "ES":"Extra cena",
            "EN":"Extra dinner",
            "IT":"Cena extra"
        }, 
        "MORERE":{
            "ES":"Más recetas",
            "EN":"More recipes",
            "IT":"Altre ricette"
        },
        "HIDR":{
            "ES":"Hidratos",
            "EN":"Carbohydrate",
            "IT":"Carboidrati"
        }, 
        "PROT":{
            "ES":"Proteínas",
            "EN":"Protein",
            "IT":"Proteine"
        },
        "OTHER":{
            "ES":"Otros",
            "EN":"Other",
            "IT":"Altro"
        },
        "SAVE":{
            "ES":"Guardar",
            "EN":"Save",
            "IT":"Salva"
        },
        "SAVEONES":{
            "ES":"Una vez que todo esté correcto, haz clic en guardar",
            "EN":"Once everything is correct, click on save",
            "IT":"Quando é tutto pronto, fai clic su Salva"
        },
        "LOGOUT":{
            "ES":"Cerrar sesión",
            "EN":"Logout",
            "IT":"Effettua il logout"
        },
        "SEND":{
            "ES":"Enviar",
            "EN":"Send",
            "IT":"Invio"
        },
        "TEXT":{
            "ES":"TEXT",
            "EN":"TEXT",
            "IT":"TEXT"
        },
       
      },
      "USERS":{
        "":{
          "ES":"",
          "EN":"",
          "IT":""
        },
        null:{
          "ES":"",
          "EN":"",
          "IT":""
        },
        undefined:{
          "ES":"",
          "EN":"",
          "IT":""
        },
        "USERS":{
            "ES":"Usuarios",
            "EN":"Users",
            "IT":"Utenti"
        },
        "SERVICE":{
            "ES":"Servicio",
            "EN":"Service",
            "IT":"Servizio"
        },
        "GUESTS":{
            "ES":"Invitados",
            "EN":"Guests",
            "IT":"Ospiti"
        },
        "SEARCH":{
            "ES":"Buscar usuario",
            "EN":"Search user",
            "IT":"Cerca utente"
        },
        "TEXT":{
            "ES":"TEXT",
            "EN":"TEXT",
            "IT":"TEXT"
        },
      },
      "PROFILE":{
        "":{
          "ES":"",
          "EN":"",
          "IT":""
        },
        null:{
          "ES":"",
          "EN":"",
          "IT":""
        },
        undefined:{
          "ES":"",
          "EN":"",
          "IT":""
        },
        "PROFILE":{
            "ES":"Perfil",
            "EN":"Profile",
            "IT":"Profilo"
        },
        "Administrador":{
            "ES":"Administrador",
            "EN":"Administrator",
            "IT":"Amministratore"
        },
        "Nutricionista":{
          "ES":"Nutricionistas",
          "EN":"Nutritionists",
          "IT":"Nutrizionisti"
        }, 
        "Deportista":{
          "ES":"Deportista",
          "EN":"Athlete",
          "IT":"Atleti"
      },
      "Cocina":{
        "ES":"Cocina",
        "EN":"Kitchen",
        "IT":"Cucine"
    },
        "FULLNAME":{
            "ES":"Nombre completo",
            "EN":"Full Name",
            "IT":"Nome Completo"
        },
        "PHONE":{
            "ES":"Teléfono",
            "EN":"Phone number",
            "IT":"Telefono"
        },
        "LOCATION":{
            "ES":"Locación",
            "EN":"Location",
            "IT":"Posizione"
        },
        "EDIT":{
            "ES":"Edtiar",
            "EN":"Edit",
            "IT":"Modificare"
        },
        "TEXT":{
            "ES":"TEXT",
            "EN":"TEXT",
            "IT":"TEXT"
        },
      },
      "REGISTER":{
        "":{
          "ES":"",
          "EN":"",
          "IT":""
        },
        null:{
          "ES":"",
          "EN":"",
          "IT":""
        },
        undefined:{
          "ES":"",
          "EN":"",
          "IT":""
        },
        "STEP":{
            "ES":"Paso",
            "EN":"Step",
            "IT":"Step"
        },
        "TEXT":{
            "ES":"TEXT",
            "EN":"TEXT",
            "IT":"TEXT"
        },
        "TYPE":{
            "ES":"Tipo de registro",
            "EN":"Type of registration",
            "IT":"Tipo di registro"
        },
        "GDATA":{
            "ES":"Datos generales",
            "EN":"General data",
            "IT":"Dati Generali"
        },
        "ADATA":{
            "ES":"Datos del deportista",
            "EN":"Athlete data",
            "IT":"Dati dell’atleta"
        },
        "PAYMENT":{
            "ES":"Pago",
            "EN":"Payment",
            "IT":"Pagamento"
        },
        "BACK":{
            "ES":"Regresar",
            "EN":"Back",
            "IT":"Indietro"
        },
        "ROL":{
            "ES":"Selecciona tu rol",
            "EN":"Select your role",
            "IT":"Seleziona la tua funzione"
        },
        "AWELCOME":{
            "ES":"Bienvenido al portal de registro de deportistas",
            "EN":"Welcome to the athlete registration portal",
            "IT":"Benvenuto nello spazio di registro per gli atleti"
        },
        "NWELCOME":{
            "ES":"Bienvenido al portal de registro de nutricionista",
            "EN":"Welcome to the nutritionist registration portal",
            "IT":"Benvenuto nello spazio di registro per i nutrizionisti"
        },
        "KWELCOME":{
            "ES":"Bienvenido al portal de registro de cocina",
            "EN":"Welcome to the kitchen registration portal",
            "IT":"Benvenuto nello spazio di registro per le cucine"
        },

        "Registro_deportista": {
          "ES": "Registro de Deportista",
          "EN": "Athlete Registration",
          "IT": "Registro Atleta"
        },
        "Registro_nutricionista": {
          "ES": "Registro de nutricionista",
          "EN": "Nutritionist registration",
          "IT": "Registro nutrizionisti"
        },
        "Registro_cocina": {
          "ES": "Registro de cocina",
          "EN": "Kitchen registration",
          "IT": "Registro cucine"
        },
        "Nombre": {
          "ES": "Nombre",
          "EN": "Name",
          "IT": "Nome"
        },
        "Apellido": {
          "ES": "Apellido",
          "EN": "Last Name",
          "IT": "Cognome"
        },
        "Club_al_que_pertenece": {
          "ES": "Club al que pertenece",
          "EN": "Club Affiliation",
          "IT": "Club a cui appartiene"
        },
        "Fecha_de_nacimiento": {
          "ES": "Fecha de nacimiento",
          "EN": "Date of Birth",
          "IT": "Data di nascita"
        },
        "Correo_electronico": {
          "ES": "Correo electrónico",
          "EN": "Email",
          "IT": "E-mail"
        },
        "Telefono": {
          "ES": "Teléfono",
          "EN": "Phone",
          "IT": "Telefono"
        },
        "País": {
          "ES": "País",
          "EN": "Country",
          "IT": "Paese"
        },
        "Ciudad": {
          "ES": "Ciudad",
          "EN": "City",
          "IT": "Citta"
        },
        "Siguiente": {
          "ES": "Siguiente",
          "EN": "Next",
          "IT": "Avanti"
        },
        "Gender": {
          "ES": "Género",
          "EN": "Gender",
          "IT": "Sesso"
        },
        "Seleccionar": {
          "ES": "Seleccionar",
          "EN": "Select",
          "IT": "Seleziona"
        },
        "Femenino": {
          "ES": "Femenino",
          "EN": "Female",
          "IT": "Donna"
        },
        "Masculino": {
          "ES": "Masculino",
          "EN": "Male",
          "IT": "Uomo"
        },
        "Otro": {
          "ES": "Otro",
          "EN": "Other",
          "IT": "Altro"
        },
        "Estado": {
          "ES": "Estado",
          "EN": "State",
          "IT": "Stato"
        },
        "Provincia": {
          "ES": "Provincia",
          "EN": "Province",
          "IT": "Provincia"
        },
        "ingresa_contrasena": {
          "ES": "Ingresa contraseña",
          "EN": "Enter Password",
          "IT": "Inserisci Password"
        },
        "confirma_contrasena": {
          "ES": "Confirma contraseña",
          "EN": "Confirm Password",
          "IT": "Conferma password"
        },
        "acepto_terminos_condiciones": {
          "ES": "Acepto términos y condiciones",
          "EN": "Accept Terms and Conditions",
          "IT": "Accetto termini e condizioni"
        },
        "deseo_recibir_promociones": {
          "ES": "Deseo recibir promociones",
          "EN": "I wish to receive promotions",
          "IT": "Voglio ricevere promozioni"
        },
        "filosofia_alimentaria": {
          "ES": "Filosofía alimentaria",
          "EN": "Food Philosophy",
          "IT": "Filosofia alimentare"
        },
        "seleccionar": {
          "ES": "Seleccionar",
          "EN": "Select",
          "IT": "Seleziona"
        },
        "omnivoro": {
          "ES": "Omnívoro",
          "EN": "Omnivore",
          "IT": "Onnivoro"
        },
        "vegetariano": {
          "ES": "Vegetariano",
          "EN": "Vegetarian",
          "IT": "Vegetariano"
        },
        "vegano": {
          "ES": "Vegano",
          "EN": "Vegan",
          "IT": "Vegano"
        },
        "intolerancia_alergia": {
          "ES": "¿Algún tipo de intolerancia o alergia? ¿Cuáles?",
          "EN": "Any intolerance or allergy? Which ones?",
          "IT": "Hai qualche tipo di intolleranza o allergia? Quale?"
        },
        "preferencias": {
          "ES": "Preferencias",
          "EN": "Preferences",
          "IT": "Preferenze"
        },
        "preferencias_alimentos": {
          "ES": "Preferencias o alimentos no deseados",
          "EN": "Preferences or undesired foods",
          "IT": "Preferenze o alimenti indesiderati"
        },
        "lugar_entrega_direccion": {
          "ES": "¿Dónde sería el lugar de entrega? / Dirección",
          "EN": "Where would be the delivery location? / Address",
          "IT": "In che luogo preferisci la consegna? Indirizzo"
        },
        "hora_entrega": {
          "ES": "¿A qué hora quieres la entrega?",
          "EN": "What time do you want the delivery?",
          "IT": "A che ora preferisci la consegna?"
        },
        "plan_necesitas": {
          "ES": "¿Qué plan necesitas?",
          "EN": "Which plan do you need?",
          "IT": "Di che piano hai bisogno?"
        },
        "comidas_cenas_440": {
          "ES": "Comidas o Cenas €440 al mes",
          "EN": "Lunch or Dinner €440 per month",
          "IT": "Pranzo o Cene 440€ al mese"
        },
        "comidas_cenas_880": {
          "ES": "Comidas y Cenas €880 al mes",
          "EN": "Lunch and Dinner €880 per month",
          "IT": "Pranzo e Cene 880€ al mese"
        },
        "personas_servicio": {
          "ES": "¿Para cuántas personas más necesitas el servicio?",
          "EN": "For how many additional people do you need the service?",
          "IT": "Per quante persone in più hai bisogno del servizio?"
        },
        "tipo_servicio": {
          "ES": "¿Qué tipo de servicio necesita?",
          "EN": "What type of service do you need?",
          "IT": "Di che servizio hai bisogno?"
        },
        "plan": {
          "ES": "Plan",
          "EN": "Plan",
          "IT": "Piano"
        },
        "iva": {
          "ES": "IVA",
          "EN": "VAT",
          "IT": "IVA"
        },
        "costo_envio": {
          "ES": "Costo de envío",
          "EN": "Shipping Cost",
          "IT": "Prezzo d’invio"
        },
        "costo_total": {
          "ES": "Costo total",
          "EN": "Total Cost",
          "IT": "Prezzo totale"
        },
        "metodo_pago": {
          "ES": "Método de pago",
          "EN": "Payment Method",
          "IT": "Modalità di pagamento"
        },
        "siguiente": {
          "ES": "Siguiente",
          "EN": "Next",
          "IT": "Avanti"
        },
        "campo_obligatorio": {
          "ES": "Este campo es obligatorio",
          "EN": "This field is required",
          "IT": "Questo campo è obbligatorio"
        },
        "codigo_postal": {
          "ES": "Código postal",
          "EN": "Postal code",
          "IT": "Codice postale"
        },
        "objetivo": {
          "ES": "Objetivo",
          "EN": "Goal",
          "IT": "Obiettivo"
        },
        "masa_muscular": {
          "ES": "Masa muscular",
          "EN": "Muscle mass",
          "IT": "Massa muscolare"
        },
        "menos_peso": {
          "ES": "Menos peso",
          "EN": "Less weight",
          "IT": "Meno peso"
        },
        "definicion": {
          "ES": "Definición",
          "EN": "Definition",
          "IT": "Definizione"
        },
        "peso": {
          "ES": "Peso",
          "EN": "Weight",
          "IT": "Peso"
        },
        "estatura": {
          "ES": "Estatura",
          "EN": "Height",
          "IT": "Altezza"
        },
        "imc": {
          "ES": "IMC",
          "EN": "BMI",
          "IT": "IMC"
        },
        "soy_nutricionista": {
          "ES": "Ya soy nutricionista club mia",
          "EN": "I am already a Club MIA nutritionist",
          "IT": "Sono già un nutrizionista del Club MIA"
        },
        "comidas": {
          "ES": "Comidas",
          "EN": "Meals",
          "IT": "Pranzi"
        },
        "cenas": {
          "ES": "Cenas",
          "EN": "Dinners",
          "IT": "Cene"
        },

        "gratuito": {
          "ES": "Gratuito",
          "EN": "Free",
          "IT": "Gratis"
        },
        
        
      },
      "PLANDETAIL":{
        "":{
          "ES":"",
          "EN":"",
          "IT":""
        },
        null:{
          "ES":"",
          "EN":"",
          "IT":""
        },
        undefined:{
          "ES":"",
          "EN":"",
          "IT":""
        },
        "enlace": {
          "ES": "Enlace",
          "EN": "Link",
          "IT": "Link"
        },
        "comentarios_general": {
            "ES": "Comentarios en general",
            "EN": "General comments",
            "IT": "Commenti generali"
          },
          "sin_comentarios": {
            "ES": "Sin comentarios",
            "EN": "No comment",
            "IT": "Nessun commento"
          },
          "enviar_a_cocina": {
            "ES": "Enviar a cocina",
            "EN": "Send to kitchen",
            "IT": "Invia in cucina"
          },
          "alergias_intolerancias": {
            "ES": "Alergias e intolerancias",
            "EN": "Allergies and intolerances",
            "IT": "Allergie ed intolleranze"
          },
          "servicio": {
            "ES": "Servicio",
            "EN": "Service",
            "IT": "Servizio"
          },
          "receta": {
            "ES": "Receta",
            "EN": "Recipe",
            "IT": "Ricetta"
          },
          "asignar": {
            "ES": "Asignar",
            "EN": "Assigned",
            "IT": "Assegnare"
          },
          "sin_asignar": {
            "ES": "Sin asignar",
            "EN": "Unassigned",
            "IT": "Senza assegnare"
          },
          "gramos": {
            "ES": "Gramos",
            "EN": "Grams",
            "IT": "Grammi"
          },
          "invitados": {
            "ES": "Invitados",
            "EN": "Guests",
            "IT": "Invitati"
          },
          "total": {
            "ES": "Total",
            "EN": "Total",
            "IT": "Totale"
          },
          "deportista": {
            "ES": "Deportista",
            "EN": "Athlete",
            "IT": "Atleta"
          },
          "tabla_nutricional": {
            "ES": "Tabla nutricional",
            "EN": "Nutritional table",
            "IT": "Tabella nutrizionale"
          },
          "ver_tabla_nutricional": {
            "ES": "Ver tabla nutricional",
            "EN": "View nutritional table",
            "IT": "Tabella nutrizionale"
          }

      },
      "NTABLE":{
        "":{
          "ES":"",
          "EN":"",
          "IT":""
        },
        null:{
          "ES":"",
          "EN":"",
          "IT":""
        },
        undefined:{
          "ES":"",
          "EN":"",
          "IT":""
        },
        "nombre_completo": {
            "ES": "Nombre completo",
            "EN": "Full name",
            "IT": "Nome completo"
          },
          "email": {
            "ES": "Email",
            "EN": "Email",
            "IT": "E-mail"
          },
          "telefono": {
            "ES": "Teléfono",
            "EN": "Phone",
            "IT": "Telefono"
          },
          "locacion": {
            "ES": "Locación",
            "EN": "Location",
            "IT": "Posizione"
          },
          "filosofia_alimentaria": {
            "ES": "Filosofía alimentaria",
            "EN": "Food philosophy",
            "IT": "Filosofia alimentare"
          },
          "alergias_intolerancias": {
            "ES": "Alergias e intolerancias",
            "EN": "Allergies and intolerances",
            "IT": "Allergie ed intolleranze"
          },
          "pasta": {
            "ES": "Pasta",
            "EN": "Pasta",
            "IT": "Pasta"
          },
          "arroz": {
            "ES": "Arroz",
            "EN": "Rice",
            "IT": "Riso"
          },
          "bulgur": {
            "ES": "Bulgur",
            "EN": "Bulgur",
            "IT": "Bulgur"
          },
          "couscous": {
            "ES": "Couscous",
            "EN": "Couscous",
            "IT": "Couscous"
          },
          "gnocchi": {
            "ES": "Gnocchi",
            "EN": "Gnocchi",
            "IT": "Gnocchi"
          },
          "legumbre": {
            "ES": "Legumbre",
            "EN": "Legumes",
            "IT": "Legumi"
          },
          "maiz": {
            "ES": "Maíz",
            "EN": "Corn",
            "IT": "Mais"
          },
          "polenta": {
            "ES": "Polenta",
            "EN": "Polenta",
            "IT": "Polenta"
          },
          "quinoa": {
            "ES": "Quinoa",
            "EN": "Quinoa",
            "IT": "Quinoa"
          },
          "tuberculo": {
            "ES": "Tubérculo",
            "EN": "Tuber",
            "IT": "Tubero"
          },
          "carne_blanca": {
            "ES": "Carne blanca",
            "EN": "White meat",
            "IT": "Carne bianca"
          },
          "huevo": {
            "ES": "Huevo",
            "EN": "Egg",
            "IT": "Uovo"
          },
          "jamon": {
            "ES": "Jamón",
            "EN": "Ham",
            "IT": "Prosciutto"
          },
          "pescado_azul": {
            "ES": "Pescado azul",
            "EN": "Oily fish",
            "IT": "Pesce azzurro"
          },
          "pescado_blanco": {
            "ES": "Pescado blanco",
            "EN": "White fish",
            "IT": "Pesce bianco"
          },
          "tofu": {
            "ES": "Tofu",
            "EN": "Tofu",
            "IT": "Tofu"
          },
          "ternera": {
            "ES": "Ternera",
            "EN": "Beef",
            "IT": "Carne"
          },
          "verduras": {
            "ES": "Verduras",
            "EN": "Vegetables",
            "IT": "Verdure"
          },
          "guardar_tabla": {
            "ES": "Guardar tabla",
            "EN": "Save table",
            "IT": "Salva tabella"
          }
      },
      "NOT":{
        "guardar_plan_semanal": {
          "ES": "Guardar plan semanal",
          "EN": "Save weekly plan",
          "IT": "Salva piano settimanale"
        },
        "plan_semanal_guardado": {
          "ES": "Haz guardado este Plan Semanal",
          "EN": "You have successfully saved this Weekly Plan",
          "IT": "Hai salvato correttamente questo piano settimanale"
        },
        "plan_semanal_enviado": {
          "ES": "ha enviado el plan semanal a",
          "EN": "has successfully sent the weekly plan to",
          "IT": "Hai inviato correttamente il piano settimanale a"
        },
        "enviar_a_cocina": {
          "ES": "Enviar a cocina",
          "EN": "Send to kitchen",
          "IT": "Inviare a cucina"
        },
        "enviado_a_cocina": {
          "ES": "Se ha enviado a cocina",
          "EN": "Sent to kitchen",
          "IT": "È stato inviato in cucina"
        },
        "enlace": {
          "ES": "Enlace",
          "EN": "Link",
          "IT": "Link"
        },
        "enlace_copiado": {
          "ES": "Enlace copiado en el portapapeles",
          "EN": "Link copied to clipboard",
          "IT": "Link copiato negli appunti"
        },
        "guardar_tabla_nutricional": {
          "ES": "Guardar tabla nutricional",
          "EN": "Save nutritional table",
          "IT": "Salva tabella nutrizionale"
        },
        "listo": {
          "ES": "¡Listo!",
          "EN": "Ready!",
          "IT": "Pronto!"
        },
        "guardar": {
          "ES": "Guardar",
          "EN": "Save",
          "IT": "Salvare"
        },
        "cambios_guardados": {
          "ES": "Se han guardado los cambios",
          "EN": "Changes have been saved",
          "IT": "Si sono salvati i cambi correttamente"
        },
        "validar_plan": {
          "ES": "Validar plan",
          "EN": "Validate plan",
          "IT": "Valida piano"
        },
        "plan_semanal_validado": {
          "ES": "Has validado tu plan semanal",
          "EN": "You have validated your weekly plan",
          "IT": "Hai validato il tuo piano settimanale"
        },
        "nuevo_deportista_creado": {
          "ES": "Se ha creado un nuevo deportista",
          "EN": "A new athlete has been created",
          "IT": "È stato creato un nuovo atleta"
        },
        "nuevo_nutricionista_creado": {
          "ES": "Se ha creado un nuevo nutricionista",
          "EN": "A new nutricionist has been created",
          "IT": "È stato creato un nuovo nutrizionista"
        },
        "registro": {
          "ES": "Registro",
          "EN": "Registration",
          "IT": "Registrazione"
        },
        "agregar_invitados": {
          "ES": "Agregar invitados",
          "EN": "Add guests",
          "IT": "Aggiungi invitati"
        },
        "seleccionar_num_invitados": {
          "ES": "Debes seleccionar un número de invitados",
          "EN": "You must select a number of guests",
          "IT": "Devi selezionare un numero di invitati"
        },
        "seleccionar_tipo_servicio": {
          "ES": "Debes seleccionar un tipo de servicio",
          "EN": "You must select a service type",
          "IT": "Devi selezionare un tipo di servizio"
        },
        "seleccionar_fecha_servicio": {
          "ES": "Debes seleccionar una fecha de servicio",
          "EN": "You must select a service date",
          "IT": "Devi selezionare una data per il servizio"
        },
        "seleccionar_zona_envio": {
          "ES": "Debes seleccionar una Zona de envío",
          "EN": "You must select a Shipping Zone",
          "IT": "Devi selezionare una zona di invio"
        },
        "asignar_nutricionista": {
          "ES": "Asignar nutricionista",
          "EN": "Assign nutritionist",
          "IT": "Assegnare nutrizionista"
        },
        "indicar_cocina_nutricionista": {
          "ES": "Debes indicar una Cocina y un Nutricionista",
          "EN": "You must indicate a Kitchen and a Nutritionist",
          "IT": "Devi indicare una cucina ed un nutrizionista"
        },
        "crear_plan_semana": {
          "ES": "Crear plan semanal",
          "EN": "Create weekly plan",
          "IT": "Creare piano settimanale"
        },
        "indicar_fecha_inicio_fin": {
          "ES": "Debes indicar una fecha de inicio y fin",
          "EN": "You must indicate a start and end date",
          "IT": "Devi indicare una data di inizio e fine"
        },
        "nuevo_plan_semanal_creado": {
          "ES": "Se ha creado un nuevo Plan Semanal",
          "EN": "A new Weekly Plan has been created",
          "IT": "È stato creato un nuovo piano settimanale"
        },
        "eliminar_usuario": {
          "ES": "Eliminar usuario",
          "EN": "Delete user",
          "IT": "Elimina utente"
        },
        "dar_de_baja": {
          "ES": "¿Desea dar de baja a:?",
          "EN": "Do you want to cancel:",
          "IT": "Vuoi annullare il servizio di:"
        },
        "eliminar_plan": {
          "ES": "Eliminar plan",
          "EN": "Delete plan",
          "IT": "Elimina piano"
        },
        "dar_de_baja_plan": {
          "ES": "¿Desea dar de baja este plan?",
          "EN": "Do you want to cancel this plan?",
          "IT": "Vuoi annullare questo piano?"
        },
        "envio": {
          "ES": "Envío",
          "EN": "Shipment",
          "IT": "Invii"
        },
        "envios": {
          "ES": "Envíos",
          "EN": "Shipments",
          "IT": "Invii"
        },
        "ver_envios": {
          "ES": "Ver envíos",
          "EN": "View shipments",
          "IT": "Vedi invii"
        },
        "dia_servicio": {
          "ES": "Días de servicio",
          "EN": "Service days",
          "IT": "Giorno del servizio"
        },
        "hora": {
          "ES": "Hora",
          "EN": "Time",
          "IT": "Ora"
        },
        "direccion": {
          "ES": "Dirección",
          "EN": "Address",
          "IT": "Indirizzo"
        },
        "porciones": {
          "ES": "Porciones",
          "EN": "Servings",
          "IT": "Porzioni"
        },
        "ninguno": {
          "ES": "Ninguno",
          "EN": "None",
          "IT": "Nessuno"
        },
        "mi_plan": {
          "ES": "Mi plan",
          "EN": "My plan",
          "IT": "Il mio piano"
        },
        "ingredientes": {
          "ES": "Ingredientes",
          "EN": "Ingredients",
          "IT": "Ingredienti"
        },
        "preparacion": {
          "ES": "Preparación",
          "EN": "Preparation",
          "IT": "Preparazione"
        },
        "mensual": {
          "ES": "Mensual",
          "EN": "Monthly",
          "IT": "Mensile"
        },
        "fecha_renovacion": {
          "ES": "Fecha de renovación",
          "EN": "Renewal date",
          "IT": "Data di rinnovo"
        },
        "pago_recurrente": {
          "ES": "Pago recurrente",
          "EN": "Recurring payment",
          "IT": "Pagamento ricorrente"
        },
        "cancelar_plan": {
          "ES": "Cancelar plan",
          "EN": "Cancel plan",
          "IT": "Cancella piano"
        },
        "fecha_inicio": {
          "ES": "Fecha de inicio",
          "EN": "Start date",
          "IT": "Data di inizio"
        },
        "fecha_fin": {
          "ES": "Fecha de fin",
          "EN": "End date",
          "IT": "Data di fine"
        },
        "nuevo_deportista": {
          "ES": "Nuevo deportista",
          "EN": "New athlete",
          "IT": "Nuovo atleta"
        },
        "nuevo_nutricionista": {
          "ES": "Nuevo nutricionista",
          "EN": "New nutricionist",
          "IT": "Nuovo nutrizionisti"
        },
        "escribe_correo_valido":{
          "ES": "Escribe un correo válido",
          "EN": "Type a valid E-mail",
          "IT": "Inserire un indirizzo e-mail valido"
        },
        "para_ver":{
          "ES": "Para ver la información debes guardar el Plan Semanal y llenar la Tabla Nutricional de cada Deportista",
          "EN": "To see the information you must save the Weekly Plan and fill in the Nutritional Table of each Athlete",
          "IT": "Per visualizzare le informazioni è necessario salvare il piano settimanale e compilare la tabella nutrizionale per ogni atleta."
        },
        "Comida": {
          "ES": "Comidas",
          "EN": "Meals",
          "IT": "Pranzi"
        },
        "Cena": {
          "ES": "Cenas",
          "EN": "Dinners",
          "IT": "Cene"
        },
        "Comida y Cena": {
          "ES": "Comidas y Cenas",
          "EN": "Meals and Dinners",
          "IT": "Pranzi e Cene"
        },
        "Omnívoro": {
          "ES": "Omnívoro",
          "EN": "Omnivore",
          "IT": "Onnivoro"
        },
        "Omnivoro": {
          "ES": "Omnívoro",
          "EN": "Omnivore",
          "IT": "Onnivoro"
        },
        "Vegetariano": {
          "ES": "Vegetariano",
          "EN": "Vegetarian",
          "IT": "Vegetariano"
        },
        "Vegano": {
          "ES": "Vegano",
          "EN": "Vegan",
          "IT": "Vegano"
        },
        "Hallal": {
          "ES": "Hallal",
          "EN": "Hallal",
          "IT": "Hallal"
        },
        "Tipo de registro": {
          "ES": "Tipo de registro",
          "EN": "Register type",
          "IT": "Tipo di registro"
        },
        "Datos generales": {
          "ES": "Datos generales",
          "EN": "General data",
          "IT": "Dati Generali"
        },
   
        "Datos del deportista": {
          "ES": "Datos del deportista",
          "EN": "Athlete's data",
          "IT": "Dati dell’atleta"
        },
        "Terminos y condiciones": {
          "ES": "Terminos y condiciones",
          "EN": "Terms and Conditions",
          "IT": "Termini e Condizioni"
        },
        "Servicio":{
          "ES":"Servicio",
          "EN":"Service",
          "IT":"Servizio"
        },
        "Pago":{
          "ES":"Pago",
          "EN":"Payment",
          "IT":"Pagamento"
        },
        "Datos":{
          "ES":"Datos",
          "EN":"Data",
          "IT":"Dati"
        },
        "Active":{
          "ES":"Activo",
          "EN":"Active",
          "IT":"Abilitato"
        },
        "Inactivo":{
          "ES":"Inactivo",
          "EN":"Inactive",
          "IT":"Disabilitato"
        },
        "":{
          "ES":"",
          "EN":"",
          "IT":""
        },
        null:{
          "ES":"",
          "EN":"",
          "IT":""
        },
        undefined:{
          "ES":"",
          "EN":"",
          "IT":""
        },
      
        
      }
}




