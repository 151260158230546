/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import "antd/dist/antd.css";
import { DatePicker, Drawer, Select } from "antd";
import "../../assets/css/Users/users.css";
import DataContext from "../../context/dataContext";
import { urlService, enviroment } from "../../services/UrlService";
import GeneralButton from "../../components/buttons/GeneralButton";
import { FiLink } from "react-icons/fi";
import moment from "moment";
import { AiFillCloseCircle, AiOutlineClose } from "react-icons/ai";
import { BiComment } from "react-icons/bi";
import { BsPeople, BsPerson } from "react-icons/bs";
import { Input } from 'antd';
import Renderif from "../../components/Renderif";
import AlertContext from "../../context/alertContext";
import { LANG } from "../commun/lang";
import AuthContext from "../../context/authContext";

var sha1 = require("sha1");

function DrawerAddGuest({show}) {
	const { Option } = Select;

	const init_Date = new Date();

	const dateFormat = "YYYY/MM/DD";

	const {showAlert} = useContext(AlertContext);
	const [state, setState] = useState({
		guests: 0,
		service:"",
		service_day:null,
		shipping:'',
		payment_link:'',
		total:0
	})
	const {lang} = useContext(AuthContext)

	const {
		drawerAddGuest,
		setDrawerAddGuest,
	} = useContext(DataContext);

	

	const onClose = () => {
		setState({...state, payment_link:'', guests:0, service:"", shipping:'', service_day:''})
		setDrawerAddGuest(false);
	};

	const onDateChange = (date, dateString) => {
		// const format = "YYYY-MM-DD HH:mm:ss";
		// let initDate = new Date(dateString).getTime();
		// let startDate = moment(initDate).format(format);
		setState({...state, service_day:date})
	};

	const getShippingCost =  ()=> {
		switch(state.shipping){
			case '1':
				return 5;
			case '2':
				return 12;
			case '3':
				return 18;
			default:
			return 0;
		}
	}
	const getGuestsCost =  ()=> {
		switch (state.service) {
			case 'Comida':
				return state.guests * 16;
			case 'Cena':
				return state.cena * 16;
			case 'Comida y Cena':
				return state.guests * 32;
			default:
				return 0
		}
	}
	const onCreateGuest = (e) => {	

		if(state.guests === 0){
			showAlert({type:'error', description:LANG.NOT.seleccionar_num_invitados[lang], message:LANG.NOT.agregar_invitados[lang]})
			return;
		}
		if(state.service === ""){
			showAlert({type:'error', description:LANG.NOT.seleccionar_tipo_servicio[lang], message:LANG.NOT.agregar_invitados[lang]})
			return;
		}
		if(state.service_day === ""){
			showAlert({type:'error', description:LANG.NOT.seleccionar_tipo_servicio[lang], message:LANG.NOT.agregar_invitados[lang]})
			return;
		}
		if(state.service_day === ""){
			showAlert({type:'error', description:LANG.NOT.seleccionar_zona_envio[lang], message:LANG.NOT.agregar_invitados[lang]})
			return;
		}

		setState({...state, payment_link:'https://buy.stripe.com/test_8wM7tC2dAgEu7NS3cf', guests:0, service:"", shipping:'', service_day:''})
		
	}
	const onCopyLink = () => {
		
		navigator.clipboard.writeText(state.payment_link);
		showAlert({type:'success', description:LANG.NOT.listo[lang], message:LANG.NOT.enlace_copiado[lang]})
		setState({...state, guests:0})
	}

	return (
		<>
			<Drawer
				closeIcon= {<AiOutlineClose size={20} />}
				title={LANG.NOT.agregar_invitados[lang]}
				placement="right"
				onClose={onClose}
				open={drawerAddGuest}
				visible={drawerAddGuest}
				size=""
			>
			

				<div className="userSelects select_custom formAddGuestDrawer">
				
						<label className="py-1 f-Poppins-Medium">Número de invitados</label>
						<Select
							defaultValue="0"
							value={state.guests.toString()}
							style={{ width: 300, marginBottom: 20 }}
							onChange={(e)=>{
								
								setState({...state, guests:parseInt(e)})
							}}
							name=""
							className="w-100 text-white"
						>
							<Option value="0">{LANG.REGISTER.seleccionar[lang]}</Option>
							<Option value="1">1</Option>
							<Option value="2">2</Option>
							<Option value="3">3</Option>
							<Option value="4">4</Option>
							<Option value="5">5</Option>
							<Option value="6">6</Option>
							<Option value="7">7</Option>
							<Option value="8">8</Option>
							<Option value="9">9</Option>
							<Option value="10">10</Option>
						</Select>
					

						<label className="py-1 f-Poppins-Medium">{LANG.REGISTER.tipo_servicio[lang]}</label>
						<Select
							defaultValue=""
							value={state.service}
							style={{ width: 300, marginBottom: 20 }}
							onChange={(e) => {
								setState({...state, service:e})
							}}
							name=""
							className="w-100"
						>
							<Option value="">{LANG.REGISTER.seleccionar[lang]}</Option>
							<Option value="Comida">{LANG.REGISTER.comidas[lang]}</Option>
							<Option value="Cena">{LANG.REGISTER.cenas[lang]}</Option>
							<Option value="Comida y Cena">{LANG.REGISTER.comidas[lang]} y {LANG.REGISTER.cenas[lang]}</Option>
						</Select>
					
						<label className="py-1 f-Poppins-Medium">{LANG.NOT.dia_servicio[lang]}</label>
						<DatePicker	
							format={dateFormat}
							name="foodDate"
							placeholder="Fecha de inicio"
							className="w-100 mb-3"
							value={state.service_day}
							onChange={onDateChange}
						/>
						<label className="py-1 f-Poppins-Medium">{LANG.NOT.envio[lang]}</label>
						<Select
							defaultValue=""
							value={state.shipping}
							style={{ width: 300, marginBottom: 20 }}
							onChange={(e) => {
								setState({...state, shipping:e})
							}}
							name=""
							className="w-100"
						>
							<Option value="">{LANG.REGISTER.seleccionar[lang]}</Option>
							<Option value="1">Zona 1</Option>
							<Option value="2">Zona 2</Option>
							<Option value="3">Zona 3</Option>
						</Select>
					
						<div className="mt-5">
							<div className="d-flex px-2 pb-1">

								<div className="f-12 f-Poppins-Medium">{LANG.PLANDETAIL.invitados[lang]}:</div>
								<div className="col"></div>
								<div className=" f-Poppins-Bold">€ {getGuestsCost().toFixed(2)}</div>

							</div>
							<div className="d-flex px-2 pb-1">

								<div className="f-12 f-Poppins-Medium">{LANG.NOT.envio[lang]}:</div>
								<div className="col"></div>
								<div className=" f-Poppins-Bold">€ {getShippingCost().toFixed(2)}</div>

							</div>

							<div className="d-flex px-2 pb-3">

								<div className="f-14 text-yellow f-Poppins-Bold">{LANG.PLANDETAIL.total[lang]}:</div>
								<div className="col"></div>
								<div className="f-14 text-yellow f-Poppins-Bold">€ {(getShippingCost() + getGuestsCost()).toFixed(2)}</div>

							</div>
						</div>
					
					
						<div className="d-flex ">
							<div className="col"></div>
							<GeneralButton
								className="btn__blue btn__general border"
								title={LANG.NOT.agregar_invitados[lang]}
								icon={<BsPeople size={18} />}
								onClick={() => {
									onCreateGuest();
								}}
							/>
						</div>
					
						<Renderif isTrue={state.payment_link !== ''}>
							<div className="d-flex py-4 px-2">
								<div className="text-mint-shade f-Poppins-Medium">
								
									<span className="">
										Link de pago
									</span>
								</div>
								<div className="col"></div>
								

								<div className="text-yellow f-Poppins-Medium cursor-pointer" onClick={()=>{onCopyLink()}}>
									<FiLink />{" "}
									Copiar Link
								</div>

							</div>
						</Renderif>	
						
				</div>
			</Drawer>
		</>
	);
}

export default DrawerAddGuest;

