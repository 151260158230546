/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from "react";
import "../../assets/css/dashboard.css";
import { Link, Redirect, Navigate, useNavigate, useParams, useLocation } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HeaderDashboard from "../../components/HeaderDashboard";
import GeneralButton from "../../components/buttons/GeneralButton";
import { BiEdit } from "react-icons/bi";
import AuthContext from "../../context/authContext";
import BackButton from "../../components/buttons/BackButton";
import { LANG } from "../commun/lang";


function UserDetail(props) {
	const navigate = useNavigate();
    const {user, lang} = useContext(AuthContext);
    const {state} = useLocation();

    
    let athlete = state;
	return (
        <>
            <HeaderDashboard />
            <div className="container-fluid p-3">

                <div className="d-flex flex-wrap align-content-center">
                    <div className="col-12 col-lg">
                        <BackButton route="/plan-detalle" />
                        <div className="text-white f-30 f-anton text-uppercase ps-1">
                            {/* PERFIL */}
                        </div>
                    
                    </div>
                </div>
            </div>
            <div className="container-fluid">

                
            
    
    
    
    
                    <div className='text-white bg-dark-blue rounded'>
    
                        <div className='d-flex p-3'>
                            <div className='col'></div>
                            {/* <GeneralButton
                                className="btn__blue btn__general mr-10"
                                title="Tabla nutricional"
                                icon={<BiEdit />}
                                onClick={() => {}}
                            /> */}
                            {user.id_rol === 2 ? 
                            <GeneralButton
                                className="btn__blue btn__general"
                                title="Editar"
                                icon={<BiEdit />}
                                onClick={() => {
                                 
                                    navigate(`/editar-perfil-deportista/${athlete.id_user}/${athlete.id_athlete}`)
                                }}
                            />
                            :<></>}
                            
                        </div>
                     
                        <div className=' bg-mint-light'>
                            <div className='border-b-mint d-flex p-3'>
                                <div className='bg-yellow p-1 rounded-3 position-relative'>
    
                                  
                                   
    
                                    <img style={{width:100}} className='rounded-3 h-100' src={
                                       
                                        athlete?.picture ? process.env.REACT_APP_API_URL+'/images/'+athlete?.picture : process.env.REACT_APP_API_URL + '/images/profile.png' 
                                        
                                        }></img>
                                 
                                    
    
                                </div>
                                <div className='col ps-2'>
                                    <div className='f-20 f-Poppins-Bold text-mint-shade '>{athlete?.firstname + " " +athlete?.lastname}</div>
                                    <div className='f-14 f-Poppins-Regular text-mint-shade'>{athlete?.id_suscription == 1 ? 'PLAN PRO' : 'PLAN ELITE'} - {LANG.NOT.mensual[lang]}</div>
                                </div>
                            </div>
    
                            <div className='d-flex border-b-mint p-3'>
                                <div className=' col f-Poppins-Medium text-mint-shade'>Club</div>
                                <div className=' col f-Poppins-Medium text-mint-shade'>{athlete?.club}</div>
                            </div>
                            <div className='d-flex border-b-mint p-3'>
                                <div className=' col f-Poppins-Medium text-mint-shade'>{LANG.REGISTER.Ciudad[lang]}</div>
                                <div className=' col f-Poppins-Medium text-mint-shade'>{athlete?.city}, {athlete?.state}</div>
                            </div>
                            <div className='d-flex border-b-mint p-3'>
                                <div className=' col f-Poppins-Medium text-mint-shade'>{LANG.REGISTER.País[lang]}</div>
                                <div className=' col f-Poppins-Medium text-mint-shade'>{athlete?.country}</div>
                            </div>
                            <div className='d-flex border-b-mint p-3'>
                                <div className=' col f-Poppins-Medium text-mint-shade'>Email</div>
                                <div className=' col f-Poppins-Medium text-mint-shade'>{athlete?.email}.</div>
                            </div>
    
                        </div>
    
                    
                        <div className=' bg-secundary-dark-blue py-2 px-3'>
                            <div className=' f-20 f-anton text-white text-uppercase'>{LANG.REGISTER.GDATA[lang]}</div>
                        </div>
                        <div className='border-b-blue-shade d-flex p-3'>
                            <div className=' col f-Poppins-Medium text-white'>{LANG.REGISTER.Gender[lang]}:</div>
                            <div className=' col f-Poppins-Medium text-white'>{athlete?.gender}</div>
                        </div>
                        <div className='border-b-blue-shade d-flex p-3'>
                            <div className=' col f-Poppins-Medium text-white'>{LANG.REGISTER.Telefono[lang]}:</div>
                            <div className=' col f-Poppins-Medium text-white'>{athlete?.phone}</div>
                        </div>
                        <div className='border-b-blue-shade d-flex p-3'>
                            <div className=' col f-Poppins-Medium text-white'>Dirección:</div>
                            <div className=' col f-Poppins-Medium text-white'>{athlete?.delevery_address}</div>
                        </div>
                        <div className=' bg-secundary-dark-blue py-2 px-3'>
                            <div className=' f-20 f-anton text-white text-uppercase'>{LANG.REGISTER.preferencias[lang]}</div>
                        </div>
                        <div className='border-b-blue-shade d-flex p-3'>
                            <div className=' col f-Poppins-Medium text-white'>{LANG.PLANDETAIL.alergias_intolerancias[lang]}:</div>
                            <div className=' col f-Poppins-Medium text-white'>{athlete?.allergy}</div>
                        </div>
                        <div className='border-b-blue-shade d-flex p-3'>
                            <div className=' col f-Poppins-Medium text-white'>{LANG.REGISTER.preferencias_alimentos[lang]}:</div>
                            <div className=' col f-Poppins-Medium text-white'>{athlete?.delevery_occur}</div>
                        </div>
                        <div className='border-b-blue-shade d-flex p-3'>
                            <div className=' col f-Poppins-Medium text-white'>{LANG.REGISTER.filosofia_alimentaria[lang]}:</div>
                            <div className=' col f-Poppins-Medium text-white'>{LANG.NOT[athlete?.fedding][lang]}</div>
                        </div>
                        <div className='border-b-blue-shade d-flex p-3'>
                        <div className=' col f-Poppins-Medium text-white'>{LANG.REGISTER.objetivo[lang]}:</div>
                        <div className=' col f-Poppins-Medium text-white'>{athlete?.objetivo}</div>
                        </div>
                        <div className='border-b-blue-shade d-flex p-3'>
                            <div className=' col f-Poppins-Medium text-white'>{LANG.REGISTER.peso[lang]}:</div>
                            <div className=' col f-Poppins-Medium text-white'>{athlete?.peso}</div>
                        </div>
                        <div className='border-b-blue-shade d-flex p-3'>
                            <div className=' col f-Poppins-Medium text-white'>{LANG.REGISTER.estatura[lang]}:</div>
                            <div className=' col f-Poppins-Medium text-white'>{athlete?.estatura}</div>
                        </div>
                        <div className='border-b-blue-shade d-flex p-3'>
                            <div className=' col f-Poppins-Medium text-white'>{LANG.REGISTER.imc[lang]}:</div>
                            <div className=' col f-Poppins-Medium text-white'>{athlete?.imc}</div>
                        </div>
                        <div className=' bg-secundary-dark-blue py-2 px-3'>
                            <div className=' f-20 f-anton text-white text-uppercase'>{LANG.PLANDETAIL.servicio[lang]}</div>
                        </div>
                        <div className='border-b-blue-shade d-flex p-3'>
                            <div className=' col f-Poppins-Medium text-white'>{LANG.REGISTER.plan[lang]}</div>
                            <div className=' col f-Poppins-Medium text-white'>{athlete?.id_suscription == 1 ? 'PLAN PRO' : 'PLAN ELITE'}</div>
                        </div>
                        <div className='border-b-blue-shade d-flex p-3'>
                            <div className=' col f-Poppins-Medium text-white'>{LANG.PLANDETAIL.invitados[lang]}</div>
                            <div className=' col f-Poppins-Medium text-white'>{athlete?.invitados}</div>
                        </div>
                        <div className='border-b-blue-shade d-flex p-3'>
                            <div className=' col f-Poppins-Medium text-white'>{LANG.PLANDETAIL.servicio[lang]}</div>
                            <div className=' col f-Poppins-Medium text-white'>{LANG.NOT[athlete?.service][lang]}</div>
                        </div>
                        <div className='border-b-blue-shade d-flex p-3'>
                            <div className=' col f-Poppins-Medium text-white'>{LANG.NOT.hora[lang]}</div>
                            <div className=' col f-Poppins-Medium text-white'>{athlete?.delevery_time}</div>
                        </div>
    
                
    
                    </div>
    
       
              
                
            
            </div>
        </>
      )
}

export default UserDetail;
