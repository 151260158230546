/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import "../../assets/css/dashboard.css";
import icon_deportistas from "../../assets/images/icons/icon_deportistas.png";
import icon_cocina from "../../assets/images/icons/icon_cocina.png";
import icon_chef from "../../assets/images/icons/icon_chef.png";
import icon_nutricionistas from "../../assets/images/icons/icon_nutricionistas.png";

import {
	GiBattleship,
	GiStrong,
	GiBookCover,
	GiCrownedHeart,
	GiCheckMark,
	GiConqueror,
} from "react-icons/gi";
import moment from "moment";
import { Select } from "antd";
import AnimatedNumber from "animated-number-react";
import { useNavigate } from "react-router-dom";

const { Option } = Select;
function InfoBlocks() {
	const navigate = useNavigate() 
	const handleChange = (value) => {
		
	};

	const formatValue = (value) => value.toFixed(0);

	return (
		<>
			<div className="home__section_data">
				<div className="d-flex flex-wrap info_blocks">
					<div className="col-12 col-lg-4 p-2 block">
						<div className="data bg-dark-blue">
							<div className="info .mb__center_grid_full text-gray">
								<div className="icon bg-yellow">
									<img
										src={'../assets/images/deportistas.png'}
										alt="icon deportista"
									/>
								</div>
								<div className="numbers">
									<div className="description mb-5">
										Deportistas Asignados
									</div>
									<div className="number text-white">
										<AnimatedNumber
											value={2}
											formatValue={formatValue}
										/>
									</div>
								</div>

								<div className="clear"></div>
							</div>
							<div className="text-mint-shade f-Poppins-Medium p-2 border-t-blue-shade cursor-pointer">
							<div className=" opacity-0">	Ver todos</div>
							</div>
						</div>
					</div>
					<div className="col-12 col-lg-4 p-2 block">
						<div className="data bg-dark-blue">
							<div className="info .mb__center_grid_full text-gray">
								<div className="icon bg-yellow">
									<img src={icon_cocina} alt="synci-logo" />
								</div>

								<div className="numbers">
									<div className="description mb-5">
										En Preparación
									</div>
									<div className="number text-white">
										<AnimatedNumber
											value={0}
											formatValue={formatValue}
										/>
									</div>
								</div>

								<div className="clear"></div>
							</div>
							<div className="text-mint-shade f-Poppins-Medium p-2 border-t-blue-shade cursor-pointer">
								<div className="opacity-0">Ver todos</div>
							</div>
						</div>
					</div>

					<div className="col-12 col-lg-4 p-2 block">
						<div className="data bg-dark-blue">
							<div className="info .mb__center_grid_full text-gray">
								<div className="icon bg-yellow">
									<img
										src={'../assets/images/enviado.png'}
										alt=""
									/>
								</div>
								<div className="numbers">
									<div className="description mb-5">
										Enviados
									</div>
									<div className="number text-white">
										<AnimatedNumber
											value={0}
											formatValue={formatValue}
										/>
									</div>
								</div>

								<div className="clear"></div>
							</div>
							<div className="text-mint-shade f-Poppins-Medium p-2 border-t-blue-shade cursor-pointer" onClick={()=>{
								navigate('/envios')
							}}>
								Ver todos
							</div>
						</div>
					</div>

					<div className="clear"></div>
				</div>
			</div>
		</>
	);
}

export default InfoBlocks;
