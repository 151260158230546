
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AuthContext from "../context/authContext";
import DeportistaContext from "../context/deportistaContext";


import { Service } from "../services/api";


export const DeportistaState = ({children}) => {


    const [state, setState] = useState({
        athletes:[],
        weekPlans:[],
        kitchens:[],
        athlete:null
    })

    const {user} = useContext(AuthContext);

    const {id_athlete} = useParams('id_athlete');
    const {id_user} = useParams('id_user');
    console.log(id_athlete)

    useEffect(()=>{
        const data = async () => {
            let _athlete = {}

            if(!id_athlete){
              _athlete = await Service("GET",`athlete/${user?.id_user}`);
            }
            console.log(_athlete)

           
           
            let _kitchens = await Service("GET",`kitchens`);
          
            let _weekplans = await Service(
              "GET",
              `weeklyPlans`
            );
           
            setState({
              ...state,
              athlete:id_athlete ? {} : _athlete.data.response,
              
              weekPlans:id_athlete ? [] : _weekplans.data.response.filter((weekplan) => weekplan.id_kitchen === _athlete.data.response.id_kitchen && (weekplan.status === 3 || weekplan.status === 1) ).reverse(),
              kitchens:_kitchens.data.response
            });
        }
        data()

        return () => {}
    },[])

    const updateAthlete = (athlete) => {
      setState({...state, athlete})
    }



    

  
    return (
      <DeportistaContext.Provider value={{
        ...state,
        updateAthlete
      }}>
        
        {children}
      </DeportistaContext.Provider>
    );
  };