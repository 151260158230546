/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import "antd/dist/antd.css";

import { Table } from "antd";
import { WeeklyColumns, WeeklyColumnsNutricionist } from "../../utilities/tabs/WeeklyColumns";
import { useNavigate } from "react-router-dom";

import PlanContext from "../../context/planContext";
import AuthContext from "../../context/authContext";

function WeeklyTabs({weekplans, kitchen, nutricionist, id_suscription}) {
	const navigate = useNavigate();
	const {setCurrentWeekPlan, athletes} = useContext(PlanContext)
	const {user} = useContext(AuthContext)

	console.log(nutricionist)


	if(nutricionist && id_suscription){

		let _athletes_nutricionista = athletes.filter((athlete) => athlete.id_nutricionist === nutricionist.id_nutricionist);
		let kitchens = []
		_athletes_nutricionista.map((athlete) => {
			kitchens.push(athlete.id_kitchen)
		})

		console.log(weekplans.filter((weekplan)=> kitchens.includes(weekplan.id_kitchen)));
		console.log(kitchens)
	}

	const _weekplans = () => {
		if(nutricionist && id_suscription){

			let _athletes_nutricionista = athletes.filter((athlete) => athlete.id_nutricionist === nutricionist.id_nutricionist);
			let kitchens = []
			_athletes_nutricionista.map((athlete) => {
				kitchens.push(athlete.id_kitchen)
			})
	
			console.log(weekplans.filter((weekplan)=> kitchens.includes(weekplan.id_kitchen)));
			console.log(kitchens)
			return weekplans.filter((weekplan)=> kitchens.includes(weekplan.id_kitchen))
		}
	}
	

	
	return (
		<>
			<div className="container-fluid px-3">
				<div className="section_seals_table">
					<div className="p-3 rounded-2 bg-dark-blue">
						<Table
							rowKey="id"
							columns={user.id_rol == 2 ? WeeklyColumnsNutricionist(id_suscription) : WeeklyColumns()}
							dataSource={
								user.id_rol === 2 && id_suscription == 1 ? _weekplans() : 
								user.id_rol === 2 && id_suscription == 2 ? weekplans.filter((weekplan) => weekplan.id_nutricionist === user.id_user) :
								weekplans.filter((weekplan)=> kitchen === '0' && weekplan.id_kitchen !== 4 ? true : weekplan.id_kitchen === kitchen && weekplan.id_kitchen !== 4) 
								
			
							}
							onRow={(record, rowIndex) => {
								return {
									onClick: () => {
										
											

										if(user.id_rol === 1 ||  (user.id_rol == 2 && id_suscription === 2)){
											setCurrentWeekPlan(record)
											navigate(`/plan-detalle/${record.id}`);
										}else{
											navigate(`/plan-detalle-nutricionista/${record.id}`);
										}
										
									
									
										
									},
								};
							}}
						/>
					</div>
				</div>
			</div>
		</>
	);
}

export default WeeklyTabs;
