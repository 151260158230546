import React from 'react'

export default function TYCOS() {
    // return(
    //     <div className=' text-bg-danger'>asd</div>
    // )
  return (
    <div className='container text-justify py-5'>
        <div className='text-mint fw-bold f-32 pb-5 text-center'>Términos y Condiciones</div>
        <div className='text-mint fw-bold'> ACEPTACIÓN DE LOS TÉRMINOS Y CONDICIONES</div>
        <br></br>

        <div className='text-white'>Para contratar el servicio MIOBIO, se deberá dar lectura íntegra a los siguientes términos y condiciones (los “Términos y Condiciones”), y cumplir totalmente con los requisitos y obligaciones aquí establecidos. Al ingresar, visitar, navegar, utilizar los Medios y/o aceptar los servicios, el Usuario acepta estar obligado por los mismos así como sujetarse a las Políticas o Aviso de Privacidad. Si no está de acuerdo con estos Términos y Condiciones, no deberá utilizar los Medios, estos Términos de Uso son un contrato que rige la relación entre usted y la empresa. El servicio se ejecutará dentro de la página electrónica miobio.club.</div>
        <br></br>
        <div className='text-mint fw-bold'>DEFINICIONES</div>
        <br></br>
        
        <div className='text-white'>
            Además de los términos en su caso definidos en los párrafos que preceden, para efectos de estos Términos y Condiciones se entenderá por:


        Sitio WEB.- La dirección electrónica https: https://miobio.club, a través de la cual se podrá contratar el servicio, sujetándose los Usuarios y Consumidores a los presentes Términos y Condiciones y al Aviso de Privacidad.


        Cuenta.- Al registro individual del Usuario que lo identificará y distinguirá como tal de los demás contratantes, misma que será necesaria para la contratación del servicio.


        Usuario.- A la persona física mayor de edad y que cuente con capacidad de ejercicio, conforme a las leyes de la República Mexicana, y que cumpla con los requisitos de contratación, ingrese al servicio y obtenga su Cuenta individual de Usuario.


        Consumidor.- A la persona que recibe el servicio, es decir, como beneficiario de este. Todas las obligaciones y requisitos consignados en los presentes Términos y Condiciones aplicarán para el Consumidor como si se tratase del Usuario, en el entendido que la persona que contrata el servicio (Usuario) pueda ser distinta al Consumidor.


        Territorio: España, limitándose a las áreas de cobertura de servicio. Para mayor información consulta miobio.club


        Entrega no reconocida: Cuando el paquete sea recibido por una persona que no sea el cliente y que no sea reconocida por este último.


        En caso de haber más definiciones en el cuerpo de los presentes Términos y Condiciones se señalará enseguida su significado.


        Los términos referidos en este apartado tendrán igual significado ya sea que se utilicen en singular o plural.
        </div>
        <br></br>
        <div className='text-mint fw-bold'>MODIFICACIONES A LOS TÉRMINOS Y CONDICIONES</div>
        <br></br>
        <div className='text-white'>La empresa se reserva el derecho de cambiar ocasionalmente estos Términos y Condiciones de acuerdo a las necesidades del servicio, con o sin notificación. Al Consumidor se le hará válida la promoción siempre y cuando muestre evidencia de nuestras promociones en campañas oficiales de la marca.
        La versión más reciente de estos Términos y Condiciones estará disponible en el Sitio WEB, misma que reemplaza las versiones anteriores. El uso de los Medios una vez que se hayan realizado las modificaciones a estos Términos y Condiciones significa que se acepta dichos cambios.</div>
        <br></br>

        <div className='text-mint fw-bold'>PRIVACIDAD</div>
        <br></br>


        <div className='text-white'>Para más información, consulta nuestra política de privacidad.</div>
        <br></br>


        <div className='text-mint fw-bold'>DE LA CUENTA</div>
        <br></br>


        <div className='text-white'>El Usuario es responsable de mantener su Cuenta, incluyendo los datos de su Usuario y contraseña, como confidenciales reconociendo que es responsable de todas las actividades que se desarrollen utilizando su Cuenta o contraseña.</div>     <br></br>


        <div className='text-mint fw-bold'>CANCELACIÓN DE LA CUENTA</div>
        <br></br>


        <div className='text-white'>
        De detectarse comportamiento fraudulento o alguna actividad ilícita, la empresa se reserva el derecho de cancelar, inhabilitar o suspender alguna cuenta en cualquier momento, y proceder de forma legal según sea el caso y el daño realizado.
        </div>
        <br></br>

        <div className='text-mint fw-bold'>DEL USO DEL SITIO WEB Y LA APLICACIÓN</div>
        <br></br>


        <div className='text-white'>
        El Usuario se compromete, además de lo establecido en los presentes, a: (a) utilizar el Sitio WEB tal como fue autorizado, únicamente para uso personal y no comercial; (b) no copiar, ni distribuir ninguna parte del Sitio WEB sin la autorización previa y por escrito, de la empresa; y (c) no alterar ni modificar ninguna parte del Sitio WEB y (d) no compartir el Sitio Web o su contenido con personas menores de edad..
        <br></br><br></br>

        Para poder tener acceso a los servicios, el Usuario deberá crear una Cuenta, no pudiendo utilizar la Cuenta de otra persona sin su autorización. La Cuenta deberá ser generada con información fiel y completa, debiendo informar inmediatamente la empresa sobre cualquier falla de seguridad o uso no autorizado de la Cuenta. La empresa no será responsable de las pérdidas que se hayan causado por cualquier uso no autorizado de la Cuenta, el Usuario reconoce y acepta que él será el responsable de las pérdidas causadas a la empresa debido a dicho uso no autorizado.
        <br></br><br></br>
        La empresa puede proporcionar enlaces a sitios WEB pertenecientes o gestionados por terceros, sin que por este hecho pueda entenderse, bajo ninguna circunstancia, que la empresa respalda el contenido, productos o servicios disponibles en dichos sitios web, y que no es responsable de su contenido o su seguridad. El enlace o conexión del Usuario a cualquier otro sitio web es de su exclusiva responsabilidad.
        <br></br><br></br>
        </div>
        <br></br>
        <div className='text-mint fw-bold'>REQUISITOS PARA LOS USUARIOS Y CONSUMIDORES</div>
        <br></br>


        <div className='text-white'>
        El público en general, podrá contratar el servicio cuando reúna todos y cada uno de los siguientes requisitos:
        <br></br><br></br>

        Ser mayor de edad conforme a las leyes Españolas cuyo supuesto general es de 18 años.
        <br></br><br></br>

        Contar con una identificación oficial vigente que acredite la fecha de su nacimiento (credencial para votar, pasaporte, cartilla del servicio militar, cédula profesional, etc., expedida por una institución gubernamental oficial española).
        Dar lectura total y aceptar los presentes Términos y Condiciones. Se entenderán aceptados los presentes Términos y Condiciones por toda persona que ingrese a los Medios y/o obtenga una Cuenta, o sea beneficiario del servicio, ya sea Usuario o Consumidor.
        <br></br><br></br>
        Proporcionar a la Cuenta datos verídicos (nombre completo incluyendo apellidos, fecha de nacimiento, domicilio, dirección de correo electrónico y teléfono(s) de contacto), conforme le sean requeridos en los Medios. Igualmente deberá proporcionar cualesquiera otros datos que le sean requeridos por la empresa.
        Contar con un método de pago válido, que podrá ser: (i) tarjeta de crédito o débito Visa, Mastercard o American Express, mismos que serán procesados por el servicio denominado MercadoPago o en su caso el servicio denominado PayPal, servicios ya contratados por la empresa.
        </div>
        <br></br>

        <div className='text-mint fw-bold'>SERVICIO</div>
        <br></br>


        <div className='text-white'>
        El servicio “MIOBIO” es un servicio de entrega a domicilio de alimentos y productos de consumo.
El servicio es exclusivo en los estados y áreas de cobertura de la propia empresa.
Los horarios de servicio son establecidos por localidad de acuerdo a las legislaciones locales, y los mismos pueden cambiar sin previo aviso.
<br></br><br></br>

Los repartidores del servicio realizan la entrega a “pie de puerta” o en sucursal, dependiendo de la localidad, por lo que no están obligados a colocar el producto al interior del inmueble.
<br></br><br></br>

        </div>
        <br></br>
        <div className='text-mint fw-bold'>DEL PROCESAMIENTO DE PAGOS</div>
<br></br>

<div className='text-white'>
La empresa se apoya, para la prestación del servicio, de un procesador de pagos de tarjetas de débito y crédito a efecto de facilitar el cobro de los productos.
Dicho tercero es responsable de la información personal de carácter financiero que él solicita, por lo que por medio de estos Términos y Condiciones, los Usuarios y Consumidores se liberan de toda responsabilidad relacionada con el procesamiento de pagos de la empresa.
<br></br><br></br>

Al proporcionar en la página la información de tu tarjeta de crédito o débito el procesador de pagos obtiene el consentimiento del Usuario para el tratamiento de los datos personales que le hubiere solicitado.
<br></br><br></br>

La empresa recibe los pagos que se recaban a través de dicho tercero sin tener acceso directo a su información financiera.
<br></br><br></br>

Motivos para la negativa del servicio.
– La participación de cualquier menor de edad.<br></br>
– Proporcionar o afirmar cualquier dato que no sea cierto o pueda ser probado por el Usuario.<br></br>
– Porque el Usuario o Consumidor se niegue u omita la entrega de documentos solicitados por la empresa, como el documento de identificación oficial.<br></br>
– Cuando el área de entrega sea catalogada como zona de alto riesgo por el proveedor de paquetería.<br></br>
– Cuando el Consumidor o usuario que recibe el Producto se encuentre en estado de ebriedad o bajo los efectos de alguna sustancia psicotrópica y ello sea evidente.<br></br>
– Cuando el personal de la empresa sea agredido u ofendido de cualquier manera.<br></br>
– Cuando el Consumidor que recibe el pedido no pueda comprobar su mayoría de edad mediante una identificación oficial o se detecte irregularidades tales como; falsificación ostensible del documento de identidad, suplantación de personas o alteración de información previamente proporcionada.<br></br>
– Cuando exista alguna restricción para acceder al domicilio final del Consumidor, señalando de manera enunciativa, más no limitativa, los casos de vecindades o residenciales. <br></br>
</div>
<br></br>

<div className='text-mint fw-bold'>PROPIEDAD INTELECTUAL</div>
<br></br>


<div className='text-white'>El Usuario acepta y reconoce que los contenidos del Sitio WEB y la Aplicación incluyendo de manera enunciativa más no limitativa, textos, marcas, avisos comerciales, denominaciones, fotografías, diseños, logotipos, imágenes, sonidos, vídeos, animaciones, grabaciones, programas de cómputo, así como otros signos distintivos y, en general, cualquier creación intelectual disponible en los Medios, están debidamente registrados y protegidos por las leyes de Propiedad Intelectual e Industrial aplicables al efecto. En virtud de lo anterior se les prohíbe al Usuario y a cualquier persona que acceda a los Medios, su uso sin haber obtenido la licencia o autorización que corresponda conforme a la ley de la materia.</div>
<br></br>

<div className='text-mint fw-bold'>OTROS</div>
<br></br>


<div className='text-white'>Se entenderá que toda persona que, directa o indirectamente, solicite o reciba el servicio, ha conocido, entendido y aceptado íntegramente sujetarse y regirse conforme a los presentes Términos y Condiciones, renunciando expresamente a su derecho a deducir, promover, reclamar o ejercer cualquier acción en contra de la empresa relacionada con los supuestos en que su responsabilidad está limitada o excluida en virtud de estos Términos y Condiciones hasta el máximo permitido por la ley.

<br></br> <br></br>
Este servicio está sujeto a cambios ocasionados por virtud de las leyes federales, locales o de resolución por parte de autoridades administrativas o judiciales, sin previo aviso a los Usuarios o al público en general.
</div>
<br></br>

<div className='text-mint fw-bold'>USO DE “COOKIES”</div>
<br></br>


<div className='text-white'>
    El Sitio WEB podrá utilizar archivos temporales (“cookies”) para facilitar el acceso al Sitio WEB o la Aplicación. Asimismo el navegador o dispositivo que se utilice podrá generar cookies que permitan la operación de otras. Puede desactivarlas y/o eliminarlas mediante las opciones de privacidad de su navegador de Internet; en el entendido que ello podría afectar el uso del Sitio WEB o la Aplicación.
</div>
<br></br>

<div className='text-mint fw-bold'>CASOS EXTRAORDINARIOS – COVID-19</div>
<br></br>


<div className='text-white'>Derivado de la situación extraordinaria por la que está pasando el país a causa del COVID- 19, existe la posibilidad de no lograr cumplir con el tiempo de entrega previsto en los presentes Términos y Condiciones.
En caso de suspensión temporal de nuestro servicio o imposibilidad de entrega de los productos por disposición oficial, los pedidos activos que hubieran sido debidamente cobrados serán entregados en cuanto se regularice dicha situación y las medidas restrictivas sean levantadas.
</div>
<br></br>
<div className='text-mint fw-bold'>LÍMITE DE RESPONSABILIDAD</div>
<br></br>


<div className='text-white'>
Al aceptar los presentes Términos y Condiciones el Usuario acepta que en ningún caso la responsabilidad total de la empresa frente el Usuario, en caso de que la misma sea determinada por una autoridad judicial o administrativa competente, por cualquier clase de daños o pérdidas, podrá exceder los montos efectivamente pagados o pagaderos por el Usuario hacia la empresa correspondientes a la orden del servicio de la cual derive el reclamo.

</div>
<br></br>
<div className=' text-mint fw-bold'>
    

JURISDICCIÓN APLICABLE
</div>
<br></br>


<div className='text-white'>
Cualquier controversia suscitada por el servicio y sus complementarias, se regirán por los presentes Términos y Condiciones, y por las leyes vigentes Europeas, sujetándose a la jurisdicción y competencia de los Tribunales de España, renunciando a la competencia de los tribunales que por beneficio de su domicilio presente o futuro pudiera corresponderles a las partes.
**ÚLTIMA ACTUALIZACIÓN: 21 de diciembre 2022**
</div>
<br></br>

    </div>
  )
}
