/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import "antd/dist/antd.css";
import "../assets/css/headerSearch.css";
import "../assets/css/seals.css";
import { BiBell, BiBellOff, BiComment } from "react-icons/bi";
import profile from "../assets/images/generals/profilep.png";
import DataContext from "../context/dataContext";
import SearchForm from "./SearchForm";
import AuthContext from "../context/authContext";
import { colors } from "../utilities/colors";
import Renderif from "./Renderif";
import moment from "moment";
import { Service } from "../services/api";
import { Popover } from "antd";
import { HiDotsVertical } from "react-icons/hi";
import { MdLanguage } from "react-icons/md";
import { LANG } from "../pages/commun/lang";





function HeaderDashboard() {

	const {user, lang} = useContext(AuthContext)


	const [state, setState] = useState({
		showNotifications:false,
		notifications:[]
	})

	useEffect(()=>{
		getUserNotifications()
		return () => {}
	},[])

	const getUserNotifications = async () => {
        let _notifications = await Service('GET',`notification/${user.id_user}`)
        state.notifications = _notifications.data.response;
        setState({...state})
    }

 



	return (
		<>
			<div className="mb__header bg-dark-blue">
				<div className="d-flex h-100">
					<div className="col"></div>
					<div className="d-flex align-items-center px-4">
						<div className="px-3 cursor-pointer position-relative" onClick={()=>{
							setState({...state, showNotifications:!state.showNotifications})
						}}>
							<BiBell color={colors.mint} size={20} />
							<Renderif isTrue={state.showNotifications}>
								
									<Notifications data={state.notifications}></Notifications>
							
								
							</Renderif>
							
						</div>
						<div className="">
							<div className="f-14 f-Poppins-Bold text-white line-1">{user?.firstname}</div>
							{/* <div className="f-12 f-Poppins-Regular text-white">{user?.rol}</div> */}
							<div className="f-12 f-Poppins-Regular text-white">{LANG.PROFILE[user?.rol][lang]}</div>
						</div>
						<div className="ps-3  d-flex justify-content-center align-items-center">
							<MdLanguage color={colors.mint} size={18}></MdLanguage>
							<Popover
				
								zIndex={99}
								placement="bottomRight"
								content={<LangOptions></LangOptions>}
								trigger="click"
							>
								<div className="cursor-pointer text-white f-14 f-Poppins-Bold ps-1">{lang}</div>
							</Popover>
						</div>

						
					</div>
				</div>
			</div>
		</>
	);
}

export const LangOptions =() => {


	
const {changeLang} = useContext(AuthContext);


	


	return (
		<>
			<div className="UserMenuToolTip">

				<div className="item" onClick={() =>{changeLang({lang:'ES'})}}>
				ES
				</div>
				<div className="item" onClick={() =>{changeLang({lang:'EN'})}}>
				EN
				
				</div>
				<div className="item" onClick={() =>{changeLang({lang:'IT'})}}>
				IT
				</div>
			</div>
		</>
	);
}


function Notifications ({data}) {

	const {user} = useContext(AuthContext)


	const NotificationItem = ({notification}) => {
		
		return (
			<div className=" border-b-blue-shade px-3 py-3">

				<div className="d-flex ">
					<div className="f-12 f-Poppins-Bold text-yellow">{notification.firstname}. </div>
				
					<div className="col"></div>
					<div className="f-12 f-Poppins-Medium text-blue-shade">{moment(notification.notification_created_at).format('D MMM')}</div>
				</div>
				<div className="f-10 f-Poppins-Medium text-blue-shade">{notification.rol}</div>
				
				<div className="f-12 f-Poppins-Medium text-light-gray">{notification.notification} {user.firstname}</div>

			</div>
		)
	}

	return (
		<div style={{top:10, right:10, marginTop:'50%', width:320, zIndex:1, borderBottom:'none'}} className=" border-blue-shade bg-dark-blue rounded-3  position-absolute overflow-auto">
				<Renderif isTrue={data?.length === 0}>
					<div className="d-flex justify-content-center align-items-center border-b-blue-shade x-3 py-2">
						<BiBellOff color={colors.mint} size={14} />
						<div className="f-12 f-Poppins-Bold de text-mint px-2">Sin Notificaciones.</div>
					</div>
				</Renderif>
				{data?.map((notification,i)=>{
					return (<NotificationItem notification={notification} key={i}></NotificationItem>)
				})}
			

		</div>
	)
}

export default HeaderDashboard;
