/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import "antd/dist/antd.css";
import "../../assets/css/seals.css";
import HeaderDashboard from "../../components/HeaderDashboard";
import {
	useLocation,
	useNavigate,
	useParams,
} from "react-router-dom";
import { IoMdClipboard, IoMdCopy, IoMdNutrition } from "react-icons/io";
import BackButton from "../../components/buttons/BackButton";
import { colors } from "../../utilities/colors";
import { BsCalendar2 } from "react-icons/bs";
import { Service } from "../../services/api";
import { BiClipboard, BiUser } from "react-icons/bi";
import AuthContext from "../../context/authContext";
import moment from "moment";
import { AiOutlinePaperClip } from "react-icons/ai";
import AlertContext from "../../context/alertContext";
import CheckBox from "../../components/buttons/checkbox";
import { Switch } from 'antd';
import { LANG } from "../commun/lang";
import Renderif from "../../components/Renderif";


function AthletesPlanCocina() {


	const navigate = useNavigate();
	const {id_plan} = useParams('id_plan')
	const {id_kitchen} = useParams('id_kitchen')


	const {user, lang} = useContext(AuthContext)
	const {showAlert} = useContext(AlertContext)
	const [state, setState] = useState({
		weekplan: [],
		athletes: [],
		nutricionist:null
	});

	useEffect(() => {
	
		const data = async () => {


			let _weekplan = await Service(
				"GET",
				`weeklyPlans/${id_kitchen}/${id_plan}`
			);
			

			let _athletes = await Service(
				"GET",
				`athletes/kitchen/${id_kitchen}`
			);
			let athletes = _athletes.data.response
			console.log(athletes)

			let _nutricionist = null
			if(user?.id_rol === 2){
				_nutricionist = await Service('GET', `nutricionist/${user.id_user}`);
			}
			console.log(_nutricionist)

		
			if(user.id_rol === 2){
				
				let _nutricionist = await Service('GET', `nutricionist/${user.id_user}`);
				athletes = athletes.filter((athlete)=>athlete.id_nutricionist === _nutricionist.data.response[0].id_nutricionist || athlete.id_nutricionist_owner === user.id_user)
			}

	
			setState({
				...state,
				weekplan: _weekplan.data.response,
				nutricionist: _nutricionist !== null ?  _nutricionist.data.response[0] : null,
				athletes
			});
		};
		data();
		return () => {};
	}, []);

	return (
		<>
			<HeaderDashboard />
	
			<div className="container-fluid p-3">
				<div className="d-flex flex-wrap align-content-center">
						<div className="col-12 col-lg">
							<BackButton route="/plan-detalle" />
							<div className="text-white f-30 f-anton text-uppercase ps-1">
								{LANG.DASHBOARD.ATHLETES[lang]} - {(state.weekplan?.kitchen)?.toUpperCase()}
							</div>
							
							<div className="d-flex align-items-center ps-1">
								<BsCalendar2 color={colors.yellow}/>
								<div className="f-14 text-yellow f-Poppins-Bold px-2">{`${moment(state.weekplan?.start_date).format('D MMM')} - ${moment(state.weekplan?.end_date).format('D MMM')}`}</div>
								<span className="bg-green-shade text-mint-shade f-12 f-Poppins-Medium rounded-5 px-2 mx-1">
									{state.athletes?.length} {LANG.DASHBOARD.ATHLETES[lang]}
								</span>
							</div>
							
						</div>
				</div>
			</div>

			<div className="container-fluid px-3 overflow-auto hide-scroll mt-3 ">
					<table className="ant-table w-100 bg-dark-blue rounded-2 overflow-hidden">
						<thead className="ant-table-thead">
							<tr>
								
								<th className="col"><div className="text-left f-anton f-14">{LANG.DASHBOARD.ATHLETES[lang]}</div></th>
								<th className="col"><div className="text-left f-anton f-14">{LANG.PLANDETAIL.tabla_nutricional[lang]}</div></th>
								{state.nutricionist?.id_suscription_nutricionist===2? 
								<th className="col"><div className="text-left f-anton f-14">{LANG.PLANDETAIL.enlace[lang]}</div></th>
								:<></>}
								<th className="col">
								{user.id_rol === 2 && state.nutricionist?.id_suscription_nutricionist===2 ? 
								<div className="text-left f-anton f-14">{LANG.PLANDETAIL.asignar[lang]}</div>
								:<></>}
								</th>
								
								
							</tr>
						</thead>
						<tbody className="ant-table-tbody">
								{state.athletes.map((item,i)=>{

									
									
									return (
										<tr className="" key={i}>
											
											<td className="">
												<div className="d-flex align-items-start">
													<BiUser className="pt-1" color={colors.mint} size={18}></BiUser>
													<div className="ps-1">
														<div className="text-left text-white f-14 f-Poppins-Medium">{`${item?.firstname} ${item?.lastname}`}</div>
														<div className="text-left f-12 f-Poppins-Bold text-mint-shade">{LANG.NOT[item?.fedding][lang]}</div>
													</div>
												</div>
											</td>
											<td>
												{/* ADMIN */}
												<Renderif isTrue={user?.id_rol === 1 && item.link === null}>

													<div className="cta cta-outline" onClick={(e)=>{
														e.stopPropagation();
														navigate(`/tabla-nutricional/${item.id_user}/${id_plan}`)
														
													}}>
														<div className="icon">
															
															<IoMdNutrition color={colors.white} size={16}></IoMdNutrition>
														</div>
														<div className="text-white f-12 f-anton line-1">{LANG.PLANDETAIL.ver_tabla_nutricional[lang]}</div>
													</div>

												</Renderif>

												{/* NUTRICIONISTA */}

												<Renderif isTrue={user?.id_rol === 2 && state.nutricionist?.id_suscription_nutricionist===1 }>

													<div className="cta cta-outline" onClick={(e)=>{
														e.stopPropagation();
														navigate(`/tabla-nutricional/${item.id_user}/${id_plan}`)
														
													}}>
														<div className="icon">
															
															<IoMdNutrition color={colors.white} size={16}></IoMdNutrition>
														</div>
														<div className="text-white f-12 f-anton line-1">{LANG.PLANDETAIL.ver_tabla_nutricional[lang]}</div>
													</div>

												</Renderif>

												{/* NUTRICIONISTA PRO */}

												<Renderif isTrue={JSON.parse(item?.link)?.includes(parseInt(id_plan)) && item.link !== null }>

													<div className="cta cta-outline" onClick={(e)=>{
														e.stopPropagation();
														navigate(`/tabla-nutricional/${item.id_user}/${id_plan}`)
														
													}}>
														<div className="icon">
															
															<IoMdNutrition color={colors.white} size={16}></IoMdNutrition>
														</div>
														<div className="text-white f-12 f-anton line-1">{LANG.PLANDETAIL.ver_tabla_nutricional[lang]}</div>
													</div>

												</Renderif>
											

											</td>
											{state.nutricionist?.id_suscription_nutricionist===2? 
											<td>

											
												
												{item.link === null || !JSON.parse(item.link).includes(parseInt(id_plan)) ? <></> :
													<div className="cta cta-outline" onClick={(e)=>{
														e.stopPropagation();
														navigator.clipboard.writeText(`https://stage.d1qxgc2kg63igj.amplifyapp.com/plan-semanal-deportista/${id_plan}/4/0/${item.id_athlete}`);
														showAlert({type:'success', message:LANG.NOT.enlace[lang], description:LANG.NOT.enlace_copiado[lang]})
													}}>
														<div className="icon">
															<AiOutlinePaperClip color={colors.white} size={16}></AiOutlinePaperClip>
														</div>
														<div className="text-white f-12 f-anton line-1">{LANG.NOT.enlace[lang]}</div>
													</div>
												}

											</td>
											:<></>}

											<td className="" width={100}>

											{ user.id_rol === 2 && state.nutricionist?.id_suscription_nutricionist===2 ? 

												<Switch color="red" checked={item.link === null || !JSON.parse(item.link).includes(parseInt(id_plan)) ? false : true} onChange={async (value)=>{

													console.log(item.link)

													let planes = []
													
													if(item.link === null){
														planes.push(parseInt(id_plan))
													}else{
														planes = JSON.parse(item.link)
													
														if(value){
															planes.push(parseInt(id_plan))
														}else{
															console.log(planes)
															let aux = planes.filter((plan) => plan !== parseInt(id_plan))
															planes = aux;
														}
														
													}
													


													
													let _athletes = state.athletes.map((athlete) => {

														if(athlete.id_athlete === item.id_athlete){
															console.log(planes)
															athlete.link = JSON.stringify(planes);
														}
														return athlete;
													
													})
			

													let _athlete = await Service('PUT', 'athlete/update', {link:JSON.stringify(planes), id_athlete:item.id_athlete});
													console.log(_athlete)
        										
													
													
													setState({...state, athletes:_athletes})
												}} />
											:<></>}

											</td>
											
											
										</tr>
									)

								})}
						</tbody>
					</table>
			</div>

		</>
	);
}

export default AthletesPlanCocina;
