import { Button, notification, Space } from "antd";
import { BiCheck } from "react-icons/bi";
import { BsCheckCircleFill, BsClockFill, BsInfoCircleFill } from "react-icons/bs";
import { IoIosWarning } from "react-icons/io";
import { MdClose, MdOutlineError } from "react-icons/md";
import AlertContext from "../context/alertContext";
import { colors } from "../utilities/colors";

export const AlertState = ({children}) => {
    const [_Alert, AlerttHolder] = notification.useNotification();
  
    
    // _Alert.open({
    //     style:{background:colors.mint_shade},
    //     duration:5, 
    //     closeIcon: <MdClose color={colors.mint} />,
    //     message: <div className='f-14 text-mint f-Poppins-Bold'>This is the content.</div>,
    //     description: (<div className='f-14 text-white f-Poppins-Regular'>This is the content of the notification. This is the content of the notification. This is the content of the notification.</div>),
    //     icon: <BiCheck color={colors.mint} />,
    //   });
    // };



    const showAlert = ({placement = 'topRight', type, duration = 4, title, message="Notificacion", description = "This is the content of the notification.", onConfirm = () => {} }) => {
      const btn = type === 'confirmation' ?  (
        <Space>
          <Button type="danger" size="small" onClick={()=>{
            onConfirm();
            notification.destroy();
          }}>
            <div className="text-white f-Poppins-Regular px-2">Eliminar</div>
          </Button>
        </Space>
      ) : (<></>);
      _Alert.open({
        placement,
        duration: type !== 'confirmation' ? duration : 0,
        message: <div className='f-14 text-mint-shade f-Poppins-Bold'>{message}</div>,
        description: (<div className='f-12 text-mint-shade f-Poppins-Regular'>{description}</div>),
        btn,
        closeIcon: <MdClose color={colors.mint_shade} size={22}/>,
        icon: alertIcon({type}),
        style:{background:colors.mint_light},
      });
    
    }

    const alertIcon = ({type}) => {
      switch (type) {
        case 'success':
          return <BsCheckCircleFill color={colors.mint} size={22}></BsCheckCircleFill>
        case 'error':
            return <MdOutlineError color={'red'} size={22}></MdOutlineError>
        case 'info':
          return <BsInfoCircleFill color={colors.blue_shade} size={22}></BsInfoCircleFill>
        case 'warning':
          
          return <IoIosWarning color={colors.yellow} size={22}></IoIosWarning>
        case 'confirmation':
          return <BsInfoCircleFill color={colors.blue_shade} size={22}></BsInfoCircleFill>
        default:
          return <></>
      }
    }




      // _Alert.open({
      //   message: `Notification ${placement}`,
      //   description: "sad",
      //   placement,
      // });
    

  
  
    return (
      <AlertContext.Provider value={{showAlert}}>
        {AlerttHolder}
        {children}
      </AlertContext.Provider>
    );
  };