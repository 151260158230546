/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from "../actions/dataActions";

export const dataInitialState = {
	data: [],
	data_revisions: [],
	data_recipes: [],
	data_chefs: [],
	data_nutricionist: [],
	data_kitchens: [],
	data_weekly_recipes: [],
	data_athletes: [],
};

export function dataReducer(state, action) {
	const { payload, type } = action;
	switch (type) {
		case TYPES.GET_DATA:
			return {
				...state,
				data: payload,
			};

		case TYPES.GET_DATA_RECIPES:
			return {
				...state,
				data_recipes: payload,
			};

		case TYPES.GET_DATA_CHEFS:
			return {
				...state,
				data_chefs: payload,
			};
		case TYPES.GET_DATA_NUTRICIONIST:
			return {
				...state,
				data_nutricionist: payload,
			};
		case TYPES.GET_DATA_KITCHENS:
			return {
				...state,
				data_kitchens: payload,
			};
		case TYPES.GET_DATA_WEEKLY_RECIPES:
			return {
				...state,
				data_weekly_recipes: payload,
			};
		case TYPES.POST_DATA:
			return {
				...state,
			};
		case TYPES.PUT_DATA:
			return {
				...state,
				data_athletes: payload,
			};
		case TYPES.GET_REVISION_BY_SEAL_ID:
			return {
				...state,
				data_revisions: payload,
			};
		default:
	}
}
