/* eslint-disable no-unused-vars */
import React, { useEffect, useContext } from "react";
import "../../assets/css/dashboard.css";
import HeaderDashboard from "../../components/HeaderDashboard";
import PlanContext from "../../context/planContext";
import WeeklyTabs from "../WeeklyPlan/WeeklyTabs";
import DashboardWidget from "../../components/DashboardWidget";
import { useNavigate } from "react-router-dom";
import AdminContext from "../../context/adminContext";
import AuthContext from "../../context/authContext";
import { LANG } from "../commun/lang";

function Dashboard() {
	const {weekplans, kitchen} = useContext(PlanContext)
	const {deportistas, cocinas, nutricionistas} = useContext(AdminContext);
	const {lang} = useContext(AuthContext);
	const navigate = useNavigate()

	return (
		<>
			<HeaderDashboard />
			<div className="container-fluid p-3">
				<div className="text-white f-30 f-anton text-uppercase ps-1">
					Dashboard
				</div>
			</div>

			<div className="container-fluid px-3 pb-3">
				<div className="d-flex flex-wrap">

					<div className="col-12 col-md-3 px-0 py-1 py-md-0 pe-md-2">
						<DashboardWidget data={{
							title:LANG.DASHBOARD.ATHLETES[lang],
							img:'../assets/images/icon_deportistas.png',
							value:deportistas.length,
							action:()=>{navigate('/users/deportistas')}
						}}/>
					</div>
					<div className="col-12 col-md-3 px-0 py-1 py-md-0 pe-md-2">
						<DashboardWidget data={{
							title:LANG.DASHBOARD.KITCHENS[lang],
							img:'../assets/images/icon_cocina.png',
							value:cocinas.length,
							action:()=>{navigate('/users/cocinas')}
						}}/>
					</div>
					<div className="col-12 col-md-3 px-0 py-1 py-md-0 pe-md-2">
						<DashboardWidget data={{
							title:LANG.DASHBOARD.CHEFS[lang],
							img:'../assets/images/icon_chef.png',
							value:1,
						}}/>
					</div>
					<div className="col-12 col-md-3 px-0 py-1 py-md-0">
						<DashboardWidget data={{
							title:LANG.DASHBOARD.NUTRICIONISTS[lang],
							img:'../assets/images/icon_nutricionistas.png',
							value:nutricionistas.length,
							action:()=>{navigate('/users/nutricionistas')}
						}}/>
					</div>
				</div>
			</div>
			
			<WeeklyTabs weekplans={weekplans} kitchen={kitchen} />
		</>
	);
}

export default Dashboard;
