/* eslint-disable no-unused-vars */
import React, {useState, useContext, useEffect } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import HeaderDashboard from "../../components/HeaderDashboard";
import GeneralButton from "../../components/buttons/GeneralButton";
import { DatePicker, Select } from "antd";

import PlanContext from "../../context/planContext";
import Renderif from "../../components/Renderif";
import WeeklyTabs from "../WeeklyPlan/WeeklyTabs";
import AuthContext from "../../context/authContext";
import { LANG } from "../commun/lang";
import { Service } from "../../services/api";



const { Option } = Select;

function PlanSemanalNutricionist() {


	const {weekplans, kitchen, kitchens, onSelectKitchen, onCreateWeeklyPlan} = useContext(PlanContext)
	const {user, lang} = useContext(AuthContext);


	const [state, setState] = useState({
		start_date:'', 
		end_date:'',
		nutricionist:null,
		id_suscription:null,
	})

	useEffect(() => {
	
		const data = async () => {
			
			let _nutricionist
			if(user?.id_rol === 2){
				_nutricionist = await Service('GET', `nutricionist/${user.id_user}`);
				console.log(_nutricionist)
				setState({
					...state,
					nutricionist:_nutricionist?.data?.response[0],
					id_suscription:_nutricionist?.data?.response[0].id_suscription_nutricionist,
				});
			}
			
		};
		data();
		return () => {};
	}, [user]);

	
	return (
		<> 
				<HeaderDashboard />
				<div className="container-fluid p-3">
					<div className="text-white f-30 f-anton text-uppercase ps-1">
						{LANG.WEEKPLAN.WEEKPLAN[lang]}
					</div>
				</div>

			
					
				<div className="container-fluid px-3 pb-3 d-flex align-items-center">
					<Renderif isTrue={user.id_rol !== 2}>
						<div className="d-flex align-items-center px-1">
							<Select
								key="cocinas"
								value={kitchen}
								style={{ width: 200 }}
								onChange={onSelectKitchen}
							>
								<Option value="0">Todas las cocinas</Option>
								{kitchens.map((kitchen,i)=>(<Option key={i + 1} value={kitchen.id_kitchen}>{kitchen.kitchen}</Option>))}
						
							</Select>
						</div>
					</Renderif>

					<Renderif isTrue={kitchen !== '0' || (user.id_rol == 2 && state.id_suscription === 2)}>
						<div className="col d-flex align-items-center px-1">

							<div className="px-2">
								<DatePicker placeholder={LANG.NOT.fecha_inicio[lang]} format={'YYYY/MM/DD'} onChange={(date)=>{state.start_date = date.format('YYYY-MM-DD')}}></DatePicker>
							</div>
						
							<div className="px-2">
								<DatePicker placeholder={LANG.NOT.fecha_fin[lang]}  format={'YYYY/MM/DD'} onChange={(date)=>{state.end_date = date.format('YYYY-MM-DD')}}></DatePicker>
							</div>
						
							
							<div className="col"></div>
							<GeneralButton
								className="btn__blue btn__general"
								title={LANG.NOT.crear_plan_semana[lang]}
								icon={<AiOutlinePlus />}
								onClick={() =>{

									onCreateWeeklyPlan({start_date:state.start_date, end_date:state.end_date})
									
									
								}}
							/>
						</div>
					</Renderif>
				</div>

				<WeeklyTabs weekplans={weekplans} kitchen={kitchen} nutricionist={state.nutricionist} id_suscription={state.id_suscription} />
		
		</>
	);
}





export default PlanSemanalNutricionist;
