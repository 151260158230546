/* eslint-disable no-unused-vars */

import React, { useReducer, useState } from "react";
import { dataReducer, dataInitialState } from "../reducers/dataReducer";
import DataContext from "../context/dataContext";
import { urlService } from "../services/UrlService";
import { statusMessage } from "../services/statusMessage";

import axios from "axios";

const DataState = (props) => {
	const [state, dispatch] = useReducer(dataReducer, dataInitialState);
	const [mstate, setMstate] = useState(false);
	const [sealCreateModal, SetSealCreateModal] = useState(false);
	const [userCreateModal, SetUserCreateModal] = useState(false);
	const [sealDetailsModal, SetSealDetailModal] = useState(false);
	const [exerciseDetailsModal, SetExerciseDetailModal] = useState(false);
	const [RevisionDetailsModal, SetRevisionDetailModal] = useState(false);
	const [selectDefault, SetSelectDefault] = useState(null);
	const [modalMessage, SetModalMessage] = useState(statusMessage[0]);
	const [displaySiderBar, setDisplaySiderBar] = useState(false);
	const [updateState, setUptadeState] = useState(false);
	const [messageModal, setMessageModal] = useState(false);
	const [modalDeleteUser, setModalDeleteUser] = useState(false);
	const [drawerSpecialist, setDrawerSpecialist] = useState(false);
	const [drawerAddGuest, setDrawerAddGuest] = useState(false);
	const [modalSendKitchen, setModalSendKitchen] = useState(false);
	const [drawerRecipe, setDrawerRecipe] = useState(false);
	const [drawerComments, setDrawerComments] = useState(false);
	const [idPlan, setIDPlan] = useState(0);

	const [idDeleteUser, setIdDeleteUser] = useState();
	const [messageModalInfo, setMessageModalInfo] = useState("Mensaje");
	const [weeklyPlanData, setWeeklyPlanData] = useState([]);
	const [quantityRecipes, SetQuantityRecipes] = useState([]);

	const RecipeAlgorithm = [
		{
			dia: "1",
			nombre: "Lunes",
			comidaPrimero: "",
			img_comidaPrimero: "",
			comidaSegundo: "",
			img_comidaSegundo: "",
			cenaPrimero: "",
			img_cenaPrimero: "",
			cenaSegundo: "",
			img_cenaSegundo: "",
		},
		{
			dia: "2",
			nombre: "Martes",
			comidaPrimero: "",
			img_comidaPrimero: "",
			comidaSegundo: "",
			img_comidaSegundo: "",
			cenaPrimero: "",
			img_cenaPrimero: "",
			cenaSegundo: "",
			img_cenaSegundo: "",
		},
		{
			dia: "3",
			nombre: "Miércoles",
			comidaPrimero: "",
			img_comidaPrimero: "",
			comidaSegundo: "",
			img_comidaSegundo: "",
			cenaPrimero: "",
			img_cenaPrimero: "",
			cenaSegundo: "",
			img_cenaSegundo: "",
		},
		{
			dia: "4",
			nombre: "Jueves",
			comidaPrimero: "",
			img_comidaPrimero: "",
			comidaSegundo: "",
			img_comidaSegundo: "",
			cenaPrimero: "",
			img_cenaPrimero: "",
			cenaSegundo: "",
			img_cenaSegundo: "",
		},
		{
			dia: "5",
			nombre: "Viernes",
			comidaPrimero: "",
			img_comidaPrimero: "",
			comidaSegundo: "",
			img_comidaSegundo: "",
			cenaPrimero: "",
			img_cenaPrimero: "",
			cenaSegundo: "",
			img_cenaSegundo: "",
		},
		{
			dia: "6",
			nombre: "Sábado",
			comidaPrimero: "",
			img_comidaPrimero: "",
			comidaSegundo: "",
			img_comidaSegundo: "",
			cenaPrimero: "",
			img_cenaPrimero: "",
			cenaSegundo: "",
			img_cenaSegundo: "",
		},
	];

	const [DataRecipeAlgorithm, setDataRecipeAlgorithm] =
		useState(RecipeAlgorithm);

	/* --------------------------------------------------------
    /* ---- GET
    -------------------------------------------------------- */

	const getData = async (endpoint) => {
		let hasError = false;
		const response = await axios
			.get(`${urlService}/${endpoint}`)
			.catch(function (error) {
				if (error) {
					hasError = true;
					catchError(error.toJSON());
					dispatch({
						type: "GET_DATA",
						payload: [],
					});
				}
			});

		if (!hasError) {
			let info = response.data.response;
			info.map((k) => {
				k["key"] = k._id;
				return true;
			});

			dispatch({
				type: "GET_DATA",
				payload: info,
			});
		}
	};

	/* --------------------------------------------------------
    /* ---- GET DATA CHEFS
    -------------------------------------------------------- */

	const getDataChefs = async (endpoint) => {
		let hasError = false;
		const response = await axios
			.get(`${urlService}/${endpoint}`)
			.catch(function (error) {
				if (error) {
					hasError = true;
					catchError(error.toJSON());
					dispatch({
						type: "GET_DATA_CHEFS",
						payload: [],
					});
				}
			});

		if (!hasError) {
			

			let info = response.data.response;
			info.map((k) => {
				k["key"] = k._id;
				return true;
			});

			dispatch({
				type: "GET_DATA_CHEFS",
				payload: info,
			});

			return info;
		}
	};

	/* --------------------------------------------------------
    /* ---- GET DATA CHEFS
    -------------------------------------------------------- */

	const getDataNutricionist = async (endpoint) => {
		let hasError = false;
		const response = await axios
			.get(`${urlService}/${endpoint}`)
			.catch(function (error) {
				if (error) {
					hasError = true;
					catchError(error.toJSON());
					dispatch({
						type: "GET_DATA_NUTRICIONIST",
						payload: [],
					});
				}
			});

		if (!hasError) {
			

			let info = response.data.response;
			info.map((k) => {
				k["key"] = k._id;
				return true;
			});

			dispatch({
				type: "GET_DATA_NUTRICIONIST",
				payload: info,
			});
			return info;
		}
	};

	/* --------------------------------------------------------
    /* ---- GET DATA CHEFS
    -------------------------------------------------------- */

	const getDataKitchens = async (endpoint) => {
		let hasError = false;
		const response = await axios
			.get(`${urlService}/${endpoint}`)
			.catch(function (error) {
				if (error) {
					hasError = true;
					catchError(error.toJSON());
					dispatch({
						type: "GET_DATA_KITCHENS",
						payload: [],
					});
				}
			});

		if (!hasError) {
			

			let info = response.data.response;
			info.map((k) => {
				k["key"] = k._id;
				return true;
			});

			dispatch({
				type: "GET_DATA_KITCHENS",
				payload: info,
			});

			return info;
		}
	};

	/* --------------------------------------------------------
    /* ---- GET DATA RECIPES
    -------------------------------------------------------- */

	const getDataRecipes = async (endpoint) => {
		let hasError = false;
		const response = await axios
			.get(`${urlService}/${endpoint}`)
			.catch(function (error) {
				if (error) {
					hasError = true;
					catchError(error.toJSON());
				}
			});

		if (!hasError) {
			//

			let info = response.data.response;
			info.map((k) => {
				k["key"] = k._id;
				return true;
			});

			dispatch({
				type: "GET_DATA_RECIPES",
				payload: info,
			});
		}
	};

	/* --------------------------------------------------------
    /* ---- GET DATA WEEKLY RECIPES
    -------------------------------------------------------- */

	const getDataWeeklyRecipes = async (endpoint) => {
		let hasError = false;
		const response = await axios
			.get(`${urlService}/${endpoint}`)
			.catch(function (error) {
				if (error) {
					hasError = true;
					catchError(error.toJSON());
				}
			});

		if (!hasError) {
			//

			let info = response.data.response;
			info.map((k) => {
				k["key"] = k._id;
				return true;
			});

			dispatch({
				type: "GET_DATA_WEEKLY_RECIPES",
				payload: info,
			});
		}
	};

	/* --------------------------------------------------------
    /* ---- GET SEAL DETAILS BY ID 
    -------------------------------------------------------- */

	const getRevisionsBySealID = async (endpoint) => {
		let hasError = false;
		const response = await axios
			.get(`${urlService}/${endpoint}`)
			.catch(function (error) {
				if (error) {
					hasError = true;
					catchError(error.toJSON());
				}
			});

		if (!hasError) {
			let info = response.data.response;
			

			info.map((k) => {
				k["key"] = k._id;
				return true;
			});

			dispatch({
				type: "GET_REVISION_BY_SEAL_ID",
				payload: info,
			});
			
			return info;
		}
	};

	const DisplayModalCreate = async (type) => {
		switch (type) {
			case "seals":
				SetSealCreateModal(true);
				setUptadeState(!updateState);
				
				return true;
			case "status":
				setUptadeState(!updateState);
				
				return true;

			default:
				return false;
		}
	};

	/* --------------------------------------------------------
    /* ---- POST
    -------------------------------------------------------- */

	const postData = async (endpoint, jsonData) => {
		let hasError = false;
		let errorInfo;
		const response = await axios
			.post(`${urlService}/${endpoint}`, jsonData)
			.catch(function (error) {
				errorInfo = error.response.data;
				
				hasError = true;
				catchError(error.toJSON());
			});

		if (!hasError) {
			let info = response;
			SetModalMessage(statusMessage[0]);
			setMstate(true);
			dispatch({
				type: "POST_DATA",
				payload: info,
			});
			return info;
		} else {
			return errorInfo;
		}
	};

	const putData = async (endpoint, jsonData) => {
		let hasError = false;
		let errorInfo;
		const response = await axios
			.put(`${urlService}/${endpoint}`, jsonData)
			.catch(function (error) {
				errorInfo = error.response.data;
				
				hasError = true;
				catchError(error.toJSON());
			});

		if (!hasError) {
			let info = response;
			SetModalMessage(statusMessage[0]);
			setMstate(true);
			dispatch({
				type: "PUT_DATA",
				payload: info,
			});
			return info;
		} else {
			return errorInfo;
		}
	};

	/* --------------------------------------------------------
    /* ---- ON SUCCESS MODAL
    -------------------------------------------------------- */

	const showSuccessModal = (val) => {
		setMstate(val);
	};

	/* --------------------------------------------------------
    /* ---- USER TOKEN GENERATO
    -------------------------------------------------------- */

	const rand = () => {
		return Math.random().toString(36).substr(2);
	};

	const UserToken = () => {
		
		return rand() + rand();
	};

	/* --------------------------------------------------------
    /* ---- SWITCH: ERROR
    -------------------------------------------------------- */

	const catchError = async (info) => {
		switch (info.status) {
			case 500:
				SetModalMessage(statusMessage[1]);
				setMstate(true);
				return true;
			case 404:
				SetModalMessage(statusMessage[2]);
				setMstate(true);
				return true;
			case 403:
				SetModalMessage(statusMessage[3]);
				setMstate(true);
				return true;

			default:
				return false;
		}
	};

	return (
		<DataContext.Provider
			value={{
				data: state.data,
				data_athletes: state.data_athletes,
				data_chefs: state.data_chefs,
				data_nutricionist: state.data_nutricionist,
				data_kitchens: state.data_kitchens,
				getData,
				data_recipes: state.data_recipes,
				getDataRecipes,
				data_weekly_recipes: state.data_weekly_recipes,
				getDataWeeklyRecipes,
				postData,
				putData,
				sealCreateModal,
				SetSealCreateModal,
				userCreateModal,
				SetUserCreateModal,
				DisplayModalCreate,
				displaySiderBar,
				setDisplaySiderBar,
				setUptadeState,
				updateState,
				DataRecipeAlgorithm,
				setDataRecipeAlgorithm,
				sealDetailsModal,
				SetSealDetailModal,
				RevisionDetailsModal,
				SetRevisionDetailModal,
				getRevisionsBySealID,
				exerciseDetailsModal,
				SetExerciseDetailModal,
				RecipeAlgorithm,
				messageModal,
				setMessageModal,
				messageModalInfo,
				setMessageModalInfo,
				modalDeleteUser,
				setModalDeleteUser,
				idDeleteUser,
				setIdDeleteUser,
				drawerSpecialist,
				setDrawerSpecialist,
				getDataChefs,
				getDataNutricionist,
				getDataKitchens,
				drawerAddGuest,
				setDrawerAddGuest,
				modalSendKitchen,
				setModalSendKitchen,
				weeklyPlanData,
				setWeeklyPlanData,
				UserToken,
				drawerRecipe,
				setDrawerRecipe,
				drawerComments,
				setDrawerComments,
				idPlan,
				setIDPlan,
				quantityRecipes,
				SetQuantityRecipes,
			}}
		>
			{props.children}
		</DataContext.Provider>
	);
};

export default DataState;
