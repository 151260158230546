import React, { useContext } from 'react'
import HeaderDashboard from '../../components/HeaderDashboard'
import {FaCcPaypal} from 'react-icons/fa'
import { colors } from '../../utilities/colors'
import { LANG } from '../commun/lang'
import AuthContext from '../../context/authContext'


export default function PlanDeportista() {
    
    const {user, lang} = useContext(AuthContext)
  return (
    <>
        <HeaderDashboard />
        <div className="mb__center_content">
            
            <div className="section_title text-white mb__section_title text-uppercase">
                MI PLAN
            </div>


            <div className='mb__center_content'>
                  <div className='text-white bg-dark-blue rounded'>

                <div className='border-b-blue-shade p-3'>
                    <div className='f-16 f-Poppins-Bold'>{user?.firstname}</div>
                    <div className='f-14 f-Poppins-Regular text-mint-shade'>Plan Elite - {LANG.NOT.mensual[lang]}</div>
                </div>

              
                <div className='border-b-blue-shade d-flex p-3'>
                    <div className=' col f-Poppins-Medium text-white'>{LANG.REGISTER.metodo_pago[lang]}</div>
                    <div className=' col f-Poppins-Medium text-white'>Stripe</div>
                </div>
                <div className='border-b-blue-shade d-flex p-3'>
                    <div className=' col f-Poppins-Medium text-white'>{LANG.NOT.fecha_renovacion[lang]}</div>
                    <div className=' col f-Poppins-Medium text-white'>06/10/2022</div>
                </div>
                <div className='border-b-blue-shade d-flex p-3'>
                    <div className=' col f-Poppins-Medium text-white'>{LANG.NOT.pago_recurrente[lang]}</div>
                    <div className=' col f-Poppins-Medium text-white'>
                        <FaCcPaypal color={colors.white} size={30}></FaCcPaypal>
                    </div>
                </div>
                <div className='d-flex p-3'>
                    <div className=' col f-Poppins-Medium text-white'>{LANG.NOT.cancelar_plan[lang]}</div>
                    <div className=' col f-Poppins-Medium text-white'>

                        <div className='d-table'>
                         <input type="submit" className='input input-outline px-3' value={LANG.NOT.cancelar_plan[lang]} />
                        </div>
                    
                    </div>
                </div>
           

            </div>

            </div>
          
            {/* <div className="section_title text-white mb__section_title text-uppercase mt-5">
                MI COMPRAS
            </div> */}

            <div className='mb__center_content d-none'>
                <div className='text-white bg-dark-blue rounded'>
                    <div className='border-b-blue-shade d-flex p-3'>
                        <div className='col f-14 f-Poppins-Medium text-white'>Plan Pro</div>
                        <div className='col f-14 f-Poppins-Medium text-white'>06/12/2022</div>
                        <div className='col f-14 f-Poppins-Medium text-white'>Є100</div>
                    </div>

                    <div className='d-flex p-3'>
                        <div className='col f-14 f-Poppins-Medium text-white'>Plan Elite</div>
                        <div className='col f-14 f-Poppins-Medium text-white'>06/12/2022</div>
                        <div className='col f-14 f-Poppins-Medium text-white'>Є50</div>
                    </div>
                </div>
            </div>
          

        
        </div>
    </>
  )
}
