/* eslint-disable no-unused-vars */
import "../../assets/css/dashboard.css";
import HeaderDashboard from "../../components/HeaderDashboard";
import {
	PlanSemanal
} from "./PlanSemanalCocina";

import DashboardWidget from '../../components/DashboardWidget'
import { useContext } from "react";
import CocinaContext from "../../context/cocinaContext";
import { useNavigate } from "react-router-dom";
import { LANG } from "../commun/lang";
import AuthContext from "../../context/authContext";



function DashboardCocina() {

	const navigate = useNavigate()

	const {athletes} = useContext(CocinaContext);
	const {lang} = useContext(AuthContext)
	
	return (
		<>
			<HeaderDashboard />

			<div className="container-fluid p-3">
				<div className="text-white f-30 f-anton text-uppercase ps-1">
					Dashboard
				</div>
			</div>

			<div className="container-fluid px-3 pb-3">
				<div className="d-flex flex-wrap">

					<div className="col-12 col-md-4 px-0 py-1 py-md-0 pe-md-2">
						<DashboardWidget data={{
							title:LANG.DASHBOARD.ATHLETES[lang],
							img:'../assets/images/icon_deportistas_2.png',
							value:athletes.length,
						}}/>
					</div>
					<div className="col-12 col-md-4 px-0 py-1 py-md-0 pe-md-2">
						<DashboardWidget data={{
							title:LANG.DASHBOARD.PREPARACION[lang],
							img:'../assets/images/icon_preparacion.png',
							value:0,
						}}/>
					</div>
					<div className="col-12 col-md-4 px-0 py-1 py-md-0">
						<DashboardWidget data={{
							title:LANG.DASHBOARD.ENVIADOS[lang],
							img:'../assets/images/icon_enviados.png',
							value:0,
							action:()=>{
								navigate('/envios')
							}
						}}/>
					</div>
				</div>
			</div>

			<PlanSemanal></PlanSemanal>
		</>
	);
}

export default DashboardCocina;
