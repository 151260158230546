/* eslint-disable no-unused-vars */
import React, { useState, useContext } from "react";
import "antd/dist/antd.css";
import { Drawer, Select } from "antd";
import "../../assets/css/Users/users.css";
import DataContext from "../../context/dataContext";
import { urlService } from "../../services/UrlService";
import GeneralButton from "../../components/buttons/GeneralButton";
import AdminContext from "../../context/adminContext";

import AlertContext from "../../context/alertContext";
import { LANG } from "../commun/lang";
import AuthContext from "../../context/authContext";

var sha1 = require("sha1");

function DrawerAddSpecialist(props) {
	const { Option } = Select;

	const {
		drawerSpecialist,
		setDrawerSpecialist,
	} = useContext(DataContext);



	const {selected_user, cocinas, nutricionistas, updateAthleteSpecialist} = useContext(AdminContext)
	const {showAlert} = useContext(AlertContext);
	const {lang} = useContext(AuthContext)




	const [state, setState] = useState({
		id_kitchen:'-1',
		id_nutricionist:'-1',
	})

	

	const onSave = async () => {

		
	
		if(state.id_nutricionist !== '-1' && state.id_kitchen !== '-1'){
			updateAthleteSpecialist({
					id_nutricionist:state.id_nutricionist,
					id_kitchen:state.id_kitchen
			})

			setDrawerSpecialist(false);
			showAlert({ type:'success', message:LANG.NOT.asignar_nutricionista[lang], description:LANG.NOT.listo[lang]})
		}else{
			showAlert({ type:'error', message:LANG.NOT.asignar_nutricionista[lang], description:LANG.NOT.indicar_cocina_nutricionista[lang]})
		}
		setState({
			id_kitchen:'-1',
			id_nutricionist:'-1',
		})
	
	};

	const showDrawer = () => {
		setDrawerSpecialist(true);
	};

	const onClose = () => {
		setDrawerSpecialist(false);
	};

	return (
		<>
			<Drawer
				placement="right"
				onClose={onClose}
				open={drawerSpecialist}
				visible={drawerSpecialist}
				destroyOnClose={true}
				forceRender={true}
			>
				<div className="UserDrawer">
				


					<div className="UserName text-uppercase text-center mb__section_title text-white">
						{selected_user?.firstname}
					</div>

					<div className="planName text-center mb__text_normal text-yellow mb-30">
						{selected_user?.fedding}
					</div>
				</div>

				<div className="userSelects select_custom d-flex flex-column justify-content-center align-items-center">
			

					<Select
					
						value={state.id_kitchen}
						style={{ width: '100%'}}
						onChange={(id_kitchen)=>{setState({...state, id_kitchen})}}
						className="ant-select-last"
					>
						<Option key="-1" value="-1">Seleccionar Cocina</Option>
						{cocinas?.map((cocina, i) => {
							return (
							
									<Option key={i} value={cocina.id_kitchen} >
										{cocina.kitchen}
									</Option>
							
							);
						})}
					</Select>

					<Select
						value={state.id_nutricionist}
						style={{ width: '100%', marginTop:20}}
						onChange={(id_nutricionist)=>{setState({...state, id_nutricionist})}}
						className="ant-select-last mb-20"
					>
						<Option  key="-1" value="-1">Selecciona un nutricionista</Option>
						{nutricionistas?.filter((nut) => nut.id_suscription_nutricionist !== 2).map((nut, i) => {
							console.log(nut)
							return (
							
									<Option value={nut.id_nutricionist} key={i}>
										{nut?.firstname} {nut?.lastname}
									</Option>
								
							);
						})}
					</Select>

					<GeneralButton
						className="btn__blue btn__general w-100 mt-3"
						title="Guardar"
						onClick={() => onSave()}
					/>
				</div>
			</Drawer>
		</>
	);
}

export default DrawerAddSpecialist;
