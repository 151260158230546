/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/css/dashboard.css";
import "../../assets/css/Generals/MenuSection.css";
import "../../assets/css/antdCustom.css";
import "../../assets/css/generals.css";
import { IoMdClose } from "react-icons/io";
import comida_1 from "../../assets/images/comidas/comida_1.png";
import comida_2 from "../../assets/images/comidas/comida_2.png";
import comida_3 from "../../assets/images/comidas/comida_3.png";
import comida_4 from "../../assets/images/comidas/comida_4.png";
import "antd/dist/antd.css";
import GeneralButton from "../../components/buttons/GeneralButton";
import DataContext from "../../context/dataContext";
import AlgorithmContext from "../../context/algorithmContext";
import { Segmented, Spin } from "antd";
import { urlService } from "../../services/UrlService";

import { Service } from "../../services/api";
import PlanContext from "../../context/planContext";
import { BiDish } from "react-icons/bi";
import { colors } from "../../utilities/colors";
import Renderif from "../../components/Renderif";
import RecipeThumbnail from "../../components/RecipeThumbnail";
import AlertContext from "../../context/alertContext";
import { LANG } from "../commun/lang";
import AuthContext from "../../context/authContext";

function WeeklySegment() {
	const navigate = useNavigate();
	const {lang} = useContext(AuthContext);


	const [state, setState] = useState({
		week_day:'Lunes',
	})

	const {weekRecipes, getRecipeByID,saveCurrentWeekPlanRecipes,onRemoveWeekPlanRecipe,onSelectWeekPlanSlot,weekPlanRecipeSlot} = useContext(PlanContext)
	

	useEffect(() => {

	}, []);

	const handleChange = (day) => {
		let _day = day;
		if(day === 'Sábado'){
			_day = 'Sabado'
		}
		if(day === 'Miércoles'){
			_day = 'Miercoles'
		}
		setState({...state, week_day:_day})

	};

	const getDayIndex = (val) => {
		let days = [
			"Lunes",
			"Martes",
			"Miercoles",
			"Jueves",
			"Viernes",
			"Sabado",
		]
		return days.indexOf(val)
	}



	return (
		<>
			<div className="segmentsPlans bg-dark-blue mb-20">
				<Spin spinning={false}>
					<Segmented
						options={[
							{label:LANG.WEEKPLAN.MONDAY[lang], value:'Lunes'},
							{label:LANG.WEEKPLAN.TUESDAY[lang], value:'Martes'},
							{label:LANG.WEEKPLAN.WEDNESDAY[lang], value:'Miercoles'},
							{label:LANG.WEEKPLAN.THURSDAY[lang], value:'Jueves'},
							{label:LANG.WEEKPLAN.FRIDAY[lang], value:'Viernes'},
							{label:LANG.WEEKPLAN.SATURDAY[lang], value:'Sabado'},

							// "Lunes",
							// "Martes",
							// "Miércoles",
							// "Jueves",
							// "Viernes",
							// "Sábado",
						]}
						onChange={handleChange}
						className="mb-20"
					/>

					<div className="tableplan">

						<div className="headerTable text-white bg-blue-shade">
							<div className="item1 mb__text_normal_bold text-uppercase">
							{LANG.REGISTER.comidas[lang]}
							</div>
							<div className="item2 mb__text_normal_bold">
								{state.week_day}
							</div>
							<div className="clear"></div>
						</div>

							{weekRecipes?.week?.find((week)=>week.day === state.week_day)?.meals.map((meal,i)=>{
								

								return (
									<div key={i} className="itemTable transition d-flex">
										<div className="col d-flex flex-column">

											<div className="text-white f-Poppins-Medium py-1">{LANG.WEEKPLAN[meal?.course.replace(" ","_")][lang]}</div>
											
											<div className="d-flex flex-wrap">
												{
													getRecipeByID(meal?.recipe)?.listComponentTypes.filter((type)=> type.descr.toUpperCase().includes('PROT') || type.descr.toUpperCase().includes('HIDR') ||type.descr.toUpperCase().includes('VERDU') ).map((type,i)=>{

														return <div key={i} className="f-10 text-mint-shade f-Poppins-Bold bg-mint rounded-pill px-2 py-1 me-1 line-1">{type.descr}</div>
													})
												}
											</div>
										</div>

										<Renderif isTrue={!meal.recipe}>
											<div className="foodContainer d-flex align-items-center p-2">
												
												<div className={`name d-flex align-items-center transition px-3 py-1 unassigned ${(weekPlanRecipeSlot?.day === getDayIndex(state.week_day) && weekPlanRecipeSlot.course===i) ? 'selected' : ''}`} onClick={()=>{
													onSelectWeekPlanSlot({day:getDayIndex(state.week_day), course:i})
												}}>
													<div className="overflow-hidden text-mint-shade no-wrap text-elipsis col">
														{LANG.PLANDETAIL.sin_asignar[lang]}
													</div>
													
												</div>
											</div>
										</Renderif>
										<Renderif isTrue={meal.recipe}>
											<div className="foodContainer d-flex align-items-center p-2">

											
										
												<RecipeThumbnail recipe={meal?.recipe}></RecipeThumbnail>

												

												
												<div className="name d-flex align-items-center transition ps-3 py-1">
													<div className="overflow-hidden no-wrap text-elipsis col">
														{/* {meal?.recipe} */}
														{getRecipeByID(meal?.recipe)?.descr}
													</div>

													<div className="icon d-flex rounded-circle p-1">
														<IoMdClose className="" size={18} onClick={(e) => {
															e.stopPropagation()
															onRemoveWeekPlanRecipe({day:getDayIndex(state.week_day), course:i})
														
															//selectRecipe(1)
														}}/>
													</div>
													
												</div>

											</div>
										</Renderif>


										
									</div>
								)
							})}
					</div>

					<div className="tablefooter">
						<div className="info">
							{/* Una vez que todo este correcto, haz clic en guardar */}
							{LANG.WEEKPLAN.SAVEONES[lang]}
						</div>

						<GeneralButton
							className="btn__blue btn__general mr-10"
							title={LANG.WEEKPLAN.SAVE[lang]}
							onClick={()=>{
								saveCurrentWeekPlanRecipes()
							}}
						/>
					</div>
				</Spin>
			</div>
		</>
	);
}
export default WeeklySegment;
