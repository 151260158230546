/* eslint-disable no-unused-vars */
import "../../assets/css/dashboard.css";
import HeaderDashboard from "../../components/HeaderDashboard";


import DashboardWidget from '../../components/DashboardWidget'
import { useContext } from "react";
import CocinaContext from "../../context/cocinaContext";
import { useNavigate } from "react-router-dom";
import { PlanSemanal } from "./PlanSemanalDeportista";
import DeportistaContext from "../../context/deportistaContext";
import { LANG } from "../commun/lang";
import AuthContext from "../../context/authContext";




function DashboardDeportista() {

	const navigate = useNavigate()

	const {athlete} = useContext(DeportistaContext);
	const {lang} = useContext(AuthContext)

	console.log(athlete)
	
	return (
		<>
			<HeaderDashboard />

			<div className="container-fluid p-3">
				<div className="text-white f-30 f-anton text-uppercase ps-1">
					Dashboard
				</div>
			</div>

			<div className="container-fluid px-3 pb-3">
				<div className="d-flex flex-wrap">

					<div className="col-12 col-md-3 px-0 py-1 py-md-0 pe-md-2">
						<DashboardWidget data={{
							title:LANG.REGISTER.Ciudad[lang],
							subtitle:athlete?.city,
							img:'../assets/images/ciudad.png',
						
						}}/>
					</div>
					<div className="col-12 col-md-3 px-0 py-1 py-md-0 pe-md-2">
						<DashboardWidget data={{
							title:'Club',
							subtitle:athlete?.club,
							img:'../assets/images/club.png',
						
						}}/>
					</div>
					<div className="col-12 col-md-3 px-0 py-1 py-md-0 pe-md-2">
						<DashboardWidget data={{
							title:LANG.REGISTER.filosofia_alimentaria[lang].split(' ')[1],
							subtitle:athlete?.fedding,
							img:'../assets/images/filosofia_alimentaria.png',
						
						}}/>
					</div>
					<div className="col-12 col-md-3 px-0 py-1 py-md-0">
						<DashboardWidget data={{
							title:LANG.PLANDETAIL.servicio[lang],
							subtitle:LANG.NOT[athlete?.service][lang],
							img:'../assets/images/servicio.png',
						
						}}/>
					</div>
				</div>
			</div>
			<PlanSemanal></PlanSemanal>
			
		</>
	);
}

export default DashboardDeportista;

// /* eslint-disable no-unused-vars */
// import React, { useEffect, useContext } from "react";
// import "../../assets/css/dashboard.css";
// import { Link, Redirect, Navigate, useNavigate } from "react-router-dom";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import HeaderDashboard from "../../components/HeaderDashboard";
// import { Table } from "antd";
// import { DashboardColumnsDeportista, DashboardSourceDeportista } from "./PlanSemanalDeportista";
// import AuthContext from "../../context/authContext";

// function DashboardDeportista() {

// 	let navigate = useNavigate()

// 	const {user} = useContext(AuthContext)

// 	const columns = [
// 		{title:'COCINAS'},
// 		{title:'SEMANA'},
// 		{title:'STATUS'}
// 	]

// 	useEffect(()=>{
		
// 	},[])

// 	return (
// 		<>
// 			<HeaderDashboard />
// 			<div className="mb__center_content">
				
// 				<div className="section_title text-white mb__section_title text-uppercase">
// 					Dashboard
// 				</div>

// 				<div className="home__section_data">
// 					<div className="d-flex flex-wrap info_blocks">
// 						<div className="col-6 col-lg-3 p-2 block">
// 							<div className="bg-dark-blue rounded-2">
// 								<div className="d-flex align-items-center ps-3 py-4">

// 									<div className="icon bg-yellow rounded-1 ">
// 										<img src="./assets/images/ciudad.png" alt="icon deportista" />
// 									</div>

// 									<div className="col">
// 										<div className="f-14 f-Poppins-Regular text-white ">
// 											Servicio
// 										</div>
// 										<div className="f-14 f-Poppins-Bold text-white">
// 											Sevilla
// 										</div>
// 									</div>
// 								</div>
// 								<div className="text-mint-shade f-Poppins-Medium p-2 border-t-blue-shade cursor-pointer">
// 									Actualizar
// 								</div>
// 							</div>
// 						</div>
// 						<div className="col-6 col-lg-3 p-2 block">
// 							<div className="bg-dark-blue rounded-2">
// 								<div className="d-flex align-items-center ps-3 py-4">

// 									<div className="icon bg-yellow rounded-1 ">
// 										<img src="./assets/images/club.png" alt="icon deportista" />
// 									</div>

// 									<div className="col">
// 										<div className="f-14 f-Poppins-Regular text-white ">
// 											Club
// 										</div>
// 										<div className="f-14 f-Poppins-Bold text-white">
// 											Sevilla
// 										</div>
// 									</div>
// 								</div>
// 								<div className="text-mint-shade f-Poppins-Medium p-2 border-t-blue-shade cursor-pointer">
// 									Actualizar
// 								</div>
// 							</div>
// 						</div>
// 						<div className="col-6 col-lg-3 p-2 block">
// 							<div className="bg-dark-blue rounded-2">
// 								<div className="d-flex align-items-center ps-3 py-4">

// 									<div className="icon bg-yellow rounded-1 ">
// 										<img src="./assets/images/filosofia_alimentaria.png" alt="icon deportista" />
// 									</div>

// 									<div className="col">
// 										<div className="f-14 f-Poppins-Regular text-white ">
// 											Filosofia Alimentaria
// 										</div>
// 										<div className="f-14 f-Poppins-Bold text-white">
// 											Ovmivoro
// 										</div>
// 									</div>
// 								</div>
// 								<div className="text-mint-shade f-Poppins-Medium p-2 border-t-blue-shade cursor-pointer">
// 									Actualizar
// 								</div>
// 							</div>
// 						</div>
// 						<div className="col-6 col-lg-3 p-2 block">
// 							<div className="bg-dark-blue rounded-2">
// 								<div className="d-flex align-items-center ps-3 py-4">

// 									<div className="icon bg-yellow rounded-1 ">
// 										<img src="./assets/images/servicio.png" alt="icon deportista" />
// 									</div>

// 									<div className="col">
// 										<div className="f-14 f-Poppins-Regular text-white ">
// 											Servicio
// 										</div>
// 										<div className="f-14 f-Poppins-Bold text-white">
// 											Comida y cena
// 										</div>
// 									</div>
// 								</div>
// 								<div className="text-mint-shade f-Poppins-Medium p-2 border-t-blue-shade cursor-pointer">
// 									Actualizar
// 								</div>
// 							</div>
// 						</div>

// 						<div className="clear"></div>
// 					</div>
// 				</div>

// 				<div className="mb__center_content">
// 					<div className="section_seals_table">
// 						<div className="data_tables bg-dark-blue overflow-auto">
// 							<Table
// 							 	rowKey="id"
// 								columns={DashboardColumnsDeportista}
// 								dataSource={DashboardSourceDeportista}
// 								onRow={(record, rowIndex) => {
// 									return {
// 									  onClick: event => {
// 									  navigate(`/plan-detalle`);
// 									  }, // click row
// 									};
// 								  }}
// 							/>
// 						</div>
// 					</div>
// 				</div>
					

// 			</div>
// 		</>
// 	);
// }

// export default DashboardDeportista;
