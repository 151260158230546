import { Drawer, Segmented, Spin } from 'antd'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { BiCheck, BiComment, BiCommentDetail, BiSend } from 'react-icons/bi'
import { BsCalendar2 } from 'react-icons/bs'
import { IoMdCalendar, IoMdClose } from 'react-icons/io'
import { MdClose } from 'react-icons/md'
import { useNavigate, useParams } from 'react-router-dom'
import BackButton from '../../components/buttons/BackButton'
import GeneralButton from '../../components/buttons/GeneralButton'
import HeaderDashboard from '../../components/HeaderDashboard'
import RecipeThumbnail from '../../components/RecipeThumbnail'
import Renderif from '../../components/Renderif'
import AlertContext from '../../context/alertContext'
import AuthContext from '../../context/authContext'
import DeportistaContext from '../../context/deportistaContext'
import PlanContext from '../../context/planContext'
import { Service } from '../../services/api'
import { DeportistaState } from '../../states/deportistaState'
import { colors } from '../../utilities/colors'
import { LANG } from '../commun/lang'
import WeeklySegment from '../WeeklyDetail/WeeklySegment'


export default function PlanSemanalDetalleDeportista() {

  let navigate = useNavigate()

  const {id_plan} = useParams();
  const { user, lang } = useContext(AuthContext);
  const { showAlert } = useContext(AlertContext);

  const [state, setState] = useState({
    showRecipeDetail:false,
    weeklyPlan:[],
    weeklyRicipes:[],
    week_day:'Lunes',
	comments:[],
	showComments:false,
	comment:'',
	showComments:false,
	validatedPlan:[],
	showValidatePlan:true
  })
  const {weekRecipes, getRecipeByID,saveCurrentWeekPlanRecipes,onRemoveWeekPlanRecipe,onSelectWeekPlanSlot,weekPlanRecipeSlot} = useContext(PlanContext)
  const {athlete} = useContext(DeportistaContext)

  	useEffect(()=>{
	
		const data = async () => {
		let _weeklyPlan = await Service('GET',`recipes/weeklyRecipes/${id_plan}`);
		// console.log(_weeklyPlan)
		console.log(athlete)
			
		
		let _commnets = await Service('GET',`comment/${id_plan}`);

		let _validated_plan = JSON.parse(localStorage.getItem("validated_plan"));
		

		setState({...state, 
			weeklyPlan:_weeklyPlan.data.response, 
			weeklyRicipes:JSON.parse(_weeklyPlan.data.response.week_recipes),
			validatedPlan: _validated_plan === null ? [] : _validated_plan
			// comments:_commnets.data.response
		})


		};


		data();
		return () => {};
	
	},[])

	const handleChange = (day) => {
		let _day = day;
		if(day === 'Sábado'){
			_day = 'Sabado'
		}
		if(day === 'Miércoles'){
			_day = 'Miercoles'
		}
		setState({...state, week_day:_day})

	};

	const getDayIndex = (val) => {
		let days = [
			"Lunes",
			"Martes",
			"Miercoles",
			"Jueves",
			"Viernes",
			"Sabado",
		]
		return days.indexOf(val)
	}
	const addComment = async () => {

		state.comments.push({
			...user,
			comment:state.comment,
			comment_created_at:Date.now()
		})
		
		let body = {
			comment:state.comment,
			id_plan:id_plan,
			id_user:user.id_user
		}
		
		state.comment = ''
		setState({...state})
		let _addComment = await Service('POST', 'comment', body);
		

	}
	const validarPlan = async () => {

		state.comments.push({
			...user,
			comment:'Ah validado su plan semanal ' + moment(state.weeklyPlan?.start_date).format('D MMM') +"-"+ moment(state.weeklyPlan?.end_date).format('D MMM'),
			comment_created_at:Date.now()
		})
		state.validatedPlan.push(id_plan);
		localStorage.setItem("validated_plan", JSON.stringify(state.validatedPlan));
		
		let body = {
			comment:state.comment,
			id_plan:id_plan,
			id_user:user.id_user
		}
		
		state.comment = ''
		setState({...state})
		let _addComment = await Service('POST', 'comment', body);
		showAlert({type:'success', message:LANG.NOT.validar_plan[lang], description: LANG.NOT.plan_semanal_validado[lang]+' ' + moment(state.weeklyPlan?.start_date).format('D MMM') +"-"+ moment(state.weeklyPlan?.end_date).format('D MMM')})
		

	}

  
  return (
    <>

      <HeaderDashboard />

      <div className="container-fluid p-3">

      <div className="d-flex flex-wrap align-content-center">
        <div className="col-12 col-lg">
          <BackButton route="/plan-detalle" />
          <div className="text-white f-30 f-anton text-uppercase ps-1">
            {LANG.WEEKPLAN.WEEKPLAN[lang]} - {(state.weeklyPlan?.kitchen)?.toUpperCase()}
          </div>
          <div className="d-flex align-items-center ps-1">
            <BsCalendar2 color={colors.yellow}/>
            <div className="f-14 text-yellow f-Poppins-Bold px-2">{`${moment(state.weeklyPlan?.start_date).format('D MMM')} - ${moment(state.weeklyPlan?.end_date).format('D MMM')}`}</div>
          </div>
        </div>

        <div className="">
        <GeneralButton
          className="btn__blue btn__general"
          title={LANG.WEEKPLAN.COMMENTS[lang]}
          icon={<BiComment />}
          onClick={() => {setState({...state, showComments:true})}}
        />
		<Renderif isTrue={!state.validatedPlan.includes(id_plan)}>

			<GeneralButton
			className="btn__blue btn__general ms-1"
			title={LANG.NOT.validar_plan[lang]}
			icon={<BiCheck />}
			onClick={() => {
				validarPlan()
				// navigate('/plan-full/'+id_plan)
				
			}}
			/>

		</Renderif>
       
        </div>
      </div>

      </div>
    
     


      <div className='container-fluid mt-3'>

      <div className="segmentsPlans bg-dark-blue mb-20">
				<Spin spinning={false}>
					<Segmented
					options={[
				

						{label:LANG.WEEKPLAN.MONDAY[lang], value:"Lunes"},
						{label:LANG.WEEKPLAN.TUESDAY[lang], value:"Martes"},
						{label:LANG.WEEKPLAN.WEDNESDAY[lang], value:"Miércoles"},
						{label:LANG.WEEKPLAN.THURSDAY[lang], value:"Jueves"},
						{label:LANG.WEEKPLAN.FRIDAY[lang], value:"Viernes"},
						{label:LANG.WEEKPLAN.SATURDAY[lang], value:"Sábado"},


					]}
						
						onChange={handleChange}
						className="mb-20"
					/>

					<div className="tableplan">

						<div className="headerTable text-white bg-blue-shade">
							<div className="item1 mb__text_normal_bold text-uppercase">
								{LANG.REGISTER.comidas[lang]}
							</div>
							<div className="item2 mb__text_normal_bold">
								
								{LANG.WEEKPLAN[state.week_day][lang]}

							</div>
							<div className="clear"></div>
						</div>

							{state.weeklyRicipes?.week?.find((week)=>week.day === state.week_day)?.meals.map((meal,i)=>{

							let show = true;

							switch (meal.course) {
								case 'Comida Primero':
									athlete?.service === 'Comida' || athlete?.service === 'Comida y Cena' ? show = true : show = false;
									break;
								case 'Comida Segundo':
									athlete?.service === 'Comida' || athlete?.service === 'Comida y Cena' ? show = true : show = false;
									break;
								case 'Cena Primero':
									athlete?.service === 'Cena' || athlete?.service === 'Comida y Cena' ? show = true : show = false;
									break;
								case 'Cena Segundo':
									athlete?.service === 'Cena' || athlete?.service === 'Comida y Cena' ? show = true : show = false;
									break;
								case 'Extra Comida':
									athlete?.service === 'Comida' || athlete?.service === 'Comida y Cena' ? show = true : show = false;
									break;
								case 'Extra Cena':
									athlete?.service === 'Cena' || athlete?.service === 'Comida y Cena' ? show = true : show = false;
									break;

								default:
									break;
							}


              
								return (
									<Renderif key={i}  isTrue={show && athlete !== null}>
										<div className="itemTable transition d-flex">
										<div className="col d-flex flex-column">

											<div className="text-white f-Poppins-Medium py-1">{LANG.WEEKPLAN[meal?.course.replace(" ","_")][lang]}</div>
											
											<div className="d-flex flex-wrap">
												{
													getRecipeByID(meal?.recipe)?.listComponentTypes.filter((type)=> type.descr.toUpperCase().includes('PROT') || type.descr.toUpperCase().includes('HIDR') ||type.descr.toUpperCase().includes('VERDU') ).map((type,i)=>{

														return <div key={i} className="f-10 text-mint-shade f-Poppins-Bold bg-mint rounded-pill px-2 py-1 me-1 line-1">{type.descr}</div>
													})
												}
											</div>
										</div>

										<Renderif isTrue={!meal.recipe}>
											<div className="foodContainer d-flex align-items-center p-2">
												
												<div className={`name d-flex align-items-center transition px-3 py-1 unassigned ${(weekPlanRecipeSlot?.day === getDayIndex(state.week_day) && weekPlanRecipeSlot.course===i) ? 'selected' : ''}`} onClick={()=>{
													// onSelectWeekPlanSlot({day:getDayIndex(state.week_day), course:i})
												}}>
													<div className="overflow-hidden text-mint-shade no-wrap text-elipsis col">
														{LANG.PLANDETAIL.sin_asignar[lang]}
													</div>
													
												</div>
											</div>
										</Renderif>

										<Renderif isTrue={meal.recipe}>
											<div className="foodContainer d-flex align-items-center p-2">

											
										
												<RecipeThumbnail recipe={meal?.recipe}></RecipeThumbnail>

												
												<div className="name d-flex align-items-center transition ps-3 py-1">
													<div className="overflow-hidden no-wrap text-elipsis col">
														{/* {meal?.recipe} */}
														{getRecipeByID(meal?.recipe)?.descr}
													</div>

													{/* <div className="icon d-flex rounded-circle p-1">
														<IoMdClose className="" size={18} onClick={(e) => {
															e.stopPropagation()
															onRemoveWeekPlanRecipe({day:getDayIndex(state.week_day), course:i})
														
															//selectRecipe(1)
														}}/>
													</div> */}
													
												</div>

											</div>
										</Renderif>


										
										</div>
									</Renderif>
									
								)
							
							
							})}
					</div>

					<div className="tablefooter">
						{/* <div className="info">
							Una vez que todo este correcto, haz clic en guardar
						</div> */}

						{/* <GeneralButton
							className="btn__blue btn__general mr-10"
							title="Guardar"
							onClick={()=>{
								saveCurrentWeekPlanRecipes()
							}}
						/> */}
					</div>
				</Spin>
	</div>
        
      </div>

      

      <Renderif isTrue={state.showRecipeDetail}>
        <div className="d-flex position-fixed vw-100 vh-100" style={{
              top: 0,
              right: 0,
              zIndex: 1,
            }}>

              <div className='col-6 col-lg-8'></div>  
              <div className='col-6 col-lg-4'>

                <div className=' bg-dark-blue border-l-mint h-100 overflow-auto '>

                  <div className='d-flex'>
                    <div className='col'></div>
                    <div className='cursor-pointer p-2' onClick={()=>{setState({...state, showRecipeDetail:false})}}>
                      <MdClose size={32} color={colors.white}></MdClose>
                    </div>
                  </div>

                  <div className='f-32 f-anton text-center text-white'>Lorem Impsum Is Text</div>
                  

                  <div className='d-flex justify-content-center align-items-center'>
                    <img className='w-50 ' src='https://picsum.photos/400/400'></img>
                  </div>

                  <div className='px-4'>

                    <div className='f-16 f-Poppins-Bold  text-white py-2'>Lorem Impsum Is Text</div>
                    <div className='f-14 f-Poppins-Regular  text-white py-2'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</div>

                  </div>
                  <div className='px-4'>

                    <div className='f-16 f-Poppins-Bold  text-white py-2'>Lorem Impsum Is Text</div>
                    <div className='f-14 f-Poppins-Regular  text-white py-2'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</div>
              

                  </div>

                  
                </div>
              
              </div>

            



          
        </div>
      </Renderif>
	  <Renderif isTrue={state.showComments}>
				<div className="d-flex position-fixed vw-100 vh-100" style={{
					top: 0,
					right: 0,
					zIndex: 1,
					}}>

					<div className='col-6 col-lg-9 bg-overlay' onClick={()=>{setState({...state, showComments:false})}}></div> 
					<div className='col-6 col-lg-3'>

						<div className=' bg-dark-blue border-l-mint h-100 overflow-auto hide-scroll'>

						<div className='d-flex'>
							<div className='col'></div>
							<div className='cursor-pointer p-2' onClick={()=>{setState({...state, showComments:false})}}>
							<MdClose size={32} color={colors.white}></MdClose>
							</div>
						</div>

						<div className='f-30 f-anton text-white px-3 border-b-blue-shade text-uppercase'>{LANG.WEEKPLAN.COMMENTS[lang]}</div>

						{state.comments.map((comment,i)=>{
							
						
							return (
								<div key={i} className=" border-b-blue-shade px-3 py-3">
									<div className="d-flex ">
										<div className="f-14 f-Poppins-Bold text-yellow">{comment?.firstname}</div>
									
										<div className="col"></div>
										{/* <div className="text-white">{new Date(comment?.comment_created_at).getDate()}</div> */}
										{/* <div className="text-white">{new Date(comment.comment_created_at)}</div> */}
										<div className="f-12 f-Poppins-Medium text-blue-shade">{moment(new Date(parseInt(comment?.comment_created_at))).format("D MMM")}</div>
									</div>
									<div className="f-12 f-Poppins-Medium text-blue-shade">{LANG.PROFILE[comment.rol][lang]}</div>
									<div className="f-14 f-Poppins-Medium text-light-gray">{comment.comment} </div>
								</div>
							) 
						})}


					

					

						
						<div className="px-3 py-3">
							<textarea value={state.comment} onChange={(e)=>{setState({...state, comment:e.currentTarget.value})}} rows={5} cols={5} className="textarea"></textarea>

							<div className="d-flex justify-content-end">
							<GeneralButton
								className="btn__blue btn__general"
								title="Enviar"
								
								icon={<BiSend />}
								onClick={() => {

									addComment();
									
								}}
							/>
							</div>
						
						</div>

						
						</div>
					
					</div>

					



				
				</div>
			</Renderif>

    
			
		</>
  )
}
