/* eslint-disable no-unused-vars */
import React, { } from "react";
import { useNavigate } from "react-router-dom";

function UsersTable({data, columns}) {
	console.log(data.length);
	
	const navigate = useNavigate();
	return (
		<div className="container-fluid px-3">
	
				<div className="p-3 rounded-2 bg-dark-blue">
	
				
					<table className="ant-table w-100 bg-dark-blue">
						<thead className="ant-table-thead">
							<tr>
								{columns.map((column,i)=>{
									return (<th key={i}>{column.title}</th>)
								})}
							</tr>
						</thead>
						<tbody className="ant-table-tbody">
							{data.map((item, i)=>{

								
								
								return(
									<tr  key={i} className={item.id_rol === 4 ? 'cta-light':''} onClick={()=>{
						
										if(item.id_rol === 4){
											navigate(`/user`, {state:item})
										}
									}}>
										{columns.map((column,i)=>{
											
											return <td key={i}>{column?.render(item)}</td>
										})}
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
		</div>
	);
}

export default UsersTable;
