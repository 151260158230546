
import { useEffect, useState } from "react";

import AdminContext from "../context/adminContext";

import { Service } from "../services/api";


export const AdminState = ({children}) => {

    const [state, setState]= useState({
        deportistas:[],
        nutricionistas:[],
        cocinas:[],
        chefs:[{
          firstname:'Chef',
          email:'manuela@manuela.com',
          lastname:""
        }],
        selected_user:null
    })

    useEffect(()=>{
        
        getUsers();
        
        return ()=>{}
    },[])

    const getUsers = async () => {
      console.log("ASDAS")
        //FALTA CATCHES EN SERVICES
        let _deportistas = await Service('GET', 'athletes');
        
        let _nutricionistas = await Service('GET', 'nutricionist');
        // let _chefs = await Service('GET', 'chefs');
        let _cocinas = await Service('GET','kitchens');

        let deportistas = _deportistas.data.response;
        let nutricionistas = _nutricionistas.data.response;
        let cocinas = _cocinas.data.response;

        deportistas.map((deportista)=>{
          deportista.nutricionista = nutricionistas.filter((nutricionista) => nutricionista.id_nutricionist === deportista.id_nutricionist)[0]
          deportista.cocina = cocinas.filter((cocina) => cocina.id_kitchen === deportista.id_kitchen)[0]
        })

        setState({...state, 
          deportistas,
          nutricionistas,
          cocinas,

        })
    }
    const onSelectUser = ({user}) => {
      setState({...state, selected_user:user})
    }

    const updateAthleteSpecialist = async ({id_nutricionist, id_kitchen})=>{

    
      let body = {
        id_athlete:state.selected_user.id_athlete,
        id_nutricionist,
        id_kitchen
      }
      
      let deportista_index = state.deportistas.findIndex((deportista)=>deportista.id_athlete === state.selected_user.id_athlete);
      console.log(deportista_index)
      state.deportistas[deportista_index].id_nutricionist = id_nutricionist
      state.deportistas[deportista_index].id_kitchen = id_kitchen
      state.deportistas[deportista_index].nutricionista = state.nutricionistas.filter((nutricionista) => nutricionista.id_nutricionist === id_nutricionist)[0]
      state.deportistas[deportista_index].cocina = state.cocinas.filter((cocina) => cocina.id_kitchen === id_kitchen)[0]

      let _updateAthleteSpecialist = await Service('PUT', 'athlete', body);
      setState({...state})
    }
    const onRemoveUser = async ({user}) => {
      let delete_user = await Service('PUT', 'athlete/delete',{id_user:user.id_user})
      console.log(user);
      if(user.id_rol === 4){
        state.deportistas = state.deportistas.filter((deportista)=> deportista.id_user !== user.id_user)
      }
   
      if(user.id_rol === 2){
        state.nutricionistas = state.nutricionistas.filter((nutricionista)=> nutricionista.id_user !== user.id_user)
      }

      if(user.id_rol === undefined){
        let cocina_index = state.cocinas.findIndex((cocina)=>cocina.id_user === user.id_user);
        state.cocinas[cocina_index].active = 0
      }
  

      setState({...state})
    }

   
  


    return (
      <AdminContext.Provider value={{
        ...state,
        onSelectUser,
        onRemoveUser,
        updateAthleteSpecialist,
        getUsers
      }}>
        {children}
      </AdminContext.Provider>
    );
  };
 