/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from "react";
import "../../assets/css/dashboard.css";
import { Link, Redirect, Navigate, useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HeaderDashboard from "../../components/HeaderDashboard";
import GeneralButton from "../../components/buttons/GeneralButton";
import { BiEdit } from "react-icons/bi";
import AuthContext from "../../context/authContext";
import { LANG } from "../commun/lang";

function Profile() {
	const navigate = useNavigate();
    const {user, lang} = useContext(AuthContext);
    console.log(user)
    
	return (
		<>
        <HeaderDashboard />
        <div className="container-fluid p-3">
            <div className="text-white f-30 f-anton text-uppercase ps-1">
                {LANG.PROFILE.PROFILE[lang]}
            </div>
        </div>
        <div className="container-fluid px-3">
            <div className='text-white bg-dark-blue rounded'>

                <div className='d-flex p-3'>
                    <div className='col'></div>
                    
                    <GeneralButton
                        className="btn__blue btn__general"
                        title={LANG.PROFILE.EDIT[lang]}
                        icon={<BiEdit />}
                        onClick={() => {
                            navigate(`/editar-perfil`)
                        }}
                    />
                    
                </div>

                <div className=' bg-mint-light'>
                    <div className='border-b-mint d-flex p-3'>
                        <div style={{marginTop:-35}} className='bg-yellow p-1 rounded-3'>
                            
                            <img style={{width:100}} className='rounded-3 h-100' src={
                              
                                    user?.picture ? process.env.REACT_APP_API_URL+'/images/'+user?.picture : process.env.REACT_APP_API_URL + '/images/profile.png' 
                                    
                                    }></img>
                        </div>
                        <div className='col ps-2 d-flex flex-column justify-content-end'>
                            <div className='f-20 f-Poppins-Bold text-mint-shade line-1'>{user?.firstname}</div>
                            <div className='f-14 f-Poppins-Regular text-mint-shade'>{user?.rol}</div>
                        </div>
                    </div>

                    <div className='d-flex border-b-mint p-3'>
                        <div className=' col f-Poppins-Medium text-mint-shade'>{LANG.PROFILE.FULLNAME[lang]}:</div>
                        <div className=' col f-Poppins-Medium text-mint-shade'>{user?.firstname} {user?.lastname}</div>
                    </div>
                    <div className='d-flex border-b-mint p-3'>
                        <div className=' col f-Poppins-Medium text-mint-shade'>Email:</div>
                        <div className=' col f-Poppins-Medium text-mint-shade'>{user?.email}</div>
                    </div>
                    <div className='d-flex border-b-mint p-3'>
                        <div className=' col f-Poppins-Medium text-mint-shade'>{LANG.PROFILE.PHONE[lang]}:</div>
                        <div className=' col f-Poppins-Medium text-mint-shade'>{user?.phone}</div>
                    </div>
                    <div className='d-flex border-b-mint p-3'>
                        <div className=' col f-Poppins-Medium text-mint-shade'>{LANG.PROFILE.LOCATION[lang]}:</div>
                        <div className=' col f-Poppins-Medium text-mint-shade'>{user?.city} {user?.state} {user?.country}</div>
                    </div>

                </div>
                <div className="py-4"></div>

            </div>
        </div>
    </>
	);
}

export default Profile;
