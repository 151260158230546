export const PRO_LINKS = [
    'https://buy.stripe.com/dR6bMrbAI0ym2xG3cd?prefilled_email=', //0
    'https://buy.stripe.com/eVa5o37ks1Cq5JSbIK?prefilled_email=', //1
    'https://buy.stripe.com/bIY03J34c4OC0py9AD?prefilled_email=', //2
    'https://buy.stripe.com/5kAg2HeMU94S7S03cg?prefilled_email=' //3
]
export const ELITE_LINKS = [
    'https://buy.stripe.com/eVa5o36go1Cq4FOdQV?prefilled_email=', //0
    'https://buy.stripe.com/dR64jZdIQ5SG2xG7sy?prefilled_email=', //1
    'https://buy.stripe.com/9AQ2bReMU3Ky8W4007?prefilled_email=', //2
    'https://buy.stripe.com/bIYeYDfQY3Kyego8wE?prefilled_email=' //3
]
export const NUTRICIONISTA_PRO = [
    'https://buy.stripe.com/cN2bMr8ow94S6NWdQQ?prefilled_email='
]
///STAGE///
export const PRO_LINKS_STAGE = [
    'https://buy.stripe.com/test_dR629ibOacoec48dRe?prefilled_email=', //0
    'https://buy.stripe.com/test_dR6eW4f0mcoed8c28y?prefilled_email=', //1
    'https://buy.stripe.com/test_4gw5lug4q9c2ecg00r?prefilled_email=', //2
    'https://buy.stripe.com/test_8wMeW43hEcoe8RW14w?prefilled_email=' //3
]
export const ELITE_LINKS_STAGE = [
    'https://buy.stripe.com/test_cN2aFO3hE2NE4BGfZn?prefilled_email=', //0
    'https://buy.stripe.com/test_7sIcNW7xU3RI3xC28B?prefilled_email=', //1
    'https://buy.stripe.com/test_eVa8xG5pMbkafgk8x0?prefilled_email=', //2
    'https://buy.stripe.com/test_fZe6py4lI2NEecgeVp?prefilled_email=' //3
]
export const NUTRICIONISTA_PRO_STAGE = [
    'https://buy.stripe.com/test_cN229i6tQbka0lq4gM?prefilled_email=', //0
]