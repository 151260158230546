/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";
import DataState from "./states/dataState";

import "./assets/css/utility.css";
import "./App.css";
import "./assets/css/grids.css";
import "./assets/css/fonts.css";
import "./assets/css/titles.css";
import "./assets/css/colors.css";
import "./assets/css/forms.css";
import "./assets/css/generals.css";
import "./assets/css/antdCustom.css";
import "./assets/css/buttons.css";
import "./assets/css/modalCreate.css";
import Login from "./pages/login/Login";
import MainMenu from "./menus/mainMenu";
import Dashboard from "./pages/dashboard/Dashboard";
import Profile from "./pages/profile/Profile";

import Users from "./pages/users/Users";

import WeeklyPlan from "./pages/WeeklyPlan/WeeklyPlan";
import WeeklyDetail from "./pages/WeeklyDetail/WeeklyDetail";
import WeeklyPlanFull from "./pages/WeeklyDetail/WeeklyPlanFull";

import { Layout } from "antd";
import Registro from "./pages/registro/Registro";
import Renderif from "./components/Renderif";
import AuthState from "./states/authState";
import AuthContext from "./context/authContext";
import DashboardDeportista from "./pages/deportista/DashboardDeportista";
import PlanDeportista from "./pages/deportista/PlanDeportista";
import PerfilDeportista from "./pages/deportista/PerfilDeportista";
// import PlanSemanalDeportista from "./pages/deportista/PlanSemanalDeportista";
import PlanSemanalDetalleDeportista from "./pages/deportista/PlanSemanalDetalleDeportista";
import PlanSemanalFullDeportista from "./pages/deportista/PlanSemanalFullDeportista";
import DashboardNutricionist from "./pages/nutricionist/DashboardNutricionist";


//COCINA
import DashboardCocina from "./pages/cocina/DashboardCocina";
import { PlanSemanalCocina } from "./pages/cocina/PlanSemanalCocina";
import PlanSemanalDetalle from "./pages/cocina/PlanSemanalDetalle";
import AthletesPlanCocina from "./pages/cocina/AthlehtesPlanCocina";
import Quantitys from "./pages/cocina/Quantitys";
import AthleteNutricionalTableCocina from "./pages/cocina/AthleteNutricionalTableCocina";

import PlanSemanalDetalleNutricionist from "./pages/nutricionist/PlanSemanalDetalleNutricionist";
import AthleteNutricionalTable from "./pages/nutricionist/AthletheNutricionalTable";
import AthletesPlan from "./pages/nutricionist/AthlehtesPlan";
import Athletes from "./pages/nutricionist/Athletes";
import PlanState from "./states/planState";

import ProfileNutricionista from "./pages/nutricionist/ProfileNutricionista";
import AltaDeportista from "./pages/nutricionist/AltaDeportista";
import { AdminState } from "./states/adminState";
import { AlertState } from "./states/alertState";
import EnviosCocina from "./pages/cocina/EnviosCocina";
import EnviosDetallCocina from "./pages/cocina/EnviosDetalleCocina";
import { CocinaState } from "./states/cocinaState";
import ProfileCocina from "./pages/cocina/ProfileCocina";
import { NutricionistaState } from "./states/nutricionistaState";
import CantidadesAdmin from "./pages/admin/CantidadesAdmin";
import DeportistaContext from "./context/deportistaContext";
import { PlanSemanalDeportista } from "./pages/deportista/PlanSemanalDeportista";
import { DeportistaState } from "./states/deportistaState";
import PlanContext from "./context/planContext";
import TYCOS from "./pages/commun/tycos";
import EditarPerfilDeportista from "./pages/deportista/EditarPerfilDeportista";
import EditProfile from "./pages/profile/EditProfile";
import UserDetail from "./pages/admin/UserDetail";
import PlanSemanalNutricionist from "./pages/nutricionist/PlanSemanalNutricionist";
import AdminContext from "./context/adminContext";
import CantidadesDeportista from "./pages/admin/CantidadesDeportista";

import Payment from "./pages/commun/payment";
import Test from "./components/test";
import AltaNutricionista from "./pages/admin/AltaNutricionista";



const { Sider, Content } = Layout;

function PrivateRoute({ children }) {
	const { user } = useContext(AuthContext);
	return user ? children : <Navigate to="/" />;
}

function App() {

	useEffect(() => {
		return () => {};
	});



	
	return (
		<AuthState>
		<Payment></Payment>
			<AlertState>
			<Layout className="bg-dark-background">
				<Router>
					<DataState>
						<MainMenu />
					</DataState>

					<Content>
						<Routes>
							<Route
								exact
								path="/"
								element={
									<DataState>
										<Login />
									</DataState>
								}
							/>
							<Route
								exact
								path="/registro"
								element={
									<DataState>
										<Registro />
									</DataState>
								}
							/>
							<Route
								exact
								path="/tycos"
								element={
							
										<TYCOS />
							
								}
							/>
							<Route
								exact
								path="/plan-semanal-deportista/:id_plan/:id_kitchen/:day/:id_athlete"
								element={
									<PlanState>
										<CantidadesDeportista></CantidadesDeportista>
									</PlanState>
							
								}
							/>
							
						</Routes>

						<RolRouter></RolRouter>
					</Content>
				</Router>
			</Layout>
			</AlertState>
		</AuthState>
	);
}

function AdminRoutes() {
	return (
		<AdminState>
			<Routes>
				<Route
					exact
					path="/dashboard"
					element={
						<PrivateRoute>
							<PlanState>
								<Dashboard />
							</PlanState>
						</PrivateRoute>
					}
				/>
				<Route
					exact
					path="/profile"
					element={
						<PrivateRoute>
							<DataState>
								<Profile />
							</DataState>
						</PrivateRoute>
					}
				/>
				<Route
					exact
					path="/editar-perfil"
					element={
						<PrivateRoute>
							
								<EditProfile />
							
						</PrivateRoute>
					}
				/>
				
				<Route
					exact
					path="/users"
					element={
						<PrivateRoute>
							<DataState>
								<Users />
							</DataState>
						</PrivateRoute>
					}
				/>
				<Route
					exact
					path="/users/:tab"
					element={
						<PrivateRoute>
							<DataState>
								<Users />
							</DataState>
						</PrivateRoute>
					}
				/>
				<Route
					exact
					path="/plan-semanal"
					element={
						<PrivateRoute>
							<PlanState>
								<WeeklyPlan />
							</PlanState>
						</PrivateRoute>
					}
				/>
				<Route
					exact
					path="/plan-detalle/:id_plan"
					element={
						<PrivateRoute>
							<PlanState>
								<WeeklyDetail />
							</PlanState>
						</PrivateRoute>
					}
				/>
				<Route
					exact
					path="/user"
					element={
					
								<UserDetail />
					
					}
				/>
				<Route
					exact
					path="/cantidades/:id_plan/:id_kitchen/:day"
					element={
						<PrivateRoute>
							<PlanState>
								<CantidadesAdmin />
							</PlanState>
						</PrivateRoute>
					}
				/>
				{/* <Route
					exact
					path="/plan-detalle/:id"
					element={
						<PrivateRoute>
							<PlanState>
								<WeeklyDetail />
							</PlanState>
						</PrivateRoute>
					}
				/> */}
				<Route
					exact
					path="/plan-deportistas/:id_plan/:id_kitchen"
					element={
						<PrivateRoute>
							<PlanState>
								<AthletesPlanCocina />
							</PlanState>
						</PrivateRoute>
					}
				/>
				<Route
					exact
					path="/plan-full/:id_plan"
					element={
						<PrivateRoute>
							<PlanState>
								<DataState>
									<WeeklyPlanFull />
								</DataState>
							</PlanState>
						</PrivateRoute>
					}
				/>
				<Route
					exact
					path="/tabla-nutricional/:id_athlete/:id_plan"
					element={
						<PrivateRoute>
							<PlanState>
								<DataState>
									<AthleteNutricionalTableCocina />
								</DataState>
							</PlanState>
						</PrivateRoute>
					}
				/>
				<Route
					exact
					path="/alta-deportista"
					element={
						<PrivateRoute>
							{/* <DataState> */}
								<AltaDeportista />
							{/* </DataState> */}
						</PrivateRoute>
					}
				/>
				<Route
					exact
					path="/alta-nutricionista"
					element={
						<PrivateRoute>
							{/* <DataState> */}
								<AltaNutricionista />
							{/* </DataState> */}
						</PrivateRoute>
					}
				/>
			</Routes>
		</AdminState>
	);
}
function ChefRouter() {
	return (
		<CocinaState>
			<Routes>
				<Route
					exact
					path="/dashboard"
					element={
						<PrivateRoute>
							<DashboardCocina />
						</PrivateRoute>
					}
				/>
				<Route
					exact
					path="/plan-semanal"
					element={
						<PrivateRoute>
							<DataState>
								<PlanSemanalCocina />
							</DataState>
						</PrivateRoute>
					}
				/>

				<Route
					exact
					path="/plan-detalle/:id_plan"
					element={
						<PrivateRoute>
							<DataState>
								<PlanState>
									<PlanSemanalDetalle />
								</PlanState>
							</DataState>
						</PrivateRoute>
					}
				/>

				<Route
					exact
					path="/cantidades/:id_plan/:id_kitchen/:day"
					element={
						<PrivateRoute>
							<DataState>
								<PlanState>
									<Quantitys />
								</PlanState>
							</DataState>
						</PrivateRoute>
					}
				/>

				<Route
					exact
					path="/plan-deportistas/:id_plan/:id_kitchen"
					element={
						<PrivateRoute>
							<DataState>
								<AthletesPlanCocina />
							</DataState>
						</PrivateRoute>
					}
				/>

				<Route
					exact
					path="/tabla-nutricional/:id_athlete/:id_plan"
					element={
						<PrivateRoute>
							<DataState>
								<PlanState>
									<AthleteNutricionalTableCocina />
								</PlanState>
							</DataState>
						</PrivateRoute>
					}
				/>
				<Route
					exact
					path="/profile"
					element={
						<PrivateRoute>
							{/* <DataState>
								<PlanState> */}
									<Profile />
								{/* </PlanState>
							</DataState> */}
						</PrivateRoute>
					}
				/>
				<Route
					exact
					path="/editar-perfil"
					element={
						<PrivateRoute>
							{/* <DataState>
								<PlanState> */}
									<EditProfile />
								{/* </PlanState>
							</DataState> */}
						</PrivateRoute>
					}
				/>
				<Route
					exact
					path="/envios"
					element={
						<PrivateRoute>
							{/* <DataState>
								<PlanState> */}
									<EnviosCocina />
								{/* </PlanState>
							</DataState> */}
						</PrivateRoute>
					}
				/>
				<Route
					exact
					path="/envios/:id_plan"
					element={
						<PrivateRoute>
							{/* <DataState>
								<PlanState> */}
									<EnviosDetallCocina />
								{/* </PlanState>
							</DataState> */}
						</PrivateRoute>
					}
				/>
			</Routes>
		</CocinaState>
	);
}
function NutricionistaRouter() {
	return (
		<NutricionistaState>
			<Routes>
				<Route
					exact
					path="/dashboard"
					element={
						<PrivateRoute>
								<DashboardNutricionist />
						</PrivateRoute>
					}
				/>
				<Route
					exact
					path="/plan-semanal"
					element={
						<PrivateRoute>
							<PlanState>
								<PlanSemanalNutricionist />
							</PlanState>
						</PrivateRoute>
					}
				/>
				<Route
					exact
					path="/plan-detalle-nutricionista/:id_plan"
					element={
						<PrivateRoute>
							<PlanState>
								<PlanSemanalDetalleNutricionist />

							</PlanState>
						</PrivateRoute>
					}
				/>
				<Route
					exact
					path="/plan-detalle/:id_plan"
					element={
						<PrivateRoute>
							<PlanState>
								<WeeklyDetail />
							</PlanState>
						</PrivateRoute>
					}
				/>
				<Route
					exact
					path="/tabla-nutricional/:id_athlete/:id_plan"
					element={
						<PrivateRoute>
							<PlanState>
								<DataState>
									<AthleteNutricionalTableCocina />
								</DataState>
							</PlanState>
						</PrivateRoute>
					}
				/>
				
				<Route
					exact
					path="/plan-deportistas/:id_plan/:id_kitchen"
					element={
						<PrivateRoute>
							<PlanState>
								<AthletesPlanCocina />
							</PlanState>
						</PrivateRoute>
					}
				/>

				<Route
					exact
					path="/profile"
					element={
						<PrivateRoute>
							
								<Profile />
							
						</PrivateRoute>
					}
				/>
				<Route
					exact
					path="/editar-perfil"
					element={
						<PrivateRoute>
							
								<EditProfile />
							
						</PrivateRoute>
					}
				/>
				<Route
					exact
					path="/plan-full/:id_plan"
					element={
						<PrivateRoute>
							<PlanState>
							
									<WeeklyPlanFull />
								
							</PlanState>
						</PrivateRoute>
					}
				/>
				<Route
					exact
					path="/deportistas"
					element={
						<PrivateRoute>
							
							<AdminState>
								<DataState>
								<Users />
								</DataState>
							</AdminState>
						
						</PrivateRoute>
					}
				/>
				<Route
					exact
					path="/alta-deportista"
					element={
						<PrivateRoute>
							<DataState>
								<AltaDeportista />
							</DataState>
						</PrivateRoute>
					}
				/>
				
					<Route
					exact
					path="/cantidades/:id_plan/:id_kitchen/:day"
					element={
						<PrivateRoute>
							<PlanState>
								<CantidadesAdmin />
							</PlanState>
						</PrivateRoute>
					}
				/>
				<Route
					exact
					path="/user"
					element={
					
								<UserDetail />
					
					}
				/>
					<Route
				exact
				path="/editar-perfil-deportista/:id_user/:id_athlete"
				element={
					<PrivateRoute>
						<DeportistaState>
							<EditarPerfilDeportista></EditarPerfilDeportista>
						</DeportistaState>
					</PrivateRoute>
				}
			/>
			</Routes>
		</NutricionistaState>
	);
}
function DeportistaRouter() {
	return (
		<DeportistaState>
			<Routes>
			<Route
				exact
				path="/dashboard"
				element={
					<PrivateRoute>
						<DashboardDeportista></DashboardDeportista>
					</PrivateRoute>
				}
			/>
			<Route
				exact
				path="/plan"
				element={
					<PrivateRoute>
						<PlanDeportista></PlanDeportista>
					</PrivateRoute>
				}
			/>
			<Route
				exact
				path="/profile"
				element={
					<PrivateRoute>
						<PerfilDeportista></PerfilDeportista>
					</PrivateRoute>
				}
			/>
			<Route
				exact
				path="/editar-perfil"
				element={
					<PrivateRoute>
						<EditarPerfilDeportista></EditarPerfilDeportista>
					</PrivateRoute>
				}
			/>
			<Route
				exact
				path="/plan-semanal"
				element={
					<PrivateRoute>
						<PlanSemanalDeportista></PlanSemanalDeportista>
					</PrivateRoute>
				}
			/>
			<Route
				exact
				path="/plan-detalle/:id_plan"
				element={
					<PrivateRoute>
						<PlanState>
							<PlanSemanalDetalleDeportista></PlanSemanalDetalleDeportista>
						</PlanState>
					</PrivateRoute>
				}
			/>
			<Route
				exact
				path="/plan-full/:id_plan"
				element={
					<PrivateRoute>
						<PlanState>
							<PlanSemanalFullDeportista></PlanSemanalFullDeportista>
						</PlanState>
					</PrivateRoute>
				}
			/>
			</Routes>
		</DeportistaState>
	);
}
function RolRouter() {
	const { user } = useContext(AuthContext);
	return (
		<>
			<Renderif isTrue={user?.id_rol === 1}>
				<AdminRoutes></AdminRoutes>
			</Renderif>
			<Renderif isTrue={user?.id_rol === 2}>
				<NutricionistaRouter></NutricionistaRouter>
			</Renderif>
			<Renderif isTrue={user?.id_rol === 3}>
				<ChefRouter></ChefRouter>
			</Renderif>
			<Renderif isTrue={user?.id_rol === 4}>
				<DeportistaRouter></DeportistaRouter>
			</Renderif>
		</>
	);
}

export default App;
