/* eslint-disable no-unused-vars */
import React, {useState, useContext } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import HeaderDashboard from "../../components/HeaderDashboard";
import GeneralButton from "../../components/buttons/GeneralButton";
import { DatePicker, Select } from "antd";
import WeeklyTabs from "./WeeklyTabs";
import PlanContext from "../../context/planContext";
import Renderif from "../../components/Renderif";
import AuthContext from "../../context/authContext";
import { LANG } from "../commun/lang";


const { Option } = Select;

function WeeklyPlan() {


	const {weekplans, kitchen, kitchens, onSelectKitchen, onCreateWeeklyPlan} = useContext(PlanContext)
	const{lang} = useContext(AuthContext)
	

	const [state, setState] = useState({
		start_date:'', 
		end_date:''
	})
	
	return (
		<> 
				<HeaderDashboard />
				<div className="container-fluid p-3">
					<div className="text-white f-30 f-anton text-uppercase ps-1">
						{/* PLAN SEMANAL */}
						{LANG.WEEKPLAN.WEEKPLAN[lang]}
					</div>
				</div>

			
					
				<div className="container-fluid px-3 pb-3 d-flex align-items-center">
					<div className="d-flex align-items-center px-1">
						<Select
							key="cocinas"
							value={kitchen}
							style={{ width: 200 }}
							onChange={onSelectKitchen}
						>
							<Option value="0">{LANG.WEEKPLAN.ALLKIT[lang]}</Option>
							{kitchens.map((kitchen,i)=>(<Option key={i + 1} value={kitchen.id_kitchen}>{kitchen.kitchen}</Option>))}
					
						</Select>
					</div>

					<Renderif isTrue={kitchen !== '0'}>
						<div className="col d-flex align-items-center px-1">

							<div className="px-2">
								<DatePicker placeholder={LANG.NOT.fecha_inicio[lang]}  format={'YYYY/MM/DD'} onChange={(date)=>{state.start_date = date.format('YYYY-MM-DD')}}></DatePicker>
							</div>
						
							<div className="px-2">
								<DatePicker placeholder={LANG.NOT.fecha_fin[lang]}  format={'YYYY/MM/DD'} onChange={(date)=>{state.end_date = date.format('YYYY-MM-DD')}}></DatePicker>
							</div>
						
							
							<div className="col"></div>
							<GeneralButton
								className="btn__blue btn__general"
								title={LANG.NOT.crear_plan_semana[lang]}
								icon={<AiOutlinePlus />}
								onClick={() =>{

									onCreateWeeklyPlan({start_date:state.start_date, end_date:state.end_date})
									
									
								}}
							/>
						</div>
					</Renderif>
				</div>

				<WeeklyTabs weekplans={weekplans} kitchen={kitchen} />
		
		</>
	);
}





export default WeeklyPlan;
