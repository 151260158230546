

import { useEffect, useState } from "react";
import AuthContext from "../context/authContext"
import { Service } from "../services/api";


const AuthState = ({children}) => {



    const [state, setState] = useState({
        user:null,
        lang:localStorage.getItem('lang'),
        loading:false
    });
    
    useEffect(()=>{
        // localStorage.setItem("lang", state.lang);
        let _user = localStorage.getItem("user");
        let user = JSON.parse(_user)    
        if(_user){
            state.user = user
        }
      
        setState({...state})


      
    },[])




    const setUser = (user) => {
        setState({...state, user})
    }
    const updateUser = (user) => {
        setState({...state, user})
        localStorage.setItem("user", JSON.stringify(user));
    }

    const getUser = async () => {
        let _user = await Service("GET", `user/${state.user.id_user}`)
        console.log(_user);
        setState({...state, user:_user.data.response})
    }

    const changeLang = ({lang}) => {
       

       
        localStorage.setItem("lang", lang);
        setState({...state, loading:false, lang})

        // setTimeout(()=>{
        //     setState({...state, loading:false})
        // },3000)
        
     
    }


    return(
        <AuthContext.Provider value={{
            ...state,
            setUser,
            updateUser,
            auth: state.user,
            getUser,
            changeLang
        }}>
            {!state.loading? children : <></>}
            {/* {children} */}
        </AuthContext.Provider>
    )
}
export default AuthState;