/* eslint-disable no-unused-vars */
import { FaTrashAlt } from "react-icons/fa";
import React, { useState, useContext } from "react";
import { FiEdit } from "react-icons/fi";
import { TbLock } from "react-icons/tb";
import { HiDotsVertical } from "react-icons/hi";
import { Space, Popover } from "antd";
import { urlService, enviroment } from "../../services/UrlService";
import "../../assets/css/Users/users.css";
import DataContext from "../../context/dataContext";
import { useNavigate } from "react-router-dom";
import { colors } from "../colors";
import { BiDish, BiUser } from "react-icons/bi";
import AdminContext from "../../context/adminContext";
import AlertContext from "../../context/alertContext";
import { Service } from "../../services/api";
import Renderif from "../../components/Renderif";
import AuthContext from "../../context/authContext";
import { LANG } from "../../pages/commun/lang";

function ColumnsUser({user}) {
	console.log(user);


	const navigate = useNavigate();
	
	const {
		setDrawerSpecialist,
		setDrawerAddGuest,
	} = useContext(DataContext);

	const {onSelectUser, onRemoveUser} = useContext(AdminContext)
	const {showAlert} = useContext(AlertContext)
	const {auth, lang} = useContext(AuthContext)
	

	const deleteUser = () => {
		
		showAlert({type:'confirmation', message:LANG.NOT.eliminar_plan[lang], description: LANG.NOT.dar_de_baja[lang] + ' ' + user.firstname + ' ?', onConfirm:async ()=>{
	
			onRemoveUser({user})
			

			
		}})
	
	};

	const addSpecialist = () => {
		setDrawerSpecialist(true);
		onSelectUser({user});
	};

	const addGuest = () => {
		setDrawerAddGuest(true);
	};


	return (
		<>
			<div className="UserMenuToolTip">

				<Renderif isTrue={user.id_rol === 4}>
					<Renderif isTrue={auth.id_rol != 2}>
					<div className="item" onClick={() => addGuest()}>
						
						{LANG.NOT.agregar_invitados[lang]}
					</div>
					</Renderif>
					<Renderif isTrue={auth.id_rol != 2}>
					<div className="item" onClick={() => addSpecialist()}>
					{LANG.NOT.asignar_nutricionista[lang]}
					</div>
					</Renderif>
		
				</Renderif>

				<div className="item" onClick={() => deleteUser()}>
					{LANG.NOT.eliminar_usuario[lang]}
				</div>
			</div>
		</>
	);
}


export default ColumnsUser;

export const DeportistaColumns = () => {return [
	{
		title: <div className="text-left f-anton">{LANG.DASHBOARD.ATHLETE[localStorage.getItem('lang')]}</div>,		
		render: (item) => (
			<>
				<div className="d-flex align-items-start cursor-pointer">
					<BiUser className="pt-1" color={colors.mint} size={18}></BiUser>
					<div className="ps-1">
						<div className="text-left text-white f-14 f-Poppins-Medium">{`${item?.firstname} ${item?.lastname}`}</div>
						<div className="text-left f-12 f-Poppins-Bold text-mint-shade">{LANG.NOT[item?.fedding][localStorage.getItem('lang')]}</div>
					</div>
				</div>
			</>
		)
	},
	{
		title: <div className="text-center f-anton">{LANG.USERS.SERVICE[localStorage.getItem('lang')]}</div>,
		render: (item) => ( <div className="text-center text-white f-Poppins-Regular">{item.service}</div>)
	},
	{
		title: <div className="text-center f-anton">{LANG.DASHBOARD.NUTRICIONIST[localStorage.getItem('lang')]}</div>,
		render: (item) => (<div className="text-center text-white f-Poppins-Regular">{item?.id_nutricionist ? item.nutricionista?.firstname : (<div className="text-mint-shade">
			{LANG.PLANDETAIL.sin_asignar[localStorage.getItem('lang')]}
		</div>)}</div>)
	},
	{
		title: <div className="text-center f-anton">{LANG.USERS.GUESTS[localStorage.getItem('lang')]}</div>,
		render: (item) => (
			
				<div className="bg-mint d-table mx-auto rounded-3">
					<div className="text-dark-blue f-12 f-Poppins-Bold px-2 py-1 line-1">{item?.invitados}</div>
				</div>
		
		)
	},
	{
		title: <div className="text-center f-anton">{LANG.DASHBOARD.KITCHEN[localStorage.getItem('lang')]}</div>,
		render: (item) => (
			<div className="text-center text-white f-Poppins-Regular">{item?.id_kitchen ? item.cocina.kitchen : (<div className="text-mint-shade">
				{LANG.PLANDETAIL.sin_asignar[localStorage.getItem('lang')]}
			</div>)}</div>
		)
	},
	{
		title:<></>,
		render: (item) => (
		
				<div className="d-flex" onClick={(e)=>{
					e.stopPropagation();
				}}>
					<Popover
						zIndex={1}
						placement="bottomLeft"
						content={<ColumnsUser user={item} />}
						trigger="click"
					>
						<div className="cursor-pointer"><HiDotsVertical color={colors.mint} size={25}/></div>
					</Popover>
				</div>
	
		)
	},
]};
export const DeportistaColumnsNutricionista = () => {return [
	{
		title: <div className="text-left f-anton">{LANG.DASHBOARD.ATHLETE[localStorage.getItem('lang')]}</div>,		
		render: (item) => (
			<>
				<div className="d-flex align-items-start cursor-pointer">
					<BiUser className="pt-1" color={colors.mint} size={18}></BiUser>
					<div className="ps-1">
						<div className="text-left text-white f-14 f-Poppins-Medium">{`${item?.firstname} ${item?.lastname}`}</div>
						
						<div className="text-left f-12 f-Poppins-Bold text-mint-shade">{LANG.NOT[item?.fedding][localStorage.getItem('lang')]}</div>
					</div>
				</div>
			</>
		)
	},
	// {
	// 	title: <div className="text-center f-anton">Servicio</div>,
	// 	render: (item) => ( <div className="text-center text-white f-Poppins-Regular">{item.service}</div>)
	// },
	// {
	// 	title: <div className="text-center f-anton">Nutricionista</div>,
	// 	render: (item) => (<div className="text-center text-white f-Poppins-Regular">{item?.id_nutricionist ? item.nutricionista?.firstname : (<div className="text-mint-shade">Sin Asignar</div>)}</div>)
	// },
	// {
	// 	title: <div className="text-center f-anton">Invitados</div>,
	// 	render: (item) => (
			
	// 			<div className="bg-mint d-table mx-auto rounded-3">
	// 				<div className="text-dark-blue f-12 f-Poppins-Bold px-2 py-1 line-1">{item?.invitados}</div>
	// 			</div>
		
	// 	)
	// },
	// {
	// 	title: <div className="text-center f-anton">Cocina</div>,
	// 	render: (item) => (
	// 		<div className="text-center text-white f-Poppins-Regular">{item?.id_kitchen ? item.cocina.kitchen : (<div className="text-mint-shade">Sin Asignar</div>)}</div>
	// 	)
	// },
	{
		title:<></>,
		render: (item) => (
		
				<div className="d-flex" onClick={(e)=>{
					e.stopPropagation();
				}}>
					<div className="col"></div>
					<div className="" style={{width:50}}>
						<Popover
							zIndex={1}
							placement="bottomLeft"
							content={<ColumnsUser user={item} />}
							trigger="click"
						>
							<div className="cursor-pointer"><HiDotsVertical color={colors.mint} size={25}/></div>
						</Popover>
					</div>
				</div>
	
		)
	},
]};
export const NutricionistaColumns = () => {return [
	{
		title: <div className="text-left f-anton">{LANG.DASHBOARD.NUTRICIONIST[localStorage.getItem('lang')]}</div>,
		render: (row) => (
	
				<div className="d-flex align-items-start">
					<BiUser className="pt-1" color={colors.mint} size={18}></BiUser>
					<div className="ps-1">
						<div className="text-left text-white f-14 f-Poppins-Medium">{`${row?.firstname} ${row?.lastname}`}</div>
						<div className="text-left f-12 f-Poppins-Bold text-mint-shade">{LANG.NOT[row?.fedding][localStorage.getItem('lang')]}</div>
					</div>
				</div>
		
		),
	},

	{
		title: <div className="text-center f-anton">{LANG.NTABLE.email[localStorage.getItem('lang')]}</div>,
		render: (row) => <div className="text-center text-white f-Poppins-Regular">{row?.email}</div>,
	},
	{
		title: <div className="text-center f-anton">{LANG.NTABLE.telefono[localStorage.getItem('lang')]}</div>,
		render: (row) => <div className="text-center text-white f-Poppins-Regular">{row?.phone}</div>,
	},
	{
		title: <div className="text-center f-anton">{LANG.REGISTER.Gender[localStorage.getItem('lang')]}</div>,
		render: (row) => <div className="text-center text-white f-Poppins-Regular">{row?.gender}</div>,
	},
	{
		title: <div className="text-center f-anton">{LANG.PROFILE.LOCATION[localStorage.getItem('lang')]}</div>,
		render: (row) => <div className="text-center text-white f-Poppins-Regular">{row?.city} {row?.state}  {row?.cp} {row?.country}</div>,
	},
	{
		title: <></>,
		render: (row) => (
			<>
				<div className="d-flex">
					<Popover
						zIndex={1}
						placement="bottomLeft"
						content={<ColumnsUser user={row} />}
						trigger="click"
					>
						<div className="cursor-pointer"><HiDotsVertical color={colors.mint} size={25}/></div>
					</Popover>
				</div>
			</>
		),
	},
]};
export const CocinaColums = () => { return  [
	{
		title: <div className="text-left f-anton">{LANG.DASHBOARD.KITCHEN[localStorage.getItem('lang')]}</div>,
		render: (row, item) => (
			<div className="d-flex align-items-start">
				<BiDish className="pt-1" color={colors.mint} size={18}></BiDish>
				<div className="ps-1">
					<div className="text-left f-14 text-white f-Poppins-Medium">{`${row?.kitchen}`}</div>
				</div>
			</div>
		),
	},

	{
		title: <div className="text-left f-anton">{LANG.PROFILE.LOCATION[localStorage.getItem('lang')]}</div>,
		render: (row) => <div className="text-left text-white f-Poppins-Regular">{row?.city} {row?.country}</div>,
	},
	{
		title: <div className="text-center f-anton">Status</div>,
		render: (row) => <div className="text-center text-white f-Poppins-Regular">{row?.active !== 0 ? (<div className="badge bg-mint text-dark-blue">{LANG.NOT.Active[localStorage.getItem('lang')]}</div>) :  (<div className="badge bg-red text-dark-blue">{LANG.NOT.Inactivo[localStorage.getItem('lang')]}</div>) }</div>,
	},
	{
		title: <div className="text-text-center f-anton">{LANG.DASHBOARD.ATHLETES[localStorage.getItem('lang')]}</div>,
		render: (row) => <div className="text-center text-white f-Poppins-Regular">{row?.total_athletes}</div>,
	},

	{
		render: (row) => (
		
				<div className="d-flex">
					<Popover
						zIndex={1}
						placement="bottomLeft"
						content={<ColumnsUser user={row} />}
						trigger="click"
					>
						<div className="cursor-pointer"><HiDotsVertical color={colors.mint} size={25}/></div>
					</Popover>
				</div>
			
		),
	},
]};
export const ChefColumns = [
	{
		title: <div className="text-left f-anton">Cocina</div>,
		render: (row, i) => (
			<div className="d-flex align-items-start">
				<BiUser className="pt-1" color={colors.mint} size={18}></BiUser>
				<div className="ps-1">
					<div className="text-left text-white f-14 f-Poppins-Medium">{`${row?.firstname} ${row?.lastname}`}</div>
				</div>
			</div>
		),
	},

	{
		title: <div className="text-center f-anton">Email</div>,
		render: (row) => <div className="text-center text-white f-Poppins-Regular">{row?.email}</div>,
	},
	{
		render: (id, row) => (
			<>
				<div className="d-flex">
					{/* <Popover
						zIndex={1}
						placement="bottomLeft"
						content={<ColumnsUser id={id} />}
						trigger="click"
					>
						<div className="cursor-pointer"><HiDotsVertical color={colors.mint} size={25}/></div>
					</Popover> */}
				</div>
			</>
		),
	},
];


