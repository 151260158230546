/* eslint-disable no-unused-vars */
import React, { useEffect, useContext } from "react";
import "../../assets/css/dashboard.css";
import { useNavigate } from "react-router-dom";

import HeaderDashboard from "../../components/HeaderDashboard";

import AuthContext from "../../context/authContext";
import DashboardWidget from "../../components/DashboardWidget";
import NutricionistaContext from "../../context/nutricionistaContext";
import {
	PlanSemanal
} from "./PlanSemanalNutricionist";
import { LANG } from "../commun/lang";


function DashboardNutricionist() {
	let navigate = useNavigate();

	const { user, lang } = useContext(AuthContext);
	const {athletes} = useContext(NutricionistaContext);


	return (
		<>
			<HeaderDashboard />
			<div className="container-fluid p-3">
				<div className="text-white f-30 f-anton text-uppercase ps-1">
					Dashboard
				</div>
			</div>

			<div className="container-fluid px-3 pb-3">
				<div className="d-flex flex-wrap">

					<div className="col-12 col-md-4 px-0 py-1 py-md-0 pe-md-2">
						<DashboardWidget data={{
							title:LANG.DASHBOARD.ATHLETES[lang],
							img:'../assets/images/icon_deportistas_2.png',
							value:athletes.length,
						}}/>
					</div>
					<div className="col-12 col-md-4 px-0 py-1 py-md-0 pe-md-2">
						<DashboardWidget data={{
							title:LANG.DASHBOARD.PREPARACION[lang],
							img:'../assets/images/icon_preparacion.png',
							value:0,
						}}/>
					</div>
					<div className="col-12 col-md-4 px-0 py-1 py-md-0">
						<DashboardWidget data={{
							title:LANG.DASHBOARD.ENVIADOS[lang],
							img:'../assets/images/icon_enviados.png',
							value:0,
							action:()=>{
								navigate('/envios')
							}
						}}/>
					</div>
				</div>
			</div>
			{/* <PlanSemanal></PlanSemanal> */}
			
		</>
	);
}

export default DashboardNutricionist;
