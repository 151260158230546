/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useContext } from "react";

import HeaderDashboard from "../../components/HeaderDashboard";
import { FaFireAlt } from "react-icons/fa";

import { AiOutlineCalendar } from "react-icons/ai";
import { colors } from "../../utilities/colors";
import { useNavigate } from "react-router-dom";
import { Service } from "../../services/api";
import AuthContext from "../../context/authContext";

import { IoMdPeople } from "react-icons/io";
import moment from "moment";

import DeportistaContext from "../../context/deportistaContext";
import { LANG } from "../commun/lang";




export function PlanSemanalDeportista() {

	const { user, lang } = useContext(AuthContext);
	return (
		<>
			<HeaderDashboard />
			<div className="container-fluid p-3">
				<div className="text-white f-30 f-anton text-uppercase ps-1">
					{LANG.WEEKPLAN.WEEKPLAN[lang]}
				</div>
			</div>
			<PlanSemanal></PlanSemanal>
		</>
	);
}
export function PlanSemanal() {
	const navigate = useNavigate();

	const { user, lang } = useContext(AuthContext);
	const {weekPlans,kitchens} = useContext(DeportistaContext);
	



	const RenderStatus = ({status}) => {


		switch (status) {
			case 0:
				return <div className="status_tab f-Poppins-Medium status_pendient">{LANG.DASHBOARD.KITCHEN[lang]}</div>
			case 1:
				return <div className="status_tab f-Poppins-Medium status_yellow">{LANG.PLANDETAIL.enviar_a_cocina[lang]}</div>
			case 2:
				return <div className="status_tab f-Poppins-Medium status_purple">Revisar</div>
			case 3:
				return <div className="status_tab f-Poppins-Medium status_green">{LANG.DASHBOARD.COMPLETE[lang]}</div>
			default:
				return <></>
		}

	}

	return (
		
		<div className="container-fluid px-3">
			<div className="overflow-auto hide-scroll  ">
				<table className="ant-table w-100 bg-dark-blue rounded-2 overflow-hidden">
					<thead className="ant-table-thead">
						<tr>
							<th className="col"><div className="text-left f-anton f-14">{LANG.DASHBOARD.KITCHEN[lang]}</div></th>
							<th className="col"><div className="text-left f-anton f-14">{LANG.DASHBOARD.WEEK[lang]}</div></th>
							{/* <th className="col"><div className="text-center f-anton f-14">Deportistas</div></th> */}
							<th className="col"><div className="text-center f-anton f-14">Status</div></th>
							
						</tr>
					</thead>
					<tbody className="ant-table-tbody">
							{weekPlans.map((item,i)=>{
								return (
									<tr className="cta-light" key={i} onClick={()=>{
										if(item.status !== 0){
											navigate(`/plan-detalle/${item.id}`)
										}
									}}>
										<td className="">
											<div className="d-flex align-items-center cursor-pointer">
												<div className="pe-3">
													<FaFireAlt color={colors.mint} size={22} />
												</div>
												<div>
													<div className="f-Poppins-Medium text-white">
														{kitchens.find((kitchen)=>kitchen.id_kitchen === item.id_kitchen).kitchen}
													</div>
													<div className="f-Poppins-Regular text-mint-shade">
														{}
													</div>
												</div>
											</div>
										</td>
										<td>
											<div className="d-flex align-items-center ">
												<div className="pe-3">
													<AiOutlineCalendar color={colors.mint} size={22} />
												</div>
												<div className="">
													<div className="f-Poppins-Meidum text-white">{`${moment(item?.start_date).format('D MMM')} - ${moment(item?.end_date).format('D MMM')}`}</div>
												</div>
											</div>
										</td>
										{/* <td>

											<div className="d-flex justify-content-center">
												<div className="cta cta-outline" onClick={(e)=>{
													e.stopPropagation();
													navigate(`/plan-deportistas/${item.id}/${item.id_kitchen}`)
													
												}}>
													<div className="icon">
														<IoMdPeople color={colors.white} size={16}></IoMdPeople>
													</div>
													<div className="text-white f-12 f-anton line-1">Ver deportistas</div>
												</div>
											</div>


										</td> */}
										<td>
											<RenderStatus status={item.status}></RenderStatus>
										</td>
									</tr>
								)

							})}
					</tbody>
				</table>
			</div>
		</div>
	
	);
}
export function PlanNumbers(props) {
	const navigate = useNavigate();

	return (
		<>
			<div
				className="users_tab bg-mint text-dark-blue cursor-pointer"
				onClick={(event) => {
					
					navigate(
						`/plan-deportistas?id=${props.id}&kitcheID=${props.kitchenID}`
					);
					event.stopPropagation();
				}}
			>
				{props.number}
			</div>
		</>
	);
}

