import React, {useContext } from "react";
import "../../assets/css/dashboard.css";
import "../../assets/css/antdCustom.css";
import "../../assets/css/generals.css";
import "antd/dist/antd.css";

import { Collapse } from "antd";

import PlanContext from "../../context/planContext";

import RecipeThumbnail from "../../components/RecipeThumbnail";
import AlertContext from "../../context/alertContext";
import { LANG } from "../commun/lang";
import AuthContext from "../../context/authContext";

const { Panel } = Collapse;

function WeeklyColapse() {

	const {extraRecipes,onAssignWeelPlanRecipe,getRecipeByID} = useContext(PlanContext)
	const {showAlert} = useContext(AlertContext)
	const {lang} = useContext(AuthContext)
	return (
		<>
			<div className="moreRecipes bg-dark-blue mb-20">
				<div className="titleHeader text-white text-center bg-blue-shade text-uppercase">
					{/* Más recetas */}
					{LANG.WEEKPLAN.MORERE[lang]}
				</div>
	
				<Collapse accordion defaultActiveKey={["1"]}>

					<Panel header={LANG.WEEKPLAN.HIDR[lang]} key="1">
						
						{extraRecipes?.hidratos.map((recipe, i)=>{
							return (
								<div key={i} className="d-flex align-items-center transition border-b-blue-shade py-3 p-1 categoryContent" onClick={()=>{
									
									onAssignWeelPlanRecipe({recipe})
								}}>

									<RecipeThumbnail recipe={recipe}></RecipeThumbnail>
									
									<div className="col" >
										<div className="f-12 f-Poppins-Medium text-white">
											{getRecipeByID(recipe)?.descr}
										</div>
									</div>
									
								</div>
							)
						})}
						

					
					</Panel>

					<Panel header={LANG.WEEKPLAN.PROT[lang]}key="2">


						{extraRecipes?.proteinas.map((recipe, i)=>{
							return (
								<div key={i} className="d-flex align-items-center transition border-b-blue-shade py-3 p-1 categoryContent" onClick={()=>{
									onAssignWeelPlanRecipe({recipe})
								}}>
									<RecipeThumbnail recipe={recipe}></RecipeThumbnail>
									<div className="col">
										<div className="f-12 f-Poppins-Medium text-white">
											{getRecipeByID(recipe)?.descr}
										</div>
									</div>
								
								</div>
							)
						})}
					
					</Panel>
			
					<Panel header={LANG.WEEKPLAN.OTHER[lang]} key="3">
						{extraRecipes?.otros.map((recipe, i)=>{
							return (
								<div key={i} className="d-flex align-items-center transition border-b-blue-shade py-3 p-1 categoryContent" onClick={()=>{
									onAssignWeelPlanRecipe({recipe})
								}}>
									<RecipeThumbnail recipe={recipe}></RecipeThumbnail>
									<div className="col">
										<div className="f-12 f-Poppins-Medium text-white">
										{getRecipeByID(recipe)?.descr}
										</div>
									</div>
									
								</div>
							)
						})}
					</Panel>
				</Collapse>
			</div>
		</>
	);
}
export default WeeklyColapse;
