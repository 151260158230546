/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import "../../assets/css/login.css";
import logo from "../../assets/images/logos/SF_Logo_2.png";
import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/icomoon/eye";
import { eyeBlocked } from "react-icons-kit/icomoon/eyeBlocked";
import { Link, Redirect, Navigate, useNavigate } from "react-router-dom";
import DataContext from "../../context/dataContext";
import { Popover } from "antd";
import AuthContext from "../../context/authContext";
import { LANG } from "../commun/lang";
import { MdLanguage } from "react-icons/md";
import { colors } from "../../utilities/colors";


function LoginForm() {

	const {user, setUser, lang} = useContext(AuthContext)

	const [type, setType] = useState("password");
	const [icon, setIcon] = useState(eyeBlocked);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loginError, setLoginError] = useState(false);
	const [emailError, setEmailError] = useState("");
	const [passwordError, setpasswordError] = useState("");
	const [errorTitle, setErrorTitle] = useState(LANG.LOGIN.credenciales_incorrectas[lang]);
	const [errorMessage, setErrorMessage] = useState(
		LANG.LOGIN.verifica_credenciales[lang]
	);

	const { setDisplaySiderBar, postData } = useContext(DataContext);

	const navigate = useNavigate();
	const showPassword = () => {
		if (type === "password") {
			setIcon(eye);
			setType("text");
		} else {
			setIcon(eyeBlocked);
			setType("password");
		}
	};

	const handleChange = (event) => {
		//setPassword(event.target.value);
		if (event.target.name === "email") {
			setEmail(event.target.value);
			setEmailError("");
		}

		if (event.target.name === "password") {
			setPassword(event.target.value);
			setpasswordError("");
		}
	};

	const emailValidation = () => {
		const regex =
			/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
		if (!email || regex.test(email) === false) {
			
			setEmailError(LANG.NOT.escribe_correo_valido[lang]);
			return false;
		} else {
			setEmailError("");
		}
		return true;
	};

	const passValidation = () => {
		if (!password) {
			
			setpasswordError("Introduce el password");
			return false;
		} else {
			setpasswordError("");
		}
		return true;
	};

	const loggin = (event) => {
		event.preventDefault();

		if (emailValidation() && passValidation()) {
			const userLogin = {
				email: email,
				password: password,
			};
			

			postData("users/login", userLogin).then((login) => {
				
		
				// let dataInfo = [];
				// if (result.data) {
				// 	dataInfo = {
				// 		message: result.data.message,
				// 		status: result.data.status,
				// 		rol: result.data.rol,
				// 		fullname: result.data.fullname,
				// 	};
				// } else {
				// 	dataInfo = {
				// 		message: result.message,
				// 		status: result.status,
				// 		rol: result.rol,
				// 		fullname: result.fullname,
				// 	};
				// }

				if (login.status === 200) {



					//localStorage.setItem("setLogin", "true");
					localStorage.setItem("user", JSON.stringify(login.data.response));
					setUser(login.data.response)
					navigate("/dashboard");



					// setLoginError(false);
					// if (dataInfo.rol === 1 || dataInfo.rol === 3) {

					// 	localStorage.setItem("setLogin", "true");
					// 	localStorage.setItem("userName", dataInfo.fullname);

					// 	if (dataInfo.rol === 1) {
					// 		localStorage.setItem("userRol", "Administador");
					// 	} else {
					// 		localStorage.setItem("userRol", "Capturista");
					// 	}

					// 	navigate("/dashboard");
					// } else {
					// 	setErrorTitle("No tines permisos para acceder");
					// 	setErrorMessage(
					// 		"Ponte en contacto con el administrador web"
					// 	);
					// 	setLoginError(true);
					// }


					
				} else if (login.status === 500) {
					setErrorTitle(LANG.LOGIN.credenciales_incorrectas[lang]);
					setErrorMessage(LANG.LOGIN.verifica_credenciales[lang]);
					setLoginError(true);
				} else {
					setLoginError(true);
					setErrorTitle(LANG.LOGIN.credenciales_incorrectas[lang]);
					setErrorMessage(LANG.LOGIN.verifica_credenciales[lang]);
					setLoginError(true);
				}
			});
		}
	};
	
	return (
		<div className="block_form">
				{/* <div className="ps-3  d-flex justify-content-center align-items-center">
					<MdLanguage color={colors.mint} size={18}></MdLanguage>
					<Popover
		
						zIndex={99}
						placement="bottomRight"
						content={<LangOptions></LangOptions>}
						trigger="click"
					>
						<div className="cursor-pointer text-white f-14 f-Poppins-Bold ps-1">{lang}</div>
					</Popover>
				</div> */}
			<div className="logo">
				<img src='../assets/logos/logo-miobio.png' alt="synci-logo"></img>
			</div>

			<div className="form_container">
				
				{loginError ? (
					<>
						<div className="errorMessage bg-gray text-white">
							<div className="errorData text-center">
								<div className="info">
									<div className="message">
										{errorTitle}
									</div>
									<div className="actions">
										{errorMessage}
									</div>
								</div>
							</div>
						</div>
					</>
				) : (
					""
				)}

				<form
					className="mb__form_login"
					autoComplete="off"
					onSubmit={loggin}
				>
					<div className="display-6 f-anton text-white my-3 text-uppercase">
						{LANG.LOGIN.ACCOUNT[lang]}
						{/* INGRESA A TU CUENTA */}
					</div>
					
					<div className="text-mint-shade f-Poppins-Medium my-1">
						{LANG.LOGIN.EMAIL[lang]}
						{/* Correo Electrónico */}
					</div>
					<input
						className="input"
						type="text"
						name="email"
						placeholder=""
						value={email}
						onChange={handleChange}
					/>
					<span className="f_error">{emailError}</span>
					<div className="text-mint-shade f-Poppins-Medium my-1">
						{LANG.LOGIN.PASSWORD[lang]}
					</div>
					<div className="password-block">
						<input
							className="input input-ouline"
							type={type}
							name="password"
							placeholder=""
							value={password}
							onChange={handleChange}
						/>
						<span onClick={showPassword}>
							<Icon icon={icon} size="22" />
						</span>
					</div>


					<span className="f_error">{passwordError}</span>

					<div className="text-right">
						{/* <a href="#pass" className="text-mint-shade f-Poppins-Medium transition">{LANG.LOGIN.FORGOT[lang]}{" "}</a> */}
					</div>
					
					<input
						type="submit"
						value={LANG.LOGIN.SIGNIN[lang]}
						className="input my-3 transition"
					/>

					<div className="d-flex justify-content-center align-items-center position-relative">

						<div className="bg-white border-top w-100 position-absolute"></div>
						
						<div className="f-Poppins-Medium bg-dark-blue text-white px-2 z-index-1 ">{LANG.LOGIN.NOACCOUNT[lang]}</div>
						
						

					</div>

					
					
				</form>
				<input
					onClick={()=>{navigate('/registro')}}
						type="submit"
						value={LANG.LOGIN.REGISTER[lang]}
						className="input input-outline my-3 transition"
					/>
			</div>
		
		</div>
		
	);
}


export default LoginForm;
