import React, { useContext } from 'react'
import HeaderDashboard from '../../components/HeaderDashboard'
import {FaCcPaypal} from 'react-icons/fa'
import { colors } from '../../utilities/colors'
import GeneralButton from '../../components/buttons/GeneralButton'
import { IoMdAdd, IoMdImage } from 'react-icons/io'
import { BiEdit } from 'react-icons/bi'
import DeportistaContext from '../../context/deportistaContext'
import { useNavigate } from 'react-router-dom'
import { useFilePicker } from 'use-file-picker'
import Renderif from '../../components/Renderif'
import { LANG } from '../commun/lang'
import AuthContext from '../../context/authContext'


export default function PerfilDeportista() {
    const {athlete} = useContext(DeportistaContext);
    const {lang} = useContext(AuthContext)

  let navigate = useNavigate()
 
  return (
    <>
        <HeaderDashboard />
        <div className="mb__center_content">
            
            <div className="section_title text-white mb__section_title text-uppercase">
                {LANG.PROFILE.PROFILE[lang]}
            </div>


            <div className='mb__center_content'>

                <div className='text-white bg-dark-blue rounded'>

                    <div className='d-flex p-3'>
                        <div className='col'></div>
                        {/* <GeneralButton
                            className="btn__blue btn__general mr-10"
                            title="Tabla nutricional"
                            icon={<BiEdit />}
                            onClick={() => {}}
                        /> */}
                        <GeneralButton
                            className="btn__blue btn__general"
                            title={LANG.PROFILE.EDIT[lang]}
                            icon={<BiEdit />}
                            onClick={() => {
                                navigate(`/editar-perfil`)
                            }}
                        />
                        
                    </div>
                 
                    <div className=' bg-mint-light'>
                        <div className='border-b-mint d-flex p-3'>
                            <div className='bg-yellow p-1 rounded-3 position-relative'>

                              
                               

                                <img style={{width:100}} className='rounded-3 h-100' src={
                                   
                                    athlete?.picture ? process.env.REACT_APP_API_URL+'/images/'+athlete?.picture : process.env.REACT_APP_API_URL + '/images/profile.png' 
                                    
                                    }></img>
                             
                                

                            </div>
                            <div className='col ps-2'>
                                <div className='f-20 f-Poppins-Bold text-mint-shade '>{athlete?.firstname + " " +athlete?.lastname}</div>
                                <div className='f-14 f-Poppins-Regular text-mint-shade'>{athlete?.id_suscription == 1 ? 'PLAN PRO' : 'PLAN ELITE'} - {LANG.NOT.mensual[lang]}</div>
                            </div>
                        </div>

                        <div className='d-flex border-b-mint p-3'>
                            <div className=' col f-Poppins-Medium text-mint-shade'>Club</div>
                            <div className=' col f-Poppins-Medium text-mint-shade'>{athlete?.club}</div>
                        </div>
                        <div className='d-flex border-b-mint p-3'>
                            <div className=' col f-Poppins-Medium text-mint-shade'>{LANG.REGISTER.Ciudad[lang]}</div>
                            <div className=' col f-Poppins-Medium text-mint-shade'>{athlete?.city}, {athlete?.state}</div>
                        </div>
                        <div className='d-flex border-b-mint p-3'>
                            <div className=' col f-Poppins-Medium text-mint-shade'>{LANG.REGISTER.País[lang]}</div>
                            <div className=' col f-Poppins-Medium text-mint-shade'>{athlete?.country}</div>
                        </div>
                        <div className='d-flex border-b-mint p-3'>
                            <div className=' col f-Poppins-Medium text-mint-shade'>Email</div>
                            <div className=' col f-Poppins-Medium text-mint-shade'>{athlete?.email}.</div>
                        </div>

                    </div>

                
                    <div className=' bg-secundary-dark-blue py-2 px-3'>
                        <div className=' f-20 f-anton text-white text-uppercase'>{LANG.REGISTER.GDATA[lang]}</div>
                    </div>
                    <div className='border-b-blue-shade d-flex p-3'>
                        <div className=' col f-Poppins-Medium text-white'>{LANG.REGISTER.Gender[lang]}</div>
                        <div className=' col f-Poppins-Medium text-white'>{athlete?.gender}</div>
                    </div>
                    <div className='border-b-blue-shade d-flex p-3'>
                        <div className=' col f-Poppins-Medium text-white'>{LANG.REGISTER.Telefono[lang]}:</div>
                        <div className=' col f-Poppins-Medium text-white'>{athlete?.phone}</div>
                    </div>
                    <div className='border-b-blue-shade d-flex p-3'>
                        <div className=' col f-Poppins-Medium text-white'>{LANG.NOT.direccion[lang]}:</div>
                        <div className=' col f-Poppins-Medium text-white'>{athlete?.delevery_address}</div>
                    </div>
                    <div className=' bg-secundary-dark-blue py-2 px-3'>
                        <div className=' f-20 f-anton text-white'>{LANG.REGISTER.preferencias[lang]}</div>
                    </div>
                    <div className='border-b-blue-shade d-flex p-3'>
                        <div className=' col f-Poppins-Medium text-white'>{LANG.PLANDETAIL.alergias_intolerancias[lang]}:</div>
                        <div className=' col f-Poppins-Medium text-white'>{athlete?.allergy}</div>
                    </div>
                    <div className='border-b-blue-shade d-flex p-3'>
                        <div className=' col f-Poppins-Medium text-white'>{LANG.REGISTER.preferencias_alimentos[lang]}:</div>
                        <div className=' col f-Poppins-Medium text-white'>{athlete?.delevery_occur}</div>
                    </div>
                    <div className='border-b-blue-shade d-flex p-3'>
                        <div className=' col f-Poppins-Medium text-white'>{LANG.REGISTER.filosofia_alimentaria[lang]}:</div>
                        <div className=' col f-Poppins-Medium text-white'>{LANG.NOT[athlete?.fedding][lang]}</div>
                    </div>
                    <div className='border-b-blue-shade d-flex p-3'>
                        <div className=' col f-Poppins-Medium text-white'>{LANG.REGISTER.objetivo[lang]}:</div>
                        <div className=' col f-Poppins-Medium text-white'>{athlete?.objetivo}</div>
                    </div>
                    <div className='border-b-blue-shade d-flex p-3'>
                        <div className=' col f-Poppins-Medium text-white'>{LANG.REGISTER.peso[lang]}:</div>
                        <div className=' col f-Poppins-Medium text-white'>{athlete?.peso}</div>
                    </div>
                    <div className='border-b-blue-shade d-flex p-3'>
                        <div className=' col f-Poppins-Medium text-white'>{LANG.REGISTER.estatura[lang]}:</div>
                        <div className=' col f-Poppins-Medium text-white'>{athlete?.estatura}</div>
                    </div>
                    <div className='border-b-blue-shade d-flex p-3'>
                        <div className=' col f-Poppins-Medium text-white'>{LANG.REGISTER.imc[lang]}:</div>
                        <div className=' col f-Poppins-Medium text-white'>{athlete?.imc}</div>
                    </div>
                 
                    <div className=' bg-secundary-dark-blue py-2 px-3'>
                        <div className=' f-20 f-anton text-white'>{LANG.PLANDETAIL.servicio[lang]}</div>
                    </div>
                    <div className='border-b-blue-shade d-flex p-3'>
                        <div className=' col f-Poppins-Medium text-white'>{LANG.REGISTER.plan[lang]}</div>
                        <div className=' col f-Poppins-Medium text-white'>{athlete?.id_suscription == 1 ? 'PLAN PRO' : 'PLAN ELITE'}</div>
                    </div>
                    <div className='border-b-blue-shade d-flex p-3'>
                        <div className=' col f-Poppins-Medium text-white'>{LANG.PLANDETAIL.invitados[lang]}</div>
                        <div className=' col f-Poppins-Medium text-white'>{athlete?.invitados}</div>
                    </div>
                    <div className='border-b-blue-shade d-flex p-3'>
                        <div className=' col f-Poppins-Medium text-white'>{LANG.PLANDETAIL.servicio[lang]}</div>
                        <div className=' col f-Poppins-Medium text-white'>{LANG.NOT[athlete?.service][lang]}</div>
                    </div>
                    <div className='border-b-blue-shade d-flex p-3'>
                        <div className=' col f-Poppins-Medium text-white'>{LANG.NOT.hora[lang]}</div>
                        <div className=' col f-Poppins-Medium text-white'>{athlete?.delevery_time}</div>
                    </div>

            

                </div>

            </div>
          
            
        
        </div>
    </>
  )
}
